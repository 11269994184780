/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/redux/store.ts
 * @author Jesse Zonneveld
 * @description Redux store
 */

/* --------------------------------- IMPORTS -------------------------------- */

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { errorSlice } from './slices/ErrorSlice';
import { userSlice } from './slices/UserSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

/* -------------------------------------------------------------------------- */

/* -------------------------------- REDUCERS -------------------------------- */

const reducers = combineReducers({
    user: userSlice.reducer,
    error: errorSlice.reducer,
});

/* -------------------------------------------------------------------------- */

/* ------------------------- PERSISTANT STATE SETUP ------------------------- */

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

/* -------------------------------------------------------------------------- */

/* ---------------------------------- STORE --------------------------------- */

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

let persistor = persistStore(store);

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { persistor, store };

/* -------------------------------------------------------------------------- */
