import { Route, Routes, Navigate } from 'react-router-dom';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import { TranscriptsWrapper } from '../pages/custom/transcripts/Transcripts';
import { ApplicationHistoryWrapper } from '../pages/custom/applications/ApplicationHistory';
import { RenewalApplicationsWrapper } from '../pages/custom/applications/renewal/RenewalApplications';
import { RegistrationApplicationsWrapper } from '../pages/custom/applications/registration/RegistrationApplications';
import { RegistrationApplicationWrapper } from '../pages/custom/applications/registration/RegistrationApplication';
import { RenewalApplicationWrapper } from '../pages/custom/applications/renewal/RenewalApplication';
import { RegistrationApplicationStatusWrapper } from '../pages/custom/applications/registration/RegistrationApplicationStatus';
import { RenewalApplicationStatusWrapper } from '../pages/custom/applications/renewal/RenewalApplicationStatus';
import { CertificateLicensureWrapper } from '../pages/custom/certificate-licensure/CertificateLicensure';
import { MyFacilitiesWrapper } from '../pages/custom/facilites/MyFacilities';
import { FacilitySearchWrapper } from '../pages/custom/facilites/FacilitySearch';
import { WalletCardWrapper } from '../pages/custom/wallet-card/WalletCard';
import { AllReceiptsWrapper } from '../pages/custom/receipts/AllReceipts';
import { PersonalReceiptsWrapper } from '../pages/custom/receipts/PersonalReceipts';
import { FacilitiesReceiptsWrapper } from '../pages/custom/receipts/FacilitiesReceipts';
import { FacilityReceiptsWrapper } from '../pages/custom/receipts/FacilityReceipts';
import { AllInvoicesWrapper } from '../pages/custom/invoices/AllInvoices';
import { PersonalInvoicesWrapper } from '../pages/custom/invoices/PersonalInvoices';
import { FacilitiesInvoicesWrapper } from '../pages/custom/invoices/FacilitiesInvoices';
import { FacilityInvoicesWrapper } from '../pages/custom/invoices/FacilityInvoices';
import { FacilitiesAssessmentsWrapper } from '../pages/custom/facilites/facility-assessments/FacilitiesAssessments';
import { FacilityAssessmentsWrapper } from '../pages/custom/facilites/facility-assessments/FacilityAssessments';
import { FacilityAssessmentWrapper } from '../pages/custom/facilites/facility-assessments/FacilityAssessment';
import { ContactInformationWrapper } from '../pages/custom/contact-information/ContactInformation';
import { ChangePasswordWrapper } from '../pages/custom/change-password/ChangePassword';
import { ChangePermissionWrapper } from '../pages/custom/change-permission/ChangePermission';
import { ChangeEmailWrapper } from '../pages/custom/change-email/ChangeEmail';
import { ReceiptWrapper } from '../pages/custom/receipts/Receipt';
import { InvoiceWrapper } from '../pages/custom/invoices/Invoice';
import { PaymentSuccessWrapper } from '../pages/custom/payment/PaymentSuccess';

const PrivateRoutes = () => {
    return (
        <Routes>
            <Route element={<MasterLayout />}>
                {/* Redirect to Dashboard after success login */}
                <Route path='auth/*' element={<Navigate to='/dashboard' />} />
                <Route path='/transcripts' element={<TranscriptsWrapper />} />
                <Route path='/applications/history' element={<ApplicationHistoryWrapper />} />
                <Route path='/applications/renewal' element={<RenewalApplicationsWrapper />} />
                <Route path='/applications/registration' element={<RegistrationApplicationsWrapper />} />
                <Route
                    path='/applications/registration/:pfn_applicationid'
                    element={<RegistrationApplicationWrapper />}
                />
                <Route path='/applications/renewal/:pfn_applicationid' element={<RenewalApplicationWrapper />} />
                <Route
                    path='/applications/registration/status/:pfn_applicationid'
                    element={<RegistrationApplicationStatusWrapper />}
                />
                <Route
                    path='/applications/renewal/status/:pfn_applicationid'
                    element={<RenewalApplicationStatusWrapper />}
                />
                <Route path='/certificate-licensure' element={<CertificateLicensureWrapper />} />
                <Route path='/facilities/my-facilities' element={<MyFacilitiesWrapper />} />
                <Route path='/facilities/search' element={<FacilitySearchWrapper />} />
                <Route path='/facilities/assessments' element={<FacilitiesAssessmentsWrapper />} />
                <Route
                    path='/facilities/assessments/:facilityName/:facilityId'
                    element={<FacilityAssessmentsWrapper />}
                />
                <Route
                    path='/facilities/assessment/:pfn_facilityid/:pfn_facilityassessmentid'
                    element={<FacilityAssessmentWrapper />}
                />
                <Route path='/wallet-card' element={<WalletCardWrapper />} />
                <Route path='/receipts/all' element={<AllReceiptsWrapper />} />
                <Route path='/receipts/personal' element={<PersonalReceiptsWrapper />} />
                <Route path='/receipts/facility' element={<FacilitiesReceiptsWrapper />} />
                <Route path='/receipts/facility/:facilityName/:facilityId' element={<FacilityReceiptsWrapper />} />
                <Route path='/receipts/:pfn_name/:pfn_paymentid' element={<ReceiptWrapper />} />
                <Route path='/invoices/all' element={<AllInvoicesWrapper />} />
                <Route path='/invoices/personal' element={<PersonalInvoicesWrapper />} />
                <Route path='/invoices/facility' element={<FacilitiesInvoicesWrapper />} />
                <Route path='/invoices/facility/:facilityName/:facilityId' element={<FacilityInvoicesWrapper />} />
                <Route path='/invoices/:invoice_name/:pfn_invoiceid' element={<InvoiceWrapper />} />
                <Route path='/payment/success' element={<PaymentSuccessWrapper />} />
                <Route path='/contact-info' element={<ContactInformationWrapper />} />
                <Route path='/change-password' element={<ChangePasswordWrapper />} />
                <Route path='/change-permission' element={<ChangePermissionWrapper />} />
                <Route path='/change-email' element={<ChangeEmailWrapper />} />
                <Route path='dashboard' element={<DashboardWrapper />} />
                <Route path='*' element={<Navigate to='/error/404' />} />
            </Route>
        </Routes>
    );
};

export { PrivateRoutes };
