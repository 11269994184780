/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/modules/auth/components/VerifyAccount.tsx
 * @author Jesse Zonneveld
 * @description Verify account component
 */

/* ----------------------------- IMPORTS & SETUP ---------------------------- */

import { useState, useEffect, FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import userApi from '../../../api/userApi';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

export const VerifyAccount: FC = () => {
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const [isVerified, setIsVerified] = useState<'success' | 'rejected' | 'verifying'>('verifying');
    const [isRedirectReady, setIsRedirectReady] = useState(false);
    const { contactid, pfn_verificationcode } = useParams();

    useEffect(() => {
        const tryToVerifyUser = async () => {
            try {
                setTimeout(async () => {
                    if (contactid && pfn_verificationcode) {
                        const response = await userApi.verifyUser(contactid, pfn_verificationcode);

                        if (response) {
                            if (response.success) {
                                setIsVerified('success');
                                setTimeout(() => {
                                    setIsRedirectReady(true);
                                }, 5000);
                            } else {
                                setIsVerified('rejected');

                                if (response.message) {
                                    setErrMsg(response.message);
                                } else {
                                    setErrMsg('Could not verify user');
                                }
                            }
                        } else {
                            setIsVerified('rejected');
                            setErrMsg('Could not verify user');
                        }
                    }
                }, 4000);
            } catch (err: any) {
                setIsVerified('rejected');
                setErrMsg('Could not verify user');
            }
        };

        tryToVerifyUser();

        return () => {
            setIsVerified('verifying');
            setErrMsg(null);
            setIsRedirectReady(false);
        };
    }, [contactid, pfn_verificationcode]);

    return (
        <div className='w-lg-800px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
            <div className='mb-10 text-center'>
                <h1 className='text-dark mb-3'>Verify Account</h1>
            </div>

            <div className='d-flex align-items-center mb-10'>
                <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                <div className='border-bottom border-gray-300 mw-50 w-100'></div>
            </div>
            {isVerified === 'verifying' ? (
                <>
                    <div
                        className='indicator-progress text-center fs-4 text-gray-400 fw-bold'
                        style={{ display: 'block' }}
                    >
                        Veryfing account, please wait...{' '}
                        <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                    </div>
                </>
            ) : isVerified === 'rejected' ? (
                <>
                    <div className='d-flex align-items-center bg-light-danger rounded p-5 mb-7'>
                        <span className='bullet bullet-vertical h-150px bg-danger me-10'></span>

                        <div className='flex-grow-1 me-2'>
                            <div className='fw-bolder text-gray-800 fs-4 mb-5'>Failed to verify account</div>
                            <span className='text-muted fw-bold d-block fs-4'>
                                <b>Error Message: </b>
                                {errMsg}
                            </span>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className='d-flex align-items-center bg-light-success rounded p-5 mb-7'>
                        <span className='bullet bullet-vertical h-150px bg-success me-10'></span>

                        <div className='flex-grow-1 me-2'>
                            <div className='fw-bolder text-gray-800 fs-4 mb-5'>
                                Account has been successfully verified!
                            </div>
                            <span className='text-muted fw-bold d-block fs-4'>
                                Redirecting to login page, this may take a few seconds...
                            </span>
                            {isRedirectReady ? <Navigate to='/auth' /> : ''}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

/* -------------------------------------------------------------------------- */
