/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/pages/custom/invoices/FacilityInvoices.tsx
 * @author Jesse Zonneveld
 * @description Facility invoices page
 */

/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useEffect, useState } from 'react';
import { PageTitle } from '../../../../_metronic/layout/core';
import Table from 'react-bootstrap/Table';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Invoice } from '../../../types';
import invoiceApi from '../../../api/invoiceApi';
import { convertIsoDateToString } from '../../../helpers';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const FacilityInvoicesPage: FC = () => {
    const [invoices, setInvoices] = useState<Invoice[]>();
    const [loading, setLoading] = useState(true);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const { facilityId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const getInvoicesAsync = async () => {
            try {
                if (facilityId) {
                    const response = await invoiceApi.getFacilityInvoices(facilityId);

                    if (response) {
                        if (!response.success) {
                            if (response.message) {
                                setErrMsg(response.message);
                            } else {
                                setErrMsg('An error occured, please try again later');
                            }
                        } else {
                            if (response.data) {
                                setInvoices(response.data);
                            } else {
                                setErrMsg('No data found');
                            }
                        }
                    } else {
                        setErrMsg('An error has occurred, please try again later');
                    }
                }
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
            }
            setLoading(false);
        };

        getInvoicesAsync();

        return () => {
            setErrMsg(null);
            setLoading(false);
            setInvoices([]);
        };
    }, [facilityId]);

    const CreateTable = () => {
        return (
            <div className='table-container mb-15'>
                <Table className='invoices-table' striped bordered>
                    <thead>
                        <tr>
                            <th className='fw-bolder'>Date</th>
                            <th className='fw-bolder'>Type</th>
                            <th className='fw-bolder'>Total Amount</th>
                            <th className='fw-bolder'>Outstanding Amount</th>
                            <th className='fw-bolder'>Invoice Payment</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoices?.map((invoice, i) => {
                            return (
                                <tr key={i}>
                                    <td className='date'>{convertIsoDateToString(invoice?.createdon)}</td>
                                    <td className='type'>{invoice._pfn_accountid_value ? 'Facility' : 'Personal'}</td>
                                    <td className='amount'>{invoice.pfn_total_Formatted}</td>
                                    <td className='outstanding'>{invoice.pfn_outstanding_Formatted}</td>
                                    <td className='action'>
                                        <Link to={`/invoices/${invoice.pfn_name}/${invoice.pfn_invoiceid}`}>
                                            <button type='button' className='btn btn-sm btn-primary my-1'>
                                                View / Pay
                                            </button>
                                        </Link>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        );
    };

    return (
        <>
            <p>View and pay your invoice(s) for fees paid to CVBC.</p>
            <div className='mb-10'>
                <div className='mb-10'>
                    {loading ? (
                        <p>Retreiviing invoices, please wait...</p>
                    ) : errMsg ? (
                        <p>{errMsg}</p>
                    ) : invoices && invoices?.length >= 1 ? (
                        CreateTable()
                    ) : (
                        <p>No invoices found.</p>
                    )}
                </div>
            </div>
            <div className='d-flex justify-content-center no-print'>
                <Link to='/invoices/all' className='me-5'>
                    <button className='btn btn-primary'>All Invoices</button>
                </Link>

                <button className='btn btn-primary' onClick={() => navigate(-1)}>
                    Back
                </button>
            </div>

            {/* <div className='container shadow-none p-10 pb-6 bg-light rounded'>
                <div className='pb-3'>
                    <span>
                        <b>Note: </b>
                    </span>
                    <span>The following fees are not tax deductible</span>
                </div>
                <ul>
                    <li>Application fees</li>
                    <li>Reinstatement fees</li>
                    <li>Criminal Record Check fees</li>
                </ul>
            </div> */}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* ------------------------------ PAGE WRAPPER ------------------------------ */

const FacilityInvoicesWrapper: FC = () => {
    const { facilityName } = useParams();
    const title = `${facilityName}: Invoices`;

    return (
        <>
            <PageTitle breadcrumbs={[]}>{title}</PageTitle>
            <FacilityInvoicesPage />
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { FacilityInvoicesWrapper };

/* -------------------------------------------------------------------------- */
