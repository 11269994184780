/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
// import {Link} from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../helpers';

const QuickLinks: FC = () => (
    <div className='menu menu-sub menu-sub-dropdown menu-column w-450px w-lg-500px' data-kt-menu='true'>
        <div
            className='d-flex flex-column flex-center bgi-no-repeat bgi-size-cover rounded-top px-9 py-10'
            style={{ backgroundImage: `url('${toAbsoluteUrl('/media/patterns/pattern-1.jpg')}')` }}
        >
            <h3 className='text-white fw-bold mb-3'>Quick Links</h3>

            <span className='badge bg-primary py-2 px-3'>cvbc</span>
        </div>

        <div className='row g-0'>
            <div className='col-6'>
                <a
                    href={`${process.env.REACT_APP_MAIN_WEBSITE_URL}/public-protection`}
                    target='_blank'
                    rel='noreferrer'
                    className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom'
                >
                    <KTSVG
                        path='/media/icons/duotune/general/gen051.svg'
                        className='svg-icon-3x svg-icon-primary mb-2'
                    />
                    <span className='fs-5 fw-bold text-gray-800 mb-0'>Public Protection</span>
                    <span className='fs-7 text-gray-400'>Regulations</span>
                </a>
            </div>

            <div className='col-6'>
                <a
                    href={`${process.env.REACT_APP_MAIN_WEBSITE_URL}/news`}
                    target='_blank'
                    rel='noreferrer'
                    className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-bottom'
                >
                    <KTSVG path='/media/icons/duotune/files/fil002.svg' className='svg-icon-3x svg-icon-primary mb-2' />
                    <span className='fs-5 fw-bold text-gray-800 mb-0'>News & Updates</span>
                    <span className='fs-7 text-gray-400'>Stay Informed</span>
                </a>
            </div>

            <div className='col-6'>
                <a
                    href={`${process.env.REACT_APP_MAIN_WEBSITE_URL}/news/blog/`}
                    target='_blank'
                    rel='noreferrer'
                    className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end'
                >
                    <KTSVG
                        path='/media/icons/duotune/communication/com007.svg'
                        className='svg-icon-3x svg-icon-primary mb-2'
                    />
                    <span className='fs-5 fw-bold text-gray-800 mb-0'>Blog</span>
                    <span className='fs-7 text-gray-400'>See What's New</span>
                </a>
            </div>

            <div className='col-6'>
                <a
                    href={`${process.env.REACT_APP_MAIN_WEBSITE_URL}/useful-links`}
                    target='_blank'
                    rel='noreferrer'
                    className='d-flex flex-column flex-center h-100 p-6 bg-hover-light'
                >
                    <KTSVG
                        path='/media/icons/duotune/abstract/abs022.svg'
                        className='svg-icon-3x svg-icon-primary mb-2'
                    />
                    <span className='fs-5 fw-bold text-gray-800 mb-0'>Useful Links</span>
                    <span className='fs-7 text-gray-400'>Guidelines</span>
                </a>
            </div>
        </div>

        <div className='py-2 text-center border-top'>
            <a
                href={process.env.REACT_APP_MAIN_WEBSITE_URL}
                target='_blank'
                rel='noreferrer'
                className='btn btn-color-gray-600 btn-active-color-primary'
            >
                cvbc.ca <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
            </a>
        </div>
    </div>
);

export { QuickLinks };
