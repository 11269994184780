/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/helpers.ts
 * @author Jesse Zonneveld
 * @description Helper functions
 */

/* --------------------------------- IMPORTS -------------------------------- */

import { Modal } from 'bootstrap';

/* -------------------------------------------------------------------------- */

/* --------------------------------- HELPER --------------------------------- */

const closeAllModals = () => {
    const openModals: HTMLCollectionOf<Element> = document.getElementsByClassName('modal show');
    for (let i = 0; i < openModals.length; i++) {
        const element = openModals[i];
        const modal = Modal.getInstance(element);
        if (modal) {
            modal.hide();
        }
    }
};

const closeModalById = (modalName: string) => {
    const myModalEl: Element = document.getElementById(modalName) as Element;
    const modal = Modal.getInstance(myModalEl);
    if (modal) {
        modal.hide();
    }
};

const convertIsoDateToString = (isoDate: string | null | undefined) => {
    if (!isoDate) {
        return '---';
    }

    try {
        const date = new Date(isoDate).toDateString();
        const dateWithNoWeekDay = date.split(' ');
        dateWithNoWeekDay.shift();
        return `${dateWithNoWeekDay[0]} ${dateWithNoWeekDay[1]}, ${dateWithNoWeekDay[2]}`;
    } catch (err) {
        return '---';
    }
};

const convertFileToBase64 = (file: File) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        // reader.onload = () => resolve(reader.result);
        reader.onload = () =>
            resolve(typeof reader.result === 'string' ? reader.result.replace(/data:.*base64,/, '') : reader.result);
        reader.onerror = (error) => reject(error);
    });

const convertPhoneNumberToHyphens = (phoneNumber: string | null | undefined) => {
    if (!phoneNumber) {
        return '';
    }

    let convertedPhoneNumber = '';

    for (var i = 0; i < phoneNumber.length; i++) {
        const char = phoneNumber.charAt(i);
        if (!(char === '(' || char === ')' || char === ' ' || char === '-')) {
            convertedPhoneNumber += char;

            if (convertedPhoneNumber.length === 3 || convertedPhoneNumber.length === 7) {
                convertedPhoneNumber += '-';
            }
        }
    }

    return convertedPhoneNumber;
};

// const convertPhoneNumberNoHyphens = (str: string) => {
//     return str.replace(/-/g, '');
// };

const removeEmpty = (obj: Object) => {
    return Object.fromEntries(Object.entries(obj).filter(([k, v]) => v !== null && v !== undefined && v !== ''));
};

const convertToSafeString = (unsafeString: string | null) => {
    if (!unsafeString) {
        return null;
    }
    return encodeURIComponent(unsafeString);
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export {
    closeAllModals,
    closeModalById,
    convertIsoDateToString,
    convertFileToBase64,
    convertPhoneNumberToHyphens,
    // convertPhoneNumberNoHyphens,
    removeEmpty,
    convertToSafeString,
};

/* -------------------------------------------------------------------------- */
