/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/api/paymentApi.ts
 * @author Jesse Zonneveld
 * @description Payment api
 */

/* --------------------------------- LINTING -------------------------------- */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

/* -------------------------------------------------------------------------- */

/* --------------------------------- IMPORTS -------------------------------- */

import { ApiResponse, Payment, StripePaymentIntent } from '../types';
import { apiClient } from './config';

/* -------------------------------------------------------------------------- */

/* ----------------------------------- API ---------------------------------- */

const createPaymentIntent = async (invoiceId: string, total: string): Promise<ApiResponse<StripePaymentIntent>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<StripePaymentIntent>>('/payments/create-payment-intent', {
            invoiceId,
            total,
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getMonerisPreloadResponse = async (invoiceId: string, total: number): Promise<ApiResponse<string>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<string>>('/payments/moneris-preload', {
            invoiceId,
            total,
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const handleMonerisReceipt = async (userId: string, ticket: string): Promise<ApiResponse<Payment>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<Payment>>('/payments/moneris-receipt', { userId, ticket });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

/* -------------------------------------------------------------------------- */

/* ------------------------------- API OBJECT ------------------------------- */

const paymentApi = {
    createPaymentIntent,
    getMonerisPreloadResponse,
    handleMonerisReceipt,
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export default paymentApi;

/* -------------------------------------------------------------------------- */
