/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/modules/auth/components/ForgotPassword.tsx
 * @author Jesse Zonneveld
 * @description Forgot password component
 */

/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import userApi from '../../../api/userApi';

/* ----------------------------- INITIAL VALUES ----------------------------- */

const initialValues = {
    pfn_username: '',
};

/* -------------------------------------------------------------------------- */

/* ------------------------------- FORM SCHEMA ------------------------------ */

const schema = Yup.object().shape({
    pfn_username: Yup.string().email('Wrong email format').required('Email is required'),
});

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

export const ForgotPassword: FC = () => {
    const [loading, setLoading] = useState(false);
    const [verificationEmailSent, setVerificationEmailSent] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const [verificationResponseMsg, setVerificationResponseMsg] = useState<string | null>(null);
    const [successfulForgotPasswordCodeSet, setSuccessfulForgotPasswordCodeSet] = useState(false);
    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        onSubmit: async (values) => {
            try {
                setVerificationEmailSent(false);
                setVerificationResponseMsg(null);
                setLoading(true);
                setErrMsg(null);
                const response = await userApi.forgotPassword(values.pfn_username);
                if (response) {
                    if (response.success) {
                        setSuccessfulForgotPasswordCodeSet(true);
                    } else {
                        if (response.message) {
                            setErrMsg(response.message);
                        } else {
                            setErrMsg('Could not send forgot password link, please try again later');
                        }
                    }
                } else {
                    setErrMsg('Could not send forgot password link, please try again later');
                }
                setLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    if (err.message) {
                        setErrMsg(err.message);
                    } else {
                        setErrMsg('Could not send forgot password link, please try again later');
                    }
                } else {
                    setErrMsg('Could not send forgot password link, please try again later');
                }
                setLoading(false);
            }
        },
    });

    const resendVerificationEmail = async () => {
        try {
            setVerificationResponseMsg('Sending, please wait...');
            setVerificationEmailSent(true);
            const response = await userApi.resendVerificationEmail(formik.values.pfn_username);

            if (response) {
                if (response.success) {
                    setVerificationResponseMsg('Sent, please check your email.');
                } else {
                    if (response.message) {
                        setVerificationResponseMsg(response.message);
                    } else {
                        setVerificationResponseMsg('Error trying to send verification email');
                    }
                }
            } else {
                setVerificationResponseMsg('Error trying to send verification email.');
            }
        } catch (err) {
            setVerificationResponseMsg('Error trying to send verification email.');
        }
    };

    return (
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
            {successfulForgotPasswordCodeSet ? (
                <>
                    <div className='text-center mb-3'>
                        <h1 className='text-dark mb-3'>Forgot Password?</h1>
                        <div className='d-flex align-items-center my-10'>
                            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                        </div>
                        <div className='text-gray-400 fw-bold fs-4'>Enter your email to reset your password</div>
                    </div>
                    <div className='mb-10 alert alert-success'>
                        <div className='alert-text font-weight-bold'>
                            A password reset link has been successfully sent. Please check your email for further
                            instructions
                        </div>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <Link to='/auth/login'>
                            <button
                                type='button'
                                id='kt_login_password_reset_form_reset_button'
                                className='btn btn-lg btn-primary fw-bolder'
                                onClick={() => {
                                    formik.resetForm();
                                    setSuccessfulForgotPasswordCodeSet(false);
                                }}
                            >
                                Back
                            </button>
                        </Link>
                    </div>
                </>
            ) : (
                <>
                    <form
                        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                        noValidate
                        id='kt_login_password_reset_form'
                        onSubmit={formik.handleSubmit}
                    >
                        <div className='text-center mb-3'>
                            <h1 className='text-dark mb-3'>Forgot Password?</h1>
                            <div className='d-flex align-items-center my-10'>
                                <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                                <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                            </div>
                            <div className='text-gray-400 fw-bold fs-4'>Enter your email to reset your password</div>
                        </div>

                        {/* Begin Form Row */}
                        <div className='fv-row'>
                            <input
                                type='email'
                                placeholder='your.email@cvbc.ca'
                                autoComplete='off'
                                {...formik.getFieldProps('pfn_username')}
                                className={clsx('form-control form-control-lg form-control-solid', {
                                    'is-invalid': formik.touched.pfn_username && formik.errors.pfn_username,
                                })}
                            />
                            {formik.touched.pfn_username && formik.errors.pfn_username && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert' className='text-danger'>
                                            {formik.errors.pfn_username}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* End Form Row */}
                        {/* Begin Form Row */}
                        <div className='fv-row mb-10 mt-1 px-1'>
                            {errMsg && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block text-center'>
                                        <span role='alert' className='text-danger'>
                                            {errMsg}.
                                        </span>
                                        <span>
                                            {errMsg === 'User is not verified' ? (
                                                verificationEmailSent ? (
                                                    <span className=' ms-2 text-primary'>
                                                        {verificationResponseMsg}
                                                    </span>
                                                ) : (
                                                    <span
                                                        className='btn-link link-primary cursor-pointer hover ms-2'
                                                        onClick={resendVerificationEmail}
                                                    >
                                                        Re-send verification email?
                                                    </span>
                                                )
                                            ) : (
                                                ''
                                            )}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* End Form Row */}
                        {/* Begin Form Row */}
                        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                            <button
                                type='submit'
                                id='kt_password_reset_submit'
                                className='btn btn-lg btn-primary fw-bolder me-4'
                                disabled={formik.isSubmitting || loading}
                            >
                                {!loading && <span className='indicator-label'>Submit</span>}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                            <Link to='/auth/login'>
                                <button
                                    type='button'
                                    id='kt_login_password_reset_form_cancel_button'
                                    className='btn btn-lg btn-light-primary fw-bolder'
                                >
                                    Cancel
                                </button>
                            </Link>
                        </div>
                        {/* End Form Row */}
                    </form>
                </>
            )}
        </div>
    );
};

/* -------------------------------------------------------------------------- */
