/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/api/authApi.ts
 * @author Jesse Zonneveld
 * @description Auth api
 */

/* --------------------------------- LINTING -------------------------------- */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

/* -------------------------------------------------------------------------- */

/* --------------------------------- IMPORTS -------------------------------- */

import { UserState } from '../redux/slices/UserSlice';
import { ApiResponse } from '../types';
import { apiClient } from './config';

/* -------------------------------------------------------------------------- */

/* ----------------------------------- API ---------------------------------- */

const loginUser = async (pfn_username: string, pfn_password: string) => {
    try {
        const { data } = await apiClient.post<ApiResponse<UserState>>('/auth/login', {
            pfn_username,
            pfn_password,
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const refreshAccessToken = async () => {
    try {
        const { data } = await apiClient.post<ApiResponse<UserState>>('/auth/refresh');
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const logoutUser = async () => {
    const { data } = await apiClient.delete<ApiResponse<null>>('/auth/logout');
    return data;
};

const logoutUserAll = async () => {
    const { data } = await apiClient.delete<ApiResponse<null>>('/auth/logout-all');
    return data;
};

const checkAuthorization = async () => {
    const { data } = await apiClient.get<ApiResponse<null>>('/auth/check-authorization');
    return data;
};

/* -------------------------------------------------------------------------- */

/* ------------------------------- API OBJECT ------------------------------- */

const authApi = { loginUser, refreshAccessToken, logoutUser, logoutUserAll, checkAuthorization };

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export default authApi;

/* -------------------------------------------------------------------------- */
