/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { KTSVG } from '../../../helpers';
import { useAppSelector } from '../../../../app/redux/hooks';
import { Link } from 'react-router-dom';
import { DropdownCustom7 } from '../../content/dropdown/DropdownCustom7';

type Props = {
    className: string;
};

const ListsWidgetCustom5: React.FC<Props> = ({ className }) => {
    const user = useAppSelector((state) => state.user);

    return (
        <div className='card card-xl-stretch mb-5 mb-xl-8'>
            {/* begin::Header */}
            <div className='card-header border-0'>
                <h3 className='card-title fw-bolder text-dark'>Primary Facility</h3>
                <div className='card-toolbar'>
                    {/* begin::Menu */}
                    <button
                        type='button'
                        className='btn btn-sm btn-icon btn-color-primary btn-active-light'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                    >
                        <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                    </button>
                    <DropdownCustom7 />
                    {/* end::Menu */}
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body pt-0'>
                <div className='note-card d-flex align-items-center bg-light rounded p-5 mb-5'>
                    <div className='note-card-inner flex-grow-1 me-2'>
                        <div className='facility-card-inner flex-grow-1 me-2'>
                            {user.facilities.primary.name ? (
                                <>
                                    <div className='fw-bolder text-gray-800 fs-6 mb-3'>
                                        <span>{user.facilities.primary.name}</span>
                                    </div>
                                    {user.facilities.primary.designatedVetId === user.id && (
                                        <div className='fw-bold text-primary mb-5'>
                                            <div className='d-flex align-items-center'>
                                                <i className='text-primary bi bi-person-fill fs-2 me-2'></i>
                                                <span>You are the designated veterinarian</span>
                                            </div>
                                        </div>
                                    )}
                                    <span className='text-muted fw-bold d-block'>
                                        {user.facilities.primary.address.line1}
                                    </span>
                                    <span className='text-muted fw-bold d-block'>
                                        {user.facilities.primary.address.line2}
                                    </span>
                                    <div>
                                        <span className='text-muted fw-bold'>
                                            {user.facilities.primary.address.city}
                                        </span>
                                        <span className='text-muted fw-bold'>, BC</span>
                                    </div>
                                    <span className='text-muted fw-bold d-block'>
                                        {user.facilities.primary.address.postalCode}
                                    </span>
                                    <span className='text-muted fw-bold d-block'>Canada</span>
                                    <span className='text-muted fw-bold d-block'>
                                        Tel: {user.facilities.primary.telephone}
                                    </span>
                                </>
                            ) : (
                                <div>
                                    You do not currently have a primary facility. Please add a facility using the{' '}
                                    <Link className='text-decoration-underline fw-bold' to={'/facilities/search'}>
                                        Facility Search
                                    </Link>{' '}
                                    or select 'Set as primary' on one of your current facilities listed in{' '}
                                    <Link
                                        className='text-decoration-underline fw-bold'
                                        to={'/facilities/my-facilities'}
                                    >
                                        Your Facilities
                                    </Link>
                                    .
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div>
                    <Link to='/facilities/my-facilities' className='btn btn-lg btn-primary fw-bolder me-5 my-2'>
                        My Facilities
                    </Link>
                    <Link to='/facilities/search' className='btn btn-lg btn-primary fw-bolder my-2'>
                        Search Facilities
                    </Link>
                </div>
            </div>
            {/* end::Body */}
        </div>
    );
};

export { ListsWidgetCustom5 };
