/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/pages/custom/facilities/components/FacilitySearchResult.tsx
 * @author Jesse Zonneveld
 * @description Facility search result component
 */

/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useState } from 'react';
import { Facility } from '../../../../types';
import { addFacility } from '../../../../redux/slices/UserSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

interface Props {
    facility: Facility;
}

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const FacilitySearchResult: FC<Props> = ({ facility }) => {
    const user = useAppSelector((state) => state.user);
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const dispatch = useAppDispatch();

    const convertPhoneNumberToHyphens = (phoneNumber: string | null) => {
        if (!phoneNumber) {
            return '';
        }

        let convertedPhoneNumber = '';

        for (var i = 0; i < phoneNumber.length; i++) {
            const char = phoneNumber.charAt(i);
            if (!(char === '(' || char === ')' || char === ' ' || char === '-')) {
                convertedPhoneNumber += char;

                if (convertedPhoneNumber.length === 3 || convertedPhoneNumber.length === 7) {
                    convertedPhoneNumber += '-';
                }
            }
        }

        return convertedPhoneNumber;
    };

    const handleAddClick = async (accountId: string) => {
        try {
            setErrMsg(null);
            setLoading(true);
            const response = await dispatch(addFacility(accountId));
            if (response) {
                if (!response.payload?.success) {
                    if (response.payload?.message) {
                        setErrMsg(response.payload.message);
                    } else {
                        setErrMsg('An error has occured, please try again later');
                    }
                }
            } else {
                setErrMsg('An error has occured, please try again later');
            }
            setLoading(false);
        } catch (err) {
            if (err instanceof Error) {
                setErrMsg(err.message);
            } else {
                setErrMsg('An error has occurred, please try again later');
            }
            setLoading(false);
        }
    };

    return (
        <>
            {errMsg ? (
                <div>{errMsg}</div>
            ) : (
                <tr>
                    <td className='name'>{facility.name}</td>
                    <td className='old-name'>{facility.pfn_oldname}</td>
                    <td className='city'>{facility.address1_city}</td>
                    <td className='contact'>{facility._pfn_contactid_value_Formatted}</td>
                    <td className='phone'>{convertPhoneNumberToHyphens(facility.telephone1)}</td>
                    <td className='pe-3'>
                        <button
                            className='btn btn-sm btn-primary'
                            disabled={
                                loading ||
                                user.facilities.primary.id === facility.accountid ||
                                user.facilities.other.some((f) => f.id === facility.accountid)
                            }
                            style={{ minWidth: '60px' }}
                            onClick={() => handleAddClick(facility.accountid)}
                        >
                            {!loading && (
                                <span className='indicator-label'>
                                    {user.facilities.primary.id === facility.accountid ||
                                    user.facilities.other.some((f) => f.id === facility.accountid) ? (
                                        <i className='bi bi-check-circle fs-3 ms-1'></i>
                                    ) : (
                                        'Add'
                                    )}
                                </span>
                            )}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    <span className='spinner-border spinner-border-sm align-middle'></span>
                                </span>
                            )}
                        </button>
                    </td>
                </tr>
            )}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { FacilitySearchResult };

/* -------------------------------------------------------------------------- */
