/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/api/transcriptApi.ts
 * @author Jesse Zonneveld
 * @description Transcript api
 */

/* --------------------------------- LINTING -------------------------------- */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

/* -------------------------------------------------------------------------- */

/* --------------------------------- IMPORTS -------------------------------- */

import {
    AddCourseFormDetails,
    ApiResponse,
    Course,
    UpdateCourseFormConvertedDetails,
    Transcript,
    UploadDocFormConvertedDetails,
} from '../types';
import { apiClient } from './config';

/* -------------------------------------------------------------------------- */

/* ----------------------------------- API ---------------------------------- */

const getTranscriptById = async (transcriptId: string): Promise<ApiResponse<Transcript>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<Transcript>>('/transcripts/transcript', { transcriptId });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getTranscriptsByUserId = async (): Promise<ApiResponse<Transcript[]>> => {
    try {
        const { data } = await apiClient.get<ApiResponse<Transcript[]>>('/transcripts/my-transcripts');
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const addCourse = async (pfn_cetranscriptid: string, details: AddCourseFormDetails) => {
    try {
        const { data } = await apiClient.post<ApiResponse<Course>>('/transcripts/add-course', {
            pfn_cetranscriptid,
            ...details,
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const editCourse = async (pfn_cecourseid: string, details: UpdateCourseFormConvertedDetails) => {
    try {
        const { data } = await apiClient.put<ApiResponse<Course>>('/transcripts/update-course', {
            ...details,
            pfn_cecourseid,
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const deleteCourse = async (pfn_cecourseid: string) => {
    try {
        const { data } = await apiClient.put<ApiResponse<Course>>('/transcripts/delete-course', {
            pfn_cecourseid,
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const uploadDocuments = async (details: UploadDocFormConvertedDetails) => {
    try {
        const { data } = await apiClient.post<ApiResponse<Course>>('/transcripts/upload-documents', details);
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const deleteDocument = async (pfn_cecourseid: string, annotationid: string) => {
    try {
        const { data } = await apiClient.put<ApiResponse<Course>>('/transcripts/delete-document', {
            pfn_cecourseid,
            annotationid,
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

/* -------------------------------------------------------------------------- */

/* ------------------------------- API OBJECT ------------------------------- */

const transcriptApi = {
    getTranscriptById,
    getTranscriptsByUserId,
    addCourse,
    editCourse,
    deleteCourse,
    uploadDocuments,
    deleteDocument,
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export default transcriptApi;

/* -------------------------------------------------------------------------- */
