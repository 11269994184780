export const SpecialMonthValues = {
    DO_NOT_RECALL: {
        value: '13',
        title: 'Do Not Recall'
    },
    PRESENT: {
        value: '14',
        title: 'Present'
    },
};

export const SpecialYearValues = {
    PRESENT: {
        value: 'present',
        title: 'Present'
    },
};
