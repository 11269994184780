/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/pages/custom/applications/renewal/formController/forms/InitialDetailsForm.tsx
 * @author Jesse Zonneveld
 * @description Application form first step
 */

/* --------------------------------- IMPORTS -------------------------------- */

import * as Yup from 'yup';
import { FC, useState } from 'react';
import { useFormik } from 'formik';
import applicationApi from '../../../../../../api/applicationApi';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../../../redux/hooks';
import { Table } from 'react-bootstrap';
import { Application } from '../../../../../../types';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

interface Props {
    goToNextStep: Function;
    application: Application;
}

/* -------------------------------------------------------------------------- */

/* ----------------------------- INITIAL VALUES ----------------------------- */

const initialValues = {
    question1: '',
    question2: '',
    question3: '',
    question4: '',
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- SCHEMA --------------------------------- */

const validationSchema = Yup.object().shape({
    question1: Yup.string().required('This question is required.'),
    // .matches(/^Yes$/, 'Please update this information now and return to this form once updated.'),
    question2: Yup.string().required('This question is required.'),
    // .matches(/^Yes$/, 'Please update this information now and return to this form once updated.'),
    question3: Yup.string().required('This question is required.'),
    // .matches(/^Yes$/, 'Please update this information now and return to this form once updated.'),
    question4: Yup.string().required('This question is required.'),
    // .matches(/^Yes$/, 'Please reach out to the Registrar’s office to provide an explanation.'),
});

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const InitialDetailsForm: FC<Props> = ({ goToNextStep, application }) => {
    const user = useAppSelector((state) => state.user);
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                setErrMsg(null);
                setLoading(true);

                if (user.statusHistory.category.value && user.statusHistory.subcategory.value) {
                    const response = await applicationApi.updateRenewalAppInitialDetails({
                        pfn_applicationid: application.pfn_applicationid,
                        question1: values.question1 === 'Yes',
                        question2: values.question2 === 'Yes',
                        question3: values.question3 === 'Yes',
                        question4: values.question4 === 'Yes',
                    });

                    if (response) {
                        if (!response.success) {
                            if (response.message) {
                                setErrMsg(response.message);
                            } else {
                                setErrMsg('An error has occured, please try again later');
                            }
                        } else {
                            navigate(`/applications/renewal/status/${response.data?.pfn_applicationid}`);
                        }
                    } else {
                        setErrMsg('An error has occured, please try again later');
                    }
                } else {
                    setErrMsg('No category and/or subcategory found on user');
                }

                setLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
                setLoading(false);
            }
        },
    });

    return (
        <>
            <div>
                <div className='card card-xl mb-5 mb-xl-8'>
                    <div className='card-header border-0'>
                        <h3 className='card-title fw-bolder text-dark'>General Information</h3>
                    </div>

                    <div className='card-body pt-0'>
                        <ul>
                            <li className='mb-5'>
                                Please review the following information about your personal contact information,
                                facility information, and CE reported to-date for the 2023-2024 cycle, then proceed to
                                the questions/declarations at the bottom of the page.
                            </li>
                            <li className='mb-5'>
                                Please note that <b>“Preferred Identifier”</b> and <b>“Preferred Name”</b> fields are
                                new and are not required. <b>“Preferred Identifier”</b> is intended for internal use
                                only, to assist CVBC staff in addressing you as you prefer to be addressed. This
                                information will not be published in the Online Registry. <b>“Preferred Name”</b> allows
                                you to identify a name that you prefer to use informally that differs in any way from
                                the legal name under which you are registered and must officially practice. This field
                                will be published in the Online Registry as a searchable field. By recording a preferred
                                name here, it will enable your clients, colleagues and the CVBC to find you in the
                                Online Registry by either your legal first name, legal last name, or preferred name.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='card card-xl mb-5 mb-xl-8'>
                    <div className='card-header border-0'>
                        <h3 className='card-title fw-bolder text-dark'>Review Contact Information</h3>
                    </div>

                    <div className='card-body pt-0'>
                        <div className='d-flex flex-wrap ms-n3'>
                            <div className='facility-card d-flex align-items-center bg-light rounded p-5 flex-grow-1 m-3'>
                                <div className='facility-card-inner flex-grow-1 me-2'>
                                    <div className='fw-bolder text-gray-800 fs-6 mb-3'>
                                        <span>Home Address</span>
                                    </div>

                                    <span className='text-gray-700 fw-bold d-block'>{user.address.home.line1}</span>
                                    <span className='text-gray-700 fw-bold d-block'>{user.address.home.line2}</span>
                                    <div>
                                        <span className='text-gray-700 fw-bold'>{user.address.home.city}</span>
                                        <span className='text-gray-700 fw-bold'>, {user.address.home.province}</span>
                                    </div>
                                    <span className='text-gray-700 fw-bold d-block'>{user.address.home.country}</span>
                                    <span className='text-gray-700 fw-bold d-block'>
                                        {user.address.home.postalCode}
                                    </span>
                                </div>
                            </div>
                            <div className='facility-card d-flex align-items-center bg-light rounded p-5 flex-grow-1 m-3'>
                                <div className='facility-card-inner flex-grow-1 me-2'>
                                    <div className='fw-bolder text-gray-800 fs-6 mb-3'>
                                        <span>
                                            Mailing Address ({user.address.mailing === 'Home' ? 'Home' : 'Work'})
                                        </span>
                                    </div>
                                    {user.address.mailing === 'Home' ? (
                                        <>
                                            <span className='text-gray-700 fw-bold d-block'>
                                                {user.address.home.line1}
                                            </span>
                                            <span className='text-gray-700 fw-bold d-block'>
                                                {user.address.home.line2}
                                            </span>
                                            <div>
                                                <span className='text-gray-700 fw-bold'>{user.address.home.city}</span>
                                                <span className='text-gray-700 fw-bold'>
                                                    , {user.address.home.province}
                                                </span>
                                            </div>
                                            <span className='text-gray-700 fw-bold d-block'>
                                                {user.address.home.country}
                                            </span>
                                            <span className='text-gray-700 fw-bold d-block'>
                                                {user.address.home.postalCode}
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <span className='text-gray-700 fw-bold d-block'>
                                                {user.facilities.primary.address.line1}
                                            </span>
                                            <span className='text-gray-700 fw-bold d-block'>
                                                {user.facilities.primary.address.line2}
                                            </span>
                                            <div>
                                                <span className='text-gray-700 fw-bold'>
                                                    {user.facilities.primary.address.city}
                                                </span>
                                                <span className='text-gray-700 fw-bold'>, BC</span>
                                            </div>
                                            <span className='text-gray-700 fw-bold d-block'>Canada</span>
                                            <span className='text-gray-700 fw-bold d-block'>
                                                {user.facilities.primary.address.postalCode}
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className='facility-card d-flex align-items-center bg-light rounded p-5 flex-grow-1 m-3'>
                                <div className='facility-card-inner flex-grow-1 me-2'>
                                    <div className='fw-bolder text-gray-800 fs-6 mb-5'>
                                        <span>Email Addresses</span>
                                    </div>

                                    <div className='mb-1'>
                                        <span className='fw-bolder text-gray-800'>Main: </span>
                                        <span className='text-gray-700'>{user.email.main}</span>
                                    </div>
                                    <div className='mb-1'>
                                        <span className='fw-bolder text-gray-800'>Personal: </span>
                                        <span className='text-gray-700'>{user.email.personal}</span>
                                    </div>
                                    <div>
                                        <span className='fw-bolder text-gray-800'>Work (optional): </span>
                                        <span className='text-gray-700'>{user.email.work}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='facility-card d-flex align-items-center bg-light rounded p-5 flex-grow-1 m-3'>
                                <div className='facility-card-inner flex-grow-1 me-2'>
                                    <div className='fw-bolder text-gray-800 fs-6 mb-5'>
                                        <span>Phone Numbers</span>
                                    </div>

                                    <div className='mb-1'>
                                        <span className='fw-bolder text-gray-800'>Mobile: </span>
                                        <span className='text-gray-700'>{user.phone.mobile || 'N/A'}</span>
                                    </div>
                                    <div className='mb-1'>
                                        <span className='fw-bolder text-gray-800'>Home: </span>
                                        <span className='text-gray-700'>{user.phone.home || 'N/A'}</span>
                                    </div>
                                    <div>
                                        <span className='fw-bolder text-gray-800'>Business (Primary Faciliy): </span>
                                        <span className='text-gray-700'>{user.phone.business}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='facility-card d-flex align-items-center bg-light rounded p-5 flex-grow-1 m-3'>
                                <div className='facility-card-inner flex-grow-1 me-2'>
                                    <div className='fw-bolder text-gray-800 fs-6 mb-5'>
                                        <span>Personal</span>
                                    </div>

                                    <div className='mb-1'>
                                        <span className='fw-bolder text-gray-800'>
                                            Preferred Identifier (optional):{' '}
                                        </span>
                                        <span className='text-gray-700'>
                                            {user.personal.preferredIdentifier.formatted || 'N/A'}
                                        </span>
                                    </div>
                                    <div className='mb-1'>
                                        <span className='fw-bolder text-gray-800'>Peferred Name (optional): </span>
                                        <span className='text-gray-700'>{user.personal.preferredName || 'N/A'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='p-10 pt-0'>
                        <Link to='/contact-info' className='btn btn-lg btn-primary fw-bolder'>
                            Edit Contact Information
                        </Link>
                    </div>
                </div>
            </div>
            <div>
                <div className='card card-xl mb-5 mb-xl-8'>
                    <div className='card-header border-0'>
                        <h3 className='card-title fw-bolder text-dark'>Review Practice Facilities</h3>
                    </div>

                    <div className='card-body pt-0'>
                        <div className='facility-card d-flex align-items-center bg-light rounded p-5 flex-grow-1'>
                            <div className='facility-card-inner flex-grow-1 me-2'>
                                <div className='mb-5'>
                                    <div className='fw-bolder text-gray-800 fs-6'>
                                        <span>Primary Facility</span>
                                    </div>
                                    <div>{user.facilities.primary.name || 'N/A'}</div>
                                </div>
                                <div>
                                    <div className='fw-bolder text-gray-800 fs-6'>
                                        <span>Other Facilities</span>
                                    </div>
                                    {user.facilities.other.map((facility, i) => {
                                        return <div key={i}>{facility.name}</div>;
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='p-10 pt-0'>
                        <Link to='/facilities/my-facilities' className='btn btn-lg btn-primary fw-bolder'>
                            Edit Practice Facilities
                        </Link>
                    </div>
                </div>
            </div>
            <div>
                <div className='card card-xl mb-5 mb-xl-8'>
                    <div className='card-header border-0'>
                        <h3 className='card-title fw-bolder text-dark'>Review Publication Permissions</h3>
                    </div>

                    <div className='card-body pt-0'>
                        <div className='facility-card d-flex align-items-center bg-light rounded p-5 flex-grow-1'>
                            <div className='facility-card-inner flex-grow-1 me-2'>
                                <div>
                                    The option for a registrant to control how much detail is published about their work
                                    location is meant to control public access to information about work environments
                                    not intended to be accessible to the general public, and also for mobile practice
                                    facilities where the office/business address is a registrant’s private residence and
                                    not intended to be directly accessible to the public. Registrants may choose to
                                    have:
                                    <ul>
                                        <li>
                                            Publication of all business contact information (Business name, phone number
                                            & street address).
                                        </li>
                                        <li>
                                            Publication of partial business information (Business name, phone number,
                                            city, but not the street address).
                                        </li>
                                        <li>
                                            No business information publication – your practice facilities/place of work
                                            will not be posted in your online registry entry.
                                        </li>
                                    </ul>
                                </div>
                                <div className='mt-10'>
                                    <span className='fw-bolder text-gray-800'>
                                        Your Current Publication Permission:{' '}
                                    </span>
                                    <span className='text-gray-700'>{user.permission.formatted || 'N/A'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='p-10 pt-0'>
                        <Link to='/change-permission' className='btn btn-lg btn-primary fw-bolder'>
                            Edit Publication Permission
                        </Link>
                    </div>
                </div>
            </div>
            <div>
                <div className='card card-xl mb-5 mb-xl-8'>
                    <div className='card-header border-0'>
                        <h3 className='card-title fw-bolder text-dark'>
                            Review CE Credits for current CE Cycle ({process.env.REACT_APP_CE_CYCLE})
                        </h3>
                    </div>

                    <div className='card-body pt-0'>
                        <div className='table-container'>
                            <Table className='transcript-courses-renewal-table border' striped bordered>
                                <thead>
                                    <tr>
                                        <th className='fw-bolder'>Start Date</th>
                                        <th className='fw-bolder'>End Date</th>
                                        <th className='fw-bolder'>Title</th>
                                        <th className='fw-bolder'>Credits Submitted</th>
                                        <th className='fw-bolder'>Credits Approved</th>
                                        <th className='fw-bolder'>Status</th>
                                        <th className='doc fw-bolder'>Supporting Documentation</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {user.transcripts[0]?.courses.map((course, i) => (
                                        <tr key={i}>
                                            <td className='date'>{course.startDate.formatted}</td>
                                            <td className='date'>{course.endDate.formatted}</td>
                                            <td className='title'>{course.name}</td>
                                            <td className='credits'>{course.creditsSubmitted.formatted}</td>
                                            <td className='credits'>{course.creditsAuditted.formatted}</td>
                                            <td className='status'>
                                                {course.status.formatted === 'Active'
                                                    ? 'Submitted'
                                                    : course.status.formatted}
                                            </td>
                                            <td className='doc'>
                                                {course.uploadedDocs.length > 0
                                                    ? course.uploadedDocs.map((doc, i) => (
                                                          <div key={i}>{doc.fileName}</div>
                                                      ))
                                                    : 'N/A'}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                        <div>
                            <span className='fw-bolder'>Note:</span> the current 2-year CE cycle ends on{' '}
                            <b>{process.env.REACT_APP_CE_EXPIRY}</b>. You have until that date to complete your required
                            CE hours for this cycle. However, you’re expected to report any CE that you completed during
                            the current registration year as part of the registration renewal process. Please take time
                            now to report any CE sessions that you completed during{' '}
                            {process.env.REACT_APP_CE_CYCLE?.split(' - ')[0]}.
                        </div>
                    </div>
                    <div className='p-10 pt-0'>
                        <Link to='/transcripts' className='btn btn-lg btn-primary fw-bolder'>
                            Add/Edit CE Courses
                        </Link>
                    </div>
                </div>
            </div>
            <div>
                <div className='card card-xl mb-5 mb-xl-8'>
                    <div className='card-header border-0'>
                        <h3 className='card-title fw-bolder text-dark'>Renewal Questionnaire</h3>
                    </div>

                    <div className='card-body pt-0'>
                        <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='initailDetailsForm'>
                            {/* Start Form Row */}
                            <div className='fv-row mb-7'>
                                Now that you’ve reviewed and updated your information, please complete the following
                                questionnaire – either “Yes” OR “No” answers will be accepted. The “Submit Application”
                                button will only activate once all questions are answered. Once you have submitted your
                                answers, you’ll be able to view and pay your invoice for next year’s fees.
                            </div>
                            {/* End Form Row */}
                            {/* Start Form Row */}
                            <div className='fv-row mb-7'>
                                <label className='form-label fs-6 fw-bolder text-dark required'>
                                    Do you confirm that the Contact Information in your registrant account is accurate
                                    and up-to-date?
                                </label>
                                <select
                                    {...formik.getFieldProps('question1')}
                                    className={clsx('form-control form-control-lg form-control-solid', {
                                        'is-invalid': formik.touched.question1 && formik.errors.question1,
                                    })}
                                    name='question1'
                                >
                                    <option disabled value=''>
                                        Select Option
                                    </option>
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>
                                </select>
                                {formik.touched.question1 && formik.errors.question1 && (
                                    <div className='fv-plugins-message-container'>
                                        <span className='text-danger' role='alert'>
                                            {formik.errors.question1}
                                        </span>
                                    </div>
                                )}
                            </div>
                            {/* End Form Row */}
                            {/* Start Form Row */}
                            <div className='fv-row mb-7'>
                                <label className='form-label fs-6 fw-bolder text-dark required'>
                                    Do you confirm that your Practice Facility information in your registrant account is
                                    complete and up-to-date?
                                </label>
                                <select
                                    {...formik.getFieldProps('question2')}
                                    className={clsx('form-control form-control-lg form-control-solid', {
                                        'is-invalid': formik.touched.question2 && formik.errors.question2,
                                    })}
                                    name='question2'
                                >
                                    <option disabled value=''>
                                        Select Option
                                    </option>
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>
                                </select>
                                {formik.touched.question2 && formik.errors.question2 && (
                                    <div className='fv-plugins-message-container'>
                                        <span className='text-danger' role='alert'>
                                            {formik.errors.question2}
                                        </span>
                                    </div>
                                )}
                            </div>
                            {/* End Form Row */}

                            {/* Start Form Row */}
                            <div className='fv-row mb-7'>
                                <label className='form-label fs-6 fw-bolder text-dark required'>
                                    Do you confirm that you have reported the CE hours that you completed during the CE
                                    Cycle {process.env.REACT_APP_CE_CYCLE}?
                                </label>
                                <select
                                    {...formik.getFieldProps('question3')}
                                    className={clsx('form-control form-control-lg form-control-solid', {
                                        'is-invalid': formik.touched.question3 && formik.errors.question3,
                                    })}
                                    name='question3'
                                >
                                    <option disabled value=''>
                                        Select Option
                                    </option>
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>
                                </select>
                                {formik.touched.question3 && formik.errors.question3 && (
                                    <div className='fv-plugins-message-container'>
                                        <span className='text-danger' role='alert'>
                                            {formik.errors.question3}
                                        </span>
                                    </div>
                                )}
                                <div className='mt-2'>
                                    <b>Note:</b> There is no minimum requirement for this year (you have 2 years to
                                    complete your required CE hours). If you haven’t completed any CE hours yet for this
                                    cycle, that is OK -- You have until the end of {process.env.REACT_APP_CE_EXPIRY} to
                                    complete your minimum required CE hours for this 2-Year CE Cycle. This question is
                                    only asking you to confirm whether or not you’ve reported any CE that you completed
                                    this year.
                                </div>
                            </div>
                            {/* End Form Row */}
                            {/* Start Form Row */}
                            <div className='fv-row mb-7'>
                                <label className='form-label fs-6 fw-bolder text-dark required'>
                                    Do you declare that you are in compliance with the Veterinarians Act, regulations
                                    and bylaws of the CVBC and with any limits or conditions imposed upon your
                                    registration under the Act or bylaws?
                                </label>
                                <select
                                    {...formik.getFieldProps('question4')}
                                    className={clsx('form-control form-control-lg form-control-solid', {
                                        'is-invalid': formik.touched.question4 && formik.errors.question4,
                                    })}
                                    name='question4'
                                >
                                    <option disabled value=''>
                                        Select Option
                                    </option>
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>
                                </select>
                                {formik.touched.question4 && formik.errors.question4 && (
                                    <div className='fv-plugins-message-container'>
                                        <span className='text-danger' role='alert'>
                                            {formik.errors.question4}
                                        </span>
                                    </div>
                                )}
                                <div className='mt-2'>
                                    If you answered "No" to questions 1, 2, or 4; or have difficulties reporting your CE 
                                    sessions, please contact the CVBC 
                                    at <a href="mailto:reception@cvbc.ca">reception@cvbc.ca</a> to receive assistance 
                                    or to provide an explanation.
                                </div>
                            </div>
                            {/* End Form Row */}

                            <div className='d-flex align-items-center mb-10'>
                                <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                                <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                            </div>

                            <div className='fv-row'>
                                <div className='d-flex justify-content-between'>
                                    <Link to='/applications/renewal'>
                                        <button type='button' className='btn btn-primary me-3'>
                                            Cancel
                                        </button>
                                    </Link>

                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        disabled={formik.isSubmitting || loading}
                                    >
                                        {!loading && <span className='indicator-label'>Submit</span>}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                                {errMsg && <div className='text-danger text-center mt-7'>{errMsg}</div>}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { InitialDetailsForm };

/* -------------------------------------------------------------------------- */
