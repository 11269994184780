/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/pages/custom/facilities/facility-assessments/FacilitiesAssessments.tsx
 * @author Jesse Zonneveld
 * @description Facilities assessments page
 */

/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useEffect, useState } from 'react';
import { PageTitle } from '../../../../../_metronic/layout/core';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../../redux/hooks';
import { FacilityState } from '../../../../redux/slices/UserSlice';
import { convertToSafeString } from '../../../../helpers';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const FacilitiesAssessmentsPage: FC = () => {
    const user = useAppSelector((state) => state.user);
    const [facilities, setFacilities] = useState<FacilityState[]>([]);
    // test
    useEffect(() => {
        const getDesignatedFacilities = () => {
            const facilities: FacilityState[] = [];

            if (user.facilities.primary.designatedVetId === user.id) {
                facilities.push(user.facilities.primary);
            }

            user.facilities.other.forEach((facility) => {
                if (facility.designatedVetId === user.id) {
                    facilities.push(facility);
                }
            });

            return facilities;
        };

        setFacilities(getDesignatedFacilities());

        return () => {
            setFacilities([]);
        };
    }, [user.facilities.other, user.facilities.primary, user.id]);

    return (
        <>
            <div className='card card-xl mb-5 mb-xl-8'>
                <div className='card-header border-0'>
                    <h3 className='card-title fw-bolder text-dark'>General Information</h3>
                </div>

                <div className='card-body pt-0'>
                    <ul>
                        <li className='mb-5'>
                            The Designated Registrant is responsible for submitting the Annual Declaration form for the
                            previous year by January 31st of the following year. The accompanying Self-Assessment Form 1
                            that assesses the previous year must also be completed and kept in the facility to be
                            available upon request. In the event of a Facility Inspection, the Declaration Form and
                            Self-Assessment Form must be submitted to the CVBC office for review by the inspector. In
                            the case of a new facility or addition of new scopes of practice, please fill out the forms
                            as they relate to the anticipated services.
                        </li>
                        <li className='mb-5'>Please select a facility below to view the associated invoices.</li>
                    </ul>
                </div>
            </div>
            <div className='card card-xl mb-5 mb-xl-8'>
                <div className='card-header border-0'>
                    <h3 className='card-title fw-bolder text-dark'>My Designated Veterinarian Facilities</h3>
                </div>

                <div className='card-body pt-0'>
                    <ul>
                        {facilities?.length > 0 ? (
                            facilities?.map((facility, i) => (
                                <li key={i} className='mb-5'>
                                    <Link
                                        to={`/facilities/assessments/${convertToSafeString(facility.name)}/${
                                            facility.id
                                        }`}
                                    >
                                        {facility.name}
                                    </Link>
                                </li>
                            ))
                        ) : (
                            <li className='mb-5'>
                                You have no facilities added to{' '}
                                <Link className='text-decoration-underline fw-bold' to={'/facilities/my-facilities'}>
                                    My Facilities
                                </Link>{' '}
                                where you are the designated veterinarian. Please add the facility that is missing by
                                using the{' '}
                                <Link className='text-decoration-underline fw-bold' to={'/facilities/search'}>
                                    Facility Search
                                </Link>
                                . If it still does not show you as the designated veterinarian, then please contact us
                                at{' '}
                                <a className='text-decoration-underline fw-bold' href='mailto:reception@cvbc.ca'>
                                    reception@cvbc.ca
                                </a>
                                .
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* ------------------------------ PAGE WRAPPER ------------------------------ */

const FacilitiesAssessmentsWrapper: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Annual Declarations</PageTitle>
            <FacilitiesAssessmentsPage />
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { FacilitiesAssessmentsWrapper };

/* -------------------------------------------------------------------------- */
