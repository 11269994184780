/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/api/facilityAssessmentsApi.ts
 * @author Jesse Zonneveld
 * @description Facility assessments api
 */

/* --------------------------------- LINTING -------------------------------- */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

/* -------------------------------------------------------------------------- */

/* --------------------------------- IMPORTS -------------------------------- */

import {
    ApiResponse,
    UpdateFacilityFormDetails,
    Facility,
    FacilityAssessment,
    AssessmentInitialDetailsFormValues,
    AssessmentInitialDetailsFormConvertedValues,
    FacilityDistrict,
    FacilitySubcategory,
    FacilityCategory,
    FacilitySpecies,
    FacilityAnimalCategory,
    FacilityServiceCategory,
    FacilityServiceSubtype,
    AssessmentYear,
} from '../types';
import { apiClient } from './config';

/* -------------------------------------------------------------------------- */

/* ----------------------------------- API ---------------------------------- */

const getFacilityAssessmentsByFacilityId = async (
    pfn_facilityid: string,
): Promise<ApiResponse<FacilityAssessment[]>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<FacilityAssessment[]>>('/facility-assessments/facility', {
            pfn_facilityid,
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getCurrentAssessmentYear = async (): Promise<ApiResponse<AssessmentYear>> => {
    try {
        const { data } = await apiClient.get<ApiResponse<AssessmentYear>>('/facility-assessments/current-year');
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getFacilityAssessmentById = async (
    pfn_facilityselfassessmentid: string,
): Promise<ApiResponse<FacilityAssessment>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<FacilityAssessment>>('/facility-assessments/assessment', {
            pfn_facilityselfassessmentid,
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const createAssessment = async (
    pfn_facilityid: string,
    pfn_assessmentyear: string,
): Promise<ApiResponse<FacilityAssessment>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<FacilityAssessment>>('/facility-assessments/create', {
            pfn_facilityid,
            pfn_assessmentyear,
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const updateAssessmentInitialDetails = async (
    details: AssessmentInitialDetailsFormConvertedValues,
    pfn_facilityselfassessmentid: string,
    pfn_facilityid: string,
): Promise<ApiResponse<FacilityAssessment>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<FacilityAssessment>>(
            '/facility-assessments/update-initial-details',
            {
                ...details,
                pfn_facilityselfassessmentid,
                pfn_facilityid,
                pfn_address1province: 'BC',
                pfn_address1country: 'Canada',
                pfn_address2province: 'BC',
                pfn_address2country: 'Canada',
                pfn_address3province: 'BC',
                pfn_address3country: 'Canada',
            },
        );
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const updateAssessmentPublication = async (
    pfn_facilityselfassessmentid: string,
    pfn_facilityid: string,
    pfn_onlinepublication: string,
): Promise<ApiResponse<FacilityAssessment>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<FacilityAssessment>>(
            '/facility-assessments/update-publication',
            {
                pfn_facilityselfassessmentid,
                pfn_facilityid,
                pfn_onlinepublication,
            },
        );
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const updateAssessmentDistrict = async (
    pfn_facilityselfassessmentid: string,
    pfn_facilityid: string,
    pfn_districtid: string,
): Promise<ApiResponse<FacilityAssessment>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<FacilityAssessment>>(
            '/facility-assessments/update-district',
            {
                pfn_facilityselfassessmentid,
                pfn_facilityid,
                pfn_districtid,
            },
        );
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getDistricts = async (): Promise<ApiResponse<FacilityDistrict[]>> => {
    try {
        const { data } = await apiClient.get<ApiResponse<FacilityDistrict[]>>(
            '/facility-assessments/facility-districts',
        );
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getSubcategories = async (): Promise<ApiResponse<FacilitySubcategory[]>> => {
    try {
        const { data } = await apiClient.get<ApiResponse<FacilitySubcategory[]>>(
            '/facility-assessments/facility-subcategories',
        );
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getCategories = async (): Promise<ApiResponse<FacilityCategory[]>> => {
    try {
        const { data } = await apiClient.get<ApiResponse<FacilityCategory[]>>(
            '/facility-assessments/facility-categories',
        );
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const updateAssessmentTypes = async (
    pfn_facilityselfassessmentid: string,
    pfn_facilityid: string,
    types: {
        pfn_name: string;
        pfn_facilitycategoryid: string;
        pfn_facilitysubcategoryid: string;
    }[],
): Promise<ApiResponse<FacilityAssessment>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<FacilityAssessment>>(
            '/facility-assessments/create-facility-types',
            {
                pfn_facilityselfassessmentid,
                pfn_facilityid,
                types,
            },
        );
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getSpecies = async (): Promise<ApiResponse<FacilitySpecies[]>> => {
    try {
        const { data } = await apiClient.get<ApiResponse<FacilitySpecies[]>>('/facility-assessments/facility-species');
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getAnimalCategories = async (): Promise<ApiResponse<FacilityAnimalCategory[]>> => {
    try {
        const { data } = await apiClient.get<ApiResponse<FacilityAnimalCategory[]>>(
            '/facility-assessments/facility-animal-categories',
        );
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const updateAssessmentAnimalSpecies = async (
    pfn_facilityselfassessmentid: string,
    pfn_facilityid: string,
    types: {
        pfn_name: string;
        pfn_animalcategoryid: string;
        pfn_speciesid: string;
    }[],
): Promise<ApiResponse<FacilityAssessment>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<FacilityAssessment>>(
            '/facility-assessments/create-animal-species',
            {
                pfn_facilityselfassessmentid,
                pfn_facilityid,
                types,
            },
        );
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getServiceCategories = async (): Promise<ApiResponse<FacilityServiceCategory[]>> => {
    try {
        const { data } = await apiClient.get<ApiResponse<FacilityServiceCategory[]>>(
            '/facility-assessments/facility-service-categories',
        );
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getServiceSubtypes = async (): Promise<ApiResponse<FacilityServiceSubtype[]>> => {
    try {
        const { data } = await apiClient.get<ApiResponse<FacilityServiceSubtype[]>>(
            '/facility-assessments/facility-service-subtypes',
        );
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const updateAssessmentVetServices = async (
    pfn_facilityselfassessmentid: string,
    pfn_facilityid: string,
    types: {
        pfn_name: string;
        pfn_servicecategoryid: string;
        pfn_servicesubtypeid: string;
    }[],
): Promise<ApiResponse<FacilityAssessment>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<FacilityAssessment>>(
            '/facility-assessments/create-vet-services',
            {
                pfn_facilityselfassessmentid,
                pfn_facilityid,
                types,
            },
        );
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const submitAssessment = async (
    pfn_facilityselfassessmentid: string,
    pfn_facilityid: string,
): Promise<ApiResponse<FacilityAssessment>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<FacilityAssessment>>('/facility-assessments/submit', {
            pfn_facilityselfassessmentid,
            pfn_facilityid,
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

/* -------------------------------------------------------------------------- */

/* ------------------------------- API OBJECT ------------------------------- */

const facilityAssessmentApi = {
    getFacilityAssessmentsByFacilityId,
    getCurrentAssessmentYear,
    getFacilityAssessmentById,
    createAssessment,
    updateAssessmentInitialDetails,
    updateAssessmentPublication,
    updateAssessmentDistrict,
    getDistricts,
    getSubcategories,
    getCategories,
    updateAssessmentTypes,
    getSpecies,
    getAnimalCategories,
    updateAssessmentAnimalSpecies,
    getServiceCategories,
    getServiceSubtypes,
    updateAssessmentVetServices,
    submitAssessment,
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export default facilityAssessmentApi;

/* -------------------------------------------------------------------------- */
