/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/api/applicationApi.ts
 * @author Jesse Zonneveld
 * @description Application api
 */

/* --------------------------------- LINTING -------------------------------- */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

/* -------------------------------------------------------------------------- */

/* --------------------------------- IMPORTS -------------------------------- */

import {
    ApiResponse,
    Application,
    ApplicationRequirement,
    Education,
    RegAppEducationFormValues,
    EducationSchool,
    EducationSpecialty,
    EducationType,
    EmploymentHistory,
    RegAppEmploymentHistoryFormValues,
    RegAppInitialDetailsFormValues,
    RenewalAppInitialDetails,
    Jurisdiction,
    RegAppJurisdictionFormValues,
    Question,
    QuestionsFormConvertedValues,
    RegAppSummary,
} from '../types';
import { apiClient } from './config';

/* -------------------------------------------------------------------------- */

/* ----------------------------------- API ---------------------------------- */

const getAppById = async (pfn_applicationid: string): Promise<ApiResponse<Application>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<Application>>('/applications/application', {
            pfn_applicationid,
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getAppsByUserId = async (): Promise<ApiResponse<Application[]>> => {
    try {
        const { data } = await apiClient.get<ApiResponse<Application[]>>('/applications/all');
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getRegAppsByUserId = async (): Promise<ApiResponse<Application[]>> => {
    try {
        const { data } = await apiClient.get<ApiResponse<Application[]>>('/applications/registration');
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getRenewalAppsByUserId = async (): Promise<ApiResponse<Application[]>> => {
    try {
        const { data } = await apiClient.get<ApiResponse<Application[]>>('/applications/renewal');
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const createNewRegApp = async (
    details: RegAppInitialDetailsFormValues,
): Promise<ApiResponse<Application | undefined>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<Application | undefined>>(
            '/applications/registration/create',
            details,
        );
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const createNewRenewalApp = async ({
    pfn_categoryid,
    pfn_subcategoryid,
}: {
    pfn_categoryid: string;
    pfn_subcategoryid: string;
}) => {
    try {
        const { data } = await apiClient.post<ApiResponse<Application | undefined>>('/applications/renewal/create', {
            pfn_categoryid,
            pfn_subcategoryid,
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const updateRenewalAppInitialDetails = async (
    details: RenewalAppInitialDetails,
): Promise<ApiResponse<Application | undefined>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<Application | undefined>>(
            '/applications/renewal/update-details',
            details,
        );
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const addEducation = async (details: RegAppEducationFormValues): Promise<ApiResponse<Education>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<Education>>(
            '/applications/registration/education/add',
            details,
        );
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const editEducation = async (
    pfn_educationid: string,
    details: RegAppEducationFormValues,
): Promise<ApiResponse<Education>> => {
    try {
        const { data } = await apiClient.put<ApiResponse<Education>>('/applications/registration/education/edit', {
            pfn_educationid,
            ...details,
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const deleteEducation = async (pfn_educationid: string): Promise<ApiResponse<Education>> => {
    try {
        const { data } = await apiClient.put<ApiResponse<Education>>('/applications/registration/education/delete', {
            pfn_educationid,
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getEducationTypes = async (): Promise<ApiResponse<EducationType[]>> => {
    try {
        const { data } = await apiClient.get<ApiResponse<EducationType[]>>(
            '/applications/registration/education/types',
        );
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getEducationSchools = async (): Promise<ApiResponse<EducationSchool[]>> => {
    try {
        const { data } = await apiClient.get<ApiResponse<EducationSchool[]>>(
            '/applications/registration/education/schools',
        );
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getEducationSpecialties = async (): Promise<ApiResponse<EducationSpecialty[]>> => {
    try {
        const { data } = await apiClient.get<ApiResponse<EducationSpecialty[]>>(
            '/applications/registration/education/specialties',
        );
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getEducationsForUser = async (): Promise<ApiResponse<Education[]>> => {
    try {
        const { data } = await apiClient.get<ApiResponse<Education[]>>('/applications/registration/education');
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const checkIfUserHasRegAppByYear = async (year: string): Promise<ApiResponse<boolean>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<boolean>>('/applications/registration/year', {
            year,
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const checkIfUserHasRenewalAppStarted = async (): Promise<ApiResponse<boolean>> => {
    try {
        const { data } = await apiClient.get<ApiResponse<boolean>>('/applications/renewal/started');
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getJurisdictions = async (): Promise<ApiResponse<Jurisdiction[]>> => {
    try {
        const { data } = await apiClient.get<ApiResponse<Jurisdiction[]>>('/applications/registration/jurisdiction');
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const addJurisdiction = async (
    pfn_applicationid: string,
    details: RegAppJurisdictionFormValues,
): Promise<ApiResponse<Jurisdiction>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<Jurisdiction>>(
            '/applications/registration/jurisdiction/add',
            { ...details, pfn_applicationid },
        );
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const editJurisdiction = async (
    pfn_jurisdictionid: string,
    details: RegAppJurisdictionFormValues,
): Promise<ApiResponse<Jurisdiction>> => {
    try {
        const { data } = await apiClient.put<ApiResponse<Jurisdiction>>(
            '/applications/registration/jurisdiction/edit',
            {
                pfn_jurisdictionid,
                ...details,
            },
        );
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const deleteJurisdiction = async (pfn_jurisdictionid: string): Promise<ApiResponse<Jurisdiction>> => {
    try {
        const { data } = await apiClient.put<ApiResponse<Jurisdiction>>(
            '/applications/registration/jurisdiction/delete',
            {
                pfn_jurisdictionid,
            },
        );
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getEmploymentHistories = async (): Promise<ApiResponse<EmploymentHistory[]>> => {
    try {
        const { data } = await apiClient.get<ApiResponse<EmploymentHistory[]>>('/applications/registration/employment');
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const addEmploymentHistory = async (
    pfn_applicationid: string,
    details: RegAppEmploymentHistoryFormValues,
): Promise<ApiResponse<EmploymentHistory>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<EmploymentHistory>>(
            '/applications/registration/employment/add',
            { ...details, pfn_applicationid },
        );
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const editEmploymentHistory = async (
    pfn_employmenthistoryid: string,
    details: RegAppEmploymentHistoryFormValues,
): Promise<ApiResponse<EmploymentHistory>> => {
    try {
        const { data } = await apiClient.put<ApiResponse<EmploymentHistory>>(
            '/applications/registration/employment/edit',
            {
                pfn_employmenthistoryid,
                ...details,
            },
        );
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const deleteEmploymentHistory = async (pfn_employmenthistoryid: string): Promise<ApiResponse<EmploymentHistory>> => {
    try {
        const { data } = await apiClient.put<ApiResponse<EmploymentHistory>>(
            '/applications/registration/employment/delete',
            {
                pfn_employmenthistoryid,
            },
        );
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getQuestions = async (pfn_applicationid: string): Promise<ApiResponse<Question[]>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<Question[]>>('/applications/registration/question', {
            pfn_applicationid,
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const updateQuestions = async (details: QuestionsFormConvertedValues): Promise<ApiResponse<Question[]>> => {
    try {
        const { data } = await apiClient.put<ApiResponse<Question[]>>('/applications/registration/question/update', {
            questions: [details.q1, details.q2, details.q3, details.q4, details.q5, details.q6],
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getRegAppSummary = async (pfn_applicationid: string): Promise<ApiResponse<RegAppSummary>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<RegAppSummary>>('/applications/registration/summary', {
            pfn_applicationid,
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const submitRegAppSummary = async (pfn_applicationid: string): Promise<ApiResponse<Application>> => {
    try {
        const { data } = await apiClient.put<ApiResponse<Application>>('/applications/registration/summary/submit', {
            pfn_applicationid,
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getAppReqs = async (pfn_applicationid: string): Promise<ApiResponse<ApplicationRequirement[]>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<ApplicationRequirement[]>>('/applications/requirements', {
            pfn_applicationid,
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

/* -------------------------------------------------------------------------- */

/* ------------------------------- API OBJECT ------------------------------- */

const applicationApi = {
    getAppById,
    getAppsByUserId,
    getRegAppsByUserId,
    getRenewalAppsByUserId,
    createNewRegApp,
    createNewRenewalApp,
    updateRenewalAppInitialDetails,
    checkIfUserHasRegAppByYear,
    checkIfUserHasRenewalAppStarted,
    addEducation,
    editEducation,
    deleteEducation,
    getEducationTypes,
    getEducationSchools,
    getEducationSpecialties,
    getEducationsForUser,
    getJurisdictions,
    addJurisdiction,
    editJurisdiction,
    deleteJurisdiction,
    getEmploymentHistories,
    addEmploymentHistory,
    editEmploymentHistory,
    deleteEmploymentHistory,
    getQuestions,
    updateQuestions,
    getRegAppSummary,
    submitRegAppSummary,
    getAppReqs,
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export default applicationApi;

/* -------------------------------------------------------------------------- */
