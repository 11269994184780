import { FC, useState } from 'react';
import { KTSVG } from '../../../../../../../_metronic/helpers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { closeAllModals } from '../../../../../../helpers';
import applicationApi from '../../../../../../api/applicationApi';
import { Application, Jurisdiction } from '../../../../../../types';
import { SpecialMonthValues, SpecialYearValues } from '../../../../../../constants';

interface Props {
    jurisdiction: Jurisdiction;
    application: Application;
    modalName: string;
    getCurrentJurisdictions: Function;
    yearsFromToRange: number[];
    monthsRange: (string | number)[];
    monthsRangeWithPresent: (string | number)[];
    yearsPracticeRange: number[];
    monthsPracticeRange: number[];
}

const validationSchema = Yup.object()
    .shape({
        pfn_name: Yup.string().required('Regulatory body is required.'),
        pfn_typeoflicense: Yup.string().required('License type is required.'),
        pfn_licensenumber: Yup.string().required('License number is required.'),
        pfn_fromyear: Yup.string()
            .required('From year is required')
            .test('checkYearRange', 'Must be no greater than 50 years ago.', async (val) => {
                return Number(val) >= new Date().getFullYear() - 50;
            }),
        pfn_toyear: Yup.string().required('To year is required'),
        pfn_frommonth: Yup.string()
            .required('From month is required.')
            .test('checkMonthRange', 'Must be less than or equal to 12 months.', async (val) => {
                return val?.toLowerCase() === SpecialMonthValues.DO_NOT_RECALL.title.toLowerCase() || (Number(val) >= 1 && Number(val) <= 13);
            }),
        pfn_tomonth: Yup.string()
            .required('To month is required')
            .test('checkMonthRange', 'Must be less than or equal to 12 months.', async (val) => {
                return val?.toLowerCase() === SpecialMonthValues.PRESENT.title.toLowerCase() || val?.toLowerCase() === SpecialMonthValues.DO_NOT_RECALL.title.toLowerCase() || (Number(val) >= 1 && Number(val) <= 14);
            }),
        pfn_practicetimeyears: Yup.string()
            .required('Practice years is required')
            .test('checkYearRange', 'Must be less than or equal to 40 years.', async (val) => {
                return Number(val) <= 40;
            }),
        pfn_practicetimemonths: Yup.string()
            .required('Practice month is required')
            .test('checkMonthRange', 'Must be less than or equal to 12 months.', async (val) => {
                return Number(val) <= 12;
            }),
        pfn_details: Yup.string().required('Scope is required.'),
    })
    .test({
        name: 'ToDateMustBeAfterFromDate',
        test: function (values) {
            if (values.pfn_toyear && values.pfn_tomonth && values.pfn_fromyear && values.pfn_frommonth) {
                if (values.pfn_fromyear === values.pfn_toyear) {
                    if (values.pfn_tomonth === SpecialMonthValues.PRESENT.value || values.pfn_frommonth === SpecialMonthValues.DO_NOT_RECALL.value || values.pfn_frommonth <= values.pfn_tomonth) {
                        return true;
                    } else {
                        return this.createError({
                            path: 'pfn_tomonth',
                            message: 'To date must be after from date.',
                        });
                    }
                } else {
                    if (values.pfn_toyear === SpecialYearValues.PRESENT.title.toLowerCase() || values.pfn_fromyear <= values.pfn_toyear) {
                        return true;
                    } else {
                        return this.createError({
                            path: 'pfn_toyear',
                            message: 'To date must be after from date.',
                        });
                    }
                }
            } else {
                return true;
            }
        },
    })
    .test({
        name: 'PresentCheck',
        test: function (values) {
            if (values.pfn_toyear && values.pfn_tomonth) {
                if (
                    (values.pfn_toyear?.toLowerCase() === SpecialMonthValues.PRESENT.title.toLowerCase() && (values.pfn_tomonth.toLowerCase() === SpecialMonthValues.PRESENT.title.toLowerCase() || values.pfn_tomonth === SpecialMonthValues.PRESENT.value)) ||
                    (values.pfn_toyear?.toLowerCase() !== SpecialMonthValues.PRESENT.title.toLowerCase() && (values.pfn_tomonth.toLowerCase() !== SpecialMonthValues.PRESENT.title.toLowerCase() && values.pfn_tomonth !== SpecialMonthValues.PRESENT.value))
                ) {
                    return true;
                } else {
                    return this.createError({
                        path: 'pfn_tomonth',
                        message: 'To year and month must both be present if present is selected.',
                    });
                }
            } else {
                return true;
            }
        },
    });

const EditJurisdictionBtn: FC<Props> = ({
    jurisdiction,
    application,
    modalName,
    getCurrentJurisdictions,
    yearsFromToRange,
    monthsRange,
    monthsRangeWithPresent,
    yearsPracticeRange,
    monthsPracticeRange,
}) => {
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);

    const initialValues = {
        pfn_name: jurisdiction.pfn_name ? jurisdiction.pfn_name : '',
        pfn_typeoflicense: jurisdiction.pfn_typeoflicense ? jurisdiction.pfn_typeoflicense : '',
        pfn_licensenumber: jurisdiction.pfn_licensenumber ? jurisdiction.pfn_licensenumber : '',
        pfn_fromyear: jurisdiction.pfn_fromyear ? jurisdiction.pfn_fromyear : '',
        pfn_frommonth: jurisdiction.pfn_frommonth ? String(jurisdiction.pfn_frommonth) : '',
        pfn_toyear: jurisdiction.pfn_toyear ? jurisdiction.pfn_toyear : '',
        pfn_tomonth: jurisdiction.pfn_tomonth ? String(jurisdiction.pfn_tomonth) : '',
        pfn_practicetimeyears: jurisdiction.pfn_practicetimeyears_Formatted
            ? jurisdiction.pfn_practicetimeyears_Formatted
            : '',
        pfn_practicetimemonths: jurisdiction.pfn_practicetimemonths_Formatted
            ? jurisdiction.pfn_practicetimemonths_Formatted
            : '',
        pfn_details: jurisdiction.pfn_details ? jurisdiction.pfn_details : '',
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                setErrMsg(null);
                setLoading(true);
                if (!jurisdiction.pfn_jurisdictionid) {
                    throw new Error('Jurisdiction selected has no id.');
                }

                if (values.pfn_toyear === SpecialYearValues.PRESENT.value) {
                    values.pfn_toyear =  SpecialYearValues.PRESENT.title;
                }

                if (values.pfn_tomonth.toLowerCase() === SpecialMonthValues.PRESENT.title.toLowerCase()) {
                    values.pfn_tomonth = SpecialMonthValues.PRESENT.value;
                }

                if (values.pfn_tomonth.toLowerCase() === SpecialMonthValues.DO_NOT_RECALL.title.toLowerCase()) {
                    values.pfn_tomonth = SpecialMonthValues.DO_NOT_RECALL.value;
                }

                if (values.pfn_frommonth.toLowerCase() === SpecialMonthValues.DO_NOT_RECALL.title.toLowerCase()) {
                    values.pfn_frommonth = SpecialMonthValues.DO_NOT_RECALL.value;
                }

                const response = await applicationApi.editJurisdiction(jurisdiction.pfn_jurisdictionid, values);
                if (response) {
                    if (!response.success) {
                        if (response.message) {
                            setErrMsg(response.message);
                        } else {
                            setErrMsg('An error has occurred, please try again later.');
                        }
                    } else {
                        closeAllModals();
                        getCurrentJurisdictions();
                    }
                } else {
                    setErrMsg('An error has occurred, please try again later.');
                }

                setLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later.');
                }
                setLoading(false);
            }
        },
    });

    const RenderEditJurisdictionModal = () => {
        return (
            <div className='modal fade' tabIndex={-1} id={modalName}>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h3 className='modal-title'>Edit Jurisdiction</h3>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
                            </div>
                        </div>
                        <div className='modal-body'>{RenderEditJurisdictionForm()}</div>
                    </div>
                </div>
            </div>
        );
    };

    const RenderEditJurisdictionForm = () => {
        return (
            <form className='form w-100 text-start' noValidate id='editJurisdictionForm'>
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Regulatory Body</label>
                    <input
                        {...formik.getFieldProps('pfn_name')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_name && formik.errors.pfn_name,
                        })}
                        name='pfn_name'
                        autoComplete='off'
                    />
                    {formik.touched.pfn_name && formik.errors.pfn_name && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_name}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>License Type</label>
                    <input
                        {...formik.getFieldProps('pfn_typeoflicense')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_typeoflicense && formik.errors.pfn_typeoflicense,
                        })}
                        name='pfn_typeoflicense'
                        autoComplete='off'
                    />
                    {formik.touched.pfn_typeoflicense && formik.errors.pfn_typeoflicense && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_typeoflicense}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>License Number</label>
                    <input
                        {...formik.getFieldProps('pfn_licensenumber')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_licensenumber && formik.errors.pfn_licensenumber,
                        })}
                        name='pfn_licensenumber'
                        autoComplete='off'
                    />
                    {formik.touched.pfn_licensenumber && formik.errors.pfn_licensenumber && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_licensenumber}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Year of Licensure (From)</label>
                    <select
                        {...formik.getFieldProps('pfn_fromyear')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_fromyear && formik.errors.pfn_fromyear,
                        })}
                        name='pfn_fromyear'
                    >
                        <option disabled value=''>
                            Select Year
                        </option>
                        {yearsFromToRange?.map((year, i) => (
                            <option key={i} value={String(year)}>
                                {year}
                            </option>
                        ))}
                    </select>
                    {formik.touched.pfn_fromyear && formik.errors.pfn_fromyear && (
                        <div className='fv-plugins-message-container'>
                            <span className='text-danger' role='alert'>
                                {formik.errors.pfn_fromyear}
                            </span>
                        </div>
                    )}
                </div>
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Month of Licensure (From)</label>
                    <select
                        {...formik.getFieldProps('pfn_frommonth')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_frommonth && formik.errors.pfn_frommonth,
                        })}
                        name='pfn_frommonth'
                    >
                        <option disabled value=''>
                            Select Month
                        </option>
                        {monthsRange?.map((month, i) => (
                            <option key={i} value={month === SpecialMonthValues.DO_NOT_RECALL.title ? SpecialMonthValues.DO_NOT_RECALL.value : String(i + 1)}>
                                {month}
                            </option>
                        ))}
                    </select>
                    {formik.touched.pfn_frommonth && formik.errors.pfn_frommonth && (
                        <div className='fv-plugins-message-container'>
                            <span className='text-danger' role='alert'>
                                {formik.errors.pfn_frommonth}
                            </span>
                        </div>
                    )}
                </div>
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Year of Licensure (To)</label>
                    <select
                        {...formik.getFieldProps('pfn_toyear')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_toyear && formik.errors.pfn_toyear,
                        })}
                        name='pfn_toyear'
                    >
                        <option disabled value=''>
                            Select Year
                        </option>
                        <option value={SpecialYearValues.PRESENT.title.toLowerCase()}>{SpecialYearValues.PRESENT.title}</option>
                        {yearsFromToRange?.map((year, i) => (
                            <option key={i} value={String(year)}>
                                {year}
                            </option>
                        ))}
                    </select>
                    {formik.touched.pfn_toyear && formik.errors.pfn_toyear && (
                        <div className='fv-plugins-message-container'>
                            <span className='text-danger' role='alert'>
                                {formik.errors.pfn_toyear}
                            </span>
                        </div>
                    )}
                </div>
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Month of Licensure (To)</label>
                    <select
                        {...formik.getFieldProps('pfn_tomonth')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_tomonth && formik.errors.pfn_tomonth,
                        })}
                        name='pfn_tomonth'
                    >
                        <option disabled value=''>
                            Select Month
                        </option>
                        {monthsRangeWithPresent?.map((month, i) => (
                            <option key={i} value={month === SpecialMonthValues.DO_NOT_RECALL.title ? SpecialMonthValues.DO_NOT_RECALL.value : month === SpecialMonthValues.PRESENT.title ? SpecialMonthValues.PRESENT.value : String(i + 1)}>
                                {month}
                            </option>
                        ))}
                    </select>
                    {formik.touched.pfn_tomonth && formik.errors.pfn_tomonth && (
                        <div className='fv-plugins-message-container'>
                            <span className='text-danger' role='alert'>
                                {formik.errors.pfn_tomonth}
                            </span>
                        </div>
                    )}
                </div>
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>
                        Time In Practice in this Jurisdiction (Years)
                    </label>
                    <select
                        {...formik.getFieldProps('pfn_practicetimeyears')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_practicetimeyears && formik.errors.pfn_practicetimeyears,
                        })}
                        name='pfn_practicetimeyears'
                    >
                        <option disabled value=''>
                            Select Year
                        </option>
                        {yearsPracticeRange?.map((year, i) => (
                            <option key={i} value={String(year)}>
                                {year}
                            </option>
                        ))}
                    </select>
                    {formik.touched.pfn_practicetimeyears && formik.errors.pfn_practicetimeyears && (
                        <div className='fv-plugins-message-container'>
                            <span className='text-danger' role='alert'>
                                {formik.errors.pfn_practicetimeyears}
                            </span>
                        </div>
                    )}
                </div>
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>
                        Time In Practice in this Jurisdiction (Plus Month)
                    </label>
                    <select
                        {...formik.getFieldProps('pfn_practicetimemonths')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_practicetimemonths && formik.errors.pfn_practicetimemonths,
                        })}
                        name='pfn_practicetimemonths'
                    >
                        <option disabled value=''>
                            Select Month
                        </option>
                        {monthsPracticeRange?.map((month, i) => (
                            <option key={i} value={String(month)}>
                                {month}
                            </option>
                        ))}
                    </select>
                    {formik.touched.pfn_practicetimemonths && formik.errors.pfn_practicetimemonths && (
                        <div className='fv-plugins-message-container'>
                            <span className='text-danger' role='alert'>
                                {formik.errors.pfn_practicetimemonths}
                            </span>
                        </div>
                    )}
                </div>
                <div className='fv-row mb-7'>
                    <label className='form-label fw-bolder text-dark fs-6 required'>Scope of Practice</label>
                    <textarea
                        autoComplete='off'
                        rows={5}
                        {...formik.getFieldProps('pfn_details')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_details && formik.errors.pfn_details,
                        })}
                    />
                    {formik.touched.pfn_details && formik.errors.pfn_details && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_details}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                <div className='d-flex align-items-center mb-10'>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                </div>
                <div className='fv-row text-start'>
                    <div className='d-flex justify-content-end'>
                        <button type='button' className='btn btn-light me-3' data-bs-dismiss='modal'>
                            Cancel
                        </button>
                        <button
                            type='button'
                            className='btn btn-primary'
                            disabled={formik.isSubmitting || loading}
                            onClick={() => {
                                formik.handleSubmit();
                            }}
                        >
                            {!loading && <span className='indicator-label'>Update</span>}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </div>
                {errMsg && <div className='text-danger text-center mt-7'>{errMsg}</div>}
            </form>
        );
    };

    return (
        <>
            <button
                type='button'
                className='btn btn-primary btn-sm btn-icon me-2'
                data-bs-toggle='modal'
                data-bs-target={`#${modalName}`}
                onClick={() => {
                    formik.resetForm();
                    setErrMsg(null);
                }}
            >
                <i className='bi bi-pencil-square fs-2'></i>
            </button>
            {RenderEditJurisdictionModal()}
        </>
    );
};

export { EditJurisdictionBtn };