/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/pages/custom/facilities/components/FacilityCurrentResult.tsx
 * @author Jesse Zonneveld
 * @description Current facility result components
 */

/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { KTSVG } from '../../../../../_metronic/helpers';
import { FacilityState, removeFacility, setPrimaryFacility } from '../../../../redux/slices/UserSlice';
import { FacilityEditDetails } from './FacilityEditDetails';
import { Link } from 'react-router-dom';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

interface Props {
    facility: FacilityState;
    modalName: string;
}

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const FacilityCurrentResult: FC<Props> = ({ facility, modalName }) => {
    const user = useAppSelector((state) => state.user);
    const [loadingRemove, setLoadingRemove] = useState(false);
    const [loadingPrimary, setLoadingPrimary] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const dispatch = useAppDispatch();

    const handleRemoveClick = async (accountId: string | null) => {
        try {
            if (accountId) {
                setErrMsg(null);
                setLoadingRemove(true);
                const response = await dispatch(removeFacility(accountId));
                if (response) {
                    if (!response.payload?.success) {
                        if (response.payload?.message) {
                            setErrMsg(response.payload.message);
                        } else {
                            setErrMsg('An error has occured, please try again later');
                        }
                    }
                } else {
                    setErrMsg('An error has occured, please try again later');
                }
                setLoadingRemove(false);
            }
        } catch (err) {
            if (err instanceof Error) {
                setErrMsg(err.message);
            } else {
                setErrMsg('An error has occurred, please try again later');
            }
            setLoadingRemove(false);
        }
    };

    const handleSetPrimaryClick = async (accountId: string | null) => {
        try {
            if (accountId) {
                setErrMsg(null);
                setLoadingPrimary(true);
                const response = await dispatch(setPrimaryFacility(accountId));
                if (response) {
                    if (!response.payload?.success) {
                        if (response.payload?.message) {
                            setErrMsg(response.payload.message);
                        } else {
                            setErrMsg('An error has occured, please try again later');
                        }
                    }
                } else {
                    setErrMsg('An error has occured, please try again later');
                }
                setLoadingPrimary(false);
            }
        } catch (err) {
            if (err instanceof Error) {
                setErrMsg(err.message);
            } else {
                setErrMsg('An error has occurred, please try again later');
            }
            setLoadingPrimary(false);
        }
    };

    const removeFacilityModal = () => {
        return (
            <div className='modal fade' tabIndex={-1} id={modalName}>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            {facility.designatedVetId === user.id ? (
                                <h5 className='modal-title'>You are the DR for this facility</h5>
                            ) : (
                                <h5 className='modal-title'>Are you sure you want to remove this facility?</h5>
                            )}

                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
                            </div>
                        </div>
                        {facility.designatedVetId === user.id ? (
                            <div className='modal-body'>
                                <div className='mb-5'>
                                    As Designated Registrant (DR) of this facility, please call 604-929-7090 or email{' '}
                                    <a className='text-decoration-underline fw-bold' href='mailto:facilities@cvbc.ca'>
                                        facilities@cvbc.ca
                                    </a>{' '}
                                    if you need to remove this listing from your profile. The current DR is required to
                                    notify the CVBC if the DR of a facility changes
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <button type='button' className='btn btn-light me-3' data-bs-dismiss='modal'>
                                        Back
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className='modal-body'>
                                <div className='d-flex justify-content-center'>
                                    <button type='button' className='btn btn-light me-3' data-bs-dismiss='modal'>
                                        Cancel
                                    </button>

                                    <button
                                        type='button'
                                        className='btn btn-danger'
                                        disabled={loadingRemove}
                                        onClick={() => handleRemoveClick(facility.id)}
                                    >
                                        {!loadingRemove && <span className='indicator-label'>Delete</span>}
                                        {loadingRemove && (
                                            <span
                                                className='indicator-progress text-light'
                                                style={{ display: 'block' }}
                                            >
                                                Please wait...
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                                {errMsg && <div className='text-danger text-center mt-7'>{errMsg}</div>}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className='facility-card d-flex align-items-center bg-light rounded p-5 mb-7'>
            {/* <span className='bullet bullet-vertical h-200px bg-primary me-10'></span> */}

            <div className='facility-card-inner flex-grow-1 me-2'>
                <div className='fw-bolder text-gray-800 fs-6 mb-3'>
                    <span>{facility.name}</span>
                </div>
                {facility.designatedVetId === user.id && (
                    <div className='fw-bold text-primary mb-5'>
                        <div className='d-flex align-items-center'>
                            <i className='text-primary bi bi-person-fill fs-2 me-2'></i>
                            <span>You are the designated veterinarian</span>
                        </div>
                    </div>
                )}
                <span className='text-muted fw-bold d-block'>{facility.address.line1}</span>
                <span className='text-muted fw-bold d-block'>{facility.address.line2}</span>
                <div>
                    <span className='text-muted fw-bold'>{facility.address.city}</span>
                    <span className='text-muted fw-bold'>, BC</span>
                </div>
                <span className='text-muted fw-bold d-block'>{facility.address.postalCode}</span>
                <span className='text-muted fw-bold d-block'>Canada</span>
                <span className='text-muted fw-bold d-block'>Tel: {facility.telephone}</span>

                <div className='mt-5'>
                    <div>
                        <span className='btn-link link-primary cursor-pointer hover'>
                            {!loadingRemove && (
                                <span
                                    className='indicator-label'
                                    data-bs-toggle='modal'
                                    data-bs-target={`#${modalName}`}
                                >
                                    Remove facility
                                </span>
                            )}
                            {loadingRemove && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Removing ...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </span>
                    </div>
                    {user.facilities.primary.id !== facility.id && (
                        <div>
                            <span className='btn-link link-primary cursor-pointer hover'>
                                {!loadingPrimary && (
                                    <span
                                        className='indicator-label'
                                        onClick={() => handleSetPrimaryClick(facility.id)}
                                    >
                                        Set as primary
                                    </span>
                                )}
                                {loadingPrimary && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Setting ...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </span>
                        </div>
                    )}

                    {facility.designatedVetId === user.id && (
                        <div>
                            {/* <div>
                                <span className='btn-link link-primary cursor-pointer hover'>Annual Declaration</span>
                            </div> */}
                            <div>
                                <span className='btn-link link-primary cursor-pointer hover underline'>
                                    <Link to={`/receipts/facility/${facility.name}/${facility.id}`}>Receipts</Link>
                                </span>
                            </div>
                            <div>
                                <span className='btn-link link-primary cursor-pointer hover underline'>
                                    <Link to={`/invoices/facility/${facility.name}/${facility.id}`}>Invoices</Link>
                                </span>
                            </div>
                            <FacilityEditDetails facility={facility} modalName={modalName + '_edit'} />
                        </div>
                    )}
                </div>
                {errMsg && <div className='mt-5 text-danger'>{errMsg}</div>}
            </div>
            {removeFacilityModal()}
        </div>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { FacilityCurrentResult };

/* -------------------------------------------------------------------------- */
