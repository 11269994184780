/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/pages/custom/facilities/facility-assessments/formController/forms/InitialDetailsForm.tsx
 * @author Jesse Zonneveld
 * @description Assessment form first step
 */

/* --------------------------------- IMPORTS -------------------------------- */

import * as Yup from 'yup';
import { FC, useState } from 'react';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { convertPhoneNumberToHyphens, removeEmpty } from '../../../../../../helpers';
import {
    AssessmentInitialDetailsFormConvertedValues,
    AssessmentInitialDetailsFormValues,
    Facility,
    FacilityAssessment,
} from '../../../../../../types';
import { omit } from 'lodash';
import facilityAssessmentApi from '../../../../../../api/facilityAssessmentsApi';
import MaskedInput from 'react-text-mask';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

interface Props {
    goToNextStep: Function;
    assessment: FacilityAssessment;
    facility: Facility;
}

/* -------------------------------------------------------------------------- */

/* --------------------------------- SCHEMA --------------------------------- */

const phoneRegExp = /^\d{3}[-]\d{3}[-]\d{4}$/;

const validationSchema = Yup.object().shape({
    pfn_name: Yup.string().required('Facility name is required'),
    pfn_address1street1: Yup.string().required('Address line 1 is required'),
    pfn_address1street2: Yup.string(),
    pfn_address1city: Yup.string().required('Address city is required'),
    pfn_address1postalcode: Yup.string().required('Address postal code is required'),
    same_address_1: Yup.string().required('This is required'),
    pfn_address2street1: Yup.string().when('same_address_1', {
        is: 'No',
        then: Yup.string().required('Address line 1 is required'),
    }),
    pfn_address2street2: Yup.string().when('same_address_1', {
        is: 'No',
        then: Yup.string(),
    }),
    pfn_address2city: Yup.string().when('same_address_1', {
        is: 'No',
        then: Yup.string().required('Address city is required'),
    }),
    pfn_address2postalcode: Yup.string().when('same_address_1', {
        is: 'No',
        then: Yup.string().required('Address postal code is required'),
    }),
    same_address_2: Yup.string().required('This is required'),
    pfn_address3street1: Yup.string().when('same_address_2', {
        is: 'No',
        then: Yup.string().required('Address line 1 is required'),
    }),
    pfn_address3street2: Yup.string().when('same_address_2', {
        is: 'No',
        then: Yup.string(),
    }),
    pfn_address3city: Yup.string().when('same_address_2', {
        is: 'No',
        then: Yup.string().required('Address city is required'),
    }),
    pfn_address3postalcode: Yup.string().when('same_address_2', {
        is: 'No',
        then: Yup.string().required('Address postal code is required'),
    }),
    pfn_phonenumber: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required('Phone number is required'),
    pfn_email: Yup.string().email('Wrong email format').required('Email is required'),
    pfn_website: Yup.string(),
    pfn_socialmedia: Yup.string(),
    pfn_contactname: Yup.string().required('Contact name is required'),
    pfn_ownername: Yup.string().required('Owner name is required'),
    pfn_otherveterinarians: Yup.string(),
    pfn_numberofotheremployees: Yup.string().required('Number of other employees is required'),
    pfn_numberofrvts: Yup.string().required('Number of RVTs is required'),
});

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const InitialDetailsForm: FC<Props> = ({ goToNextStep, assessment, facility }) => {
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);

    const initialValues = {
        pfn_name: assessment.pfn_name ? assessment.pfn_name : facility.name ? facility.name : '',
        pfn_address1street1: assessment.pfn_address1street1
            ? assessment.pfn_address1street1
            : facility.address1_line1
            ? facility.address1_line1
            : '',
        pfn_address1street2: assessment.pfn_address1street2
            ? assessment.pfn_address1street2
            : facility.address1_line2
            ? facility.address1_line2
            : '',
        pfn_address1city: assessment.pfn_address1city
            ? assessment.pfn_address1city
            : facility.address1_city
            ? facility.address1_city
            : '',
        pfn_address1postalcode: assessment.pfn_address1postalcode
            ? assessment.pfn_address1postalcode
            : facility.address1_postalcode
            ? facility.address1_postalcode
            : '',
        same_address_1: !assessment.pfn_address2street1
            ? ''
            : assessment.pfn_address1street1 && assessment.pfn_address1street1 === assessment.pfn_address2street1
            ? 'Yes'
            : 'No',
        pfn_address2street1: assessment.pfn_address2street1 ? assessment.pfn_address2street1 : '',
        pfn_address2street2: assessment.pfn_address2street2 ? assessment.pfn_address2street2 : '',
        pfn_address2city: assessment.pfn_address2city ? assessment.pfn_address2city : '',
        pfn_address2postalcode: assessment.pfn_address2postalcode ? assessment.pfn_address2postalcode : '',
        same_address_2: !assessment.pfn_address3street1
            ? ''
            : assessment.pfn_address1street1 && assessment.pfn_address1street1 === assessment.pfn_address3street1
            ? 'Yes'
            : 'No',
        pfn_address3street1: assessment.pfn_address3street1 ? assessment.pfn_address3street1 : '',
        pfn_address3street2: assessment.pfn_address3street2 ? assessment.pfn_address3street2 : '',
        pfn_address3city: assessment.pfn_address3city ? assessment.pfn_address3city : '',
        pfn_address3postalcode: assessment.pfn_address3postalcode ? assessment.pfn_address3postalcode : '',
        pfn_phonenumber: assessment.pfn_phonenumber
            ? assessment.pfn_phonenumber
            : facility.telephone1
            ? convertPhoneNumberToHyphens(facility.telephone1)
            : '',
        pfn_email: assessment.pfn_email ? assessment.pfn_email : facility.emailaddress1 ? facility.emailaddress1 : '',
        pfn_website: assessment.pfn_website ? assessment.pfn_website : facility.websiteurl ? facility.websiteurl : '',
        pfn_socialmedia: assessment.pfn_socialmedia
            ? assessment.pfn_socialmedia
            : facility.pfn_socialmedia
            ? facility.pfn_socialmedia
            : '',
        pfn_contactname: assessment.pfn_contactname
            ? assessment.pfn_contactname
            : facility._pfn_contactid_value_Formatted
            ? facility._pfn_contactid_value_Formatted
            : '',
        pfn_ownername: assessment.pfn_ownername
            ? assessment.pfn_ownername
            : facility._pfn_contactid1_value_Formatted
            ? facility._pfn_contactid1_value_Formatted
            : '',
        pfn_otherveterinarians: '',
        pfn_numberofotheremployees: assessment.pfn_numberofotheremployees ? assessment.pfn_numberofotheremployees : '',
        pfn_numberofrvts: assessment.pfn_numberofrvts ? assessment.pfn_numberofrvts : '',
    };

    const convertValues = (values: AssessmentInitialDetailsFormValues) => {
        const convertedValues: AssessmentInitialDetailsFormConvertedValues = omit(values, [
            'pfn_numberofrvts',
            'pfn_numberofotheremployees',
        ]);

        if (values.same_address_1 === 'Yes') {
            convertedValues.pfn_address2street1 = values.pfn_address1street1;
            convertedValues.pfn_address2street2 = values.pfn_address1street2;
            convertedValues.pfn_address2city = values.pfn_address1city;
            convertedValues.pfn_address2postalcode = values.pfn_address1postalcode;
        }

        if (values.same_address_2 === 'Yes') {
            convertedValues.pfn_address3street1 = values.pfn_address1street1;
            convertedValues.pfn_address3street2 = values.pfn_address1street2;
            convertedValues.pfn_address3city = values.pfn_address1city;
            convertedValues.pfn_address3postalcode = values.pfn_address1postalcode;
        }

        convertedValues.pfn_otherveterinarians = getVets();

        convertedValues.pfn_numberofrvts = Number(values.pfn_numberofrvts);
        convertedValues.pfn_numberofotheremployees = String(values.pfn_numberofotheremployees);

        return removeEmpty(convertedValues);
    };

    const getVets = () => {
        let vets = '';

        facility.pfn_account_contact?.forEach((vet) => {
            if (vet.firstname && vet.lastname) {
                if (vets) {
                    vets += `, ${vet.firstname} ${vet.lastname}`;
                } else {
                    vets += `${vet.firstname} ${vet.lastname}`;
                }
            }
        });

        return vets;
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                setErrMsg(null);
                setLoading(true);

                const response = await facilityAssessmentApi.updateAssessmentInitialDetails(
                    omit(convertValues(values), [
                        'same_address_1',
                        'same_address_2',
                    ]) as AssessmentInitialDetailsFormConvertedValues,
                    assessment.pfn_facilityselfassessmentid,
                    assessment._pfn_facilityid_value,
                );

                if (response) {
                    if (!response.success) {
                        if (response.message) {
                            setErrMsg(response.message);
                        } else {
                            setErrMsg('An error has occured, please try again later');
                        }
                    } else {
                        goToNextStep();
                    }
                } else {
                    setErrMsg('An error has occured, please try again later');
                }

                setLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
                setLoading(false);
            }
        },
    });

    return (
        <>
            {errMsg ? (
                <div>{errMsg}</div>
            ) : (
                <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='initailDetailsForm'>
                    {/* Start Form Row */}
                    <div className='fv-row mb-7'>
                        <label className='form-label fs-6 fw-bolder text-dark required'>Facility Name</label>
                        <input
                            {...formik.getFieldProps('pfn_name')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid': formik.touched.pfn_name && formik.errors.pfn_name,
                            })}
                            name='pfn_name'
                            autoComplete='off'
                        />
                        {formik.touched.pfn_name && formik.errors.pfn_name && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_name}
                                </span>
                            </div>
                        )}
                    </div>
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    <div className='fv-row mb-7'>
                        <label className='form-label fs-6 fw-bolder text-dark required'>Street Address Line 1</label>
                        <input
                            {...formik.getFieldProps('pfn_address1street1')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid': formik.touched.pfn_address1street1 && formik.errors.pfn_address1street1,
                            })}
                            name='pfn_address1street1'
                            autoComplete='off'
                        />
                        {formik.touched.pfn_address1street1 && formik.errors.pfn_address1street1 && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_address1street1}
                                </span>
                            </div>
                        )}
                    </div>
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    <div className='fv-row mb-7'>
                        <label className='form-label fs-6 fw-bolder text-dark'>Street Address Line 2</label>
                        <input
                            {...formik.getFieldProps('pfn_address1street2')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid': formik.touched.pfn_address1street2 && formik.errors.pfn_address1street2,
                            })}
                            name='pfn_address1street2'
                            autoComplete='off'
                        />
                        {formik.touched.pfn_address1street2 && formik.errors.pfn_address1street2 && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_address1street2}
                                </span>
                            </div>
                        )}
                    </div>
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    <div className='row fv-row'>
                        {/* Start Form Column */}
                        <div className='col-xl-6'>
                            <div className='fv-row mb-7'>
                                <label className='form-label fs-6 fw-bolder text-dark required'>
                                    Street Address City
                                </label>
                                <input
                                    {...formik.getFieldProps('pfn_address1city')}
                                    className={clsx('form-control form-control-lg form-control-solid', {
                                        'is-invalid': formik.touched.pfn_address1city && formik.errors.pfn_address1city,
                                    })}
                                    name='pfn_address1city'
                                    autoComplete='off'
                                />
                                {formik.touched.pfn_address1city && formik.errors.pfn_address1city && (
                                    <div className='fv-plugins-message-container'>
                                        <span className='text-danger' role='alert'>
                                            {formik.errors.pfn_address1city}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* End Form Column */}
                        {/* Start Form Column */}
                        <div className='col-xl-6'>
                            <div className='fv-row mb-7'>
                                <label className='form-label fs-6 fw-bolder text-dark required'>
                                    Street Address Postal Code
                                </label>
                                <input
                                    {...formik.getFieldProps('pfn_address1postalcode')}
                                    className={clsx('form-control form-control-lg form-control-solid', {
                                        'is-invalid':
                                            formik.touched.pfn_address1postalcode &&
                                            formik.errors.pfn_address1postalcode,
                                    })}
                                    name='pfn_address1postalcode'
                                    autoComplete='off'
                                />
                                {formik.touched.pfn_address1postalcode && formik.errors.pfn_address1postalcode && (
                                    <div className='fv-plugins-message-container'>
                                        <span className='text-danger' role='alert'>
                                            {formik.errors.pfn_address1postalcode}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* End Form Column */}
                    </div>
                    {/* End Form Row */}

                    {/* Start Form Row */}
                    <div className='fv-row mb-7'>
                        <label className='form-label fs-6 fw-bolder text-dark required'>
                            Is mailing address same as street address?
                        </label>
                        <select
                            {...formik.getFieldProps('same_address_1')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid': formik.touched.same_address_1 && formik.errors.same_address_1,
                            })}
                            name='same_address_1'
                        >
                            <option disabled value=''>
                                Select Option
                            </option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                        </select>
                        {formik.touched.same_address_1 && formik.errors.same_address_1 && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.same_address_1}
                                </span>
                            </div>
                        )}
                    </div>
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    {formik.values.same_address_1 === 'No' && (
                        <>
                            <div className='fv-row mb-7'>
                                <label className='form-label fs-6 fw-bolder text-dark required'>
                                    Mailing Address Line 1
                                </label>
                                <input
                                    autoComplete='off'
                                    {...formik.getFieldProps('pfn_address2street1')}
                                    className={clsx('form-control form-control-lg form-control-solid', {
                                        'is-invalid':
                                            formik.touched.pfn_address2street1 && formik.errors.pfn_address2street1,
                                    })}
                                />
                                {formik.touched.pfn_address2street1 && formik.errors.pfn_address2street1 && (
                                    <div className='fv-plugins-message-container'>
                                        <span className='text-danger' role='alert'>
                                            {formik.errors.pfn_address2street1}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    {formik.values.same_address_1 === 'No' && (
                        <>
                            <div className='fv-row mb-7'>
                                <label className='form-label fs-6 fw-bolder text-dark'>Mailing Address Line 2</label>
                                <input
                                    autoComplete='off'
                                    {...formik.getFieldProps('pfn_address2street2')}
                                    className={clsx('form-control form-control-lg form-control-solid', {
                                        'is-invalid':
                                            formik.touched.pfn_address2street2 && formik.errors.pfn_address2street2,
                                    })}
                                />
                                {formik.touched.pfn_address2street2 && formik.errors.pfn_address2street2 && (
                                    <div className='fv-plugins-message-container'>
                                        <span className='text-danger' role='alert'>
                                            {formik.errors.pfn_address2street2}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    {formik.values.same_address_1 === 'No' && (
                        <>
                            <div className='row fv-row'>
                                {/* Start Form Column */}
                                <div className='col-xl-6'>
                                    <div className='fv-row mb-7'>
                                        <label className='form-label fs-6 fw-bolder text-dark required'>
                                            Mailing Address City
                                        </label>
                                        <input
                                            autoComplete='off'
                                            {...formik.getFieldProps('pfn_address2city')}
                                            className={clsx('form-control form-control-lg form-control-solid', {
                                                'is-invalid':
                                                    formik.touched.pfn_address2city && formik.errors.pfn_address2city,
                                            })}
                                        />
                                        {formik.touched.pfn_address2city && formik.errors.pfn_address2city && (
                                            <div className='fv-plugins-message-container'>
                                                <span className='text-danger' role='alert'>
                                                    {formik.errors.pfn_address2city}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* End Form Column */}
                                {/* Start Form Column */}
                                <div className='col-xl-6'>
                                    <div className='fv-row mb-7'>
                                        <label className='form-label fs-6 fw-bolder text-dark required'>
                                            Mailing Address Postal Code
                                        </label>
                                        <input
                                            autoComplete='off'
                                            {...formik.getFieldProps('pfn_address2postalcode')}
                                            className={clsx('form-control form-control-lg form-control-solid', {
                                                'is-invalid':
                                                    formik.touched.pfn_address2postalcode &&
                                                    formik.errors.pfn_address2postalcode,
                                            })}
                                        />
                                        {formik.touched.pfn_address2postalcode && formik.errors.pfn_address2postalcode && (
                                            <div className='fv-plugins-message-container'>
                                                <span className='text-danger' role='alert'>
                                                    {formik.errors.pfn_address2postalcode}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* End Form Column */}
                            </div>
                        </>
                    )}
                    {/* End Form Row */}

                    {/* Start Form Row */}
                    <div className='fv-row mb-7'>
                        <label className='form-label fs-6 fw-bolder text-dark required'>
                            Is controlled drug shipping address same as street address?
                        </label>
                        <select
                            {...formik.getFieldProps('same_address_2')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid': formik.touched.same_address_2 && formik.errors.same_address_2,
                            })}
                            name='same_address_2'
                        >
                            <option disabled value=''>
                                Select Option
                            </option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                        </select>
                        {formik.touched.same_address_2 && formik.errors.same_address_2 && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.same_address_2}
                                </span>
                            </div>
                        )}
                    </div>
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    {formik.values.same_address_2 === 'No' && (
                        <>
                            <div className='fv-row mb-7'>
                                <label className='form-label fs-6 fw-bolder text-dark required'>
                                    Shipping Address Line 1
                                </label>
                                <input
                                    autoComplete='off'
                                    {...formik.getFieldProps('pfn_address3street1')}
                                    className={clsx('form-control form-control-lg form-control-solid', {
                                        'is-invalid':
                                            formik.touched.pfn_address3street1 && formik.errors.pfn_address3street1,
                                    })}
                                />
                                {formik.touched.pfn_address3street1 && formik.errors.pfn_address3street1 && (
                                    <div className='fv-plugins-message-container'>
                                        <span className='text-danger' role='alert'>
                                            {formik.errors.pfn_address3street1}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    {formik.values.same_address_2 === 'No' && (
                        <>
                            <div className='fv-row mb-7'>
                                <label className='form-label fs-6 fw-bolder text-dark'>Shipping Address Line 2</label>
                                <input
                                    autoComplete='off'
                                    {...formik.getFieldProps('pfn_address3street2')}
                                    className={clsx('form-control form-control-lg form-control-solid', {
                                        'is-invalid':
                                            formik.touched.pfn_address3street2 && formik.errors.pfn_address3street2,
                                    })}
                                />
                                {formik.touched.pfn_address3street2 && formik.errors.pfn_address3street2 && (
                                    <div className='fv-plugins-message-container'>
                                        <span className='text-danger' role='alert'>
                                            {formik.errors.pfn_address3street2}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    {formik.values.same_address_2 === 'No' && (
                        <>
                            <div className='row fv-row'>
                                {/* Start Form Column */}
                                <div className='col-xl-6'>
                                    <div className='fv-row mb-7'>
                                        <label className='form-label fs-6 fw-bolder text-dark required'>
                                            Shipping Address City
                                        </label>
                                        <input
                                            autoComplete='off'
                                            {...formik.getFieldProps('pfn_address3city')}
                                            className={clsx('form-control form-control-lg form-control-solid', {
                                                'is-invalid':
                                                    formik.touched.pfn_address3city && formik.errors.pfn_address3city,
                                            })}
                                        />
                                        {formik.touched.pfn_address3city && formik.errors.pfn_address3city && (
                                            <div className='fv-plugins-message-container'>
                                                <span className='text-danger' role='alert'>
                                                    {formik.errors.pfn_address3city}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* End Form Column */}
                                {/* Start Form Column */}
                                <div className='col-xl-6'>
                                    <div className='fv-row mb-7'>
                                        <label className='form-label fs-6 fw-bolder text-dark required'>
                                            Shipping Address Postal Code
                                        </label>
                                        <input
                                            autoComplete='off'
                                            {...formik.getFieldProps('pfn_address3postalcode')}
                                            className={clsx('form-control form-control-lg form-control-solid', {
                                                'is-invalid':
                                                    formik.touched.pfn_address3postalcode &&
                                                    formik.errors.pfn_address3postalcode,
                                            })}
                                        />
                                        {formik.touched.pfn_address3postalcode && formik.errors.pfn_address3postalcode && (
                                            <div className='fv-plugins-message-container'>
                                                <span className='text-danger' role='alert'>
                                                    {formik.errors.pfn_address3postalcode}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* End Form Column */}
                            </div>
                        </>
                    )}
                    {/* End Form Row */}

                    {/* Start Form Row */}
                    <div className='row fv-row'>
                        {/* Start Form Column */}
                        <div className='col-xl-6'>
                            <div className='fv-row mb-7'>
                                <label className='form-label fw-bolder text-dark fs-6 required'>Phone Number</label>
                                <MaskedInput
                                    mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                    {...formik.getFieldProps('pfn_phonenumber')}
                                    className={clsx('form-control form-control-lg form-control-solid', {
                                        'is-invalid': formik.touched.pfn_phonenumber && formik.errors.pfn_phonenumber,
                                    })}
                                />
                                {formik.touched.pfn_phonenumber && formik.errors.pfn_phonenumber && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert' className='text-danger'>
                                                {formik.errors.pfn_phonenumber}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* End Form Column */}
                        {/* Start Form Column */}
                        <div className='col-xl-6'>
                            <div className='fv-row mb-7'>
                                <label className='form-label fw-bolder text-dark fs-6 required'>Email</label>

                                <input
                                    {...formik.getFieldProps('pfn_email')}
                                    className={clsx('form-control form-control-lg form-control-solid', {
                                        'is-invalid': formik.touched.pfn_email && formik.errors.pfn_email,
                                    })}
                                />
                                {formik.touched.pfn_email && formik.errors.pfn_email && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert' className='text-danger'>
                                                {formik.errors.pfn_email}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* End Form Column */}
                    </div>
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    <div className='row fv-row'>
                        {/* Start Form Column */}
                        <div className='col-xl-6'>
                            <div className='fv-row mb-7'>
                                <label className='form-label fs-6 fw-bolder text-dark'>Website</label>
                                <input
                                    placeholder='url'
                                    {...formik.getFieldProps('pfn_website')}
                                    className={clsx('form-control form-control-lg form-control-solid', {
                                        'is-invalid': formik.touched.pfn_website && formik.errors.pfn_website,
                                    })}
                                    name='pfn_website'
                                    autoComplete='off'
                                />
                                {formik.touched.pfn_website && formik.errors.pfn_website && (
                                    <div className='fv-plugins-message-container'>
                                        <span role='alert' className='text-danger'>
                                            {formik.errors.pfn_website}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* End Form Column */}
                        {/* Start Form Column */}
                        <div className='col-xl-6'>
                            <div className='fv-row mb-7'>
                                <label className='form-label fs-6 fw-bolder text-dark'>Social Media</label>
                                <input
                                    placeholder='url'
                                    {...formik.getFieldProps('pfn_socialmedia')}
                                    className={clsx('form-control form-control-lg form-control-solid', {
                                        'is-invalid': formik.touched.pfn_socialmedia && formik.errors.pfn_socialmedia,
                                    })}
                                    name='pfn_socialmedia'
                                    autoComplete='off'
                                />
                                {formik.touched.pfn_socialmedia && formik.errors.pfn_socialmedia && (
                                    <div className='fv-plugins-message-container'>
                                        <span role='alert' className='text-danger'>
                                            {formik.errors.pfn_socialmedia}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* End Form Column */}
                    </div>
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    <div className='row fv-row'>
                        {/* Start Form Column */}
                        <div className='col-xl-6'>
                            <div className='fv-row mb-7'>
                                <label className='form-label fs-6 fw-bolder text-dark required'>Contact Name</label>
                                <input
                                    {...formik.getFieldProps('pfn_contactname')}
                                    className={clsx('form-control form-control-lg form-control-solid', {
                                        'is-invalid': formik.touched.pfn_contactname && formik.errors.pfn_contactname,
                                    })}
                                    name='pfn_contactname'
                                    autoComplete='off'
                                />
                                {formik.touched.pfn_contactname && formik.errors.pfn_contactname && (
                                    <div className='fv-plugins-message-container'>
                                        <span className='text-danger' role='alert'>
                                            {formik.errors.pfn_contactname}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* End Form Column */}
                        {/* Start Form Column */}
                        <div className='col-xl-6'>
                            <div className='fv-row mb-7'>
                                <label className='form-label fs-6 fw-bolder text-dark required'>Owner</label>
                                <input
                                    {...formik.getFieldProps('pfn_ownername')}
                                    className={clsx('form-control form-control-lg form-control-solid', {
                                        'is-invalid': formik.touched.pfn_ownername && formik.errors.pfn_ownername,
                                    })}
                                    name='pfn_ownername'
                                    autoComplete='off'
                                />
                                {formik.touched.pfn_ownername && formik.errors.pfn_ownername && (
                                    <div className='fv-plugins-message-container'>
                                        <span className='text-danger' role='alert'>
                                            {formik.errors.pfn_ownername}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* End Form Column */}
                    </div>
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    <div className='row fv-row'>
                        {/* Start Form Column */}
                        {/* <div className='col-xl-6'> */}
                        <div className='fv-row mb-7'>
                            <label className='form-label fs-6 fw-bolder text-dark'>
                                Other Veterinarians (Registrants can add or remove themselves by accessing their portal
                                and choosing if the information is published. Or contact{' '}
                                <a className='text-decoration-underline fw-bold' href='mailto:reception@cvbc.ca'>
                                    reception@cvbc.ca
                                </a>{' '}
                                to update)
                            </label>
                            <textarea
                                {...formik.getFieldProps('pfn_otherveterinarians')}
                                className={clsx('form-control form-control-lg form-control-solid', {
                                    'is-invalid':
                                        formik.touched.pfn_otherveterinarians && formik.errors.pfn_otherveterinarians,
                                })}
                                value={getVets()}
                                name='pfn_otherveterinarians'
                                autoComplete='off'
                                disabled={true}
                            />
                            {formik.touched.pfn_otherveterinarians && formik.errors.pfn_otherveterinarians && (
                                <div className='fv-plugins-message-container'>
                                    <span className='text-danger' role='alert'>
                                        {formik.errors.pfn_otherveterinarians}
                                    </span>
                                </div>
                            )}
                        </div>
                        {/* </div> */}
                        {/* End Form Column */}
                    </div>
                    {/* End Form Row */}
                    {/* Start Form Row */}
                    <div className='row fv-row'>
                        {/* Start Form Column */}
                        <div className='col-xl-6'>
                            <div className='fv-row mb-7'>
                                <label className='form-label fs-6 fw-bolder text-dark required'>Number of RVTs</label>
                                <input
                                    {...formik.getFieldProps('pfn_numberofrvts')}
                                    type='number'
                                    className={clsx('form-control form-control-lg form-control-solid', {
                                        'is-invalid': formik.touched.pfn_numberofrvts && formik.errors.pfn_numberofrvts,
                                    })}
                                    name='pfn_numberofrvts'
                                    autoComplete='off'
                                />
                                {formik.touched.pfn_numberofrvts && formik.errors.pfn_numberofrvts && (
                                    <div className='fv-plugins-message-container'>
                                        <span className='text-danger' role='alert'>
                                            {formik.errors.pfn_numberofrvts}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* End Form Column */}
                        {/* Start Form Column */}
                        <div className='col-xl-6'>
                            <div className='fv-row mb-7'>
                                <label className='form-label fs-6 fw-bolder text-dark required'>
                                    Number of other employees
                                </label>
                                <input
                                    {...formik.getFieldProps('pfn_numberofotheremployees')}
                                    type='number'
                                    className={clsx('form-control form-control-lg form-control-solid', {
                                        'is-invalid':
                                            formik.touched.pfn_numberofotheremployees &&
                                            formik.errors.pfn_numberofotheremployees,
                                    })}
                                    name='pfn_numberofotheremployees'
                                    autoComplete='off'
                                />
                                {formik.touched.pfn_numberofotheremployees && formik.errors.pfn_numberofotheremployees && (
                                    <div className='fv-plugins-message-container'>
                                        <span className='text-danger' role='alert'>
                                            {formik.errors.pfn_numberofotheremployees}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* End Form Column */}
                    </div>
                    {/* End Form Row */}

                    <div className='d-flex align-items-center mb-10'>
                        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                    </div>

                    <div className='fv-row'>
                        <div className='d-flex justify-content-between'>
                            <Link to='/facilities/assessments'>
                                <button type='button' className='btn btn-primary me-3'>
                                    Cancel
                                </button>
                            </Link>

                            <button type='submit' className='btn btn-primary' disabled={formik.isSubmitting || loading}>
                                {!loading && <span className='indicator-label'>Next</span>}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                    {errMsg && <div className='text-danger text-center mt-7'>{errMsg}</div>}
                </form>
            )}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { InitialDetailsForm };

/* -------------------------------------------------------------------------- */
