/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/pages/custom/applications/renewal/RenewalApplications.tsx
 * @author Jesse Zonneveld
 * @description Renewal applications page
 */

/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { PageTitle } from '../../../../../_metronic/layout/core';
import applicationApi from '../../../../api/applicationApi';
import { convertIsoDateToString } from '../../../../helpers';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { Application } from '../../../../types';
import { createRenewalApp } from '../../../../redux/slices/UserSlice';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const RenewalApplicationsPage: FC = () => {
    const user = useAppSelector((state) => state.user);
    const [loading, setLoading] = useState(true);
    const [loadingCreateApp, setLoadingCreateApp] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const [errMsgCreateApp, setErrMsgCreateApp] = useState<string | null>(null);
    const [applications, setApplications] = useState<Application[]>([]);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const getApplicationsAsync = async () => {
            try {
                const response = await applicationApi.getRenewalAppsByUserId();

                if (response) {
                    if (!response.success) {
                        if (response.message) {
                            setErrMsg(response.message);
                        } else {
                            setErrMsg('An error occured, please try again later');
                        }
                    } else {
                        if (response.data) {
                            setApplications(response.data);
                        } else {
                            setErrMsg('No data found');
                        }
                    }
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
            }
            setLoading(false);
        };

        getApplicationsAsync();

        return () => {
            setLoading(false);
            setErrMsg(null);
            setApplications([]);
        };
    }, []);

    const createNewApplication = async () => {
        try {
            setLoadingCreateApp(true);
            if (user.statusHistory.category.value && user.statusHistory.subcategory.value) {
                const response = await applicationApi.checkIfUserHasRenewalAppStarted();

                if (response) {
                    if (!response.success) {
                        if (response.message) {
                            setErrMsg(response.message);
                        } else {
                            setErrMsg('An error occured, please try again later');
                        }
                    } else {
                        if (response.data === false) {
                            const response2 = await dispatch(
                                createRenewalApp({
                                    pfn_categoryid: user.statusHistory.category.value,
                                    pfn_subcategoryid: user.statusHistory.subcategory.value,
                                }),
                            );

                            // const response2 = await applicationApi.createNewRenewalApp({
                            //     pfn_categoryid: user.statusHistory.category.value,
                            //     pfn_subcategoryid: user.statusHistory.subcategory.value,
                            // });
                            if (!response2.payload?.success) {
                                if (response2.payload?.message) {
                                    setErrMsgCreateApp(response2.payload?.message);
                                } else {
                                    setErrMsgCreateApp('An error occured, please try again later');
                                }
                            } else {
                                if (response2.payload?.data) {
                                    navigate(`/applications/renewal/${response2.payload?.data.pfn_applicationid}`);
                                } else {
                                    setErrMsgCreateApp('An error occured, please try again later');
                                }
                            }
                        } else {
                            setErrMsgCreateApp('You already have a renewal application started for this year');
                        }
                    }
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
            } else {
                setErrMsg('No category and/or subcategory found on user');
            }
            setLoadingCreateApp(false);
        } catch (err) {
            if (err instanceof Error) {
                setErrMsgCreateApp(err.message);
            } else {
                setErrMsgCreateApp('An error has occurred, please try again later');
            }
            setLoadingCreateApp(false);
        }
    };

    const generateGeneralInfo = () => {
        return (
            <div className='card card-xl mb-5 mb-xl-8'>
                <div className='card-header border-0'>
                    <h3 className='card-title fw-bolder text-dark'>General Information</h3>
                </div>

                <div className='card-body pt-0'>
                    <ul>
                        <li className='mb-5'>
                            The online renewal application process requires registrants to complete a series of
                            questions/declarations before you will be able to proceed to payment of your 2024
                            registration fees.
                        </li>
                        <li className='mb-5'>
                            This process will only allow you to renew your current form of registration for the 2024
                            registration year (eg. if you currently have a full Private Practice registration, you can
                            only renew for Private Practice registration through the online renewal process).
                        </li>
                        <li className='mb-5'>
                            If you wish to change your registration status for 2024, please:{' '}
                            <ol>
                                <li>Complete the renewal questions/declarations.</li>
                                <li>
                                    <b>Do not</b> proceed to the invoice payment.
                                </li>
                                <li>
                                    Contact{' '}
                                    <a className='text-decoration-underline fw-bold' href='mailto:registration@cvbc.ca'>
                                        registration@cvbc.ca
                                    </a>
                                    to discuss your plans and find out what you will need to do to complete your desired
                                    registration change.
                                </li>
                            </ol>
                        </li>
                    </ul>
                </div>
            </div>
        );
    };

    const GenerateMyApplications = () => {
        return (
            <div className='card card-xl mb-5 mb-xl-8'>
                <div className='card-header border-0'>
                    <h3 className='card-title fw-bolder text-dark'>My Renewal Applications</h3>
                </div>

                <div className='card-body pt-0'>
                    {applications.length > 0 ? (
                        <div className='table-container'>
                            <Table className='applications-table' striped bordered>
                                <thead>
                                    <tr>
                                        <th className='date fw-bolder ps-3'>Renewal Year</th>
                                        <th className='date fw-bolder'>Created Date</th>
                                        <th className='fw-bolder'>Type</th>
                                        <th className='fw-bolder'>Status</th>
                                        <th className='fw-bolder'>Details Completed</th>
                                        <th className='fw-bolder'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {applications.map((application, i) => {
                                        if (application.pfn_purpose_Formatted === 'Renewal') {
                                            return (
                                                <tr key={i}>
                                                    <td className='date ps-3'>
                                                        {application._pfn_renewalyearid_value_Formatted}
                                                    </td>
                                                    <td className='date'>
                                                        {convertIsoDateToString(application?.createdon)}
                                                    </td>
                                                    <td className='type'>
                                                        {application._pfn_applicationtypeid_value_Formatted}
                                                    </td>
                                                    <td className='status'>{application.statuscode_Formatted}</td>
                                                    <td className='details'>{application.pfn_fomcomplete_Formatted}</td>
                                                    <td className='action'>
                                                        {application.statuscode_Formatted === 'Active' ? (
                                                            <>
                                                                {application.pfn_fomcomplete ? (
                                                                    <Link
                                                                        to={`/applications/renewal/status/${application.pfn_applicationid}`}
                                                                    >
                                                                        <button
                                                                            type='button'
                                                                            className='btn btn-sm btn-primary my-1'
                                                                        >
                                                                            Check Status
                                                                        </button>
                                                                    </Link>
                                                                ) : (
                                                                    <Link
                                                                        to={`/applications/renewal/${application.pfn_applicationid}`}
                                                                    >
                                                                        <button
                                                                            type='button'
                                                                            className='btn btn-sm btn-primary my-1'
                                                                        >
                                                                            Continue Application
                                                                        </button>
                                                                    </Link>
                                                                )}
                                                            </>
                                                        ) : application.statuscode_Formatted === 'Granted' ? (
                                                            <Link
                                                                to={`/applications/renewal/status/${application.pfn_applicationid}`}
                                                            >
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-sm btn-primary my-1 me-2'
                                                                >
                                                                    View Summary
                                                                </button>
                                                            </Link>
                                                        ) : (
                                                            <div>---</div>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                    {user.renewalYear && applications[0].statuscode_Formatted !== 'Active' && (
                                        <div className='mt-10'>
                                            <button
                                                type='button'
                                                className='btn btn-primary my-1'
                                                onClick={createNewApplication}
                                                disabled={loadingCreateApp}
                                            >
                                                {!loadingCreateApp && (
                                                    <span className='indicator-label'>
                                                        Start {user.renewalYear} New Application
                                                    </span>
                                                )}
                                                {loadingCreateApp && (
                                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                                        Please wait...
                                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                    </span>
                                                )}
                                            </button>
                                            {errMsgCreateApp && <div>{errMsgCreateApp}</div>}
                                        </div>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    ) : user.renewalYear ? (
                        <div>
                            <div>
                                You have not yet started a renewal application for next year. Please press the button
                                below to begin the process. You will be able to save and resume the your registration
                                renewal at any point if you don’t complete the entire process at once. Once the
                                questionnaire is complete and submitted and an invoice has been issued, you will also be
                                able to find the invoice under “Invoices” on your Dashboard page.
                            </div>
                            <div className='mt-10'>
                                <button
                                    type='button'
                                    className='btn btn-primary my-1'
                                    onClick={createNewApplication}
                                    disabled={loadingCreateApp}
                                >
                                    {!loadingCreateApp && (
                                        <span className='indicator-label'>
                                            Start {user.renewalYear} New Application
                                        </span>
                                    )}
                                    {loadingCreateApp && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                                {errMsgCreateApp && <div>{errMsgCreateApp}</div>}
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className='mb-5'>
                                Your account does not need to renew yet so there is no need to visit this page. Please
                                use the navigation menu or go back to the dashboard to select a different option.
                            </div>
                            <div className='pt-lg-10 mb-10'>
                                <div className='text-center'>
                                    <Link to='/' className='btn btn-lg btn-primary fw-bolder'>
                                        Go to Dashboard
                                    </Link>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            {user.statusHistory.subcategory.formatted === 'Applicant' ? (
                <>
                    <div className='mb-5'>
                        You are not yet registered so there is no need to visit this page. Please use the navigation
                        menu or go back to the dashboard to select a different option.
                    </div>
                    <div className='pt-lg-10 mb-10'>
                        <div className='text-center'>
                            <Link to='/' className='btn btn-lg btn-primary fw-bolder'>
                                Go to Dashboard
                            </Link>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    {generateGeneralInfo()}
                    {loading ? (
                        <p>Retreiviing applications, please wait...</p>
                    ) : errMsg ? (
                        <p>{errMsg}</p>
                    ) : (
                        <>{GenerateMyApplications()}</>
                    )}
                </>
            )}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- WRAPPER -------------------------------- */

const RenewalApplicationsWrapper: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Renewal Applications</PageTitle>
            <RenewalApplicationsPage />
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { RenewalApplicationsWrapper };

/* -------------------------------------------------------------------------- */
