/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/modules/auth/LogoutAll.tsx
 * @author Jesse Zonneveld
 * @description Logout all component
 */

/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import { logoutUserAll } from '../../redux/slices/UserSlice';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

export const LogoutAll: FC = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const logout = async () => {
            await dispatch(logoutUserAll());
        };
        logout();
    }, [dispatch]);

    return <Navigate to='/auth' />;
};

/* -------------------------------------------------------------------------- */
