/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/routing/AppRoutes.tsx
 * @author Jesse Zonneveld
 * @description App routing
 */

/* ----------------------------- IMPORTS & SETUP ---------------------------- */

import { FC } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { PrivateRoutes } from './PrivateRoutes';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { Logout, AuthPage, LogoutAll } from '../modules/auth';
import { App } from '../App';
import { useAppSelector } from '../redux/hooks';
import { InvoicePublicWrapper } from '../pages/custom/invoices/InvoicePublic';
import { PaymentSuccessPublicWrapper } from '../pages/custom/payment/PaymentSuccessPublic';
import { ReceiptPublicWrapper } from '../pages/custom/receipts/ReceiptPublic';

const { PUBLIC_URL } = process.env;

/* -------------------------------------------------------------------------- */

/* --------------------------------- ROUTING -------------------------------- */

const AppRoutes: FC = () => {
    const userIsLoggedIn = useAppSelector((state) => state.user.username);

    return (
        <BrowserRouter basename={PUBLIC_URL}>
            <Routes>
                <Route element={<App />}>
                    <Route path='error/*' element={<ErrorsPage />} />
                    <Route path='logout' element={<Logout />} />
                    <Route path='logout-all' element={<LogoutAll />} />
                    {userIsLoggedIn ? (
                        <>
                            <Route path='/*' element={<PrivateRoutes />} />
                            <Route index element={<Navigate to='/dashboard' />} />
                        </>
                    ) : (
                        <>
                            <Route path='auth/*' element={<AuthPage />} />
                            <Route
                                path='shared/invoices/:invoice_name/:pfn_invoiceid'
                                element={<InvoicePublicWrapper />}
                            />
                            <Route path='shared/payment/success' element={<PaymentSuccessPublicWrapper />} />
                            <Route path='shared/receipts/:pfn_name/:pfn_paymentid' element={<ReceiptPublicWrapper />} />

                            <Route path='*' element={<Navigate to='/auth' />} />
                        </>
                    )}
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { AppRoutes };

/* -------------------------------------------------------------------------- */
