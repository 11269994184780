/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/pages/contact-information/components/ContactInfoWorkAddress.tsx
 * @author Jesse Zonneveld
 * @description Work address section for the contact information page
 */

/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { updateMailingAddress } from '../../../../redux/slices/UserSlice';
import { Link } from 'react-router-dom';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const ContactInfoWorkAddress: FC = () => {
    const user = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);

    return (
        <>
            {user.facilities.primary.name ? (
                <div className='card card-xl mb-5 mb-xl-8'>
                    <div className='card-header border-0'>
                        <h3 className='card-title fw-bolder text-dark'>
                            Work Address {user.address.mailing === 'Work' && ' (Mailing Address)'}
                        </h3>
                    </div>

                    <div className='card-body pt-0'>
                        <div className='mb-5'>
                            <span className='fw-bolder'>Note:</span> your work address is automatically set to the
                            address of the Practice Facility that is identified as your Primary Facility – to manage
                            your facilities,{' '}
                            <Link className='text-decoration-underline fw-bold' to={'/facilities/my-facilities'}>
                                click here.
                            </Link>
                        </div>
                        <div className='facility-card d-flex align-items-center bg-light rounded p-5 mb-7'>
                            <div className='facility-card-inner flex-grow-1 me-2'>
                                <div className='fw-bolder text-gray-800 fs-6 mb-3'>
                                    <span>{user.facilities.primary.name}</span>
                                </div>
                                <span className='text-muted fw-bold d-block'>
                                    {user.facilities.primary.address.line1}
                                </span>
                                <span className='text-muted fw-bold d-block'>
                                    {user.facilities.primary.address.line2}
                                </span>
                                <div>
                                    <span className='text-muted fw-bold'>{user.facilities.primary.address.city}</span>
                                    <span className='text-muted fw-bold'>, BC</span>
                                </div>
                                <span className='text-muted fw-bold d-block'>Canada</span>
                                <span className='text-muted fw-bold d-block'>
                                    {user.facilities.primary.address.postalCode}
                                </span>
                            </div>
                        </div>

                        {user.address.mailing !== 'Work' && (
                            <button
                                className='btn btn-sm btn-primary ms-3'
                                disabled={loading}
                                onClick={async () => {
                                    try {
                                        setLoading(true);
                                        setErrMsg(null);
                                        const response = await dispatch(updateMailingAddress('Work'));
                                        if (response) {
                                            if (!response.payload?.success) {
                                                if (response.payload?.message) {
                                                    setErrMsg(response.payload.message);
                                                } else {
                                                    setErrMsg('An error has occured, please try again later');
                                                }
                                            }
                                        } else {
                                            setErrMsg('An error has occured, please try again later');
                                        }
                                        setLoading(false);
                                    } catch (err) {
                                        if (err instanceof Error) {
                                            setErrMsg(err.message);
                                        } else {
                                            setErrMsg('An error has occurred, please try again later');
                                        }
                                        setLoading(false);
                                    }
                                }}
                            >
                                {!loading && <span className='indicator-label'>Set as mailing address</span>}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        )}

                        {errMsg && <div className='text-danger text-center mt-7'>{errMsg}</div>}
                    </div>
                </div>
            ) : (
                <div className='card card-xl mb-5 mb-xl-8'>
                    <div className='card-header border-0'>
                        <h3 className='card-title fw-bolder text-dark'>
                            Work Address {user.address.mailing === 'Work' && ' (Mailing Address)'}
                        </h3>
                    </div>

                    <div className='card-body pt-0'>
                        <div className='note-card d-flex align-items-center bg-light rounded p-5 mb-7'>
                            <div className='note-card-inner flex-grow-1 me-2'>
                                <div className='text-muted fw-bold'>
                                    Your work address will be automatically set when you select a{' '}
                                    <Link
                                        className='text-decoration-underline fw-bold'
                                        to={'/facilities/my-facilities'}
                                    >
                                        Primary Facility
                                    </Link>
                                    .
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { ContactInfoWorkAddress };

/* -------------------------------------------------------------------------- */
