/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/modules/auth/components/Registration.tsx
 * @author Jesse Zonneveld
 * @description Registration component
 */

/* ----------------------------- IMPORTS & SETUP ---------------------------- */

import { useState, useEffect, useRef, FC } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components';
import MaskedInput from 'react-text-mask';
import { RegisterFormValues } from '../../../types';
import userApi from '../../../api/userApi';
import miscApi from '../../../api/miscApi';
import { removeEmpty } from '../../../helpers';

/* -------------------------------------------------------------------------- */

/* --------------------------- FORM INITIAL VALUES -------------------------- */

const initialValues = {
    emailaddress1: '',
    pfn_salutation: '',
    firstname: '',
    middlename: '',
    lastname: '',
    gendercode: '',
    pfn_preferredidentifier: '',
    nickname: '',
    birthdate: '',
    pfn_birthplace: '',
    pfn_immigrationstatus: '',
    pfn_wpexpirationdate: '',
    pfn_immigrationstatusnumber: '',
    telephone2: '',
    mobilephone: '',
    // telephone1: '',
    address2_line1: '',
    address2_line2: '',
    address2_city: '',
    address2_stateorprovince: '',
    address2_postalcode: '',
    address2_country: '',
    pfn_categoryid: '',
    pfn_password: '',
    passwordConfirmation: '',
};

/* -------------------------------------------------------------------------- */

/* ------------------------------- FORM SCHEMA ------------------------------ */

const phoneRegExp = /^\d{3}[-]\d{3}[-]\d{4}$/;
// const postalCodeRegExp = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

export const Registration: FC = () => {
    const [hidden, setHidden] = useState(true);
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const [submitted, setSubmitted] = useState(false);
    const emailHasBeenTested = useRef(false);
    const emailIsValid = useRef(false);
    const [currentEmail, setCurrentEmail] = useState('');
    const [countries, setCountries] = useState<string[]>([]);
    const [provincesOrStates, setProvincesOrStates] = useState<string[]>([]);

    const registrationSchema = Yup.object().shape({
        emailaddress1: Yup.string()
            .email('Wrong email format')
            .required('Email is required')
            .test('checkIfEmailAlreadyExists', 'Email address already in use', async (val) => {
                try {
                    if (val && val !== currentEmail) {
                        setCurrentEmail(val);
                        emailHasBeenTested.current = false;
                    }

                    if (val && val.match(emailRegex)) {
                        if (emailHasBeenTested.current === false) {
                            const response = await userApi.checkIfEmailAlreadyExists(val);
                            emailHasBeenTested.current = true;

                            if (response && response.data) {
                                emailIsValid.current = false;
                            } else {
                                emailIsValid.current = true;
                            }
                        }
                        return emailIsValid.current;
                    }
                    return false;
                } catch (err) {
                    return false;
                }
            }),
        pfn_salutation: Yup.string().required('Salutation is required'),
        firstname: Yup.string()
            .matches(/^[a-z ,.'-]+$/i, 'Please enter valid name')
            .max(50)
            .required('First name is required'),
        middlename: Yup.string()
            .optional()
            .matches(/^[a-z ,.'-]+$/i, 'Please enter valid name')
            .max(50),
        lastname: Yup.string()
            .matches(/^[a-z ,.'-]+$/i, 'Please enter valid name')
            .max(50)
            .required('Last name is required'),
        gendercode: Yup.string().required('Gender is required'),
        pfn_preferredidentifier: Yup.string(),
        nickname: Yup.string(),
        birthdate: Yup.string()
            .required('Birthday is required')
            .test('checkIfDateIsTooOld', 'Birthdate too old', (val) => {
                if (!val) {
                    return true;
                }
                const dateLimit = new Date();
                dateLimit.setFullYear(new Date().getFullYear() - 120);
                return new Date(val) > dateLimit;
            })
            .test('checkIfDateIsFuture', 'Birthdate must be in the past', (val) => {
                if (!val) {
                    return true;
                }

                return new Date() > new Date(val);
            }),
        pfn_birthplace: Yup.string()
            .required('Place of birth is required')
            .matches(/^[a-z]+$/i, 'Please enter valid country'),
        pfn_immigrationstatus: Yup.string().required('Immigration status is required'),
        pfn_immigrationstatusnumber: Yup.string().when('pfn_immigrationstatus', {
            is: 'Canadian Citizenship',
            otherwise: Yup.string().required('Immigration number is required'),
        }),
        pfn_wpexpirationdate: Yup.string().when('pfn_immigrationstatus', {
            is: 'Work Permit',
            then: Yup.string().required('Work permit expiry date is required'),
        }),
        telephone2: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
        mobilephone: Yup.string()
            .matches(phoneRegExp, 'Phone number is not valid')
            .required('Mobile phone is required'),
        // telephone1: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
        address2_line1: Yup.string().required('Address Line 1 is required'),
        address2_line2: Yup.string(),
        address2_city: Yup.string().required('Address city is required'),
        address2_stateorprovince: Yup.string().required('Address province is required'),
        address2_postalcode: Yup.string().required('Address postal code is required'),
        address2_country: Yup.string().required('Address country is required'),
        pfn_categoryid: Yup.string().required('Category is required'),
        pfn_password: Yup.string()
            .required('Password is required')
            .min(8, 'Minimum 8 characters is required')
            .matches(/[a-z]+/, 'One lowercase character is required')
            .matches(/[A-Z]+/, 'One uppercase character is required')
            .matches(/[@$!%*#?&.+=_-]+/, 'One special character is required')
            .matches(/\d+/, 'One number is required'),
        passwordConfirmation: Yup.string()
            .required('Password confirmation is required')
            .when('pfn_password', {
                is: (val: string) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf([Yup.ref('pfn_password')], 'Passwords need to match'),
            }),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: registrationSchema,
        onSubmit: async (values) => {
            try {
                setErrMsg(null);
                setLoading(true);
                const convertedValues = convertValues(values);
                const response = await userApi.registerUser(convertedValues as RegisterFormValues);
                if (response) {
                    if (!response.success) {
                        if (response.message) {
                            setErrMsg(response.message || 'An error has occured, please try again later');
                        }
                    }
                } else {
                    setErrMsg('An error has occured, please try again later');
                }
                setSubmitted(true);
                setLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
                setSubmitted(true);
                setLoading(false);
            }
        },
    });

    useEffect(() => {
        PasswordMeterComponent.bootstrap();
    }, []);

    useEffect(() => {
        const getCountiesAsync = async () => {
            try {
                const response = await miscApi.getCountries();
                setCountries(response?.data ? response.data : []);
            } catch (err) {
                console.error(err);
            }
        };

        getCountiesAsync();

        return () => {
            setCountries([]);
        };
    }, []);

    useEffect(() => {
        if (formik.values.address2_country === 'Canada') {
            getProvincesAsync();
        } else if (formik.values.address2_country === 'United States') {
            getStatesAsync();
        }

        return () => {
            setProvincesOrStates([]);
        };
    }, [formik.values.address2_country]);

    const getProvincesAsync = async () => {
        try {
            const response = await miscApi.getProvinces();
            setProvincesOrStates(response?.data ? response.data : []);
        } catch (err) {
            console.error(err);
        }
    };

    const getStatesAsync = async () => {
        try {
            const response = await miscApi.getStates();
            setProvincesOrStates(response?.data ? response.data : []);
        } catch (err) {
            console.error(err);
        }
    };

    const convertValues = (values: RegisterFormValues) => {
        if (values.pfn_immigrationstatus !== 'Work Permit') {
            values.pfn_wpexpirationdate = '';
        }

        if (values.pfn_immigrationstatus === 'Canadian Citizenship') {
            values.pfn_immigrationstatusnumber = '';
        }

        return removeEmpty(values);
    };

    return (
        <div className='w-lg-800px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
            <form
                className='form w-300 fv-plugins-bootstrap5 fv-plugins-framework'
                id='registration_form'
                onSubmit={formik.handleSubmit}
            >
                <div className='mb-10 text-center'>
                    <h1 className='text-dark mb-3'>Create an Account</h1>
                    <div className='text-gray-400 fw-bold fs-4'>
                        Already have an account?
                        <Link to='/auth/login' className='link-primary fw-bolder' style={{ marginLeft: '5px' }}>
                            Sign In
                        </Link>
                    </div>
                </div>

                <div className='d-flex align-items-center mb-10'>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                    {/* <span className='fw-bold text-gray-400 fs-7 mx-2'>OR</span> */}
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                </div>

                {submitted && errMsg ? (
                    <>
                        <div className='mb-lg-15 alert alert-danger'>
                            <div className='alert-text font-weight-bold text-danger'>{errMsg}</div>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <Link to='/auth/login'>
                                <button
                                    type='button'
                                    id='kt_login_password_reset_form_reset_button'
                                    className='btn btn-lg btn-primary fw-bolder'
                                    onClick={() => {
                                        formik.resetForm();
                                        setSubmitted(false);
                                        setErrMsg(null);
                                    }}
                                >
                                    Back
                                </button>
                            </Link>
                        </div>
                    </>
                ) : (
                    submitted && (
                        <>
                            <div className='mt-10 alert alert-success'>
                                <div className='alert-text font-weight-bold'>
                                    Your registration has been successfully submitted, please check your email to
                                    confirm your account
                                </div>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <Link to='/auth/login'>
                                    <button
                                        type='button'
                                        id='kt_login_password_reset_form_reset_button'
                                        className='btn btn-lg btn-primary fw-bolder'
                                        onClick={() => {
                                            formik.resetForm();
                                            setSubmitted(false);
                                        }}
                                    >
                                        Back
                                    </button>
                                </Link>
                            </div>
                        </>
                    )
                )}
                {!submitted && (
                    <>
                        {/* Start Form Row */}
                        <div className='row fv-row mb-7'>
                            {/* Start Form Column */}
                            <div className='col-xl-6'>
                                <div className='fv-row mb-5'>
                                    <label className='form-label fw-bolder text-dark fs-6 required'>Email</label>

                                    <input
                                        type='text'
                                        {...formik.getFieldProps('emailaddress1')}
                                        className={clsx('form-control form-control-lg form-control-solid', {
                                            'is-invalid': formik.touched.emailaddress1 && formik.errors.emailaddress1,
                                        })}
                                    />
                                    {formik.touched.emailaddress1 && formik.errors.emailaddress1 && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert' className='text-danger'>
                                                    {formik.errors.emailaddress1}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* End Form Column */}
                            {/* Start Form Column */}
                            <div className='col-xl-6'>
                                <div className='fv-row mb-5'>
                                    <label className='form-label fw-bolder text-dark fs-6 required'>Salutation</label>
                                    <select
                                        {...formik.getFieldProps('pfn_salutation')}
                                        className={clsx('form-control form-control-lg form-control-solid', {
                                            'is-invalid': formik.touched.pfn_salutation && formik.errors.pfn_salutation,
                                        })}
                                        name='pfn_salutation'
                                    >
                                        <option disabled value=''>
                                            Select Salutation
                                        </option>
                                        <option value='Dr.'>Dr.</option>
                                        <option value='Mr.'>Mr.</option>
                                        <option value='Mrs.'>Mrs.</option>
                                        <option value='Ms.'>Ms.</option>
                                    </select>
                                    {formik.touched.pfn_salutation && formik.errors.pfn_salutation && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert' className='text-danger'>
                                                    {formik.errors.pfn_salutation}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* End Form Column */}
                        </div>
                        {/* End Form Row */}
                        {/* Start Form Row */}
                        <div className='row fv-row mb-7'>
                            {/* Start Form Column */}
                            <div className='col-xl-4'>
                                <div className='fv-row mb-5'>
                                    <label className='form-label fw-bolder text-dark fs-6 required'>First name</label>

                                    <input
                                        type='text'
                                        {...formik.getFieldProps('firstname')}
                                        className={clsx('form-control form-control-lg form-control-solid', {
                                            'is-invalid': formik.touched.firstname && formik.errors.firstname,
                                        })}
                                    />
                                    {formik.touched.firstname && formik.errors.firstname && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert' className='text-danger'>
                                                    {formik.errors.firstname}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* End Form Column */}
                            {/* Start Form Column */}
                            <div className='col-xl-4'>
                                <div className='fv-row mb-5'>
                                    <label className='form-label fw-bolder text-dark fs-6'>Middle name</label>
                                    <input
                                        type='text'
                                        {...formik.getFieldProps('middlename')}
                                        className={clsx('form-control form-control-lg form-control-solid', {
                                            'is-invalid': formik.touched.middlename && formik.errors.middlename,
                                        })}
                                    />
                                    {formik.touched.middlename && formik.errors.middlename && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert' className='text-danger'>
                                                    {formik.errors.middlename}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* End Form Column */}
                            {/* Start Form Column */}
                            <div className='col-xl-4'>
                                <div className='fv-row mb-5'>
                                    <label className='form-label fw-bolder text-dark fs-6 required'>Last name</label>

                                    <input
                                        type='text'
                                        {...formik.getFieldProps('lastname')}
                                        className={clsx('form-control form-control-lg form-control-solid', {
                                            'is-invalid': formik.touched.lastname && formik.errors.lastname,
                                        })}
                                    />
                                    {formik.touched.lastname && formik.errors.lastname && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert' className='text-danger'>
                                                    {formik.errors.lastname}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* End Form Column */}
                        </div>
                        {/* End Form Row */}
                        {/* Start Form Row */}
                        <div className='row fv-row mb-7'>
                            {/* Start Form Column */}
                            <div className='col-xl-4'>
                                <div className='fv-row mb-5'>
                                    <label className='form-label fw-bolder text-dark fs-6 required'>Gender</label>

                                    <select
                                        {...formik.getFieldProps('gendercode')}
                                        className={clsx('form-control form-control-lg form-control-solid', {
                                            'is-invalid': formik.touched.gendercode && formik.errors.gendercode,
                                        })}
                                        name='gendercode'
                                    >
                                        <option disabled value=''>
                                            Select Gender
                                        </option>
                                        <option value='Male'>Male</option>
                                        <option value='Female'>Female</option>
                                    </select>
                                    {formik.touched.gendercode && formik.errors.gendercode && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert' className='text-danger'>
                                                    {formik.errors.gendercode}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* End Form Column */}
                            {/* Start Form Column */}
                            <div className='col-xl-4'>
                                <div className='fv-row mb-5'>
                                    <label className='form-label fw-bolder text-dark fs-6'>Preferred Identifier</label>
                                    <select
                                        {...formik.getFieldProps('pfn_preferredidentifier')}
                                        className={clsx('form-control form-control-lg form-control-solid', {
                                            'is-invalid':
                                                formik.touched.pfn_preferredidentifier &&
                                                formik.errors.pfn_preferredidentifier,
                                        })}
                                        name='pfn_preferredidentifier'
                                    >
                                        <option disabled value=''>
                                            Select Identifier
                                        </option>
                                        <option value='---'>None</option>
                                        <option value='He/him'>He/him</option>
                                        <option value='She/her'>She/her</option>
                                        <option value='They/them'>They/them</option>
                                    </select>
                                    {formik.touched.pfn_preferredidentifier && formik.errors.pfn_preferredidentifier && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert' className='text-danger'>
                                                    {formik.errors.pfn_preferredidentifier}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* End Form Column */}
                            {/* Start Form Column */}
                            <div className='col-xl-4'>
                                <div className='fv-row mb-5'>
                                    <label className='form-label fw-bolder text-dark fs-6'>Preferred Name</label>

                                    <input
                                        type='text'
                                        {...formik.getFieldProps('nickname')}
                                        className={clsx('form-control form-control-lg form-control-solid', {
                                            'is-invalid': formik.touched.nickname && formik.errors.nickname,
                                        })}
                                    />
                                    {formik.touched.nickname && formik.errors.nickname && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert' className='text-danger'>
                                                    {formik.errors.nickname}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* End Form Column */}
                        </div>
                        {/* End Form Row */}
                        {/* Start Form Row */}
                        <div className='row fv-row mb-7'>
                            {/* Start Form Column */}
                            <div className='col-xl-6'>
                                <div className='fv-row mb-5'>
                                    <label className='form-label fw-bolder text-dark fs-6 required'>Birthday</label>

                                    <input
                                        type='date'
                                        min='1900-01-01'
                                        max='9999-12-31'
                                        {...formik.getFieldProps('birthdate')}
                                        className={clsx('form-control form-control-lg form-control-solid', {
                                            'is-invalid': formik.touched.birthdate && formik.errors.birthdate,
                                        })}
                                    />
                                    {formik.touched.birthdate && formik.errors.birthdate && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert' className='text-danger'>
                                                    {formik.errors.birthdate}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* End Form Column */}
                            {/* Start Form Column */}
                            <div className='col-xl-6'>
                                <div className='fv-row mb-5'>
                                    <label className='form-label fw-bolder text-dark fs-6 required'>
                                        Place of Birth (Country)
                                    </label>

                                    <input
                                        type='text'
                                        {...formik.getFieldProps('pfn_birthplace')}
                                        className={clsx('form-control form-control-lg form-control-solid', {
                                            'is-invalid': formik.touched.pfn_birthplace && formik.errors.pfn_birthplace,
                                        })}
                                    />
                                    {formik.touched.pfn_birthplace && formik.errors.pfn_birthplace && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert' className='text-danger'>
                                                    {formik.errors.pfn_birthplace}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* End Form Column */}
                        </div>
                        {/* End Form Row */}
                        {/* Start Form Row */}
                        <div className='row fv-row mb-7'>
                            {/* Start Form Column */}
                            <div
                                className={clsx({
                                    'col-xl-4': formik.values.pfn_immigrationstatus === 'Work Permit',
                                    'col-xl-6':
                                        formik.values.pfn_immigrationstatus === 'Permanent Resident' ||
                                        formik.values.pfn_immigrationstatus === 'Work Permit - Pending',
                                    'col-xl-12': formik.values.pfn_immigrationstatus === 'Canadian Citizenship',
                                })}
                            >
                                <div className='fv-row mb-5'>
                                    <label className='form-label fw-bolder text-dark fs-6 required'>
                                        Immigration Status
                                    </label>

                                    <select
                                        {...formik.getFieldProps('pfn_immigrationstatus')}
                                        className={clsx('form-control form-control-lg form-control-solid', {
                                            'is-invalid':
                                                formik.touched.pfn_immigrationstatus &&
                                                formik.errors.pfn_immigrationstatus,
                                        })}
                                        name='pfn_immigrationstatus'
                                    >
                                        <option disabled value=''>
                                            Select Status
                                        </option>
                                        <option value='Canadian Citizenship'>Canadian Citizenship</option>
                                        <option value='Permanent Resident'>Permanent Resident</option>
                                        <option value='Work Permit'>Work Permit</option>
                                        <option value='Work Permit - Pending'>Work Permit - Pending</option>
                                    </select>
                                    {formik.touched.pfn_immigrationstatus && formik.errors.pfn_immigrationstatus && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert' className='text-danger'>
                                                    {formik.errors.pfn_immigrationstatus}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* End Form Column */}
                            {/* Start Form Column */}
                            <div
                                className={clsx({
                                    'col-xl-4': formik.values.pfn_immigrationstatus === 'Work Permit',
                                    'col-xl-6':
                                        formik.values.pfn_immigrationstatus === 'Permanent Resident' ||
                                        formik.values.pfn_immigrationstatus === 'Work Permit - Pending',
                                    'd-none':
                                        formik.values.pfn_immigrationstatus === 'Canadian Citizenship' ||
                                        !formik.values.pfn_immigrationstatus,
                                })}
                            >
                                <div className='fv-row mb-5'>
                                    <label className='form-label fw-bolder text-dark fs-6 required'>
                                        Immigration Number
                                    </label>
                                    <input
                                        type='text'
                                        {...formik.getFieldProps('pfn_immigrationstatusnumber')}
                                        className={clsx('form-control form-control-lg form-control-solid', {
                                            'is-invalid':
                                                formik.touched.pfn_immigrationstatusnumber &&
                                                formik.errors.pfn_immigrationstatusnumber,
                                        })}
                                    />
                                    {formik.touched.pfn_immigrationstatusnumber &&
                                        formik.errors.pfn_immigrationstatusnumber && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert' className='text-danger'>
                                                        {formik.errors.pfn_immigrationstatusnumber}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>
                            {/* End Form Column */}
                            {/* Start Form Column */}
                            <div
                                className={clsx({
                                    'col-xl-4': formik.values.pfn_immigrationstatus === 'Work Permit',
                                    'd-none': formik.values.pfn_immigrationstatus !== 'Work Permit',
                                })}
                            >
                                <div className='fv-row mb-5'>
                                    <label className='form-label fw-bolder text-dark fs-6 required'>
                                        Work Permit Expiry Date
                                    </label>
                                    <input
                                        type='date'
                                        min='1900-01-01'
                                        max='9999-12-31'
                                        {...formik.getFieldProps('pfn_wpexpirationdate')}
                                        className={clsx('form-control form-control-lg form-control-solid', {
                                            'is-invalid':
                                                formik.touched.pfn_wpexpirationdate &&
                                                formik.errors.pfn_wpexpirationdate,
                                        })}
                                    />
                                    {formik.touched.pfn_wpexpirationdate && formik.errors.pfn_wpexpirationdate && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert' className='text-danger'>
                                                    {formik.errors.pfn_wpexpirationdate}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* End Form Column */}
                        </div>
                        {/* End Form Row */}
                        {/* Start Form Row */}
                        <div className='row fv-row mb-7'>
                            {/* Start Form Column */}
                            <div className='col-xl-6'>
                                <div className='fv-row mb-5'>
                                    <label className='form-label fw-bolder text-dark fs-6'>Home Phone</label>
                                    <MaskedInput
                                        mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                        {...formik.getFieldProps('telephone2')}
                                        className={clsx('form-control form-control-lg form-control-solid', {
                                            'is-invalid': formik.touched.telephone2 && formik.errors.telephone2,
                                        })}
                                    />

                                    {formik.touched.telephone2 && formik.errors.telephone2 && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert' className='text-danger'>
                                                    {formik.errors.telephone2}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* End Form Column */}
                            {/* Start Form Column */}
                            <div className='col-xl-6'>
                                <div className='fv-row mb-5'>
                                    <label className='form-label fw-bolder text-dark fs-6 required'>Mobile Phone</label>
                                    <MaskedInput
                                        mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                        {...formik.getFieldProps('mobilephone')}
                                        className={clsx('form-control form-control-lg form-control-solid', {
                                            'is-invalid': formik.touched.mobilephone && formik.errors.mobilephone,
                                        })}
                                    />
                                    {formik.touched.mobilephone && formik.errors.mobilephone && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert' className='text-danger'>
                                                    {formik.errors.mobilephone}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* End Form Column */}
                            {/* Start Form Column */}
                            {/* <div className='col-xl-4'>
                                <div className='fv-row mb-5'>
                                    <label className='form-label fw-bolder text-dark fs-6'>Business Phone</label>
                                    <MaskedInput
                                        mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                        {...formik.getFieldProps('telephone1')}
                                        className={clsx('form-control form-control-lg form-control-solid', {
                                            'is-invalid': formik.touched.telephone1 && formik.errors.telephone1,
                                        })}
                                    />
                                    {formik.touched.telephone1 && formik.errors.telephone1 && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert' className='text-danger'>
                                                    {formik.errors.telephone1}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div> */}
                            {/* End Form Column */}
                        </div>
                        {/* End Form Row */}

                        <div className='d-flex align-items-center mb-10'>
                            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                            {/* <span className='fw-bold text-gray-400 fs-7 mx-2'>OR</span> */}
                            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                        </div>

                        {/* Start Form Group */}
                        <div>
                            <label className='form-label fw-bolder text-dark fs-6 mb-7 required'>Home Address</label>

                            {/* Start Form Row */}
                            <div className='fv-row mb-5'>
                                <label className='form-label fs-6'>Address Line 1</label>
                                <input
                                    type='text'
                                    {...formik.getFieldProps('address2_line1')}
                                    className={clsx('form-control form-control-lg form-control-solid', {
                                        'is-invalid': formik.touched.address2_line1 && formik.errors.address2_line1,
                                    })}
                                />
                                {formik.touched.address2_line1 && formik.errors.address2_line1 && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert' className='text-danger'>
                                                {formik.errors.address2_line1}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* End Form Row */}
                            {/* Start Form Row */}
                            <div className='fv-row mb-5'>
                                <label className='form-label fs-6'>Address Line 2</label>
                                <input
                                    type='text'
                                    {...formik.getFieldProps('address2_line2')}
                                    className={clsx('form-control form-control-lg form-control-solid', {
                                        'is-invalid': formik.touched.address2_line2 && formik.errors.address2_line2,
                                    })}
                                />
                                {formik.touched.address2_line2 && formik.errors.address2_line2 && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert' className='text-danger'>
                                                {formik.errors.address2_line2}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* End Form Row */}
                            {/* Start Form Row */}
                            <div className='row fv-row mb-5'>
                                {/* Start Form Column */}
                                <div className='col-xl-6'>
                                    <div className='fv-row mb-5'>
                                        <label className='form-label fs-6'>City</label>
                                        <input
                                            type='text'
                                            {...formik.getFieldProps('address2_city')}
                                            className={clsx('form-control form-control-lg form-control-solid', {
                                                'is-invalid':
                                                    formik.touched.address2_city && formik.errors.address2_city,
                                            })}
                                        />
                                        {formik.touched.address2_city && formik.errors.address2_city && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert' className='text-danger'>
                                                        {formik.errors.address2_city}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* End Form Column */}
                                {/* Start Form Column */}
                                <div className='col-xl-6'>
                                    <div className='fv-row mb-5'>
                                        <label className='form-label fs-6'>Country</label>
                                        {countries.length >= 1 ? (
                                            <select
                                                {...formik.getFieldProps('address2_country')}
                                                className={clsx('form-control form-control-lg form-control-solid', {
                                                    'is-invalid':
                                                        formik.touched.address2_country &&
                                                        formik.errors.address2_country,
                                                })}
                                                name='address2_country'
                                            >
                                                <option disabled value=''>
                                                    Select Country
                                                </option>
                                                {countries &&
                                                    countries.map((country, i) => {
                                                        return (
                                                            <option key={i} value={country}>
                                                                {country}
                                                            </option>
                                                        );
                                                    })}
                                                <option value='Other'>Other</option>
                                            </select>
                                        ) : (
                                            <input
                                                type='text'
                                                {...formik.getFieldProps('address2_country')}
                                                className={clsx('form-control form-control-lg form-control-solid', {
                                                    'is-invalid':
                                                        formik.touched.address2_country &&
                                                        formik.errors.address2_country,
                                                })}
                                            />
                                        )}
                                        {formik.touched.address2_country && formik.errors.address2_country && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert' className='text-danger'>
                                                        {formik.errors.address2_country}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* End Form Column */}
                            </div>
                            {/* End Form Row */}
                            {/* Start Form Row */}
                            <div className='row fv-row mb-5'>
                                {/* Start Form Column */}
                                <div className='col-xl-6'>
                                    <div className='fv-row mb-5'>
                                        <label className='form-label fs-6'>Postal Code</label>
                                        <MaskedInput
                                            mask={[
                                                /[a-zA-Z0-9]/,
                                                /[a-zA-Z0-9]/,
                                                /[a-zA-Z0-9]/,
                                                /[a-zA-Z0-9]/,
                                                /[a-zA-Z0-9]/,
                                                /[a-zA-Z0-9]/,
                                            ]}
                                            {...formik.getFieldProps('address2_postalcode')}
                                            className={clsx('form-control form-control-lg form-control-solid', {
                                                'is-invalid':
                                                    formik.touched.address2_postalcode &&
                                                    formik.errors.address2_postalcode,
                                            })}
                                        />
                                        {formik.touched.address2_postalcode && formik.errors.address2_postalcode && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert' className='text-danger'>
                                                        {formik.errors.address2_postalcode}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* End Form Column */}
                                {/* Start Form Column */}
                                <div className='col-xl-6'>
                                    <div className='fv-row mb-5'>
                                        <label className='form-label fs-6'>Province/State</label>
                                        {formik.values.address2_country === 'Canada' ||
                                        formik.values.address2_country === 'United States' ? (
                                            <select
                                                {...formik.getFieldProps('address2_stateorprovince')}
                                                className={clsx('form-control form-control-lg form-control-solid', {
                                                    'is-invalid':
                                                        formik.touched.address2_stateorprovince &&
                                                        formik.errors.address2_stateorprovince,
                                                })}
                                                name='address2_stateorprovince'
                                            >
                                                <option disabled value=''>
                                                    {formik.values.address2_country === 'Canada'
                                                        ? 'Select Province'
                                                        : formik.values.address2_country === 'United States'
                                                        ? 'Select State'
                                                        : 'Select'}
                                                </option>
                                                {provincesOrStates &&
                                                    provincesOrStates.map((place, i) => {
                                                        return (
                                                            <option key={i} value={place}>
                                                                {place}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                        ) : (
                                            <input
                                                type='text'
                                                {...formik.getFieldProps('address2_stateorprovince')}
                                                className={clsx('form-control form-control-lg form-control-solid', {
                                                    'is-invalid':
                                                        formik.touched.address2_stateorprovince &&
                                                        formik.errors.address2_stateorprovince,
                                                })}
                                            />
                                        )}

                                        {formik.touched.address2_stateorprovince &&
                                            formik.errors.address2_stateorprovince && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert' className='text-danger'>
                                                            {formik.errors.address2_stateorprovince}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                </div>
                                {/* End Form Column */}
                            </div>
                            {/* End Form Row */}
                        </div>
                        {/* End Form Group */}

                        <div className='d-flex align-items-center mb-10'>
                            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                            {/* <span className='fw-bold text-gray-400 fs-7 mx-2'>OR</span> */}
                            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                        </div>

                        {/* Start Form Row */}
                        <div className='fv-row mb-10'>
                            <label className='form-label fw-bolder text-dark fs-6 required'>Category</label>

                            <select
                                {...formik.getFieldProps('pfn_categoryid')}
                                className={clsx('form-control form-control-lg form-control-solid', {
                                    'is-invalid': formik.touched.pfn_categoryid && formik.errors.pfn_categoryid,
                                })}
                                name='pfn_categoryid'
                            >
                                <option disabled value=''>
                                    Select Category
                                </option>
                                <option value='Private Practice'>Private Practice</option>
                                <option value='Specialty Private Practice'>Specialty Private Practice</option>
                                <option value='Public Sector'>Public Sector</option>
                            </select>
                            <div className='text-muted mt-1'>Please select the category you are planning to apply.</div>
                            {formik.touched.pfn_categoryid && formik.errors.pfn_categoryid && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert' className='text-danger'>
                                            {formik.errors.pfn_categoryid}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* End Form Row */}

                        <div className='d-flex align-items-center mb-10'>
                            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                            {/* <span className='fw-bold text-gray-400 fs-7 mx-2'>OR</span> */}
                            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                        </div>

                        {/* Start Form Row */}
                        <div className='mb-10 fv-row' data-kt-password-meter='true'>
                            <div className='mb-1'>
                                <label className='form-label fw-bolder text-dark fs-6 required'>Password</label>

                                <div className='position-relative mb-3'>
                                    <div className='password-input-container'>
                                        <input
                                            type={hidden ? 'password' : 'text'}
                                            autoComplete='off'
                                            {...formik.getFieldProps('pfn_password')}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid password-input',
                                                {
                                                    'is-invalid':
                                                        formik.touched.pfn_password && formik.errors.pfn_password,
                                                },
                                            )}
                                        />
                                        <i
                                            className={clsx('bi fs-1 password-toggle', {
                                                'bi-eye': hidden,
                                                'bi-eye-slash': !hidden,
                                            })}
                                            onClick={() => {
                                                setHidden(!hidden);
                                            }}
                                        ></i>
                                    </div>
                                    {formik.touched.pfn_password && formik.errors.pfn_password && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert' className='text-danger'>
                                                    {formik.errors.pfn_password}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div
                                    className='d-flex align-items-center mb-3'
                                    data-kt-password-meter-control='highlight'
                                >
                                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                                </div>
                            </div>
                            <div className='text-muted'>
                                Use 8 or more characters with a mix of letters, numbers & symbols.
                            </div>
                        </div>
                        {/* End Form Row */}

                        {/* Start Form Row */}
                        <div className='fv-row mb-10'>
                            <label className='form-label fw-bolder text-dark fs-6 required'>Confirm Password</label>
                            <div className='password-input-container'>
                                <input
                                    type={hidden ? 'password' : 'text'}
                                    autoComplete='off'
                                    {...formik.getFieldProps('passwordConfirmation')}
                                    className={clsx('form-control form-control-lg form-control-solid password-input', {
                                        'is-invalid':
                                            formik.touched.passwordConfirmation && formik.errors.passwordConfirmation,
                                    })}
                                />
                                <i
                                    className={clsx('bi fs-1 password-toggle', {
                                        'bi-eye': hidden,
                                        'bi-eye-slash': !hidden,
                                    })}
                                    onClick={() => {
                                        setHidden(!hidden);
                                    }}
                                ></i>
                            </div>
                            {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert' className='text-danger'>
                                            {formik.errors.passwordConfirmation}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* End Form Row */}

                        <div className='d-flex align-items-center mb-10'>
                            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                            {/* <span className='fw-bold text-gray-400 fs-7 mx-2'>OR</span> */}
                            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                        </div>

                        {/* Start Form Row */}
                        <div className='text-center'>
                            <button
                                type='submit'
                                id='kt_sign_up_submit'
                                className='btn btn-lg btn-primary w-100 mb-5'
                                // disabled={formik.isSubmitting || !(formik.isValid && formik.dirty)}
                                disabled={formik.isSubmitting || loading}
                            >
                                {!loading && <span className='indicator-label'>Submit</span>}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                            <Link to='/auth/login'>
                                <button
                                    type='button'
                                    id='kt_login_signup_form_cancel_button'
                                    className='btn btn-lg btn-light-primary w-100 mb-5'
                                >
                                    Cancel
                                </button>
                            </Link>
                        </div>
                        {/* End Form Row */}
                    </>
                )}
            </form>
        </div>
    );
};

/* -------------------------------------------------------------------------- */
