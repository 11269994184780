import React from 'react';
import { MenuItem } from './MenuItem';
import { MenuInnerWithSub } from './MenuInnerWithSub';
// import {MegaMenu} from './MegaMenu'
import { useIntl } from 'react-intl';
import { useAppSelector } from '../../../../app/redux/hooks';

export function MenuInner() {
    const intl = useIntl();
    const user = useAppSelector((state) => state.user);

    return (
        <>
            <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard' />
            {/* <MenuItem title='Layout Builder' to='/builder' /> */}

            <MenuInnerWithSub title='Registration' to='/registration' menuPlacement='bottom-start' menuTrigger='click'>
                <MenuInnerWithSub
                    title='Application'
                    to='/application'
                    hasArrow={true}
                    menuPlacement='right-start'
                    menuTrigger={`{default:'click', lg: 'hover'}`}
                >
                    {user.statusHistory.subcategory.formatted === 'Applicant' ? (
                        <MenuItem to='/applications/registration' title='Registration' />
                    ) : (
                        <>
                            <div className='menu-item me-lg-1'>
                                <a
                                    className='menu-link py-3'
                                    href='https://www.cvbc.ca/registration/class-and-name-changes/'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    <span className='menu-title'>Registration Class Transfer</span>
                                </a>
                            </div>
                            {user.renewalYear && <MenuItem to='/applications/renewal' title='Renewal' />}
                        </>
                    )}
                    <MenuItem to='/applications/history' title='Application History' />
                </MenuInnerWithSub>
                <MenuItem title='Certificate of Licensure' to='/certificate-licensure' />
                <MenuItem title='Wallet Card' to='/wallet-card' />
                {/* <MenuItem title='Manage Facilities' to='/manage-facilties' /> */}
                <MenuInnerWithSub
                    title='Facilities'
                    to='/facilities'
                    hasArrow={true}
                    menuPlacement='right-start'
                    menuTrigger={`{default:'click', lg: 'hover'}`}
                >
                    <MenuItem to='/facilities/my-facilities' title='My Facilities' />
                    <MenuItem to='/facilities/search' title='Facility Search' />
                    <MenuItem to='/facilities/assessments' title='Annual Declarations' />
                </MenuInnerWithSub>
                <MenuInnerWithSub
                    title='Receipts'
                    to='/receipts'
                    hasArrow={true}
                    menuPlacement='right-start'
                    menuTrigger={`{default:'click', lg: 'hover'}`}
                >
                    <MenuItem to='/receipts/all' title='All Receipts' />
                    <MenuItem to='/receipts/personal' title='Personal Receipts' />
                    <MenuItem to='/receipts/facility' title='Facility Receipts' />
                </MenuInnerWithSub>
                {/* <MenuItem title='Receipts' to='/receipts' /> */}
                <MenuInnerWithSub
                    title='Invoices'
                    to='/invoices'
                    hasArrow={true}
                    menuPlacement='right-start'
                    menuTrigger={`{default:'click', lg: 'hover'}`}
                >
                    <MenuItem to='/invoices/all' title='All Invoices' />
                    <MenuItem to='/invoices/personal' title='Personal Invoices' />
                    <MenuItem to='/invoices/facility' title='Facility Invoices' />
                </MenuInnerWithSub>
                {/* <MenuItem title='Invoices' to='/invoices' /> */}
            </MenuInnerWithSub>
            <MenuInnerWithSub
                title='Continuing Education'
                to='/continuing-education'
                menuPlacement='bottom-start'
                menuTrigger='click'
            >
                <MenuItem title='CE Transcripts' to='/transcripts' />
                <div className='menu-item me-lg-1'>
                    <a className='menu-link py-3' href='https://classroom.cvbc.ca/' target='_blank' rel='noreferrer'>
                        <span className='menu-title'>Learning Hub</span>
                    </a>
                </div>
                {/* <MenuItem title='Submit Credits' to='/submit-credits' /> */}
                {/* <MenuItem title='Event Registration' to='/event-registration' /> */}

                {/* <MenuInnerWithSub
          title='Pages'
          to='/crafted/pages'
          fontIcon='bi-archive'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuInnerWithSub
            title='Profile'
            to='/crafted/pages/profile'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
            <MenuItem
              to='/crafted/pages/profile/connections'
              title='Connections'
              hasBullet={true}
            />
          </MenuInnerWithSub>
          <MenuInnerWithSub
            title='Wizards'
            to='/crafted/pages/wizards'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/pages/wizards/horizontal' title='Horizontal' hasBullet={true} />
            <MenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
          </MenuInnerWithSub>
        </MenuInnerWithSub>

        <MenuInnerWithSub
          title='Accounts'
          to='/crafted/accounts'
          fontIcon='bi-person'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
          <MenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
        </MenuInnerWithSub>

        <MenuInnerWithSub
          title='Errors'
          to='/error'
          fontIcon='bi-sticky'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/error/404' title='Error 404' hasBullet={true} />
          <MenuItem to='/error/500' title='Error 500' hasBullet={true} />
        </MenuInnerWithSub>

        <MenuInnerWithSub
          title='Widgets'
          to='/crafted/widgets'
          fontIcon='bi-layers'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
          <MenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
          <MenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
          <MenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
          <MenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
          <MenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
        </MenuInnerWithSub> */}
            </MenuInnerWithSub>
            <MenuInnerWithSub
                title='Account Management'
                to='/account-management'
                menuPlacement='bottom-start'
                menuTrigger='click'
            >
                <MenuItem title='Contact Information' to='/contact-info' />
                <MenuItem title='Change Password' to='/change-password' />
                <MenuItem title='Change Permission' to='/change-permission' />
                <MenuItem title='Change Email' to='/change-email' />
            </MenuInnerWithSub>
            <div className='menu-item me-lg-1'>
                <a className='menu-link py-3' href='https://cvbc.ca/for-registrant/' target='_blank' rel='noreferrer'>
                    <span className='menu-title'>Registrant Website Content</span>
                </a>
            </div>

            {/* <MenuInnerWithSub
        isMega={true}
        title='Mega menu'
        to='/mega-menu'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MegaMenu />
      </MenuInnerWithSub> */}
        </>
    );
}
