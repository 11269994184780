/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/api/facilityApi.ts
 * @author Jesse Zonneveld
 * @description Facility api
 */

/* --------------------------------- LINTING -------------------------------- */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

/* -------------------------------------------------------------------------- */

/* --------------------------------- IMPORTS -------------------------------- */

import { ApiResponse, UpdateFacilityFormDetails, Facility } from '../types';
import { apiClient } from './config';

/* -------------------------------------------------------------------------- */

/* ----------------------------------- API ---------------------------------- */

const getFacilitiesByName = async (searchQuery: string | undefined): Promise<ApiResponse<Facility[]>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<Facility[]>>('/facilities/search', { searchQuery });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getFacilityById = async (accountid: string): Promise<ApiResponse<Facility>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<Facility>>('/facilities/find-by-id', { accountid });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getFacilitiesByUserId = async (): Promise<ApiResponse<Facility[]>> => {
    try {
        const { data } = await apiClient.get<ApiResponse<Facility[]>>('/facilities/my-facilities');
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const addFacility = async (accountid: string) => {
    try {
        const { data } = await apiClient.put<ApiResponse<Facility>>('/facilities/add', { accountid });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const removeFacility = async (accountid: string) => {
    try {
        const { data } = await apiClient.put<ApiResponse<string>>('/facilities/remove', { accountid });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const updateFacilityDetails = async (details: UpdateFacilityFormDetails) => {
    try {
        const { data } = await apiClient.put<ApiResponse<Facility>>('/facilities/edit', details);
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

/* -------------------------------------------------------------------------- */

/* ------------------------------- API OBJECT ------------------------------- */

const facilityApi = {
    getFacilitiesByName,
    getFacilityById,
    getFacilitiesByUserId,
    addFacility,
    removeFacility,
    updateFacilityDetails,
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export default facilityApi;

/* -------------------------------------------------------------------------- */
