/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/pages/custom/facilities/facility-assessments/formController/forms/DeclarationsForm.tsx
 * @author Jesse Zonneveld
 * @description Assessment form seventh step
 */

/* --------------------------------- IMPORTS -------------------------------- */

import * as Yup from 'yup';
import { FC, useState } from 'react';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { FacilityAssessment } from '../../../../../../types';
import facilityAssessmentApi from '../../../../../../api/facilityAssessmentsApi';
import { useAppSelector } from '../../../../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { convertToSafeString } from '../../../../../../helpers';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

interface Props {
    goToNextStep: Function;
    assessment: FacilityAssessment;
    goToPreviousStep: Function;
}

/* -------------------------------------------------------------------------- */

/* --------------------------------- SCHEMA --------------------------------- */

const validationSchema = Yup.object().shape({
    declaration: Yup.string()
        .required('This field is required is required')
        .test('checkIfAgree', 'Please contact CVBC as to why you do not agree, thank you.', (val) => {
            return val === 'Yes';
        }),
});

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const DeclarationsForm: FC<Props> = ({ goToNextStep, assessment, goToPreviousStep }) => {
    const user = useAppSelector((state) => state.user);
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const navigate = useNavigate();

    const initialValues = {
        declaration: '',
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                setErrMsg(null);
                setLoading(true);

                const response = await facilityAssessmentApi.submitAssessment(
                    assessment.pfn_facilityselfassessmentid,
                    assessment._pfn_facilityid_value,
                );

                if (response) {
                    if (!response.success) {
                        if (response.message) {
                            setErrMsg(response.message);
                        } else {
                            setErrMsg('An error has occured, please try again later');
                        }
                    } else {
                        navigate(
                            `/facilities/assessments/${convertToSafeString(
                                assessment._pfn_facilityid_value_Formatted,
                            )}/${assessment._pfn_facilityid_value}`,
                        );
                    }
                } else {
                    setErrMsg('An error has occured, please try again later');
                }

                setLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
                setLoading(false);
            }
        },
    });

    return (
        <>
            {errMsg ? (
                <div>{errMsg}</div>
            ) : (
                <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='initailDetailsForm'>
                    {/* Start Form Row */}
                    <div className='fv-row mb-7'>
                        <label className='form-label fs-6'>
                            <div className='mb-5'>
                                I,{' '}
                                <span className='text-primary'>
                                    {user.firstname} {user.lastname}
                                </span>
                                , the Designated Registrant of{' '}
                                <span className='text-primary'>{assessment._pfn_facilityid_value_Formatted}</span>{' '}
                                hereby sign this undertaking declaring that I have read and understood my duties under
                                section 3.6 of{' '}
                                <a
                                    href='https://www.cvbc.ca/wp-content/uploads/2020/03/Part-3-Accreditation-and-Naming.pdf'
                                    target='_blank'
                                    rel='noreferrer'
                                    className='text-decoration-underline'
                                >
                                    Part 3 of the CVBC Bylaws 3
                                </a>
                                , and without limiting the generality of section 3.6, I understand that:
                            </div>
                            <ol>
                                <li className='mb-2'>
                                    I will not provide a fuller range of practice services than my accredited Scope of
                                    Practice without prior authorization by the Practice Facility Accreditation
                                    Committee. Additional species within a category (e.g., companion animals) that is
                                    already accredited may be seen, but those in another unaccredited species category
                                    cannot be seen without approval of the PFAC.
                                </li>
                                <li>
                                    If the facility is planning to relocate or perform renovations, I will contact the
                                    CVBC to arrange for an inspection and accreditation of the space before the facility
                                    starts providing services from the new or renovated space.
                                </li>
                            </ol>
                        </label>
                        <select
                            {...formik.getFieldProps('declaration')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid': formik.touched.declaration && formik.errors.declaration,
                            })}
                            name='declaration'
                        >
                            <option disabled value=''>
                                Select Option
                            </option>
                            <option value='Yes'>Yes, I agree</option>
                            <option value='No'>No, I do not agree</option>
                        </select>
                        {formik.touched.declaration && formik.errors.declaration && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.declaration}
                                </span>
                            </div>
                        )}
                    </div>
                    {/* End Form Row */}

                    <div className='d-flex align-items-center mb-10'>
                        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                    </div>

                    <div className='fv-row'>
                        <div className='d-flex justify-content-between'>
                            <button type='button' className='btn btn-primary me-3' onClick={() => goToPreviousStep()}>
                                Previous
                            </button>

                            <button type='submit' className='btn btn-primary' disabled={formik.isSubmitting || loading}>
                                {!loading && <span className='indicator-label'>Submit</span>}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                    {errMsg && <div className='text-danger text-center mt-7'>{errMsg}</div>}
                </form>
            )}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { DeclarationsForm };

/* -------------------------------------------------------------------------- */
