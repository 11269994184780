/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/pages/change-permission/ChangePermission.tsx
 * @author Jesse Zonneveld
 * @description Change permission page
 */

/* --------------------------------- IMPORTS -------------------------------- */
import { FC, useState } from 'react';
import { PageTitle } from '../../../../_metronic/layout/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { updatePermission } from '../../../redux/slices/UserSlice';
import { Link } from 'react-router-dom';

/* -------------------------------------------------------------------------- */

/* ------------------------------- FORM SCHEMA ------------------------------ */

const schema = Yup.object().shape({
    pfn_onlinepublication: Yup.string().required('Online publication is required'),
});

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const ChangePermission: FC = () => {
    const user = useAppSelector((state) => state.user);
    const [loading, setLoading] = useState(false);
    const [successfullySubmitted, setSuccessfullySubmitted] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const dispatch = useAppDispatch();

    const initialValues = {
        pfn_onlinepublication: user.permission.formatted ? user.permission.formatted : '',
    };

    const formik = useFormik({
        initialValues,
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                setErrMsg(null);
                setLoading(true);
                setSuccessfullySubmitted(false);
                const response = await dispatch(updatePermission(values.pfn_onlinepublication));
                if (response) {
                    if (!response.payload?.success) {
                        if (response.payload?.message) {
                            setErrMsg(response.payload.message);
                        } else {
                            setErrMsg('An error has occured, please try again later');
                        }
                    } else {
                        setSuccessfullySubmitted(true);
                    }
                } else {
                    setErrMsg('An error has occured, please try again later');
                }
                setLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
                setLoading(false);
            }
        },
    });

    return (
        <div className='w-lg-600px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
            <form className='form w-100' onSubmit={formik.handleSubmit} id='edit_phone_form'>
                <div className='mb-10'>
                    <div className='mb-5'>
                        <span className='fw-bold'>Note:</span> The Veterinarians Act (section 41) sets requirements for
                        what must be published in a registrant’s entry in the CVBC’s Online Registry. But, in order for
                        the CVBC to include any details about the veterinary practice(s) at which you work, you must
                        provide permission. This is intended to control public access to information about work
                        environments not intended to be accessible to the general public, and also for mobile practice
                        facilities where the office/business address is a registrant’s private residence and not
                        intended to be directly accessible to the public.
                    </div>
                    <div>
                        <div className='mb-2'>Publication Options:</div>
                        <ol>
                            <li>
                                Publication of all business contact information (Business name, phone number & street
                                address)
                            </li>
                            <li>
                                Publication of partial business information (Business name, phone number, city, but not
                                the street address)
                            </li>
                            <li>
                                No business information publication – your practice facilities/place of work will not be
                                posted in your online registry entry.
                            </li>
                        </ol>
                    </div>
                </div>
                {/* Start Form Row */}
                <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark mb-5'>
                        Please choose how much of your business information you would like published in your entry in
                        the CVBC’s Online Registry:
                    </label>
                    <select
                        {...formik.getFieldProps('pfn_onlinepublication')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_onlinepublication && formik.errors.pfn_onlinepublication,
                        })}
                        name='pfn_onlinepublication'
                    >
                        <option disabled value=''>
                            Select Permission Type
                        </option>
                        <option value='All Business Info'>Publication of all business contact information</option>
                        <option value='Business Name & Phone'>Publication of partial business information</option>
                        <option value='None'>No business information publication</option>
                    </select>
                    {formik.touched.pfn_onlinepublication && formik.errors.pfn_onlinepublication && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                    {formik.errors.pfn_onlinepublication}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}

                <div className='d-flex align-items-center mb-10'>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                </div>

                <div className='fv-row'>
                    <div className='d-flex justify-content-end'>
                        <Link to='/'>
                            <button className='btn btn-light me-3'>Back</button>
                        </Link>

                        <button type='submit' className='btn btn-primary' disabled={formik.isSubmitting || loading}>
                            {!loading && <span className='indicator-label'>Submit</span>}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </div>
                {errMsg && <div className='text-danger text-center mt-7'>{errMsg}</div>}
                {successfullySubmitted && (
                    <div className='text-primary text-center mt-7'>Your permission has been succeffuly updated</div>
                )}
            </form>
        </div>
    );
};

/* -------------------------------------------------------------------------- */

/* ------------------------------ PAGE WRAPPER ------------------------------ */

const ChangePermissionWrapper: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Change Permissions for Publication of Work Information</PageTitle>
            <ChangePermission />
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { ChangePermissionWrapper };
