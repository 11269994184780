/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/pages/change-email/components/ChangeMainEmail.tsx
 * @author Jesse Zonneveld
 * @description Change main email section for the change email page
 */

/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useState } from 'react';
import { KTSVG } from '../../../../../_metronic/helpers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { changeMainEmail } from '../../../../redux/slices/UserSlice';
import { closeModalById } from '../../../../helpers';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const ChangeMainEmail: FC = () => {
    const user = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);

    const initialValues = {
        mainEmail: user.email.main === user.email.personal ? 'personal' : 'work',
    };

    const validationSchema = Yup.object().shape({
        mainEmail: Yup.string()
            .test('checkIfSelectedEmailExistsOnUser', 'Email selected currently not set up', (val) => {
                if (val === 'work') {
                    if (user.email.work) {
                        return true;
                    } else {
                        return false;
                    }
                } else if (val === 'personal') {
                    if (user.email.personal) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            })
            .test('checkIfSelectedEmailAlreadyMain', 'Email selected is already the main email', (val) => {
                if (val === 'work') {
                    if (user.email.main !== user.email.work) {
                        return true;
                    } else {
                        return false;
                    }
                } else if (val === 'personal') {
                    if (user.email.main !== user.email.personal) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                setErrMsg(null);
                setLoading(true);

                if (values.mainEmail !== 'work' && values.mainEmail !== 'personal') {
                    throw new Error('Main email must be personal or email');
                }

                const response = await dispatch(changeMainEmail(values.mainEmail));
                if (response) {
                    if (!response.payload?.success) {
                        if (response.payload?.message) {
                            setErrMsg(response.payload.message);
                        } else {
                            setErrMsg('An error has occured, please try again later');
                        }
                    } else {
                        closeModalById('kt_modal_edit_main_email');
                    }
                } else {
                    setErrMsg('An error has occured, please try again later');
                }

                setLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
                setLoading(false);
            }
        },
    });

    const editMainEmailModal = () => {
        return (
            <div className='modal fade' tabIndex={-1} id='kt_modal_edit_main_email'>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Edit Main Email</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
                            </div>
                        </div>
                        <div className='modal-body'>{editMainEmailForm()}</div>
                    </div>
                </div>
            </div>
        );
    };

    const editMainEmailForm = () => {
        return (
            <form className='form w-100' onSubmit={formik.handleSubmit} id='edit_email_form'>
                {/* Start Form Row */}
                <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Main Email</label>
                    <select
                        {...formik.getFieldProps('mainEmail')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.mainEmail && formik.errors.mainEmail,
                        })}
                        name='mainEmail'
                    >
                        <option disabled value=''>
                            Select Main Email
                        </option>
                        <option value='personal'>Personal</option>
                        <option value='work'>Work</option>
                    </select>
                    {formik.touched.mainEmail && formik.errors.mainEmail && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                    {formik.errors.mainEmail}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}

                <div className='d-flex align-items-center mb-10 mt-9'>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                </div>

                <div className='fv-row'>
                    <div className='d-flex justify-content-end'>
                        <button type='button' className='btn btn-light me-3' data-bs-dismiss='modal'>
                            Cancel
                        </button>

                        <button type='submit' className='btn btn-primary' disabled={formik.isSubmitting || loading}>
                            {!loading && <span className='indicator-label'>Submit</span>}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </div>
                {errMsg && <div className='text-danger text-center mt-7'>{errMsg}</div>}
            </form>
        );
    };

    return (
        <div className='d-flex flex-column mb-5'>
            <label className='form-label fs-6 fw-bolder text-dark'>Main Email (Username)</label>
            <div className='d-flex align-items-center me-2 bg-light h-45px rounded'>
                <div className='px-5 flex-grow-1 fs-6 d-block text-gray-600 fw-bold'>
                    {user.email.main === user.email.personal ? 'Personal' : 'Work'}
                </div>
                <button
                    className='btn btn-icon btn-primary h-100 rounded-0 rounded-end'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_edit_main_email'
                    onClick={() => {
                        formik.resetForm();
                        setErrMsg(null);
                    }}
                >
                    <i className='bi bi-pencil-square fs-2 pe-0'></i>
                </button>
            </div>
            {editMainEmailModal()}
        </div>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { ChangeMainEmail };

/* -------------------------------------------------------------------------- */
