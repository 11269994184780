/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/pages/custom/applications/registration/RegistrationApplications.tsx
 * @author Jesse Zonneveld
 * @description Registration applications page
 */

/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PageTitle } from '../../../../../_metronic/layout/core';
import applicationApi from '../../../../api/applicationApi';
import { convertIsoDateToString } from '../../../../helpers';
import { useAppSelector } from '../../../../redux/hooks';
import { Application } from '../../../../types';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const RegistrationApplicationsPage: FC = () => {
    const user = useAppSelector((state) => state.user);
    const [loading, setLoading] = useState(true);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const [applications, setApplications] = useState<Application[]>([]);
    // const [transcripts, setTranscripts] = useState<Transcript[]>();
    // const [eligible, setEligable] = useState(true);
    // const [renewalCount, setRenewalCount] = useState(0);
    // const renewalYear = '2023';
    // const renewalEnd = new Date(Date.UTC(2024, 1, 1));

    // useEffect(() => {
    //     if (
    //         user.registrationNumber === '01952' ||
    //         user.registrationNumber === '02981' ||
    //         user.registrationNumber === '00974' ||
    //         user.registrationNumber === '01010' ||
    //         user.registrationNumber === '01962' ||
    //         user.registrationNumber === '03136' ||
    //         user.registrationNumber === '01533' ||
    //         user.registrationNumber === '00399' ||
    //         user.registrationNumber === '00984' ||
    //         user.registrationNumber === '01092' ||
    //         user.registrationNumber === '02915' ||
    //         user.registrationNumber === '01236' ||
    //         user.registrationNumber === '01891' ||
    //         user.registrationNumber === '03031' ||
    //         user.registrationNumber === '01667' ||
    //         user.registrationNumber === '02602' ||
    //         user.registrationNumber === '01002' ||
    //         user.registrationNumber === '01968' ||
    //         user.registrationNumber === '00868' ||
    //         user.registrationNumber === '03216'
    //     ) {
    //         setEligable(false);
    //     }
    // }, [user.registrationNumber]);

    useEffect(() => {
        const getApplicationsAsync = async () => {
            try {
                const response = await applicationApi.getRegAppsByUserId();

                if (response) {
                    if (!response.success) {
                        if (response.message) {
                            setErrMsg(response.message);
                        } else {
                            setErrMsg('An error occured, please try again later');
                        }
                    } else {
                        if (response.data) {
                            setApplications(response.data);
                        } else {
                            setErrMsg('No data found');
                        }
                    }
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
            }
            setLoading(false);
        };

        getApplicationsAsync();

        return () => {
            setLoading(false);
            setErrMsg(null);
            setApplications([]);
        };
    }, []);

    const generateGeneralInfo = () => {
        return (
            <div className='card card-xl mb-5 mb-xl-8'>
                <div className='card-header border-0'>
                    <h3 className='card-title fw-bolder text-dark'>General Information</h3>
                </div>

                <div className='card-body pt-0'>
                    <ul>
                        <li className='mb-5'>
                            Please read through the{' '}
                            <a
                                className='text-decoration-underline fw-bold'
                                href='https://www.cvbc.ca/registration/registration-application/'
                                target='_blank'
                                rel='noreferrer'
                            >
                                Registration Application Guide
                            </a>{' '}
                            in its entirety before continuing with the application process below. There you will find an
                            extensive guide on all you need to know about the application process.{' '}
                        </li>
                        <li className='mb-5'>
                            To complete the Online Application for Registration please start a new online application
                            below or continue with an existing online application. Alternatively, you can print and
                            manually complete the{' '}
                            <a
                                className='text-decoration-underline fw-bold'
                                href='https://www.cvbc.ca/wp-content/uploads/2020/03/Application-for-Registration.pdf'
                                target='_blank'
                                rel='noreferrer'
                            >
                                pdf-version of the application form.
                            </a>
                        </li>
                        <li className='mb-5'>
                            With either format (online applications or pdf-version), a hard copy of the completed
                            application must be signed in front of a notary public or commissioner of oaths, then the
                            original document, with all supporting materials, must be mailed to the CVBC office to the
                            attention of the Registration Coordinator.
                        </li>
                    </ul>
                </div>
            </div>
        );
    };

    const GenerateMyApplications = () => {
        return (
            <div className='card card-xl mb-5 mb-xl-8'>
                <div className='card-header border-0'>
                    <h3 className='card-title fw-bolder text-dark'>My Registration Applications</h3>
                </div>

                <div className='card-body pt-0'>
                    {applications.length > 0 ? (
                        <div className='table-container'>
                            <Table className='applications-table' striped bordered>
                                <thead>
                                    <tr>
                                        <th className='date fw-bolder ps-3'>Registration Year</th>
                                        <th className='date fw-bolder'>Created Date</th>
                                        {/* <th className='fw-bolder'>Received Date</th> */}
                                        <th className='fw-bolder'>Type</th>
                                        <th className='fw-bolder'>Status</th>
                                        <th className='fw-bolder'>Details Completed</th>
                                        <th className='fw-bolder'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {applications.map((application, i) => {
                                        if (application.pfn_purpose_Formatted === 'Registration') {
                                            return (
                                                <tr key={i}>
                                                    <td className='date ps-3'>
                                                        {application._pfn_renewalyearid_value_Formatted}
                                                    </td>
                                                    <td className='date'>
                                                        {convertIsoDateToString(application?.createdon)}
                                                    </td>
                                                    {/* <td className='date'>
                                                    {convertIsoDateToString(application?.pfn_receiveddate)}
                                                </td> */}
                                                    <td className='type'>
                                                        {application._pfn_applicationtypeid_value_Formatted}
                                                    </td>
                                                    <td className='status'>{application.statuscode_Formatted}</td>
                                                    <td className='details'>{application.pfn_fomcomplete_Formatted}</td>
                                                    <td className='action'>
                                                        {application.statuscode_Formatted === 'Active' ||
                                                        application.statuscode_Formatted === 'Ready to be finalized' ? (
                                                            <>
                                                                {application.pfn_fomcomplete ? (
                                                                    <>
                                                                        <Link
                                                                            to={`/applications/registration/status/${application.pfn_applicationid}`}
                                                                        >
                                                                            <button
                                                                                type='button'
                                                                                className='btn btn-sm btn-primary my-1 me-2'
                                                                            >
                                                                                Check Status
                                                                            </button>
                                                                        </Link>
                                                                        <Link
                                                                            to={`/applications/registration/${application.pfn_applicationid}`}
                                                                        >
                                                                            <button
                                                                                type='button'
                                                                                className='btn btn-sm btn-primary my-1'
                                                                            >
                                                                                Print Summary
                                                                            </button>
                                                                        </Link>
                                                                    </>
                                                                ) : (
                                                                    <Link
                                                                        to={`/applications/registration/${application.pfn_applicationid}`}
                                                                    >
                                                                        <button
                                                                            type='button'
                                                                            className='btn btn-sm btn-primary my-1'
                                                                        >
                                                                            Continue Application
                                                                        </button>
                                                                    </Link>
                                                                )}
                                                            </>
                                                        ) : application.statuscode_Formatted === 'Granted' ? (
                                                            <Link
                                                                to={`/applications/registration/status/${application.pfn_applicationid}`}
                                                            >
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-sm btn-primary my-1 me-2'
                                                                >
                                                                    View Summary
                                                                </button>
                                                            </Link>
                                                        ) : (
                                                            <div>---</div>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    ) : (
                        <div>
                            <div>
                                You have not yet started an online application. Please press the button below to begin
                                the process. You can save and resume the online application here at any point if you do
                                not finish the entire application.
                            </div>
                            <div className='mt-10'>
                                <Link to={'/applications/registration/new-application'}>
                                    <button type='button' className='btn btn-primary my-1'>
                                        Start New Application
                                    </button>
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    //     {% fetchxml collection="applications" cache="PT1S" %}
    //     <fetch distinct="false" mapping="logical" output-format="xml-platform" version="1.0" returntotalrecordcount="true" >
    //         <entity name="pfn_application">
    //             <attribute name="pfn_applicationid" />
    //             <attribute name="pfn_name" />
    //             <attribute name="pfn_fomcomplete"/>
    //             <attribute name="pfn_purpose" />
    //             <attribute name="statuscode" />
    //             <attribute name="pfn_renewalyearid" />
    //             <order attribute="pfn_name" descending="true" />
    //             <filter type="and">
    //                 <condition attribute="pfn_contactid" operator="eq" uitype="contact" value="{{user.id}}" />
    //             </filter>
    //         </entity>
    //     </fetch>
    // {% endfetchxml %}

    //     {% fetchxml collection="Trans" cache="PT1S" %}
    //     <fetch distinct="false" mapping="logical" output-format="xml-platform" version="1.0" count="1" returntotalrecordcount="true">
    //         <entity name="pfn_cetranscript">
    //             <attribute name="pfn_cetranscriptid"/>
    //             <attribute name="pfn_name"/>
    //             <attribute name="createdon"/>
    //             <attribute name="statuscode"/>
    //             <attribute name="pfn_renewalyearid"/>
    //             <attribute name="pfn_startdate"/>
    //             <attribute name="pfn_enddate"/>
    //             <order descending="true" attribute="pfn_name"/>
    //             <filter type="and">
    //                 <condition attribute="pfn_contactid" value="{{user.id}}" operator="eq"/>
    //                 <condition attribute="statecode" operator="eq" value="0" />
    //                 {% if renewalYear is odd %}
    //                     <condition attribute="pfn_name" operator="like" value="%{{renewalYear - 1}}%" />
    //                 {% else %}
    //                     <condition attribute="pfn_name" operator="like" value="%{{renewalYear}}%" />
    //                 {% endif %}
    //             </filter>
    //         </entity>
    //     </fetch>
    // {% endfetchxml %}

    return (
        <>
            {user.statusHistory.subcategory.formatted !== 'Applicant' ? (
                <>
                    <div className='mb-5'>
                        You are already registered so there is no need to visit this page. Please use the navigation
                        menu or go back to the dashboard to select a different option.
                    </div>
                    <div className='pt-lg-10 mb-10'>
                        <div className='text-center'>
                            <Link to='/' className='btn btn-lg btn-primary fw-bolder'>
                                Go to Dashboard
                            </Link>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    {generateGeneralInfo()}
                    {loading ? (
                        <p>Retreiviing applications, please wait...</p>
                    ) : errMsg ? (
                        <p>{errMsg}</p>
                    ) : (
                        <>{GenerateMyApplications()}</>

                        // <div>
                        //     <div>
                        //         {applications && applications.length >= 1 ? (
                        //             applications.map((application) => {
                        //                 if (
                        //                     application.pfn_purpose_Formatted === 'Renewal' &&
                        //                     application.statuscode_Formatted !== 'Cancelled' &&
                        //                     application._pfn_renewalyearid_value_Formatted === renewalYear
                        //                 ) {
                        //                     setRenewalCount(renewalCount + 1);
                        //                 }

                        //                 if (
                        //                     application.statuscode_Formatted === 'Active' ||
                        //                     application.statuscode_Formatted === 'Ready to be finalized'
                        //                 ) {
                        //                     return (
                        //                         <div>
                        //                             {application.pfn_fomcomplete ? (
                        //                                 <a
                        //                                     href={`/applications/application-status/${application.pfn_applicationid}`}
                        //                                 >
                        //                                     Check {application.pfn_purpose_Formatted} Application Status
                        //                                 </a>
                        //                             ) : (
                        //                                 <a href={`/applications/${application.pfn_applicationid}`}>
                        //                                     {application.pfn_purpose_Formatted} Application Form
                        //                                 </a>
                        //                             )}
                        //                         </div>
                        //                     );
                        //                 } else if (
                        //                     application.pfn_purpose_Formatted === 'Renewal' &&
                        //                     application.statuscode_Formatted === 'Granted' &&
                        //                     application._pfn_renewalyearid_value_Formatted === renewalYear
                        //                 ) {
                        //                     return <div>You have compeleted the renewal process successfully!</div>;
                        //                 } else {
                        //                     return <div>Nothing 1</div>;
                        //                 }
                        //             })
                        //         ) : user.statusHistory.subcategory.formatted === 'Applicant' ? (
                        //             <div>
                        //                 <a href='/online-application'>Create Application</a>
                        //             </div>
                        //         ) : (
                        //             <div>No applications for this user and user status doe snot equal 'Applicant'</div>
                        //         )}
                        //     </div>

                        //     {/* {transcripts && transcripts.length === 1 && renewalCount === 0 && (
                        //         <div>
                        //             {transcripts.map((transcript) => {
                        //                 return (
                        //                     <div>
                        //                         {user.statusHistory.status.formatted === 'Active' &&
                        //                         (user.statusHistory.category.formatted === 'Private Practice' ||
                        //                             user.statusHistory.category.formatted === 'Public Sector' ||
                        //                             user.statusHistory.category.formatted === 'Specialty Private Practice') &&
                        //                         (user.statusHistory.subcategory.formatted === 'License - Full' ||
                        //                             user.statusHistory.subcategory.formatted === 'Provisional Supervised' ||
                        //                             user.statusHistory.subcategory.formatted === 'Non-Practising' ||
                        //                             user.statusHistory.subcategory.formatted === 'Provisional - Full') &&
                        //                         eligible === true ? (
                        //                             <div>
                        //                                 <div>
                        //                                     All registrants are required to complete 30 CE hours every 2-year
                        //                                     cycle, and to report their annual CE hours during registration
                        //                                     renewal. The current 2-year CE cycle ends on December 31, 2022. CE
                        //                                     hours can be reported in the CE Transcripts section, by clicking on
                        //                                     2021-2022 and selecting “Add Course”. If you have any problems or
                        //                                     questions, please contact us at 604-929-7090 or reception@cvbc.ca.
                        //                                 </div>

                        //                                 <div>
                        //                                     <a href={`/renewals/${renewalYear}`}>Renew Licensure</a>
                        //                                 </div>

                        //                                 {Number(renewalYear) % 2 !== 0 &&
                        //                                 transcript.statuscode_Formatted !== 'Completed' ? (
                        //                                     <div>
                        //                                         You must report your minimum required Continuing Education hours
                        //                                         for the 2021-2022 CE cycle by December 31, 2022. Registrants who
                        //                                         have not reported their required hours by the deadline will be
                        //                                         included in the audit overseen by the Continuing Competence
                        //                                         Committee in the new year. Please contact us at 604-929-7090 or
                        //                                         reception@cvbc.ca.
                        //                                     </div>
                        //                                 ) : (
                        //                                     <div>
                        //                                         <a href={`/renewals/${renewalYear}`}>Renew Licensure</a>
                        //                                     </div>
                        //                                 )}
                        //                             </div>
                        //                         ) : (
                        //                             <div>Nothing again</div>
                        //                         )}
                        //                     </div>
                        //                 );
                        //             })}
                        //         </div>
                        //     )} */}
                        // </div>
                    )}
                </>
            )}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- WRAPPER -------------------------------- */

const RegistrationApplicationsWrapper: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Registration Applications</PageTitle>
            <RegistrationApplicationsPage />
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { RegistrationApplicationsWrapper };

/* -------------------------------------------------------------------------- */
