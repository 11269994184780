/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { KTSVG } from '../../../helpers';
import { Link } from 'react-router-dom';
import { DropdownCustom8 } from '../../content/dropdown/DropdownCustom8';

type Props = {
    className: string;
};

const ListsWidgetCustom6: React.FC<Props> = ({ className }) => {
    return (
        <div className='card card-xl-stretch mb-5 mb-xl-8'>
            {/* begin::Header */}
            <div className='card-header border-0'>
                <h3 className='card-title fw-bolder text-dark'>Active Invoices</h3>
                <div className='card-toolbar'>
                    {/* begin::Menu */}
                    <button
                        type='button'
                        className='btn btn-sm btn-icon btn-color-primary btn-active-light'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                    >
                        <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                    </button>
                    <DropdownCustom8 />
                    {/* end::Menu */}
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body pt-0'>
                <div className='note-card d-flex align-items-center bg-light rounded p-5 mb-3'>
                    <div className='note-card-inner flex-grow-1 me-2 py-0'>
                        <div className='d-flex flex-wrap'>
                            <Link to='/invoices/personal' className='btn btn-lg btn-primary fw-bolder me-5 my-3'>
                                Personal
                            </Link>
                            <Link to='/invoices/facility' className='btn btn-lg btn-primary fw-bolder me-5 my-3'>
                                Facility
                            </Link>
                            <Link to='/invoices/all' className='btn btn-lg btn-primary fw-bolder my-3'>
                                All
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* end::Body */}
        </div>
    );
};

export { ListsWidgetCustom6 };
