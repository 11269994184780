/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../../app/redux/hooks';
import { toAbsoluteUrl } from '../../../helpers';

const HeaderUserMenu: FC = () => {
    const user = useAppSelector((state) => state.user);

    return (
        <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-400px'
            data-kt-menu='true'
        >
            <div className='menu-item px-3'>
                <div className='menu-content d-flex align-items-center px-3 ps-6'>
                    <div className='symbol symbol-50px me-5'>
                        <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')} />
                    </div>

                    <div className='d-flex flex-column'>
                        <div className='fw-bolder d-flex align-items-center fs-5'>
                            {user.personal.preferredName ? user.personal.preferredName : user.firstname} {user.lastname}
                            {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
                        </div>
                        <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
                            {user.username}
                        </a>
                    </div>
                </div>
            </div>

            <div className='separator my-2 mb-5'></div>

            <div className='menu-item px-5 text-gray-600'>
                <div className='px-5'>
                    <span className='text-primary'>Registrant Type:</span>{' '}
                    <span className='text-muted'>{user.statusHistory.type.formatted}</span>
                </div>
            </div>

            <div className='menu-item px-5 text-gray-600'>
                <div className='px-5'>
                    <span className='text-primary'>Registration Category:</span>{' '}
                    <span className='text-muted'>{user.statusHistory.category.formatted}</span>
                </div>
            </div>

            <div className='menu-item px-5 text-gray-600'>
                <div className='px-5'>
                    <span className='text-primary'>Registration Subcategory:</span>{' '}
                    <span className='text-muted'>{user.statusHistory.subcategory.formatted}</span>
                </div>
            </div>

            <div className='menu-item px-5 text-gray-600'>
                <div className='px-5'>
                    <span className='text-primary'>Registration Number:</span>{' '}
                    <span className='text-muted'>{user.registrationNumber ? user.registrationNumber : 'NA'}</span>
                </div>
            </div>

            <div className='menu-item px-5 text-gray-600'>
                <div className='px-5'>
                    <span className='text-primary'>Effective Date:</span>{' '}
                    <span className='text-muted'>{user.statusHistory.effectiveDate.converted}</span>
                </div>
            </div>

            <div className='menu-item px-5 text-gray-600'>
                <div className='px-5'>
                    <span className='text-primary'>Expiry Date:</span>{' '}
                    <span className='text-muted'>{user.statusHistory.expiryDate.converted}</span>
                </div>
            </div>

            {/* <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div> */}

            {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          My Statements
        </a>
      </div> */}

            <div className='separator my-2 mt-5'></div>

            {/* <Languages /> */}

            <div className='menu-item px-5'>
                <Link to='/contact-info' className='menu-link px-5'>
                    Contact Information
                </Link>
            </div>

            <div className='menu-item px-5'>
                <Link to='/change-password' className='menu-link px-5'>
                    Change Password
                </Link>
            </div>

            <div className='menu-item px-5'>
                <Link to='/logout-all' className='menu-link px-5'>
                    Sign Out All Devices
                </Link>
            </div>

            <div className='menu-item px-5'>
                <Link to='/logout' className='menu-link px-5'>
                    Sign Out
                </Link>
            </div>
        </div>
    );
};

export { HeaderUserMenu };
