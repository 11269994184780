/* eslint-disable react/jsx-no-target-blank */
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';
import { KTSVG } from '../../../helpers';
// import {KTSVG} from '../../../helpers'
// import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { useAppSelector } from '../../../../app/redux/hooks';

export function AsideMenuMain() {
    const intl = useIntl();
    const user = useAppSelector((state) => state.user);

    return (
        <>
            <AsideMenuItem
                to='/dashboard'
                icon='/media/icons/duotune/general/gen022.svg'
                title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
                fontIcon='bi-app-indicator'
            />
            {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Registration</span>
                </div>
            </div>
            <AsideMenuItemWithSub
                to='/applications'
                title='Applications'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/abstract/abs027.svg'
            >
                {user.statusHistory.subcategory.formatted === 'Applicant' ? (
                    <AsideMenuItem to='/applications/registration' title='Registration' hasBullet={true} />
                ) : user.renewalYear ? (
                    <>
                        <div className='menu-item'>
                            <a
                                className='menu-link without-sub'
                                href='https://www.cvbc.ca/registration/class-and-name-changes/'
                                target='_blank'
                                rel='noreferrer'
                            >
                                <span className='menu-bullet fs-3'>•</span>
                                <span className='menu-title'>Registration Class Transfer</span>
                            </a>
                        </div>
                        <AsideMenuItem to='/applications/renewal' title='Renewal' hasBullet={true} />
                    </>
                ) : (
                    <div className='menu-item'>
                        <a
                            className='menu-link without-sub'
                            href='https://www.cvbc.ca/registration/class-and-name-changes/'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <span className='menu-bullet fs-3'>•</span>
                            <span className='menu-title'>Registration Class Transfer</span>
                        </a>
                    </div>
                )}
                <AsideMenuItem to='/applications/history' title='Application History' hasBullet={true} />
            </AsideMenuItemWithSub>
            <AsideMenuItem
                to='/certificate-licensure'
                title='Certificate of Licensure'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/abstract/abs037.svg'
            />
            <AsideMenuItem
                to='/wallet-card'
                title='Wallet Card'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/communication/com005.svg'
            />
            {/* <AsideMenuItem
                to='/manage-facilities'
                title='Manage Facilties'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/abstract/abs027.svg'
            /> */}
            <AsideMenuItemWithSub
                to='/facilities'
                title='Facilties'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/abstract/abs027.svg'
            >
                <AsideMenuItem to='/facilities/my-facilities' title='My Facilities' hasBullet={true} />
                <AsideMenuItem to='/facilities/search' title='Facility Search' hasBullet={true} />
                <AsideMenuItem to='/facilities/assessments' title='Annual Declarations' hasBullet={true} />
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
                to='/receipts'
                title='Receipts'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/coding/cod002.svg'
            >
                <AsideMenuItem to='/receipts/all' title='All Receipts' hasBullet={true} />
                <AsideMenuItem to='/receipts/personal' title='Personal Receipts' hasBullet={true} />
                <AsideMenuItem to='/receipts/facility' title='Facility Receipts' hasBullet={true} />
            </AsideMenuItemWithSub>

            {/* <AsideMenuItem
                to='/receipts'
                title='Receipts'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/coding/cod002.svg'
            /> */}
            <AsideMenuItemWithSub
                to='/invoices'
                title='Invoices'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/general/gen005.svg'
            >
                <AsideMenuItem to='/invoices/all' title='All Invoices' hasBullet={true} />
                <AsideMenuItem to='/invoices/personal' title='Personal Invoices' hasBullet={true} />
                <AsideMenuItem to='/invoices/facility' title='Facility Invoices' hasBullet={true} />
            </AsideMenuItemWithSub>
            {/* <AsideMenuItem
                to='/invoices'
                title='Invoices'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/coding/cod002.svg'
            /> */}
            {/* <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      /> */}
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Continuing Education</span>
                </div>
            </div>
            <AsideMenuItem
                to='/transcripts'
                title='CE Transcripts'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/general/gen005.svg'
            />

            <div className='menu-item'>
                <a className='menu-link without-sub' href='https://classroom.cvbc.ca/' target='_blank' rel='noreferrer'>
                    <span className='menu-icon'>
                        <KTSVG path='/media/icons/duotune/general/gen017.svg' className='svg-icon-2' />
                    </span>

                    <i className={clsx('bi fs-3', '/media/icons/duotune/general/gen005.svg')}></i>
                    <span className='menu-title'>Learning Hub</span>
                </a>
            </div>

            {/* <AsideMenuItem
                to='/submit-credits'
                title='Submit Credits'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/general/gen016.svg'
            /> */}
            {/* <AsideMenuItem
                to='/event-registration'
                title='Event Registration'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/files/fil002.svg'
            /> */}
            {/* <AsideMenuItemWithSub
                to='/crafted/pages'
                title='Pages'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/general/gen022.svg'
            >
                <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
                    <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
                    <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
                    <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
                    <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
                    <AsideMenuItem to='/crafted/pages/profile/connections' title='Connections' hasBullet={true} />
                </AsideMenuItemWithSub>

                <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
                    <AsideMenuItem to='/crafted/pages/wizards/horizontal' title='Horizontal' hasBullet={true} />
                    <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
                </AsideMenuItemWithSub>
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
                to='/crafted/accounts'
                title='Accounts'
                icon='/media/icons/duotune/communication/com006.svg'
                fontIcon='bi-person'
            >
                <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
                <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
                to='/error'
                title='Errors'
                fontIcon='bi-sticky'
                icon='/media/icons/duotune/general/gen040.svg'
            >
                <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
                <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
                to='/crafted/widgets'
                title='Widgets'
                icon='/media/icons/duotune/general/gen025.svg'
                fontIcon='bi-layers'
            >
                <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
                <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
                <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
                <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
                <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
                <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
            </AsideMenuItemWithSub> */}

            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Manage Account</span>
                </div>
            </div>
            <AsideMenuItem
                to='/contact-info'
                title='Contact Information'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/maps/map008.svg'
            />
            <AsideMenuItem
                to='/change-password'
                title='Change Password'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/general/gen051.svg'
            />
            <AsideMenuItem
                to='/change-permission'
                title='Change Permission'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/coding/cod001.svg'
            />
            <AsideMenuItem
                to='/change-email'
                title='Change Email'
                fontIcon='bi-archive'
                icon='/media/icons/duotune/communication/com002.svg'
            />
            <div className='menu-item'>
                <div className='menu-content'>
                    <div className='separator mx-1 my-4'></div>
                </div>
            </div>
            {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
        </>
    );
}
