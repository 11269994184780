/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/pages/custom/facilities/facility-assessments/formController/AssessmentWizard.tsx
 * @author Jesse Zonneveld
 * @description Assessment wizard
 */

/* --------------------------------- IMPORTS -------------------------------- */

import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { Facility, FacilityAssessment } from '../../../../../types';
import { InitialDetailsForm } from './forms/InitialDetailsForm';
import { PublicationForm } from './forms/PublicationForm';
import { DistrictsForm } from './forms/DistrictsForm';
import facilityAssessmentApi from '../../../../../api/facilityAssessmentsApi';
import { Link } from 'react-router-dom';
import { FacilityTypesForm } from './forms/FacilityTypesForm';
import { AnimalSpeciesForm } from './forms/AnimalSpeciesForm';
import { FacilityServicesForm } from './forms/FacilityServicesForm';
import { DeclarationsForm } from './forms/DeclarationsForm';
import { useAppSelector } from '../../../../../redux/hooks';
import facilityApi from '../../../../../api/facilityApi';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

interface Props {
    initialStep: number;
    assessmentId: string;
    facilityId: string;
}

/* -------------------------------------------------------------------------- */

/* --------------------------------- HELPERS -------------------------------- */

const stepsTitles = [
    'Initial Details',
    'Publications',
    'Districts',
    'Facility Types',
    'Animal Species',
    'Services',
    'Declarations',
];

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const AssessmentWizard: FC<Props> = ({ initialStep, assessmentId, facilityId }) => {
    const user = useAppSelector((state) => state.user);
    const [currentStep, setCurrentStep] = useState(initialStep);
    const [assessment, setAssessment] = useState<FacilityAssessment | null>(null);
    const [facility, setFacility] = useState<Facility | null>(null);
    const [refreshAssessment, setRefreshAssessment] = useState(false);
    const [loading, setLoading] = useState(true);
    const [facilityLoading, setFacilityLoading] = useState(true);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const [facilityErrMsg, setFacilityErrMsg] = useState<string | null>(null);

    useEffect(() => {
        const getAssessmentByIdAsync = async () => {
            try {
                setLoading(true);
                if (assessmentId) {
                    const response = await facilityAssessmentApi.getFacilityAssessmentById(assessmentId);

                    if (response) {
                        if (!response.success) {
                            setErrMsg(
                                response?.message ||
                                    `No FacilityAssessment with the assessmentId: ${assessmentId} exists`,
                            );
                        } else {
                            if (response.data) {
                                if (response.data._pfn_contactid_value === user.id) {
                                    setAssessment(response.data);
                                } else {
                                    setErrMsg('You are not the designated Vet for this facility');
                                }
                            }
                        }
                    } else {
                        setErrMsg('An error has occurred, please try again later');
                    }
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
            }
            setLoading(false);
        };

        const getfacilityAsync = async () => {
            try {
                setFacilityLoading(true);
                if (facilityId) {
                    const response = await facilityApi.getFacilityById(facilityId);

                    if (response) {
                        if (!response.success) {
                            setFacilityErrMsg(response?.message || `No facility with the id: ${facilityId} exists`);
                        } else {
                            if (response.data) {
                                setFacility(response.data);
                            }
                        }
                    } else {
                        setFacilityErrMsg('An error has occurred, please try again later');
                    }
                } else {
                    setFacilityErrMsg('An error has occurred, please try again later');
                }
            } catch (err) {
                if (err instanceof Error) {
                    setFacilityErrMsg(err.message);
                } else {
                    setFacilityErrMsg('An error has occurred, please try again later');
                }
            }
            setFacilityLoading(false);
        };

        getAssessmentByIdAsync();
        getfacilityAsync();

        if (refreshAssessment) {
            setRefreshAssessment(false);
        }

        return () => {
            setAssessment(null);
            setErrMsg(null);
            setLoading(false);
        };
    }, [assessmentId, facilityId, refreshAssessment, user.id]);

    const goToNextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const goToPreviousStep = () => {
        setRefreshAssessment(true);
        setCurrentStep(currentStep - 1);
    };

    const renderStepContent = () => {
        if (assessment && facility) {
            switch (currentStep) {
                case 0:
                    return (
                        <InitialDetailsForm goToNextStep={goToNextStep} assessment={assessment} facility={facility} />
                    );

                case 1:
                    return (
                        <PublicationForm
                            goToNextStep={goToNextStep}
                            assessment={assessment}
                            goToPreviousStep={goToPreviousStep}
                        />
                    );

                case 2:
                    return (
                        <DistrictsForm
                            goToNextStep={goToNextStep}
                            goToPreviousStep={goToPreviousStep}
                            assessment={assessment}
                        />
                    );

                case 3:
                    return (
                        <FacilityTypesForm
                            goToNextStep={goToNextStep}
                            goToPreviousStep={goToPreviousStep}
                            assessment={assessment}
                        />
                    );

                case 4:
                    return (
                        <AnimalSpeciesForm
                            goToNextStep={goToNextStep}
                            goToPreviousStep={goToPreviousStep}
                            assessment={assessment}
                        />
                    );

                case 5:
                    return (
                        <FacilityServicesForm
                            goToNextStep={goToNextStep}
                            goToPreviousStep={goToPreviousStep}
                            assessment={assessment}
                        />
                    );

                case 6:
                    return (
                        <DeclarationsForm
                            goToNextStep={goToNextStep}
                            goToPreviousStep={goToPreviousStep}
                            assessment={assessment}
                        />
                    );

                default:
                    return <div>Not Found</div>;
            }
        } else {
            setErrMsg('Assessment or facility not found');
        }
    };

    return (
        <>
            {loading || facilityLoading ? (
                <div>Loading assessment form, please wait...</div>
            ) : errMsg ? (
                <div>
                    <div className='mb-20'>{errMsg}</div>
                    <div className='d-flex justify-content-center'>
                        <Link to='/'>
                            <button type='button' className='btn btn-primary fw-bolder'>
                                Back to Dashboard
                            </button>
                        </Link>
                    </div>
                </div>
            ) : facilityErrMsg ? (
                <div>
                    <div className='mb-20'>{facilityErrMsg}</div>
                    <div className='d-flex justify-content-center'>
                        <Link to='/'>
                            <button type='button' className='btn btn-primary fw-bolder'>
                                Back to Dashboard
                            </button>
                        </Link>
                    </div>
                </div>
            ) : assessment && facility ? (
                <>
                    {assessment.pfn_formcompleted ? (
                        <div>
                            <div className='mb-20'>
                                This facility assessment is already complete. Please go back and select a diffrent
                                option.
                            </div>
                            <div className='d-flex justify-content-center'>
                                <Link to='/facilities/my-facilities'>
                                    <button type='button' className='btn btn-primary fw-bolder mx-2'>
                                        My Facilities
                                    </button>
                                </Link>
                                <Link to='/'>
                                    <button type='button' className='btn btn-primary fw-bolder mx-2'>
                                        Back to Dashboard
                                    </button>
                                </Link>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className='wizard-stepper-progress'>
                                {stepsTitles.map((stepTitle, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            {i !== 0 && (
                                                <div
                                                    className={clsx('step-divider', {
                                                        current: currentStep === i,
                                                        completed: i < currentStep,
                                                    })}
                                                ></div>
                                            )}
                                            <div className='step'>
                                                <div
                                                    className={clsx('step-number', {
                                                        current: currentStep === i,
                                                        completed: i < currentStep,
                                                    })}
                                                >
                                                    {i + 1}
                                                </div>
                                                <div
                                                    className={clsx('step-title', {
                                                        current: currentStep === i,
                                                        completed: i < currentStep,
                                                    })}
                                                >
                                                    {stepTitle}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                            <div className='mb-10'>{renderStepContent()}</div>
                        </div>
                    )}
                </>
            ) : (
                <div>
                    <div className='mb-20'>
                        No FacilityAssessment with the pfn_facilityassessmentid: {assessmentId} exists
                    </div>
                    <div className='d-flex justify-content-start'>
                        <Link to='/'>
                            <button type='button' className='btn btn-primary fw-bolder'>
                                Back to Dashboard
                            </button>
                        </Link>
                    </div>
                </div>
            )}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { AssessmentWizard };

/* -------------------------------------------------------------------------- */
