import * as Yup from 'yup';
import { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import applicationApi from '../../../../../../api/applicationApi';
import clsx from 'clsx';
import { Application, Jurisdiction } from '../../../../../../types';
import { Table } from 'react-bootstrap';
import { EditJurisdictionBtn } from '../editBtns/EditJurisdictionBtn';
import { DeleteJurisdictionBtn } from '../deleteBtns/deleteJurisdictionBtn';
import { SpecialMonthValues, SpecialYearValues } from '../../../../../../constants';

interface Props {
    goToNextStep: Function;
    goToPreviousStep: Function;
    application: Application;
}

const initialValues = {
    pfn_name: '',
    pfn_typeoflicense: '',
    pfn_licensenumber: '',
    pfn_fromyear: '',
    pfn_toyear: '',
    pfn_frommonth: '',
    pfn_tomonth: '',
    pfn_practicetimeyears: '',
    pfn_practicetimemonths: '',
    pfn_details: '',
};

const validationSchema = Yup.object().shape({
    pfn_name: Yup.string().required('Regulartory body is required'),
    pfn_typeoflicense: Yup.string().required('License type is required'),
    pfn_licensenumber: Yup.string().required('License number is required'),
    pfn_practicetimeyears: Yup.string()
        .required('Practice years is required')
        .test('checkYearRange', 'Must be less than or equal to 40 years', async (val) => {
            return Number(val) <= 40;
        }),
    pfn_practicetimemonths: Yup.string()
        .required('Practice month is required')
        .test('checkMonthRange', 'Must be less than or equal to 12 months', async (val) => {
            return Number(val) <= 12;
        }),
    pfn_fromyear: Yup.string()
        .required('From year is required')
        .test('checkYearRange', 'Must be no greater than 50 years ago', async (val) => {
            return Number(val) >= new Date().getFullYear() - 50;
        }),
    pfn_toyear: Yup.string().required('To year is required'),
    pfn_frommonth: Yup.string()
        .required('From month is required')
        .test('checkMonthRange', 'Must be less than or equal to 12 months', async (val) => {
            return val?.toLowerCase() === SpecialMonthValues.DO_NOT_RECALL.title.toLowerCase() || (Number(val) >= 1 && Number(val) <= 13);
        }),
    pfn_tomonth: Yup.string()
        .required('To month is required')
        .test('checkMonthRange', 'Must be less than or equal to 12 months', async (val) => {
            return val?.toLowerCase() === SpecialMonthValues.PRESENT.title.toLowerCase() || val?.toLowerCase() === SpecialMonthValues.DO_NOT_RECALL.title.toLowerCase() || (Number(val) >= 1 && Number(val) <= 14);
        }),
    pfn_details: Yup.string().required('Scope is required'),
})
.test({
    name: 'ToDateMustBeAfterFromDate',
    test: function (values) {
        if (values.pfn_toyear && values.pfn_tomonth && values.pfn_fromyear && values.pfn_frommonth) {
            if (values.pfn_fromyear === values.pfn_toyear) {
                if (values.pfn_tomonth === SpecialMonthValues.PRESENT.value || values.pfn_frommonth === SpecialMonthValues.DO_NOT_RECALL.value || values.pfn_frommonth <= values.pfn_tomonth) {
                    return true;
                } else {
                    return this.createError({
                        path: 'pfn_tomonth',
                        message: 'To date must be after from date',
                    });
                }
            } else {
                if (values.pfn_toyear === SpecialYearValues.PRESENT.title.toLowerCase() || values.pfn_fromyear <= values.pfn_toyear) {
                    return true;
                } else {
                    return this.createError({
                        path: 'pfn_toyear',
                        message: 'To date must be after from date',
                    });
                }
            }
        } else {
            return true;
        }
    },
})
.test({
    name: 'PresentCheck',
    test: function (values) {
        if (values.pfn_toyear && values.pfn_tomonth) {
            if (
                (values.pfn_toyear?.toLowerCase() === SpecialMonthValues.PRESENT.title.toLowerCase() && (values.pfn_tomonth.toLowerCase() === SpecialMonthValues.PRESENT.title.toLowerCase() || values.pfn_tomonth === SpecialMonthValues.PRESENT.value)) ||
                (values.pfn_toyear?.toLowerCase() !== SpecialMonthValues.PRESENT.title.toLowerCase() && (values.pfn_tomonth.toLowerCase() !== SpecialMonthValues.PRESENT.title.toLowerCase() && values.pfn_tomonth !== SpecialMonthValues.PRESENT.value))
            ) {
                return true;
            } else {
                return this.createError({
                    path: 'pfn_tomonth',
                    message: 'To year and month must both be present if present is selected',
                });
            }
        } else {
            return true;
        }
    },
});

const JurisdictionForm: FC<Props> = ({ goToNextStep, goToPreviousStep, application }) => {
    const [formLoading, setFormLoading] = useState(false);
    const [jurisdictionsLoading, setJurisdictionsLoading] = useState(true);
    const [formErrMsg, setFormErrMsg] = useState<string | null>(null);
    const [yearsFromToRange, setYearsFromToRange] = useState<number[]>([]);
    const [monthsRange, setMonthsRange] = useState<string[]>([]);
    const [monthsRangeWithPresent, setMonthsRangeWithPresent] = useState<string[]>([]);
    const [yearsPracticeRange, setYearsPracticeRange] = useState<number[]>([]);
    const [monthsPracticeRange, setMonthsPracticeRange] = useState<number[]>([]);
    const [currentJurisdictions, setCurrentJurisdictions] = useState<Jurisdiction[]>([]);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                setFormErrMsg(null);
                setFormLoading(true);
                
                if (values.pfn_tomonth.toLowerCase() === SpecialMonthValues.PRESENT.title.toLowerCase()) {
                    values.pfn_tomonth = SpecialMonthValues.PRESENT.value;
                }

                if (values.pfn_tomonth.toLowerCase() === SpecialMonthValues.DO_NOT_RECALL.title.toLowerCase()) {
                    values.pfn_tomonth = SpecialMonthValues.DO_NOT_RECALL.value;
                }

                if (values.pfn_frommonth.toLowerCase() === SpecialMonthValues.DO_NOT_RECALL.title.toLowerCase()) {
                    values.pfn_frommonth = SpecialMonthValues.DO_NOT_RECALL.value;
                }

                const response = await applicationApi.addJurisdiction(application.pfn_applicationid, values);

                if (response) {
                    if (!response.success) {
                        if (response.message) {
                            setFormErrMsg(response.message);
                        } else {
                            setFormErrMsg('An error has occured, please try again later');
                        }
                    } else {
                        getCurrentJurisdictions();
                        formik.resetForm();
                    }
                } else {
                    setFormErrMsg('An error has occured, please try again later');
                }

                setFormLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setFormErrMsg(err.message);
                } else {
                    setFormErrMsg('An error has occurred, please try again later');
                }
                setFormLoading(false);
            }
        },
    });

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const newYearFromToRange: number[] = [];
        const newMonthRange: string[] = [];
        const newMonthRangeWithPresent: string[] = [];
        const newYearPracticeRange: number[] = [];
        const newMonthPracticeRange: number[] = [];

        for (let i = 0; i <= 50; i++) {
            newYearFromToRange.push(currentYear - i);
        }

        for (let i = 1; i <= 12; i++) {
            newMonthRange.push(i.toString());
        }
        newMonthRange.push(SpecialMonthValues.DO_NOT_RECALL.title);

        newMonthRangeWithPresent.push(...newMonthRange, SpecialMonthValues.PRESENT.title);

        for (let i = 0; i <= 40; i++) {
            newYearPracticeRange.push(i);
        }

        for (let i = 0; i <= 11; i++) {
            newMonthPracticeRange.push(i);
        }

        setYearsFromToRange(newYearFromToRange);
        setMonthsRange(newMonthRange);
        setMonthsRangeWithPresent(newMonthRangeWithPresent);
        setYearsPracticeRange(newYearPracticeRange);
        setMonthsPracticeRange(newMonthPracticeRange);

        return () => {
            setYearsFromToRange([]);
            setMonthsRange([]);
            setMonthsRangeWithPresent([]);
            setYearsPracticeRange([]);
            setMonthsPracticeRange([]);
        };
    }, []);

    useEffect(() => {
        getCurrentJurisdictions();

        return () => {
            setJurisdictionsLoading(false);
            setFormErrMsg(null);
            setFormLoading(false);
            setCurrentJurisdictions([]);
        };
    }, []);

    const getCurrentJurisdictions = async () => {
        try {
            setJurisdictionsLoading(true);
            setFormErrMsg(null);
            const response = await applicationApi.getJurisdictions();

            if (response) {
                if (!response.success) {
                    setFormErrMsg('An error has occurred, please try again later');
                } else {
                    if (response.data) {
                        setCurrentJurisdictions(response.data);
                    }
                }
            } else {
                setFormErrMsg('An error has occurred, please try again later');
            }

            setJurisdictionsLoading(false);
        } catch (err) {
            if (err instanceof Error) {
                setFormErrMsg(err.message);
            } else {
                setFormErrMsg('An error has occurred, please try again later');
            }
            setJurisdictionsLoading(false);
        }
    };

    const handleNextClick = async () => {
        goToNextStep();
    };

    const handleBackClick = async () => {
        goToPreviousStep();
    };

    const renderCurrentJurisdictions = () => {
        return (
            <div className='mb-10'>
                <h5 className='mt-5'>List of your jurisdictions:</h5>
                <div className='table-container'>
                    <Table className='jurisdictions-table' striped bordered>
                        <thead>
                            <tr>
                                <th className='fw-bolder'>Regulatory Body Name</th>
                                <th className='fw-bolder'>License Type</th>
                                <th className='fw-bolder'>License Number</th>
                                <th className='fw-bolder'>From</th>
                                <th className='fw-bolder'>To</th>
                                <th className='fw-bolder'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentJurisdictions?.map((jurisdiction, i) => {
                                return (
                                    <tr key={i}>
                                        <td className='body'>{jurisdiction.pfn_name}</td>
                                        <td className='type'>{jurisdiction.pfn_typeoflicense}</td>
                                        <td className='number'>{jurisdiction.pfn_licensenumber}</td>
                                        <td className='from'>{jurisdiction.pfn_fromyear}</td>
                                        <td className='to'>{jurisdiction.pfn_toyear || SpecialYearValues.PRESENT.title}</td>
                                        <td className='action'>
                                            {new Date(jurisdiction.createdon) > new Date(Date.now() - 604800000) && (
                                                <>
                                                    <EditJurisdictionBtn
                                                        jurisdiction={jurisdiction}
                                                        application={application}
                                                        modalName={`kt_modal_edit_jurisdiction_${i}`}
                                                        getCurrentJurisdictions={getCurrentJurisdictions}
                                                        yearsFromToRange={yearsFromToRange}
                                                        monthsRange={monthsRange}
                                                        monthsRangeWithPresent={monthsRangeWithPresent}
                                                        yearsPracticeRange={yearsPracticeRange}
                                                        monthsPracticeRange={monthsPracticeRange}
                                                    />
                                                    <DeleteJurisdictionBtn
                                                        jurisdiction={jurisdiction}
                                                        getCurrentJurisdictions={getCurrentJurisdictions}
                                                        modalName={`kt_modal_delete_jurisdiction_${i}`}
                                                    />
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    };

    return (
        <>
            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='addJurisdictionForm'>
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Regulatory Body Name</label>
                    <input
                        {...formik.getFieldProps('pfn_name')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_name && formik.errors.pfn_name,
                        })}
                        name='pfn_name'
                        autoComplete='off'
                    />
                    {formik.touched.pfn_name && formik.errors.pfn_name && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_name}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>License Type</label>
                    <input
                        {...formik.getFieldProps('pfn_typeoflicense')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_typeoflicense && formik.errors.pfn_typeoflicense,
                        })}
                        name='pfn_typeoflicense'
                        autoComplete='off'
                    />
                    {formik.touched.pfn_typeoflicense && formik.errors.pfn_typeoflicense && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_typeoflicense}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>License Number</label>
                    <input
                        {...formik.getFieldProps('pfn_licensenumber')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_licensenumber && formik.errors.pfn_licensenumber,
                        })}
                        name='pfn_licensenumber'
                        autoComplete='off'
                    />
                    {formik.touched.pfn_licensenumber && formik.errors.pfn_licensenumber && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_licensenumber}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Year of Licensure (From)</label>
                    <select
                        {...formik.getFieldProps('pfn_fromyear')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_fromyear && formik.errors.pfn_fromyear,
                        })}
                        name='pfn_fromyear'
                    >
                        <option disabled value=''>
                            Select Year
                        </option>
                        {yearsFromToRange?.map((year, i) => (
                            <option key={i} value={String(year)}>
                                {year}
                            </option>
                        ))}
                    </select>
                    {formik.touched.pfn_fromyear && formik.errors.pfn_fromyear && (
                        <div className='fv-plugins-message-container'>
                            <span className='text-danger' role='alert'>
                                {formik.errors.pfn_fromyear}
                            </span>
                        </div>
                    )}
                </div>
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Month of Licensure (From)</label>
                    <select
                        {...formik.getFieldProps('pfn_frommonth')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_frommonth && formik.errors.pfn_frommonth,
                        })}
                        name='pfn_frommonth'
                    >
                        <option disabled value=''>
                            Select Month
                        </option>
                        {monthsRange?.map((month, i) => (
                            <option key={i} value={month === SpecialMonthValues.DO_NOT_RECALL.title ? SpecialMonthValues.DO_NOT_RECALL.value : String(i + 1)}>
                                {month}
                            </option>
                        ))}
                    </select>
                    {formik.touched.pfn_frommonth && formik.errors.pfn_frommonth && (
                        <div className='fv-plugins-message-container'>
                            <span className='text-danger' role='alert'>
                                {formik.errors.pfn_frommonth}
                            </span>
                        </div>
                    )}
                </div>
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Year of Licensure (To)</label>
                    <select
                        {...formik.getFieldProps('pfn_toyear')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_toyear && formik.errors.pfn_toyear,
                        })}
                        name='pfn_toyear'
                        >
                            <option disabled value=''>
                                Select Year
                            </option>
                            <option value={SpecialYearValues.PRESENT.title.toLowerCase()}>{SpecialYearValues.PRESENT.title}</option>
                            {yearsFromToRange?.map((year, i) => (
                                <option key={i} value={String(year)}>
                                    {year}
                                </option>
                            ))}
                        </select>
                        {formik.touched.pfn_toyear && formik.errors.pfn_toyear && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_toyear}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className='fv-row mb-7'>
                        <label className='form-label fs-6 fw-bolder text-dark required'>Month of Licensure (To)</label>
                        <select
                            {...formik.getFieldProps('pfn_tomonth')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid': formik.touched.pfn_tomonth && formik.errors.pfn_tomonth,
                            })}
                            name='pfn_tomonth'
                        >
                            <option disabled value=''>
                                Select Month
                            </option>
                            {monthsRangeWithPresent?.map((month, i) => (
                                <option key={i} value={month === SpecialMonthValues.DO_NOT_RECALL.title ? SpecialMonthValues.DO_NOT_RECALL.value : month === SpecialMonthValues.PRESENT.title ? SpecialMonthValues.PRESENT.value : String(i + 1)}>
                                    {month}
                                </option>
                            ))}
                        </select>
                        {formik.touched.pfn_tomonth && formik.errors.pfn_tomonth && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_tomonth}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className='fv-row mb-7'>
                        <label className='form-label fs-6 fw-bolder text-dark required'>
                            Time In Practice in this Jurisdiction (Years)
                        </label>
                        <select
                            {...formik.getFieldProps('pfn_practicetimeyears')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid': formik.touched.pfn_practicetimeyears && formik.errors.pfn_practicetimeyears,
                            })}
                            name='pfn_practicetimeyears'
                        >
                            <option disabled value=''>
                                Select Year
                            </option>
                            {yearsPracticeRange?.map((year, i) => (
                                <option key={i} value={String(year)}>
                                    {year}
                                </option>
                            ))}
                        </select>
                        {formik.touched.pfn_practicetimeyears && formik.errors.pfn_practicetimeyears && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_practicetimeyears}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className='fv-row mb-7'>
                        <label className='form-label fs-6 fw-bolder text-dark required'>
                            Time In Practice in this Jurisdiction (Plus Month)
                        </label>
                        <select
                            {...formik.getFieldProps('pfn_practicetimemonths')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid': formik.touched.pfn_practicetimemonths && formik.errors.pfn_practicetimemonths,
                            })}
                            name='pfn_practicetimemonths'
                        >
                            <option disabled value=''>
                                Select Month
                            </option>
                            {monthsPracticeRange?.map((month, i) => (
                                <option key={i} value={String(month)}>
                                    {month}
                                </option>
                            ))}
                        </select>
                        {formik.touched.pfn_practicetimemonths && formik.errors.pfn_practicetimemonths && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_practicetimemonths}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className='fv-row mb-7'>
                        <label className='form-label fw-bolder text-dark fs-6 required'>Scope of Practice</label>
                        <textarea
                            autoComplete='off'
                            rows={5}
                            {...formik.getFieldProps('pfn_details')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid': formik.touched.pfn_details && formik.errors.pfn_details,
                            })}
                        />
                        {formik.touched.pfn_details && formik.errors.pfn_details && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span className='text-danger' role='alert'>
                                        {formik.errors.pfn_details}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                    {formErrMsg && <div className='text-danger text-center'>{formErrMsg}</div>}
    
                    <button type='submit' className='btn btn-primary mb-15' disabled={formik.isSubmitting || formLoading}>
                        {!formLoading && <span className='indicator-label'>Add Jurisdiction</span>}
                        {formLoading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Please wait...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
    
                    {jurisdictionsLoading ? (
                        <h5>Loading your current jurisdictions, please wait...</h5>
                    ) : (
                        <>
                            <div className='d-flex align-items-center mb-10'>
                                <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                                <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                            </div>
                            {renderCurrentJurisdictions()}
                        </>
                    )}
    
                    <div className='d-flex align-items-center mb-10'>
                        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                    </div>
    
                    <div className='fv-row'>
                        <div className='d-flex justify-content-between'>
                            <button type='button' className='btn btn-primary me-3' onClick={handleBackClick}>
                                Back
                            </button>
    
                            <button type='button' className='btn btn-primary me-3' onClick={handleNextClick}>
                                Next
                            </button>
                        </div>
                    </div>
                </form>
            </>
        );
    };
    
    export { JurisdictionForm };