/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/pages/contact-information/components/ContactInfoPhone.tsx
 * @author Jesse Zonneveld
 * @description Phone section for the contact information page
 */

/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useState } from 'react';
import { KTSVG } from '../../../../../_metronic/helpers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import MaskedInput from 'react-text-mask';
import { updatePhone } from '../../../../redux/slices/UserSlice';
import { closeModalById } from '../../../../helpers';

/* -------------------------------------------------------------------------- */

/* ------------------------------- FORM SCHEMA ------------------------------ */

const phoneRegExp = /^\d{3}[-]\d{3}[-]\d{4}$/;

const editPhoneSchema = Yup.object().shape({
    telephone2: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    mobilephone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Mobile phone is required'),
    // telephone2: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
});

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const ContactInfoPhone: FC = () => {
    const user = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);

    const initialValues = {
        telephone2: user.phone.home ? user.phone.home : '',
        mobilephone: user.phone.mobile ? user.phone.mobile : '',
        // telephone2: user.phone.business ? user.phone.business : '',
    };

    const formik = useFormik({
        initialValues,
        validationSchema: editPhoneSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                setErrMsg(null);
                setLoading(true);
                const response = await dispatch(updatePhone(values));
                if (response) {
                    if (!response.payload?.success) {
                        if (response.payload?.message) {
                            setErrMsg(response.payload.message);
                        } else {
                            setErrMsg('An error has occured, please try again later');
                        }
                    } else {
                        closeModalById('kt_modal_edit_phone');
                    }
                } else {
                    setErrMsg('An error has occured, please try again later');
                }
                setLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
                setLoading(false);
            }
        },
    });

    const editPhoneModal = () => {
        return (
            <div className='modal fade' tabIndex={-1} id='kt_modal_edit_phone'>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Edit Phone Information</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
                            </div>
                        </div>
                        <div className='modal-body'>{editPhoneForm()}</div>
                    </div>
                </div>
            </div>
        );
    };

    const editPhoneForm = () => {
        return (
            <form className='form w-100' onSubmit={formik.handleSubmit} id='edit_phone_form'>
                {/* Start Form Row */}
                <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6 required'>Mobile Phone</label>
                    <MaskedInput
                        mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        {...formik.getFieldProps('mobilephone')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                                'is-invalid': formik.touched.mobilephone && formik.errors.mobilephone,
                            },
                            // {
                            //   'is-valid': formik.touched.mobilephone && !formik.errors.mobilephone,
                            // }
                        )}
                    />
                    {formik.touched.mobilephone && formik.errors.mobilephone && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                    {formik.errors.mobilephone}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>Home Phone</label>
                    <MaskedInput
                        mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        {...formik.getFieldProps('telephone2')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                                'is-invalid': formik.touched.telephone2 && formik.errors.telephone2,
                            },
                            // {
                            //   'is-valid': formik.touched.telephone2 && !formik.errors.telephone2,
                            // }
                        )}
                    />
                    {formik.touched.telephone2 && formik.errors.telephone2 && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                    {formik.errors.telephone2}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                {/* <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>Business Phone</label>
                    <MaskedInput
                        mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        {...formik.getFieldProps('telephone2')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                                'is-invalid': formik.touched.telephone2 && formik.errors.telephone2,
                            },
                            // {
                            //   'is-valid': formik.touched.telephone2 && !formik.errors.telephone2,
                            // }
                        )}
                    />
                    {formik.touched.telephone2 && formik.errors.telephone2 && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                    {formik.errors.telephone2}
                                </span>
                            </div>
                        </div>
                    )}
                </div> */}
                {/* End Form Row */}

                <div className='d-flex align-items-center mb-10'>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                </div>

                <div className='fv-row'>
                    <div className='d-flex justify-content-end'>
                        <button type='button' className='btn btn-light me-3' data-bs-dismiss='modal'>
                            Cancel
                        </button>

                        <button type='submit' className='btn btn-primary' disabled={formik.isSubmitting || loading}>
                            {!loading && <span className='indicator-label'>Submit</span>}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </div>
                {errMsg && <div className='text-danger text-center mt-7'>{errMsg}</div>}
            </form>
        );
    };

    return (
        <div className='card card-xl mb-5 mb-xl-8'>
            <div className='card-header border-0'>
                <h3 className='card-title fw-bolder text-dark'>Phone</h3>
            </div>

            <div className='card-body pt-0'>
                <div className='d-flex align-items-center bg-light rounded p-5 mb-7'>
                    <span className='bullet bullet-vertical h-40px bg-primary me-10'></span>

                    <div className='flex-grow-1 me-2'>
                        <div className='fw-bolder text-gray-800 fs-6'>Mobile Phone</div>
                        <span className='text-muted fw-bold d-block'>{user.phone.mobile || '---'}</span>
                    </div>
                </div>
                <div className='d-flex align-items-center bg-light rounded p-5 mb-7'>
                    <span className='bullet bullet-vertical h-40px bg-primary me-10'></span>

                    <div className='flex-grow-1 me-2'>
                        <div className='fw-bolder text-gray-800 fs-6'>Home Phone</div>
                        <span className='text-muted fw-bold d-block'>{user.phone.home || '---'}</span>
                    </div>
                </div>
                <div className='d-flex align-items-center bg-light rounded p-5 mb-7'>
                    <span className='bullet bullet-vertical h-40px bg-primary me-10'></span>

                    <div className='flex-grow-1 me-2'>
                        <div className='fw-bolder text-gray-800 fs-6'>Business Phone (Primary Facility)</div>
                        <span className='text-muted fw-bold d-block'>{user.phone.business || '---'}</span>
                    </div>
                </div>

                <button
                    className='btn btn-sm btn-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_edit_phone'
                    onClick={() => {
                        formik.resetForm();
                        setErrMsg(null);
                    }}
                >
                    Edit
                </button>
                {editPhoneModal()}
            </div>
        </div>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { ContactInfoPhone };

/* -------------------------------------------------------------------------- */
