/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/redux/hooks.ts
 * @author Jesse Zonneveld
 * @description Redux hooks
 */

/* --------------------------------- IMPORTS -------------------------------- */

import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import type { RootState, AppDispatch } from './store';

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/* -------------------------------------------------------------------------- */
