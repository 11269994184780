/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/pages/custom/applications/renewal/RenewalApplicationStatus.tsx
 * @author Jesse Zonneveld
 * @description Renewal application status page
 */

/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { PageTitle } from '../../../../../_metronic/layout/core';
import applicationApi from '../../../../api/applicationApi';
import { Application, ApplicationRequirement, Invoice, Receipt } from '../../../../types';
import { Table } from 'react-bootstrap';
import invoiceApi from '../../../../api/invoiceApi';
import receiptApi from '../../../../api/receiptApi';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const RenewalApplicationStatusPage: FC = () => {
    const [apploading, setAppLoading] = useState(true);
    const [reqsloading, setReqsLoading] = useState(true);
    const [invsloading, setInvsLoading] = useState(true);
    const [receiptsloading, setReceiptsLoading] = useState(true);
    const [appErrMsg, setAppErrMsg] = useState<string | null>(null);
    const [reqsErrMsg, setReqsErrMsg] = useState<string | null>(null);
    const [invsErrMsg, setInvsErrMsg] = useState<string | null>(null);
    const [receiptsErrMsg, setReceiptsErrMsg] = useState<string | null>(null);
    const [application, setApplication] = useState<Application | null>(null);
    const [applicationReqs, setApplicationReqs] = useState<ApplicationRequirement[]>([]);
    const [applicationInvs, setApplicationInvs] = useState<Invoice[]>([]);
    const [applicationReceipts, setApplicationReceipts] = useState<Receipt[]>([]);
    const { pfn_applicationid } = useParams();

    useEffect(() => {
        const getRenewalAppByIdAsync = async () => {
            try {
                if (pfn_applicationid) {
                    const response = await applicationApi.getAppById(pfn_applicationid);

                    if (response) {
                        if (!response.success) {
                            setAppErrMsg(`No Application with the pfn_applicationid: ${pfn_applicationid} exists`);
                        } else {
                            if (response.data) {
                                setApplication(response.data);
                            }
                        }
                    } else {
                        setAppErrMsg('An error has occurred, please try again later');
                    }
                } else {
                    setAppErrMsg('An error has occurred, please try again later');
                }
            } catch (err) {
                if (err instanceof Error) {
                    setAppErrMsg(err.message);
                } else {
                    setAppErrMsg('An error has occurred, please try again later');
                }
            }
            setAppLoading(false);
        };

        const getRenewalAppReqsAsync = async () => {
            try {
                if (pfn_applicationid) {
                    const response = await applicationApi.getAppReqs(pfn_applicationid);

                    if (response) {
                        if (!response.success) {
                            setInvsErrMsg(`No requirements with the pfn_applicationid: ${pfn_applicationid} exists`);
                        } else {
                            if (response.data) {
                                setApplicationReqs(response.data);
                            }
                        }
                    } else {
                        setReqsErrMsg('An error has occurred, please try again later');
                    }
                } else {
                    setReqsErrMsg('An error has occurred, please try again later');
                }
            } catch (err) {
                if (err instanceof Error) {
                    setReqsErrMsg(err.message);
                } else {
                    setReqsErrMsg('An error has occurred, please try again later');
                }
            }
            setReqsLoading(false);
        };

        const getRenewalAppInvsAsync = async () => {
            try {
                if (pfn_applicationid) {
                    const response = await invoiceApi.getInvoicesByAppId(pfn_applicationid);

                    if (response) {
                        if (!response.success) {
                            setInvsErrMsg(`No invoices with the pfn_applicationid: ${pfn_applicationid} exists`);
                        } else {
                            if (response.data) {
                                setApplicationInvs(response.data);
                            }
                        }
                    } else {
                        setInvsErrMsg('An error has occurred, please try again later');
                    }
                } else {
                    setInvsErrMsg('An error has occurred, please try again later');
                }
            } catch (err) {
                if (err instanceof Error) {
                    setInvsErrMsg(err.message);
                } else {
                    setInvsErrMsg('An error has occurred, please try again later');
                }
            }
            setInvsLoading(false);
        };

        const getRenewalAppReceiptsAsync = async () => {
            try {
                if (pfn_applicationid) {
                    const response = await receiptApi.getReceiptsByAppId(pfn_applicationid);

                    if (response) {
                        if (!response.success) {
                            setReceiptsErrMsg(`No invoices with the pfn_applicationid: ${pfn_applicationid} exists`);
                        } else {
                            if (response.data) {
                                setApplicationReceipts(response.data);
                            }
                        }
                    } else {
                        setReceiptsErrMsg('An error has occurred, please try again later');
                    }
                } else {
                    setReceiptsErrMsg('An error has occurred, please try again later');
                }
            } catch (err) {
                if (err instanceof Error) {
                    setReceiptsErrMsg(err.message);
                } else {
                    setReceiptsErrMsg('An error has occurred, please try again later');
                }
            }
            setReceiptsLoading(false);
        };

        getRenewalAppReqsAsync();
        getRenewalAppByIdAsync();
        getRenewalAppInvsAsync();
        getRenewalAppReceiptsAsync();

        return () => {
            setAppErrMsg(null);
            setAppLoading(false);
            setApplication(null);
            setApplicationInvs([]);
            setApplicationReceipts([]);
            setApplicationReqs([]);
            setInvsErrMsg(null);
            setInvsLoading(false);
            setReceiptsErrMsg(null);
            setReceiptsLoading(false);
            setReqsErrMsg(null);
            setReqsLoading(false);
        };
    }, [pfn_applicationid]);

    return (
        <>
            {apploading || reqsloading || invsloading || receiptsloading ? (
                <div>Loading application status, please wait...</div>
            ) : appErrMsg ? (
                <div>
                    <div className='mb-20'>{appErrMsg}</div>
                    <div className='d-flex justify-content-center'>
                        <Link to='/'>
                            <button type='button' className='btn btn-primary fw-bolder'>
                                Back to Dashboard
                            </button>
                        </Link>
                    </div>
                </div>
            ) : !application ? (
                <div>
                    <div className='mb-20'>No Application with the pfn_applicationid: {pfn_applicationid} exists</div>
                    <div className='d-flex justify-content-start'>
                        <Link to='/'>
                            <button type='button' className='btn btn-primary fw-bolder'>
                                Back to Dashboard
                            </button>
                        </Link>
                    </div>
                </div>
            ) : (
                // ) : application.statuscode_Formatted !== 'Active' ? (
                //     <div>
                //         <div className='mb-20'>
                //             This Application is not active, please go back to the applications history page to view a
                //             different application.
                //         </div>
                //         <div className='d-flex justify-content-start'>
                //             <Link to='/applications/history'>
                //                 <button type='button' className='btn btn-primary fw-bolder'>
                //                     Back to Applications
                //                 </button>
                //             </Link>
                //         </div>
                //     </div>
                // ) : (
                <>
                    <div className='mb-15'>
                        <h4 className='text-primary mb-5'>Application Details:</h4>
                        <div className='text-start table-container'>
                            <Table className='status-details-table' striped bordered>
                                <tbody>
                                    <tr>
                                        <td className='name fw-bolder'>Application Name</td>
                                        <td className='value'>{application.pfn_name}</td>
                                    </tr>
                                    <tr>
                                        <td className='type fw-bolder'>Application Type</td>
                                        <td className='value'>{application._pfn_applicationtypeid_value_Formatted}</td>
                                    </tr>
                                    <tr>
                                        <td className='status fw-bolder'>Application Status</td>
                                        <td className='value'>{application.statuscode_Formatted}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {applicationInvs?.length > 0 && (
                        <div className='mb-15'>
                            <h4 className='text-primary mb-5'>Application Invoices:</h4>
                            {invsErrMsg ? (
                                <div className='text-danger'>{invsErrMsg}</div>
                            ) : (
                                <div className='text-start table-container'>
                                    <Table className='status-invoices-table' striped bordered>
                                        <thead>
                                            <tr>
                                                <th className='fw-bolder'>Invoice Name</th>
                                                <th className='fw-bolder'>Total</th>
                                                <th className='fw-bolder'>Paid</th>
                                                <th className='fw-bolder'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {applicationInvs.map((inv, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td className='name'>{inv.pfn_name}</td>
                                                        <td className='total'>{inv.pfn_total_Formatted}</td>
                                                        <td className='paid'>
                                                            {inv.pfn_outstanding === 0 ? 'Yes' : 'No'}
                                                        </td>
                                                        <td className='action'>
                                                            <Link to={`/invoices/${inv.pfn_name}/${inv.pfn_invoiceid}`}>
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-sm btn-primary my-1'
                                                                >
                                                                    View / Pay
                                                                </button>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            )}
                        </div>
                    )}
                    {applicationReceipts?.length > 0 && (
                        <div className='mb-15'>
                            <h4 className='text-primary mb-5'>Application Receipts:</h4>
                            {receiptsErrMsg ? (
                                <div className='text-danger'>{receiptsErrMsg}</div>
                            ) : (
                                <div className='text-start table-container'>
                                    <Table className='status-invoices-table' striped bordered>
                                        <thead>
                                            <tr>
                                                <th className='fw-bolder'>Receipts Name</th>
                                                <th className='fw-bolder'>Total</th>
                                                <th className='fw-bolder'>Paid</th>
                                                <th className='fw-bolder'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {applicationReceipts.map((receipt, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td className='name'>{receipt.pfn_name}</td>
                                                        <td className='total'>{receipt.pfn_amount_Formatted}</td>
                                                        <td className='paid'>Yes</td>
                                                        <td className='action'>
                                                            <Link
                                                                to={`/receipts/${receipt.pfn_name}/${receipt.pfn_paymentid}`}
                                                            >
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-sm btn-primary my-1'
                                                                >
                                                                    View Receipt
                                                                </button>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            )}
                        </div>
                    )}
                    <div className='mb-10'>
                        <h4 className='text-primary mb-5'>Application Requirements:</h4>

                        {reqsErrMsg ? (
                            <div className='text-danger'>{reqsErrMsg}</div>
                        ) : (
                            <div className='text-start table-container'>
                                <Table className='status-requirements-table' striped bordered>
                                    <thead>
                                        <tr>
                                            <th className='fw-bolder'>Requirement</th>
                                            <th className='fw-bolder'>Completed</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {applicationReqs.map((req, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td className='name'>{req.pfn_name}</td>
                                                    <td className='completed'>{req.pfn_requirementmet_Formatted}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        )}
                    </div>

                    <div>
                        <div></div>
                    </div>
                </>
            )}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- WRAPPER -------------------------------- */

const RenewalApplicationStatusWrapper: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Renewal Application Status</PageTitle>
            <RenewalApplicationStatusPage />
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { RenewalApplicationStatusWrapper };

/* -------------------------------------------------------------------------- */
