/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useLayout } from '../../core';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import { AsideMenu } from './AsideMenu';
// import { useAppDispatch } from '../../../../app/redux/hooks';
// import { refreshUser } from '../../../../app/redux/slices/UserSlice';

const AsideDefault: FC = () => {
    const { config, classes } = useLayout();
    const { aside } = config;
    // const dispatch = useAppDispatch();
    // const [loading, setLoading] = useState(false);
    // const [errMsg, setErrMsg] = useState<string | null>(null);

    // const refreshUserState = async () => {
    //     try {
    //         setLoading(true);
    //         setErrMsg(null);

    //         const response = await dispatch(refreshUser());
    //         if (response) {
    //             if (!response.payload?.success) {
    //                 if (response.payload?.message) {
    //                     setErrMsg('Failed');
    //                 } else {
    //                     setErrMsg('Failed');
    //                 }
    //             }
    //         } else {
    //             setErrMsg('Failed');
    //         }

    //         setLoading(false);
    //     } catch (err) {
    //         console.error(err);
    //         if (err instanceof Error) {
    //             setErrMsg('Failed');
    //         } else {
    //             setErrMsg('Failed');
    //         }
    //         setLoading(false);
    //     }
    // };

    return (
        <div
            id='kt_aside'
            className={clsx('aside', classes.aside.join(' '))}
            data-kt-drawer='true'
            data-kt-drawer-name='aside'
            data-kt-drawer-activate='{default: true, lg: false}'
            data-kt-drawer-overlay='true'
            data-kt-drawer-width="{default:'200px', '300px': '250px'}"
            data-kt-drawer-direction='start'
            data-kt-drawer-toggle='#kt_aside_mobile_toggle'
        >
            {/* begin::Brand */}
            <div className='aside-logo flex-column-auto' id='kt_aside_logo'>
                {/* begin::Logo */}
                {aside.theme === 'dark' && (
                    <Link to='/dashboard'>
                        <img
                            alt='Logo'
                            className='h-50px logo ms-n5'
                            src={toAbsoluteUrl('/media/logos/cvbc_logo_trans_white.png')}
                        />
                    </Link>
                )}
                {aside.theme === 'light' && (
                    <Link to='/dashboard'>
                        <img
                            alt='Logo'
                            className='h-50px logo ms-n5'
                            src={toAbsoluteUrl('/media/logos/cvbc_logo_trans_white.png')}
                        />
                    </Link>
                )}
                {/* end::Logo */}

                {/* begin::Aside toggler */}
                {aside.minimize && (
                    <div
                        id='kt_aside_toggle'
                        className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle'
                        data-kt-toggle='true'
                        data-kt-toggle-state='active'
                        data-kt-toggle-target='body'
                        data-kt-toggle-name='aside-minimize'
                    >
                        <KTSVG path={'/media/icons/duotune/arrows/arr080.svg'} className={'svg-icon-1 rotate-180'} />
                    </div>
                )}
                {/* custom code */}
                {aside.minimized && (
                    <div className='w-auto px-0'>
                        <KTSVG path={'/media/icons/duotune/arrows/arr080.svg'} className={'svg-icon-1 rotate-180'} />
                    </div>
                )}
                {/* end::Aside toggler */}
            </div>
            {/* end::Brand */}

            {/* begin::Aside menu */}
            <div className='aside-menu flex-column-fluid'>
                <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
            </div>
            {/* end::Aside menu */}

            {/* begin::Footer */}
            <div className='aside-footer flex-column-auto pt-5 pb-7 px-5' id='kt_aside_footer'>
                {/* <button className='btn btn-custom btn-custom-me btn-primary w-100 mb-3' onClick={refreshUserState}>
                    {!loading && <span className='indicator-label'>Refresh User {errMsg && `(${errMsg})`}</span>}
                    {loading && (
                        <span className='indicator-progress text-light' style={{ display: 'block' }}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button> */}
                <a
                    target='_blank'
                    className='btn btn-custom btn-custom-me btn-primary w-100'
                    href={process.env.REACT_APP_MAIN_WEBSITE_URL}
                    data-bs-toggle='tooltip'
                    data-bs-trigger='hover'
                    data-bs-dismiss-='click'
                    title='cvbc main website'
                >
                    <span className='btn-label'>Back to Main Website</span>
                    <span className='svg-icon btn-icon svg-icon-2 svg-home-website-button'>
                        <KTSVG path='/media/icons/duotune/general/gen001.svg' />
                    </span>
                </a>
            </div>
            {/* end::Footer */}
        </div>
    );
};

export { AsideDefault };
