/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/pages/custom/applications/formController/editBtns/EditEducationBtn.tsx
 * @author Jesse Zonneveld
 * @description Edit education button
 */

/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useState } from 'react';
import { KTSVG } from '../../../../../../../_metronic/helpers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { closeAllModals } from '../../../../../../helpers';
import { Education, EducationSchool, EducationSpecialty, EducationType } from '../../../../../../types';
import applicationApi from '../../../../../../api/applicationApi';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

interface Props {
    education: Education;
    modalName: string;
    getCurrentEducations: Function;
    educationTypes: EducationType[];
    educationSpecialties: EducationSpecialty[];
    educationSchools: EducationSchool[];
    graduationYears: number[];
}

/* -------------------------------------------------------------------------- */

/* ------------------------------- FORM SCHEMA ------------------------------ */

const validationSchema = Yup.object().shape({
    pfn_educationtypeid: Yup.string().required('Education type is required'),
    pfn_accountid: Yup.string().required('Education school is required'),
    pfn_year: Yup.string().required('Graduation year is required'),
    pfn_otherinstitutionname: Yup.string().when('pfn_accountid', {
        is: 'Other',
        then: Yup.string().required('Other institution name is required'),
    }),
    pfn_educationdiplomateid: Yup.string().when('pfn_educationtypeid', {
        is: '38169efa-3b8b-ea11-a811-000d3a0c8cd2',
        then: Yup.string().required('Education specialty is required'),
        // otherwise: Yup.string().test('empty-check', 'Education specialty must be empty if not diplomate', (val) => {
        //     if (val?.length === 0) {
        //         return true;
        //     } else {
        //         return false;
        //     }
        // }),
    }),
});

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const EditEducationBtn: FC<Props> = ({
    education,
    modalName,
    getCurrentEducations,
    educationTypes,
    educationSchools,
    educationSpecialties,
    graduationYears,
}) => {
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);

    const initialValues = {
        pfn_educationtypeid: education._pfn_educationtypeid_value ? education._pfn_educationtypeid_value : '',
        pfn_educationdiplomateid: education._pfn_educationdiplomateid_value
            ? education._pfn_educationdiplomateid_value
            : '',
        pfn_accountid: education._pfn_accountid_value ? education._pfn_accountid_value : 'Other',
        pfn_otherinstitutionname: education.pfn_otherinstitutionname ? education.pfn_otherinstitutionname : '',
        pfn_year: education.pfn_year ? education.pfn_year : '',
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                setErrMsg(null);
                setLoading(true);
                if (!education.pfn_educationid) {
                    throw new Error('Education selected has no id');
                }

                if (values.pfn_accountid === 'Other') {
                    values.pfn_accountid = '';
                } else {
                    values.pfn_otherinstitutionname = '';
                }

                if (values.pfn_educationtypeid !== '38169efa-3b8b-ea11-a811-000d3a0c8cd2') {
                    values.pfn_educationdiplomateid = '';
                }

                const response = await applicationApi.editEducation(education.pfn_educationid, values);
                if (response) {
                    if (!response.success) {
                        if (response.message) {
                            setErrMsg(response.message);
                        } else {
                            setErrMsg('An error has occured, please try again later');
                        }
                    } else {
                        closeAllModals();
                        getCurrentEducations();
                    }
                } else {
                    setErrMsg('An error has occured, please try again later');
                }

                setLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
                setLoading(false);
            }
        },
    });

    const RenderEditEducationModal = () => {
        return (
            <div className='modal fade' tabIndex={-1} id={modalName}>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h3 className='modal-title'>Edit Education</h3>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
                            </div>
                        </div>
                        <div className='modal-body'>{RenderEditEducationForm()}</div>
                    </div>
                </div>
            </div>
        );
    };

    const RenderEditEducationForm = () => {
        return (
            <form className='form w-100 text-start' noValidate id='editEducationForm'>
                {/* Start Form Row */}
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Education Type</label>

                    <select
                        {...formik.getFieldProps('pfn_educationtypeid')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_educationtypeid && formik.errors.pfn_educationtypeid,
                        })}
                        name='pfn_educationtypeid'
                    >
                        <option disabled value=''>
                            Select Type
                        </option>
                        {educationTypes.map((type, i) => {
                            return (
                                <option key={i} value={type.pfn_educationtypeid}>
                                    {type.pfn_name}
                                </option>
                            );
                        })}
                    </select>

                    {formik.touched.pfn_educationtypeid && formik.errors.pfn_educationtypeid && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_educationtypeid}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>School</label>
                    <select
                        {...formik.getFieldProps('pfn_accountid')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_accountid && formik.errors.pfn_accountid,
                        })}
                        name='pfn_accountid'
                    >
                        <option disabled value=''>
                            Select Institution
                        </option>
                        {educationSchools.map((school, i) => {
                            return (
                                <option key={i} value={school.accountid}>
                                    {school.name}
                                </option>
                            );
                        })}
                        <option value='Other'>Other Institution</option>
                    </select>
                    {formik.touched.pfn_accountid && formik.errors.pfn_accountid && (
                        <div className='fv-plugins-message-container'>
                            <span className='text-danger' role='alert'>
                                {formik.errors.pfn_accountid}
                            </span>
                        </div>
                    )}
                    <div className='text-muted'>
                        <b>Note:</b> If the school you are looking for is not in this list please chose 'Other
                        Institution'.
                    </div>
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                {formik.values.pfn_accountid === 'Other' && (
                    <div className='fv-row mb-7'>
                        <label className='form-label fs-6 fw-bolder text-dark required'>Institution Name</label>
                        <input
                            {...formik.getFieldProps('pfn_otherinstitutionname')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid':
                                    formik.touched.pfn_otherinstitutionname && formik.errors.pfn_otherinstitutionname,
                            })}
                            name='pfn_otherinstitutionname'
                            autoComplete='off'
                        />
                        {formik.touched.pfn_otherinstitutionname && formik.errors.pfn_otherinstitutionname && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_otherinstitutionname}
                                </span>
                            </div>
                        )}
                    </div>
                )}
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Graduation Year</label>
                    <select
                        {...formik.getFieldProps('pfn_year')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_year && formik.errors.pfn_year,
                        })}
                        name='pfn_year'
                    >
                        <option disabled value=''>
                            Select Year
                        </option>
                        {graduationYears?.map((year, i) => {
                            return (
                                <option key={i} value={String(year)}>
                                    {year}
                                </option>
                            );
                        })}
                    </select>
                    {formik.touched.pfn_year && formik.errors.pfn_year && (
                        <div className='fv-plugins-message-container'>
                            <span className='text-danger' role='alert'>
                                {formik.errors.pfn_year}
                            </span>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                {formik.values.pfn_educationtypeid === '38169efa-3b8b-ea11-a811-000d3a0c8cd2' && (
                    <div className='fv-row mb-7'>
                        <label className='form-label fs-6 fw-bolder text-dark required'>Specialty</label>
                        <select
                            {...formik.getFieldProps('pfn_educationdiplomateid')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid':
                                    formik.touched.pfn_educationdiplomateid && formik.errors.pfn_educationdiplomateid,
                            })}
                            name='pfn_educationdiplomateid'
                        >
                            <option disabled value=''>
                                Select Specialty
                            </option>
                            {educationSpecialties.map((specialty, i) => {
                                return (
                                    <option key={i} value={specialty.pfn_educationdiplomateid}>
                                        {specialty.pfn_name}
                                    </option>
                                );
                            })}
                        </select>
                        {formik.touched.pfn_educationdiplomateid && formik.errors.pfn_educationdiplomateid && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>
                                    {formik.errors.pfn_educationdiplomateid}
                                </span>
                            </div>
                        )}
                    </div>
                )}
                {/* End Form Row */}

                <div className='d-flex align-items-center mb-10'>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                </div>

                <div className='fv-row text-start'>
                    <div className='d-flex justify-content-end'>
                        <button type='button' className='btn btn-light me-3' data-bs-dismiss='modal'>
                            Cancel
                        </button>

                        <button
                            type='button'
                            className='btn btn-primary'
                            disabled={formik.isSubmitting || loading}
                            onClick={() => {
                                formik.handleSubmit();
                            }}
                        >
                            {!loading && <span className='indicator-label'>Update</span>}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </div>
                {errMsg && <div className='text-danger text-center mt-7'>{errMsg}</div>}
            </form>
        );
    };

    return (
        <>
            <button
                type='button'
                className='btn btn-primary btn-sm btn-icon me-2'
                data-bs-toggle='modal'
                data-bs-target={`#${modalName}`}
                onClick={() => {
                    formik.resetForm();
                    setErrMsg(null);
                }}
            >
                <i className='bi bi-pencil-square fs-2'></i>
            </button>

            {RenderEditEducationModal()}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { EditEducationBtn };

/* -------------------------------------------------------------------------- */
