/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/pages/custom/applications/renewal/formController/RenewalAppWizard.tsx
 * @author Jesse Zonneveld
 * @description Registration application wizard
 */

/* --------------------------------- IMPORTS -------------------------------- */

import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { Application } from '../../../../../types';
import { InitialDetailsForm } from './forms/InitialDetailsForm';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

interface Props {
    initialStep: number;
    application: Application;
}

/* -------------------------------------------------------------------------- */

/* --------------------------------- HELPERS -------------------------------- */

const stepsTitles = ['Initial Details', 'Summary'];

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const RenewalAppWizard: FC<Props> = ({ initialStep, application }) => {
    const [currentStep, setCurrentStep] = useState(initialStep);

    const goToNextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    // const goToPreviousStep = () => {
    //     setCurrentStep(currentStep - 1);
    // };

    const renderStepContent = () => {
        switch (currentStep) {
            case 0:
                return <InitialDetailsForm goToNextStep={goToNextStep} application={application} />;

            // case 1:
            //     if (application) {
            //         return <SummaryForm goToPreviousStep={goToPreviousStep} application={application} />;
            //     } else {
            //         return <div>Application not found</div>;
            //     }

            default:
                return <div>Not Found</div>;
        }
    };

    return (
        <>
            <div className='no-print'>
                <div className='wizard-stepper-progress'>
                    {stepsTitles.map((stepTitle, i) => {
                        return (
                            <React.Fragment key={i}>
                                {i !== 0 && (
                                    <div
                                        className={clsx('step-divider', {
                                            current: currentStep === i,
                                            completed: i < currentStep,
                                        })}
                                    ></div>
                                )}
                                <div className='step'>
                                    <div
                                        className={clsx('step-number', {
                                            current: currentStep === i,
                                            completed: i < currentStep,
                                        })}
                                    >
                                        {i + 1}
                                    </div>
                                    <div
                                        className={clsx('step-title', {
                                            current: currentStep === i,
                                            completed: i < currentStep,
                                        })}
                                    >
                                        {stepTitle}
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
            <div className='mb-10'>{renderStepContent()}</div>
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { RenewalAppWizard };

/* -------------------------------------------------------------------------- */
