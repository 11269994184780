/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/modules/auth/AuthPage.tsx
 * @author Jesse Zonneveld
 * @description Auth Page component
 */

/* --------------------------------- IMPORTS -------------------------------- */

import { useEffect } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Registration } from './components/Registration';
import { ForgotPassword } from './components/ForgotPassword';
import { Login } from './components/Login';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { VerifyAccount } from './components/VerifyAccount';
import { ResetPassword } from './components/ResetPassword';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const AuthLayout = () => {
    useEffect(() => {
        document.body.classList.add('bg-white');
        return () => {
            document.body.classList.remove('bg-white');
        };
    }, []);

    return (
        <div
            className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
            style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/bg/dog_lake.jpg')})`,
                backgroundSize: 'cover',
            }}
        >
            <div
                className='d-flex flex-column flex-column-fluid'
                style={{
                    backgroundColor: 'rgba(255,255,255,0.9)',
                }}
            >
                <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                    <a href='/auth' className='mb-12'>
                        <img
                            className='auth-logo'
                            alt='Logo'
                            src={toAbsoluteUrl('/media/logos/cvbc_logo_trans_blue.png')}
                        />
                    </a>
                    <Outlet />
                </div>
                <div className='d-flex flex-center flex-column-auto p-10'>
                    <div className='d-flex align-items-center fw-bold fs-6'>
                        <a
                            href={process.env.REACT_APP_MAIN_WEBSITE_URL}
                            target='_blank'
                            rel='noreferrer'
                            className='text-muted text-hover-primary px-2'
                        >
                            Back to Main Website
                        </a>
                    </div>
                </div>
            </div>
        </div>
        // <div
        //     className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        //     style={{
        //         backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
        //     }}
        // >
        //     <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        //         <a href='/auth' className='mb-12'>
        //             <img alt='Logo' src={toAbsoluteUrl('/media/logos/cvbc_logo_trans_blue.png')} className='h-80px' />
        //         </a>
        //         <Outlet />
        //     </div>
        //     <div className='d-flex flex-center flex-column-auto p-10'>
        //         <div className='d-flex align-items-center fw-bold fs-6'>
        //             <a href={process.env.REACT_APP_MAIN_WEBSITE_URL} className='text-muted text-hover-primary px-2'>
        //                 Back to Main Website
        //             </a>
        //         </div>
        //     </div>
        // </div>
    );
};

/* -------------------------------------------------------------------------- */

/* ---------------------------------- PAGE ---------------------------------- */

const AuthPage = () => (
    <Routes>
        <Route element={<AuthLayout />}>
            <Route path='login' element={<Login />} />
            <Route path='registration' element={<Registration />} />
            <Route path='forgot-password' element={<ForgotPassword />} />
            <Route path='verify/:contactid/:pfn_verificationcode' element={<VerifyAccount />} />
            <Route path='reset-password/:contactid/:pfn_passwordresetcode' element={<ResetPassword />} />
            <Route index element={<Login />} />
            <Route path='*' element={<Navigate to='/auth' />} />
        </Route>
    </Routes>
);

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { AuthPage };

/* -------------------------------------------------------------------------- */
