/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/pages/custom/facilities/facility-assessments/formController/forms/FacilityTypesForm.tsx
 * @author Jesse Zonneveld
 * @description Application form fourth step
 */

/* --------------------------------- IMPORTS -------------------------------- */

import * as Yup from 'yup';
import { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { FacilityAssessment, FacilityCategory, FacilitySubcategory } from '../../../../../../types';
import facilityAssessmentApi from '../../../../../../api/facilityAssessmentsApi';
import { Table } from 'react-bootstrap';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

interface Props {
    goToNextStep: Function;
    assessment: FacilityAssessment;
    goToPreviousStep: Function;
}

/* -------------------------------------------------------------------------- */

/* --------------------------------- SCHEMA --------------------------------- */

const validationSchema = Yup.object().shape({
    types: Yup.array()
        .of(Yup.string())
        .required('Must pick atleast one type')
        .test('atleastOne', 'Must select atleast one type', (val) => {
            if (val && val.length > 0) {
                return true;
            } else {
                return false;
            }
        }),
});

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const FacilityTypesForm: FC<Props> = ({ goToNextStep, goToPreviousStep, assessment }) => {
    const [formLoading, setFormLoading] = useState(false);
    const [subcategoriesLoading, setSubcategoriesLoading] = useState(true);
    const [categoriesLoading, setCategoriesLoading] = useState(true);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const [subcategories, setSubcategories] = useState<FacilitySubcategory[]>([]);
    const [categories, setCategories] = useState<FacilityCategory[]>([]);

    useEffect(() => {
        const getSubcategoriesAsync = async () => {
            try {
                setSubcategoriesLoading(true);
                const response = await facilityAssessmentApi.getSubcategories();

                if (response) {
                    if (!response.success) {
                        setErrMsg(response?.message || 'No subcategories found');
                    } else {
                        if (response.data) {
                            setSubcategories(response.data);
                        }
                    }
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
            }
            setSubcategoriesLoading(false);
        };

        const getCategoriesAsync = async () => {
            try {
                setCategoriesLoading(true);
                const response = await facilityAssessmentApi.getCategories();

                if (response) {
                    if (!response.success) {
                        setErrMsg(response?.message || 'No categories found');
                    } else {
                        if (response.data) {
                            setCategories(response.data);
                        }
                    }
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
            }
            setCategoriesLoading(false);
        };

        getSubcategoriesAsync();
        getCategoriesAsync();

        return () => {
            setSubcategories([]);
            setCategories([]);
            setErrMsg(null);
            setSubcategoriesLoading(false);
            setCategoriesLoading(false);
        };
    }, []);

    const getTypes = () => {
        const newTypes: string[] = [];

        assessment.facilityTypes?.forEach((type) => {
            if (type.pfn_facilitytype_facilitysubcategory && type.pfn_facilitytype_facilitysubcategory.length > 0) {
                type.pfn_facilitytype_facilitysubcategory.forEach((sub) =>
                    newTypes.push(sub.pfn_facilitysubcategoryid),
                );
            } else {
                newTypes.push(type._pfn_facilitycategoryid_value);
            }
        });

        return newTypes;
    };

    const convertValues = (values: string[]) => {
        const newValues: {
            pfn_name: string;
            pfn_facilitycategoryid: string;
            pfn_facilitysubcategoryid: string;
        }[] = [];

        values.forEach((value) => {
            const category = categories.find((cat) => cat.pfn_facilitycategoryid === value);
            const subcategory = subcategories.find((sub) => sub.pfn_facilitysubcategoryid === value);

            if (category) {
                newValues.push({
                    pfn_name: category.pfn_name,
                    pfn_facilitycategoryid: category.pfn_facilitycategoryid,
                    pfn_facilitysubcategoryid: '',
                });
            } else if (subcategory) {
                newValues.push({
                    pfn_name: subcategory._pfn_facilitycategoryid_value_Formatted || 'No Name',
                    pfn_facilitycategoryid: subcategory._pfn_facilitycategoryid_value,
                    pfn_facilitysubcategoryid: subcategory.pfn_facilitysubcategoryid,
                });
            }
        });

        return newValues;
    };

    const initialValues: { types: string[] } = {
        types: assessment.facilityTypes && assessment.facilityTypes.length > 0 ? getTypes() : [],
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                setErrMsg(null);
                setFormLoading(true);

                if (assessment) {
                    const response = await facilityAssessmentApi.updateAssessmentTypes(
                        assessment.pfn_facilityselfassessmentid,
                        assessment._pfn_facilityid_value,
                        convertValues(values.types),
                    );

                    if (response) {
                        if (!response.success) {
                            if (response.message) {
                                setErrMsg(response.message);
                            } else {
                                setErrMsg('An error has occured, please try again later');
                            }
                        } else {
                            goToNextStep();
                        }
                    } else {
                        setErrMsg('An error has occured, please try again later');
                    }
                } else {
                    setErrMsg('No assessment found');
                }

                setFormLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
                setFormLoading(false);
            }
        },
    });

    return (
        <>
            {subcategoriesLoading || categoriesLoading ? (
                <div>loading...</div>
            ) : errMsg ? (
                <div>{errMsg}</div>
            ) : (
                <div>
                    <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='typesForm'>
                        <div className='table-container mb-7'>
                            <label className='form-label fs-6 fw-bolder text-dark required mb-5'>
                                Please fill in all Facility Types that apply to your practice (please read{' '}
                                <a
                                    href='https://www.cvbc.ca/wp-content/uploads/2023/11/Appendix-Definitions.pdf'
                                    target='_blank'
                                    rel='noreferrer'
                                    className='text-decoration-underline'
                                >
                                    Definitions Document
                                </a>{' '}
                                for more info).
                            </label>
                            <Table className='subcategories-table' striped bordered>
                                <thead>
                                    <tr>
                                        <th className='fw-bolder'>Category</th>
                                        <th className='fw-bolder'>Subcategory</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {categories?.map((category, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className='category'>{category.pfn_name}</td>
                                                {subcategories.some(
                                                    (sub) =>
                                                        sub._pfn_facilitycategoryid_value ===
                                                        category.pfn_facilitycategoryid,
                                                ) ? (
                                                    <td className='subcategory'>
                                                        {subcategories.map((subcategory, k) => {
                                                            if (
                                                                subcategory._pfn_facilitycategoryid_value ===
                                                                category.pfn_facilitycategoryid
                                                            ) {
                                                                return (
                                                                    <div key={k}>
                                                                        <input
                                                                            type='checkbox'
                                                                            {...formik.getFieldProps('types')}
                                                                            className={clsx('form-check-inline ', {
                                                                                'is-invalid':
                                                                                    formik.touched.types &&
                                                                                    formik.errors.types,
                                                                            })}
                                                                            name='types'
                                                                            value={
                                                                                subcategory.pfn_facilitysubcategoryid
                                                                            }
                                                                            checked={formik.values.types.includes(
                                                                                subcategory.pfn_facilitysubcategoryid,
                                                                            )}
                                                                        />
                                                                        <label>{subcategory.pfn_name}</label>
                                                                    </div>
                                                                );
                                                            } else {
                                                                return null;
                                                            }
                                                        })}
                                                    </td>
                                                ) : (
                                                    <td>
                                                        <div>
                                                            <input
                                                                type='checkbox'
                                                                {...formik.getFieldProps('types')}
                                                                className={clsx('form-check-inline ', {
                                                                    'is-invalid':
                                                                        formik.touched.types && formik.errors.types,
                                                                })}
                                                                name='types'
                                                                value={category.pfn_facilitycategoryid}
                                                                checked={formik.values.types.includes(
                                                                    category.pfn_facilitycategoryid,
                                                                )}
                                                            />
                                                            <label>{category.pfn_name}</label>
                                                        </div>
                                                    </td>
                                                )}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                            {formik.touched.types && formik.errors.types && (
                                <div className='fv-plugins-message-container'>
                                    <span className='text-danger' role='alert'>
                                        {formik.errors.types}
                                    </span>
                                </div>
                            )}
                        </div>

                        <div className='d-flex align-items-center mb-10'>
                            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                        </div>

                        <div className='fv-row'>
                            <div className='d-flex justify-content-between'>
                                <button
                                    type='button'
                                    className='btn btn-primary me-3'
                                    onClick={() => goToPreviousStep()}
                                >
                                    Previous
                                </button>

                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    disabled={formik.isSubmitting || formLoading}
                                >
                                    {!formLoading && <span className='indicator-label'>Next</span>}
                                    {formLoading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                        {errMsg && <div className='text-danger text-center mt-7'>{errMsg}</div>}
                    </form>
                </div>
            )}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { FacilityTypesForm };

/* -------------------------------------------------------------------------- */
