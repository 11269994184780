/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/pages/custom/facilities/FacilitySearch.tsx
 * @author Jesse Zonneveld
 * @description Facility search page
 */

/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../../_metronic/layout/core';
import Table from 'react-bootstrap/Table';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Facility } from '../../../types';
import facilityApi from '../../../api/facilityApi';
import { Link } from 'react-router-dom';
import { FacilitySearchResult } from './components/FacilitySearchResult';

/* -------------------------------------------------------------------------- */

/* --------------------------- FORM INITIAL VALUES -------------------------- */

const initialValues = {
    searchQuery: '',
};

/* -------------------------------------------------------------------------- */

/* ------------------------------- FORM SCHEMA ------------------------------ */

const validationSchema = Yup.object().shape({
    searchQuery: Yup.string(),
});

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const FacilitySearchPage: FC = () => {
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const [errMsgAdd, setErrMsgAdd] = useState<string | null>(null);
    const [facilities, setFacilities] = useState<Facility[]>();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                setErrMsg(null);
                setErrMsgAdd(null);
                setFacilities(undefined);
                const response = await facilityApi.getFacilitiesByName(values.searchQuery);

                if (response) {
                    if (response.success) {
                        setFacilities(response.data);
                    } else {
                        if (response.message) {
                            setErrMsg(response.message);
                        } else {
                            setErrMsg('An error has occured, please try again later');
                        }
                    }
                } else {
                    setErrMsg('An error has occured, please try again later');
                }
                setLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    if (err.message) {
                        setErrMsg(err.message);
                    } else {
                        setErrMsg('An error has occured, please try again later');
                    }
                } else {
                    setErrMsg('An error has occured, please try again later');
                }
                setLoading(false);
            }
        },
    });

    return (
        <>
            <div className='card card-xl mb-5 mb-xl-8'>
                <div className='card-header border-0'>
                    <h3 className='card-title fw-bolder text-dark'>General Information</h3>
                </div>

                <div className='card-body pt-0'>
                    <ul>
                        <li className='mb-5'>
                            If your veterinary work is in an environment other than a veterinary practice facility (eg.
                            industry, government, etc), please contact the office (reception@cvbc.ca) to update your
                            work information – the below list only includes accredited practice facilities.
                        </li>
                        <li className='mb-5'>
                            Please update our records by removing or adding facilities as necessary. To add a facility
                            to your record, please search for it using the search form below. To manage your facilities
                            please go to{' '}
                            <Link className='text-decoration-underline fw-bold' to={'/facilities/my-facilities'}>
                                My Facilities
                            </Link>
                            .
                        </li>
                        <li>
                            If the list below is not accurate, please update our records by contacting us at{' '}
                            <a className='text-decoration-underline fw-bold' href='mailto:reception@cvbc.ca'>
                                reception@cvbc.ca
                            </a>
                            .
                        </li>
                    </ul>
                </div>
            </div>

            <div className='card card-xl mb-5 mb-xl-8'>
                <div className='card-header border-0'>
                    <h3 className='card-title fw-bolder text-dark'>Facility Search</h3>
                </div>
                <div className='card-body pt-0'>
                    <form className='form' onSubmit={formik.handleSubmit} noValidate id='faciltySearchForm'>
                        {/* begin::Form group */}
                        <div className='fv-row mb-10'>
                            <div className='d-flex'>
                                <input
                                    {...formik.getFieldProps('searchQuery')}
                                    className={clsx('form-control form-control-lg form-control-solid')}
                                    name='searchQuery'
                                    style={{ borderRadius: '7px 0 0 7px' }}
                                    placeholder='Enter a facility name'
                                />
                                <button
                                    type='submit'
                                    id='kt_sign_in_submit'
                                    className='btn btn-lg btn-primary'
                                    style={{ borderRadius: '0 7px 7px 0' }}
                                    disabled={formik.isSubmitting || loading}
                                >
                                    {!loading && <span className='indicator-label'>Search</span>}
                                    {loading && (
                                        <span
                                            className='indicator-progress'
                                            style={{ display: 'block', whiteSpace: 'nowrap' }}
                                        >
                                            Searching...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                        {/* end::Form group */}

                        {errMsg || errMsgAdd ? (
                            <>
                                <div>{errMsg}</div>
                                <div>{errMsgAdd}</div>
                            </>
                        ) : facilities && facilities.length === 0 ? (
                            <div>No facilities found, please try another search.</div>
                        ) : facilities && facilities.length >= 1 ? (
                            <>
                                <div className='d-flex align-items-center mb-10'>
                                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                                </div>

                                <div className='fv-row'>
                                    <div className='table-container'>
                                        <Table striped bordered className='facility-table'>
                                            <thead>
                                                <tr>
                                                    <th className='fw-bolder'>Facility Name</th>
                                                    <th className='fw-bolder'>Previous Name</th>
                                                    <th className='fw-bolder'>City</th>
                                                    <th className='fw-bolder'>Designated Veterinarian</th>
                                                    <th className='fw-bolder'>Phone</th>
                                                    <th className='fw-bolder'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {facilities &&
                                                    facilities.map((facility, i) => {
                                                        return <FacilitySearchResult key={i} facility={facility} />;
                                                    })}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </>
                        ) : (
                            ''
                        )}
                    </form>
                </div>
            </div>
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* ---------------------------- COMPONENT WRAPPER --------------------------- */

const FacilitySearchWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.FACILITYSEARCH' })}</PageTitle>
            <FacilitySearchPage />
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { FacilitySearchWrapper };

/* -------------------------------------------------------------------------- */
