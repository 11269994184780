/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/pages/custom/facilities/components/FacilityEditDetails.tsx
 * @author Jesse Zonneveld
 * @description Edit details for a current facility result
 */

/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useState } from 'react';
import { useAppDispatch } from '../../../../redux/hooks';
import { KTSVG } from '../../../../../_metronic/helpers';
import { FacilityState, updateFacilityDetails } from '../../../../redux/slices/UserSlice';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import clsx from 'clsx';
import MaskedInput from 'react-text-mask';
import { closeModalById } from '../../../../helpers';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

interface Props {
    facility: FacilityState;
    modalName: string;
}

/* -------------------------------------------------------------------------- */

/* ------------------------------- FORM SCHEMA ------------------------------ */

const phoneRegExp = /^\d{3}[-]\d{3}[-]\d{4}$/;
const websiteRegExp =
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

const validationSchema = Yup.object().shape({
    accountid: Yup.string().required('accountid is required'),
    pfn_onlinepublication: Yup.string().required('Online publication is required'),
    emailaddress1: Yup.string().email('Wrong email format').required('Email is required'),
    emailaddress2: Yup.string().email('Wrong email format'),
    telephone1: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    fax: Yup.string().matches(phoneRegExp, 'Fax number is not valid'),
    websiteurl: Yup.string().matches(websiteRegExp, 'Enter a valid url.'),
});

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const FacilityEditDetails: FC<Props> = ({ facility, modalName }) => {
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const dispatch = useAppDispatch();

    const initialValues = {
        accountid: facility.id ? facility.id : '',
        pfn_onlinepublication: facility.permission.formatted ? facility.permission.formatted : '',
        emailaddress1: facility.email.main ? facility.email.main : '',
        emailaddress2: facility.email.additional ? facility.email.additional : '',
        telephone1: facility.telephone ? facility.telephone : '',
        fax: facility.fax ? facility.fax : '',
        websiteurl: facility.website ? facility.website : '',
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                setErrMsg(null);
                setLoading(true);
                const response = await dispatch(updateFacilityDetails(values));
                if (response) {
                    if (!response.payload?.success) {
                        if (response.payload?.message) {
                            setErrMsg(response.payload.message);
                        } else {
                            setErrMsg('An error has occured, please try again later');
                        }
                    } else {
                        closeModalById(modalName);
                    }
                } else {
                    setErrMsg('An error has occured, please try again later');
                }
                setLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
                setLoading(false);
            }
        },
    });

    const GenerateEditFacilityDetailsModal = () => {
        return (
            <div className='modal fade' tabIndex={-1} id={modalName}>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Edit Facility Details: {facility.name}</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
                            </div>
                        </div>
                        <div className='modal-body'>{GenerateEditFacilityDetailsForm()}</div>
                    </div>
                </div>
            </div>
        );
    };

    const GenerateEditFacilityDetailsForm = () => {
        return (
            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='editFacilityDetails'>
                {/* Start Form Row */}
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Main Email</label>
                    <input
                        {...formik.getFieldProps('emailaddress1')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.emailaddress1 && formik.errors.emailaddress1,
                        })}
                        type='email'
                        name='emailaddress1'
                        autoComplete='off'
                    />
                    {formik.touched.emailaddress1 && formik.errors.emailaddress1 && (
                        <div className='fv-plugins-message-container'>
                            <span role='alert' className='text-danger'>
                                {formik.errors.emailaddress1}
                            </span>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Additional Email</label>
                    <input
                        {...formik.getFieldProps('emailaddress2')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.emailaddress2 && formik.errors.emailaddress2,
                        })}
                        type='email'
                        name='emailaddress2'
                        autoComplete='off'
                    />
                    {formik.touched.emailaddress2 && formik.errors.emailaddress2 && (
                        <div className='fv-plugins-message-container'>
                            <span role='alert' className='text-danger'>
                                {formik.errors.emailaddress2}
                            </span>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-7'>
                    <label className='form-label fw-bolder text-dark fs-6'>Phone</label>
                    <MaskedInput
                        mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        {...formik.getFieldProps('telephone1')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.telephone1 && formik.errors.telephone1,
                        })}
                    />
                    {formik.touched.telephone1 && formik.errors.telephone1 && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                    {formik.errors.telephone1}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-7'>
                    <label className='form-label fw-bolder text-dark fs-6'>Fax</label>
                    <MaskedInput
                        mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        {...formik.getFieldProps('fax')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.fax && formik.errors.fax,
                        })}
                    />
                    {formik.touched.fax && formik.errors.fax && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                    {formik.errors.fax}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Website</label>
                    <input
                        placeholder='url'
                        {...formik.getFieldProps('websiteurl')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.websiteurl && formik.errors.websiteurl,
                        })}
                        name='websiteurl'
                        autoComplete='off'
                    />
                    {formik.touched.websiteurl && formik.errors.websiteurl && (
                        <div className='fv-plugins-message-container'>
                            <span role='alert' className='text-danger'>
                                {formik.errors.websiteurl}
                            </span>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                        Permission for Publication of Facility Information in Online Registry
                    </label>
                    <select
                        {...formik.getFieldProps('pfn_onlinepublication')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_onlinepublication && formik.errors.pfn_onlinepublication,
                        })}
                        name='pfn_onlinepublication'
                    >
                        <option disabled value=''>
                            Select Permission Type
                        </option>
                        <option value='All Business Info'>Business Name, Phone Number, and Full Address</option>
                        <option value='Business Name & Phone'>Business Name, Phone Number and City</option>
                    </select>
                    {formik.touched.pfn_onlinepublication && formik.errors.pfn_onlinepublication && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                    {formik.errors.pfn_onlinepublication}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}

                <div className='text-muted mb-5'>
                    <b>Note:</b> To make any change to the practice facility’s mailing address and/or physical location
                    (street) address, please contact{' '}
                    <a className='text-decoration-underline fw-bold' href='mailto:facilities@cvbc.ca'>
                        facilities@cvbc.ca
                    </a>
                    . A relocation of the actual facility address requires CVBC approval before services can be provided
                    from the new location.
                </div>

                <div className='d-flex align-items-center mb-10'>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                </div>

                <div className='fv-row'>
                    <div className='d-flex justify-content-end'>
                        <button type='button' className='btn btn-light me-3' data-bs-dismiss='modal'>
                            Cancel
                        </button>

                        <button type='submit' className='btn btn-primary' disabled={formik.isSubmitting || loading}>
                            {!loading && <span className='indicator-label'>Submit</span>}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </div>
                {errMsg && <div className='text-danger text-center mt-7'>{errMsg}</div>}
            </form>
        );
    };

    return (
        <>
            <div>
                <span
                    className='btn-link link-primary cursor-pointer hover'
                    data-bs-toggle='modal'
                    data-bs-target={`#${modalName}`}
                    onClick={() => {
                        formik.resetForm();
                        setErrMsg(null);
                    }}
                >
                    Edit Details
                </span>
            </div>
            {GenerateEditFacilityDetailsModal()}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { FacilityEditDetails };

/* -------------------------------------------------------------------------- */
