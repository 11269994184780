/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/pages/custom/applications/registration/RegistrationApplication.tsx
 * @author Jesse Zonneveld
 * @description Registration application page
 */

/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { PageTitle } from '../../../../../_metronic/layout/core';
import applicationApi from '../../../../api/applicationApi';
// import { useAppSelector } from '../../../../redux/hooks';
import { Application } from '../../../../types';
import { RenewalAppWizard } from './formController/RenewalAppWizard';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const RenewalApplicationPage: FC = () => {
    // const user = useAppSelector((state) => state.user);
    const [loading, setLoading] = useState(true);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    // const [alreadyStartedApplication, setAlreadyStartedApp] = useState(false);
    const [application, setApplication] = useState<Application | null>(null);
    const { pfn_applicationid } = useParams();

    useEffect(() => {
        // const checkIfUserNeedsToStartNewApp = async () => {
        //     try {
        //         const response = await applicationApi.checkIfUserHasRenewalAppStarted();

        //         if (response) {
        //             if (!response.success) {
        //                 if (response.message) {
        //                     setErrMsg(response.message);
        //                 } else {
        //                     setErrMsg('An error occured, please try again later');
        //                 }
        //             } else {
        //                 if (response.data) {
        //                     setAlreadyStartedApp(true);
        //                 }
        //             }
        //         } else {
        //             setErrMsg('An error has occurred, please try again later');
        //         }
        //     } catch (err) {
        //         if (err instanceof Error) {
        //             setErrMsg(err.message);
        //         } else {
        //             setErrMsg('An error has occurred, please try again later');
        //         }
        //     }
        //     setLoading(false);
        // };

        const getRenewalAppByIdAsync = async () => {
            try {
                setLoading(true);
                if (pfn_applicationid) {
                    const response = await applicationApi.getAppById(pfn_applicationid);

                    if (response) {
                        if (!response.success) {
                            // setErrMsg(`No Application with the pfn_applicationid: ${pfn_applicationid} exists`);
                            setErrMsg(
                                response?.message ||
                                    `No Application with the pfn_applicationid: ${pfn_applicationid} exists`,
                            );
                        } else {
                            if (response.data) {
                                setApplication(response.data);
                            }
                        }
                    } else {
                        setErrMsg('An error has occurred, please try again later');
                    }
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
            }
            setLoading(false);
        };

        // if (pfn_applicationid === 'new-application') {
        //     if (user.statusHistory.subcategory.formatted === 'Applicant') {
        //         setErrMsg('You must be registered to view this page');
        //         setLoading(false);
        //     } else if (user.statusHistory.status.formatted !== 'Active') {
        //         setErrMsg('You must be an active member to view this page');
        //         setLoading(false);
        //     } else {
        //         // checkIfUserNeedsToStartNewApp();
        //     }
        // } else {
        getRenewalAppByIdAsync();
        // }

        return () => {
            // setAlreadyStartedApp(false);
            setApplication(null);
            setErrMsg(null);
            setLoading(false);
        };
    }, [pfn_applicationid]);

    return (
        <>
            {/* {!user.renewalYear ? (
                <div>
                    <div className='mb-20'>Renewal application is not ready for your account.</div>
                    <div className='d-flex justify-content-center'>
                        <Link to='/'>
                            <button type='button' className='btn btn-primary fw-bolder'>
                                Back to Dashboard
                            </button>
                        </Link>
                    </div>
                </div> */}
            {loading ? (
                <div>Loading application form, please wait...</div>
            ) : errMsg ? (
                <div>
                    <div className='mb-20'>{errMsg}</div>
                    <div className='d-flex justify-content-center'>
                        <Link to='/'>
                            <button type='button' className='btn btn-primary fw-bolder'>
                                Back to Dashboard
                            </button>
                        </Link>
                    </div>
                </div>
            ) : // ) : pfn_applicationid === 'new-application' && alreadyStartedApplication ? (
            //     <div>
            //         <div className='mb-20'>
            //             You have already started a {new Date().getFullYear()} renewal application. Please go to{' '}
            //             <Link className='text-decoration-underline fw-bold' to={'/applications/renewal'}>
            //                 your current renewal applications
            //             </Link>{' '}
            //             to continue the application you already started.
            //         </div>
            //         <div className='d-flex justify-content-center'>
            //             <Link to='/applications/registration'>
            //                 <button type='button' className='btn btn-primary fw-bolder mx-2'>
            //                     My Applications
            //                 </button>
            //             </Link>
            //             <Link to='/'>
            //                 <button type='button' className='btn btn-primary fw-bolder mx-2'>
            //                     Back to Dashboard
            //                 </button>
            //             </Link>
            //         </div>
            //     </div>
            // ) : pfn_applicationid === 'new-application' ? (
            //     <div>
            //         <RenewalAppWizard initialStep={0} application={null} />
            //     </div>
            application ? (
                <div>
                    <RenewalAppWizard initialStep={0} application={application} />
                </div>
            ) : (
                <div>
                    <div className='mb-20'>No Application with the pfn_applicationid: {pfn_applicationid} exists</div>
                    <div className='d-flex justify-content-start'>
                        <Link to='/'>
                            <button type='button' className='btn btn-primary fw-bolder'>
                                Back to Dashboard
                            </button>
                        </Link>
                    </div>
                </div>
            )}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- WRAPPER -------------------------------- */

const RenewalApplicationWrapper: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Online Renewal Application</PageTitle>
            <RenewalApplicationPage />
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { RenewalApplicationWrapper };

/* -------------------------------------------------------------------------- */
