/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/pages/contact-information/components/ContactInfoHomeAddress.tsx
 * @author Jesse Zonneveld
 * @description Home address section for the contact information page
 */

/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useEffect, useState } from 'react';
import { KTSVG } from '../../../../../_metronic/helpers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import MaskedInput from 'react-text-mask';
import { updateAddress, updateMailingAddress } from '../../../../redux/slices/UserSlice';
import miscApi from '../../../../api/miscApi';
import { closeModalById } from '../../../../helpers';

/* -------------------------------------------------------------------------- */

/* ------------------------------- FORM SCHEMA ------------------------------ */

const editAddressSchema = Yup.object().shape({
    address2_line1: Yup.string().required('Address Line 1 is required'),
    address2_line2: Yup.string(),
    address2_city: Yup.string().required('Address city is required'),
    address2_stateorprovince: Yup.string().required('Address province is required'),
    address2_postalcode: Yup.string().required('Address postal code is required'),
    address2_country: Yup.string().required('Address country is required'),
});

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const ContactInfoHomeAddress: FC = () => {
    const user = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [settingMailingAddress, setSettingMailingAddress] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const [errMsgMailing, setErrMsgMailing] = useState<string | null>(null);
    const [countries, setCountries] = useState<string[]>([]);
    const [provincesOrStates, setProvincesOrStates] = useState<string[]>([]);

    const initialValues = {
        address2_line1: user.address.home.line1 ? user.address.home.line1 : '',
        address2_line2: user.address.home.line2 ? user.address.home.line2 : '',
        address2_city: user.address.home.city ? user.address.home.city : '',
        address2_stateorprovince: user.address.home.province ? user.address.home.province : '',
        address2_postalcode: user.address.home.postalCode ? user.address.home.postalCode : '',
        address2_country: user.address.home.country ? user.address.home.country : '',
    };

    const formik = useFormik({
        initialValues,
        validationSchema: editAddressSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                setErrMsg(null);
                setLoading(true);
                const response = await dispatch(updateAddress(values));
                if (response) {
                    if (!response.payload?.success) {
                        if (response.payload?.message) {
                            setErrMsg(response.payload.message);
                        } else {
                            setErrMsg('An error has occured, please try again later');
                        }
                    } else {
                        closeModalById('kt_modal_edit_home');
                    }
                } else {
                    setErrMsg('An error has occured, please try again later');
                }
                setLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
                setLoading(false);
            }
        },
    });

    useEffect(() => {
        const getCountiesAsync = async () => {
            try {
                const response = await miscApi.getCountries();
                setCountries(response?.data ? response.data : []);
            } catch (err) {
                console.error(err);
            }
        };

        getCountiesAsync();

        return () => {
            setCountries([]);
        };
    }, []);

    const getProvincesAsync = async () => {
        try {
            const response = await miscApi.getProvinces();
            setProvincesOrStates(response?.data ? response.data : []);
        } catch (err) {
            console.error(err);
        }
    };

    const getStatesAsync = async () => {
        try {
            const response = await miscApi.getStates();
            setProvincesOrStates(response?.data ? response.data : []);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (formik.values.address2_country === 'Canada') {
            getProvincesAsync();
        } else if (formik.values.address2_country === 'United States') {
            getStatesAsync();
        }

        return () => {
            setProvincesOrStates([]);
        };
    }, [formik.values.address2_country]);

    const editHomeAddressModal = () => {
        return (
            <div className='modal fade' tabIndex={-1} id='kt_modal_edit_home'>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Edit Home Address</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
                            </div>
                        </div>
                        <div className='modal-body'>{editHomeAddressForm()}</div>
                    </div>
                </div>
            </div>
        );
    };

    const editHomeAddressForm = () => {
        return (
            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='edit_home_form'>
                {/* Start Form Row */}
                <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Street Address Line 1</label>
                    <input
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('address2_line1')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.address2_line1 && formik.errors.address2_line1,
                        })}
                    />
                    {formik.touched.address2_line1 && formik.errors.address2_line1 && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                    {formik.errors.address2_line1}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Street Address Line 2</label>
                    <input
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('address2_line2')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.address2_line2 && formik.errors.address2_line2,
                        })}
                    />
                    {formik.touched.address2_line2 && formik.errors.address2_line2 && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                    {formik.errors.address2_line2}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>City</label>
                    <input
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('address2_city')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.address2_city && formik.errors.address2_city,
                        })}
                    />
                    {formik.touched.address2_city && formik.errors.address2_city && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                    {formik.errors.address2_city}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Country</label>
                    <select
                        {...formik.getFieldProps('address2_country')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.address2_country && formik.errors.address2_country,
                        })}
                        name='address2_country'
                    >
                        <option disabled value=''>
                            Select Country
                        </option>
                        {countries &&
                            countries.map((country, i) => {
                                return (
                                    <option key={i} value={country}>
                                        {country}
                                    </option>
                                );
                            })}
                        <option value='Other'>Other</option>
                    </select>
                    {formik.touched.address2_country && formik.errors.address2_country && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                    {formik.errors.address2_country}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Province</label>
                    {formik.values.address2_country === 'Canada' ||
                    formik.values.address2_country === 'United States' ? (
                        <select
                            {...formik.getFieldProps('address2_stateorprovince')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid':
                                    formik.touched.address2_stateorprovince && formik.errors.address2_stateorprovince,
                            })}
                            name='address2_stateorprovince'
                        >
                            <option disabled value=''>
                                {formik.values.address2_country === 'Canada'
                                    ? 'Select Province'
                                    : formik.values.address2_country === 'United States'
                                    ? 'Select State'
                                    : 'Select'}
                            </option>
                            {provincesOrStates &&
                                provincesOrStates.map((place, i) => {
                                    return (
                                        <option key={i} value={place}>
                                            {place}
                                        </option>
                                    );
                                })}
                        </select>
                    ) : (
                        <input
                            type='text'
                            autoComplete='off'
                            {...formik.getFieldProps('address2_stateorprovince')}
                            className={clsx('form-control form-control-lg form-control-solid', {
                                'is-invalid':
                                    formik.touched.address2_stateorprovince && formik.errors.address2_stateorprovince,
                            })}
                        />
                    )}

                    {formik.touched.address2_stateorprovince && formik.errors.address2_stateorprovince && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                    {formik.errors.address2_stateorprovince}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='row fv-row mb-5'>
                    {/* Start Form Column */}
                    <div className='col-xl-6'>
                        <div className='fv-row mb-5'>
                            <label className='form-label fs-6 required'>Postal Code</label>
                            <MaskedInput
                                mask={[
                                    /[a-zA-Z0-9]/,
                                    /[a-zA-Z0-9]/,
                                    /[a-zA-Z0-9]/,
                                    /[a-zA-Z0-9]/,
                                    /[a-zA-Z0-9]/,
                                    /[a-zA-Z0-9]/,
                                ]}
                                {...formik.getFieldProps('address2_postalcode')}
                                className={clsx('form-control form-control-lg form-control-solid', {
                                    'is-invalid':
                                        formik.touched.address2_postalcode && formik.errors.address2_postalcode,
                                })}
                            />
                            {formik.touched.address2_postalcode && formik.errors.address2_postalcode && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert' className='text-danger'>
                                            {formik.errors.address2_postalcode}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {/* End Form Column */}
                </div>
                {/* End Form Row */}

                <div className='text-muted mb-5'>
                    Address changes will be reflected immediately, but may be subject to verification by CVBC staff to
                    ensure conformance with Canada Post standards.
                </div>

                <div className='d-flex align-items-center mb-10'>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                </div>

                <div className='fv-row'>
                    <div className='d-flex justify-content-end'>
                        <button type='button' className='btn btn-light me-3' data-bs-dismiss='modal'>
                            Cancel
                        </button>

                        <button type='submit' className='btn btn-primary' disabled={formik.isSubmitting || loading}>
                            {!loading && <span className='indicator-label'>Submit</span>}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </div>
                {errMsg && <div className='text-danger text-center mt-7'>{errMsg}</div>}
            </form>
        );
    };

    return (
        <>
            <div className='card card-xl mb-5 mb-xl-8'>
                <div className='card-header border-0'>
                    <h3 className='card-title fw-bolder text-dark'>
                        Home Address {user.address.mailing === 'Home' && ' (Mailing Address)'}
                    </h3>
                </div>

                <div className='card-body pt-0'>
                    <div className='facility-card d-flex align-items-center bg-light rounded p-5 mb-7'>
                        {/* <span className='bullet bullet-vertical h-100px bg-primary me-10'></span> */}

                        <div className='facility-card-inner flex-grow-1 me-2'>
                            {/* <div className='fw-bolder text-gray-800 fs-6 mb-3'>
                                <span>Home</span>
                            </div> */}
                            <span className='text-muted fw-bold d-block'>{user.address.home.line1}</span>
                            <span className='text-muted fw-bold d-block'>{user.address.home.line2}</span>
                            <div>
                                <span className='text-muted fw-bold'>{user.address.home.city}</span>
                                <span className='text-muted fw-bold'>, {user.address.home.province}</span>
                            </div>
                            <span className='text-muted fw-bold d-block'>{user.address.home.country}</span>
                            <span className='text-muted fw-bold d-block'>{user.address.home.postalCode}</span>
                        </div>
                    </div>
                    <button
                        className='btn btn-sm btn-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_edit_home'
                        onClick={() => {
                            formik.resetForm();
                            setErrMsg(null);
                        }}
                    >
                        Edit
                    </button>
                    {user.address.mailing !== 'Home' && (
                        <button
                            className='btn btn-sm btn-primary ms-3'
                            disabled={settingMailingAddress}
                            onClick={async () => {
                                try {
                                    setSettingMailingAddress(true);
                                    setErrMsgMailing(null);
                                    const response = await dispatch(updateMailingAddress('Home'));
                                    if (response) {
                                        if (!response.payload?.success) {
                                            if (response.payload?.message) {
                                                setErrMsgMailing(response.payload.message);
                                            } else {
                                                setErrMsgMailing('An error has occured, please try again later');
                                            }
                                        }
                                    } else {
                                        setErrMsgMailing('An error has occured, please try again later');
                                    }
                                    setSettingMailingAddress(false);
                                } catch (err) {
                                    if (err instanceof Error) {
                                        setErrMsgMailing(err.message);
                                    } else {
                                        setErrMsgMailing('An error has occurred, please try again later');
                                    }
                                    setSettingMailingAddress(false);
                                }
                            }}
                        >
                            {!settingMailingAddress && <span className='indicator-label'>Set as mailing address</span>}
                            {settingMailingAddress && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    )}
                    {errMsgMailing && <div className='text-danger text-center mt-7'>{errMsgMailing}</div>}
                    {editHomeAddressModal()}
                </div>
            </div>
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { ContactInfoHomeAddress };

/* -------------------------------------------------------------------------- */
