/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { Link } from 'react-router-dom';

const DropdownCustom3: FC = () => {
    return (
        <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3'
            data-kt-menu='true'
        >
            <div className='menu-item px-3'>
                <div className='menu-content text-muted pb-2 px-3 fs-7 text-uppercase'>Options</div>
            </div>

            <div className='menu-item px-3'>
                <Link to='/contact-info' className='menu-link px-3'>
                    Update address
                </Link>
            </div>
            <div className='menu-item px-3'>
                <Link to='/contact-info' className='menu-link px-3'>
                    Update contact
                </Link>
            </div>

            <div className='menu-item px-3'>
                <Link to='/change-email' className='menu-link px-3'>
                    Change username
                </Link>
            </div>
            <div className='menu-item px-3'>
                <Link to='/change-password' className='menu-link px-3'>
                    Change password
                </Link>
            </div>
        </div>
    );
};

export { DropdownCustom3 };
