/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/api/userApi.ts
 * @author Jesse Zonneveld
 * @description User api
 */

/* --------------------------------- LINTING -------------------------------- */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

/* -------------------------------------------------------------------------- */

/* --------------------------------- IMPORTS -------------------------------- */

import { UserState } from '../redux/slices/UserSlice';
import { apiClient } from './config';
import {
    ApiResponse,
    UpdateEmailFormValues,
    UpdatePersonalInfoFormValues,
    UpdatePhoneFormValues,
    RegisterFormValues,
    UpdateAddressFormValues,
    User,
} from '../types';

/* -------------------------------------------------------------------------- */

/* ----------------------------------- API ---------------------------------- */

const registerUser = async (details: RegisterFormValues) => {
    try {
        const { data } = await apiClient.post<ApiResponse<UserState>>('/users/register', details);
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const updatePhone = async (details: UpdatePhoneFormValues) => {
    try {
        const { data } = await apiClient.put<ApiResponse<UserState>>('/users/update-phone', details);
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const requestChangeEmail = async (pfn_changeemailaddress: string) => {
    try {
        const { data } = await apiClient.put<ApiResponse<UserState>>('/users/request-change-email', {
            pfn_changeemailaddress,
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const changeEmail = async (details: UpdateEmailFormValues) => {
    try {
        const { data } = await apiClient.put<ApiResponse<UserState>>('/users/change-email', details);
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const changeMainEmail = async (mainEmail: 'work' | 'personal') => {
    try {
        const { data } = await apiClient.put<ApiResponse<UserState>>('/users/change-main-email', { mainEmail });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const deleteEmail = async (emailType: 'work' | 'personal') => {
    try {
        const { data } = await apiClient.put<ApiResponse<UserState>>('/users/delete-email', { emailType });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const updateAddress = async (details: UpdateAddressFormValues) => {
    try {
        const { data } = await apiClient.put<ApiResponse<UserState>>('/users/update-address', details);
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const updateMailingAddress = async (pfn_mailingaddress: string) => {
    try {
        const { data } = await apiClient.put<ApiResponse<UserState>>('/users/update-mailing-address', {
            pfn_mailingaddress,
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const updatePersonalInfo = async (details: UpdatePersonalInfoFormValues) => {
    try {
        const { data } = await apiClient.put<ApiResponse<UserState>>('/users/update-personal-info', details);
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const updatePermission = async (pfn_onlinepublication: string) => {
    try {
        const { data } = await apiClient.put<ApiResponse<UserState>>('/users/update-permission', {
            pfn_onlinepublication,
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const updatePrimaryFacility = async (accountid: string) => {
    try {
        const { data } = await apiClient.put<ApiResponse<UserState>>('/users/set-primary-facility', {
            accountid,
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const updateLocum = async (pfn_locum: boolean) => {
    try {
        const { data } = await apiClient.put<ApiResponse<UserState>>('/users/update-locum', {
            pfn_locum,
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const checkIfEmailAlreadyExists = async (email: string) => {
    try {
        const { data } = await apiClient.post<ApiResponse<string>>('/users/check-if-email-already-exists', { email });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const forgotPassword = async (pfn_username: string) => {
    try {
        const { data } = await apiClient.put<ApiResponse<string>>('/users/forgot-password', { pfn_username });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const resetPassword = async (
    contactid: string,
    pfn_passwordresetcode: string,
    pfn_password: string,
    passwordConfirmation: string,
) => {
    try {
        const { data } = await apiClient.put<ApiResponse<string>>(
            `/users/reset-password/${contactid}/${pfn_passwordresetcode}`,
            {
                pfn_password,
                passwordConfirmation,
            },
        );
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const changePassword = async (
    contactid: string,
    currentPassword: string,
    newPassword: string,
    newPasswordConfirmation: string,
) => {
    try {
        const { data } = await apiClient.put<ApiResponse<UserState>>(`/users/change-password`, {
            contactid,
            currentPassword,
            newPassword,
            newPasswordConfirmation,
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const resendVerificationEmail = async (pfn_username: string) => {
    try {
        const { data } = await apiClient.put<ApiResponse<string>>('/users/resend-verification-email', {
            pfn_username,
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const verifyUser = async (contactid: string, pfn_verificationcode: string) => {
    try {
        const { data } = await apiClient.put<ApiResponse<UserState>>(
            `/users/verify/${contactid}/${pfn_verificationcode}`,
        );
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getUserInfo = async () => {
    try {
        const { data } = await apiClient.get<ApiResponse<UserState>>('/users/me');
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getUserById = async (pfn_contactid: string): Promise<ApiResponse<User>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<User>>('/users/get-user-by-id', { pfn_contactid });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

/* -------------------------------------------------------------------------- */

/* ------------------------------- API OBJECT ------------------------------- */

const userApi = {
    registerUser,
    updatePhone,
    requestChangeEmail,
    changeEmail,
    changeMainEmail,
    deleteEmail,
    updateAddress,
    updateMailingAddress,
    updatePersonalInfo,
    updatePermission,
    updatePrimaryFacility,
    updateLocum,
    checkIfEmailAlreadyExists,
    verifyUser,
    forgotPassword,
    resetPassword,
    changePassword,
    resendVerificationEmail,
    getUserInfo,
    getUserById,
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export default userApi;

/* -------------------------------------------------------------------------- */
