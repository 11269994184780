/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/pages/custom/transcripts/components/UploadDocumentsBtn.tsx
 * @author Jesse Zonneveld
 * @description Upload document button with modal components
 */

/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useState } from 'react';
import { KTSVG } from '../../../../../_metronic/helpers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { CourseState, uploadDocuments } from '../../../../redux/slices/UserSlice';
import { convertFileToBase64 } from '../../../../helpers';
import { useAppDispatch } from '../../../../redux/hooks';
import { UploadDocFormConvertedDetails, UploadDocFormDetails } from '../../../../types';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

interface Props {
    course: CourseState;
    modalName: string;
}

/* -------------------------------------------------------------------------- */

/* --------------------------- FORM INITIAL VALUES -------------------------- */

// const initialValues = {
//     documentbody: '',
// };
const initialValues = {
    docs: [{ id: 0, documentbody: '' }],
};

/* -------------------------------------------------------------------------- */

/* ------------------------------- FORM SCHEMA ------------------------------ */

const MAX_FILE_SIZE = 5242880; //5MB

const validFileExtensions = [
    'image/jpg',
    'image/gif',
    'image/png',
    'image/jpeg',
    'image/svg+xml',
    'image/webp',
    'application/pdf',
];

function isValidFileType(fileType: string | undefined) {
    if (!fileType) {
        return false;
    }

    return validFileExtensions.indexOf(fileType) > -1;
}

const validationSchema = Yup.object().shape({
    docs: Yup.array().of(
        Yup.object().shape({
            documentbody: Yup.mixed()
                .required('Document is required')
                .test('is-valid-type', 'Not a valid file type', (value) => {
                    return isValidFileType(value && value.type);
                })
                .test('is-valid-size', 'Max allowed size is 5mb', (value) => {
                    return value && value.size && value.size <= MAX_FILE_SIZE;
                }),
        }),
    ),
});

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const UploadDocumentBtn: FC<Props> = ({ course, modalName }) => {
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const dispatch = useAppDispatch();

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                setErrMsg(null);
                setLoading(true);
                if (!course.id) {
                    throw new Error('Course selected has no id');
                }

                const convertedValues = await convertValues(course.id, values);
                if (convertedValues) {
                    const response = await dispatch(uploadDocuments(convertedValues));
                    if (response) {
                        if (!response.payload?.success) {
                            if (response.payload?.message) {
                                setErrMsg(response.payload.message);
                            } else {
                                setErrMsg('An error has occured, please try again later');
                            }
                        }
                    } else {
                        setErrMsg('An error has occured, please try again later');
                    }
                } else {
                    setErrMsg('File could not be converted to Base64');
                }
                setLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
                setLoading(false);
            }
        },
    });

    const convertValues = async (
        pfn_cecourseid: string,
        values: UploadDocFormDetails,
    ): Promise<UploadDocFormConvertedDetails | null> => {
        try {
            const convertedValues: UploadDocFormConvertedDetails = { docs: [] };

            for (let i = 0; i < values.docs.length; i++) {
                convertedValues.docs.push({
                    pfn_cecourseid,
                    filename: values.docs[i].documentbody.name,
                    mimetype: values.docs[i].documentbody.type,
                    documentbody: await convertFileToBase64(values.docs[i].documentbody),
                });
            }

            return convertedValues;
        } catch (err) {
            console.error(err);
            return null;
        }
    };

    const GenerateUploadDocumentsModal = () => {
        return (
            <div className='modal fade' tabIndex={-1} id={modalName}>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Upload Documents</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
                            </div>
                        </div>
                        <div className='modal-body'>{GenerateUploadDocumentsForm()}</div>
                    </div>
                </div>
            </div>
        );
    };

    const GenerateUploadDocumentsForm = () => {
        return (
            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='uploadDocsForm'>
                {formik.values.docs.map((doc) => {
                    return (
                        // Start Form Row
                        <div key={doc.id} className='fv-row text-start mb-5'>
                            <label className='form-label fs-6 fw-bolder text-dark required'>
                                Document ({doc.id + 1})
                            </label>
                            <input
                                onBlur={formik.getFieldProps(`docs[${doc.id}].documentbody`).onBlur}
                                onChange={(event) => {
                                    formik.setFieldValue(
                                        `docs[${doc.id}].documentbody`,
                                        event.currentTarget.files && event.currentTarget.files[0],
                                    );
                                }}
                                className={clsx('form-control form-control-lg form-control-solid', {
                                    'is-invalid':
                                        formik.touched.docs?.[doc.id]?.documentbody &&
                                        (formik.errors.docs?.[doc.id] as any)?.documentbody,
                                })}
                                name={`docs[${doc.id}].documentbody`}
                                type='file'
                            />
                            {formik.touched.docs?.[doc.id]?.documentbody &&
                                (formik.errors.docs?.[doc.id] as any)?.documentbody && (
                                    <div className='fv-plugins-message-container'>
                                        <span role='alert' className='text-danger'>
                                            {(formik.errors.docs?.[doc.id] as any)?.documentbody}
                                        </span>
                                    </div>
                                )}
                        </div>
                        // End Form Row
                    );
                })}
                <button
                    className='btn btn-primary btn-sm btn-icon me-2'
                    type='button'
                    onClick={() => {
                        formik.setValues({
                            docs: [...formik.values.docs, { id: formik.values.docs.length, documentbody: '' }],
                        });
                    }}
                >
                    <i className='bi bi-plus fs-1'></i>
                </button>
                {formik.values.docs.length > 1 && (
                    <button
                        className='btn btn-primary btn-sm btn-icon me-2'
                        type='button'
                        onClick={() => {
                            formik.setValues({
                                docs: formik.values.docs.splice(0, formik.values.docs.length - 1),
                            });
                        }}
                    >
                        <i className='bi bi-dash fs-1'></i>
                    </button>
                )}
                <div className='d-flex align-items-center mb-10 mt-5'>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                </div>

                <div className='fv-row text-start'>
                    <div className='d-flex justify-content-end'>
                        <button type='button' className='btn btn-light me-3' data-bs-dismiss='modal'>
                            Cancel
                        </button>

                        <button type='submit' className='btn btn-primary' disabled={formik.isSubmitting || loading}>
                            {!loading && <span className='indicator-label'>Upload</span>}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </div>
                {errMsg && <div className='text-danger text-center mt-7'>{errMsg}</div>}
            </form>
        );
    };

    return (
        <>
            <button
                className='btn btn-primary btn-sm'
                data-bs-toggle='modal'
                data-bs-target={`#${modalName}`}
                onClick={() => {
                    formik.resetForm();
                    setErrMsg(null);
                }}
            >
                Upload Document
            </button>

            {GenerateUploadDocumentsModal()}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { UploadDocumentBtn };

/* -------------------------------------------------------------------------- */
