/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';

const DropdownCustom5: FC = () => {
    return (
        <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3'
            data-kt-menu='true'
        >
            <div className='menu-item px-3'>
                <div className='menu-content text-muted pb-2 px-3 fs-7 text-uppercase'>Help</div>
            </div>

            <div className='menu-item px-3'>
                <a
                    href='https://www.cvbc.ca/registration/continuing-education/'
                    target='_blank'
                    rel='noreferrer'
                    className='menu-link px-3'
                >
                    More Info
                </a>
            </div>
        </div>
    );
};

export { DropdownCustom5 };
