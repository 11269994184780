/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/pages/custom/payment/PaymentSuccessPublic.tsx
 * @author Jesse Zonneveld
 * @description Payment success public page
 */

/* --------------------------------- IMPORTS -------------------------------- */

import { FC } from 'react';
import { PageTitle } from '../../../../_metronic/layout/core';
import { Link, useLocation } from 'react-router-dom';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const PaymentSuccessPublicPage: FC = () => {
    const location = useLocation();
    const { pfn_name, pfn_paymentid, user } = location.state as any;

    return (
        <div className='share-payment-success-page'>
            <div className='card text-center pb-15 w-lg-600px mx-auto'>
                <div className='card-header justify-content-center'>
                    <h3 className='card-title fw-bolder text-dark'>Payment Successful!</h3>
                </div>

                <div className='card-body'>
                    Your payment was successful! If your receipt is not created over the next 24 hours then please
                    contact us at{' '}
                    <a className='text-decoration-underline fw-bold' href='mailto:reception@cvbc.ca'>
                        reception@cvbc.ca
                    </a>
                    .
                </div>
                <div className='d-flex justify-content-center'>
                    <Link to='/auth'>
                        <button className='btn btn-primary mx-3'>Back</button>
                    </Link>
                    <Link to={`/shared/receipts/${pfn_name}/${pfn_paymentid}`} state={{ user }}>
                        <button className='btn btn-primary mx-3'>View Receipt</button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

/* -------------------------------------------------------------------------- */

/* ------------------------------ PAGE WRAPPER ------------------------------ */

const PaymentSuccessPublicWrapper: FC = () => {
    const title = `Payment`;

    return (
        <>
            <PageTitle breadcrumbs={[]}>{title}</PageTitle>
            <PaymentSuccessPublicPage />
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { PaymentSuccessPublicWrapper };

/* -------------------------------------------------------------------------- */
