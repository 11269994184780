/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/pages/custom/transcripts/components/EditCourseBtn.tsx
 * @author Jesse Zonneveld
 * @description Edit course button with modal components
 */

/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useState } from 'react';
import { KTSVG } from '../../../../../_metronic/helpers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { CourseState, editCourse } from '../../../../redux/slices/UserSlice';
import { useAppDispatch } from '../../../../redux/hooks';
import { closeModalById, convertFileToBase64 } from '../../../../helpers';
import { UploadedDocumentDelete } from './UploadedDocumentDelete';
import { UpdateCourseFormConvertedDetails, UpdateCourseFormDetails } from '../../../../types';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

interface Props {
    course: CourseState;
    modalName: string;
}

/* -------------------------------------------------------------------------- */

/* ------------------------------- FORM SCHEMA ------------------------------ */

const MAX_FILE_SIZE = 5242880; //5MB

const validFileExtensions = [
    'image/jpg',
    'image/gif',
    'image/png',
    'image/jpeg',
    'image/svg+xml',
    'image/webp',
    'application/pdf',
];

function isValidFileType(fileType: string | undefined) {
    if (!fileType) {
        return true;
    }

    return validFileExtensions.indexOf(fileType) > -1;
}

const validationSchema = Yup.object().shape({
    pfn_name: Yup.string().required('Title is required'),
    pfn_sponsor: Yup.string(),
    pfn_location: Yup.string(),
    pfn_speaker: Yup.string().required('Speaker is required'),
    pfn_startdate1: Yup.string().required('Start date is required'),
    pfn_enddate1: Yup.string().required('End date is required'),
    pfn_creditssubmitted: Yup.string()
        .required('Credits is required')
        .test('checkMaxMin', 'Must be no within the range of 0 - 2000', async (val) => {
            return Number(val) <= 2000 && Number(val) >= 0;
        }),
    docs: Yup.array().of(
        Yup.object().shape({
            documentbody: Yup.mixed()
                // .required('Document is required')
                .test('is-valid-type', 'Not a valid file type', (value) => {
                    return isValidFileType(value && value.type);
                })
                .test('is-valid-size', 'Max allowed size is 5mb', (value) => {
                    if (!value) {
                        return true;
                    }
                    return value.size && value.size <= MAX_FILE_SIZE;
                }),
        }),
    ),
});

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const EditCourseBtn: FC<Props> = ({ course, modalName }) => {
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const dispatch = useAppDispatch();

    const initialValues = {
        pfn_name: course.name ? course.name : '',
        pfn_sponsor: course.sponsor ? course.sponsor : '',
        pfn_location: course.location ? course.location : '',
        pfn_speaker: course.speaker ? course.speaker : '',
        pfn_startdate1: course.startDate.value ? course.startDate.value : '',
        pfn_enddate1: course.endDate.value ? course.endDate.value : '',
        pfn_creditssubmitted: course.creditsSubmitted.value ? course.creditsSubmitted.value : 0,
        docs: [{ id: 0, documentbody: '' }],
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                setErrMsg(null);
                setLoading(true);
                if (!course.id) {
                    throw new Error('Course selected has no id');
                }

                const convertedValues = await convertValues(course.id, values);

                if (!convertedValues) {
                    throw new Error('Values could not be converted');
                }

                const response = await dispatch(editCourse({ courseId: course.id, details: convertedValues }));
                if (response) {
                    if (!response.payload?.success) {
                        if (response.payload?.message) {
                            setErrMsg(response.payload.message);
                        } else {
                            setErrMsg('An error has occured, please try again later');
                        }
                    } else {
                        closeModalById(modalName);
                    }
                } else {
                    setErrMsg('An error has occured, please try again later');
                }

                setLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsg(err.message);
                } else {
                    setErrMsg('An error has occurred, please try again later');
                }
                setLoading(false);
            }
        },
    });

    const convertValues = async (
        pfn_cecourseid: string,
        values: UpdateCourseFormDetails,
    ): Promise<UpdateCourseFormConvertedDetails | null> => {
        try {
            const convertedDocValues: {
                pfn_cecourseid: string;
                filename: string;
                mimetype: string;
                documentbody: any;
            }[] = [];

            for (let i = 0; i < values.docs.length; i++) {
                if (values.docs[i].documentbody) {
                    convertedDocValues.push({
                        pfn_cecourseid,
                        filename: values.docs[i].documentbody.name,
                        mimetype: values.docs[i].documentbody.type,
                        documentbody: await convertFileToBase64(values.docs[i].documentbody),
                    });
                }
            }

            return { ...values, docs: convertedDocValues };
        } catch (err) {
            console.error(err);
            return null;
        }
    };

    const GenerateEditCourseModal = () => {
        return (
            <div className='modal fade' tabIndex={-1} id={modalName}>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Edit Course</h5>
                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
                            </div>
                        </div>
                        <div className='modal-body'>{GenerateEditCourseForm()}</div>
                    </div>
                </div>
            </div>
        );
    };

    const GenerateEditCourseForm = () => {
        return (
            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='editCourseForm'>
                {/* Start Form Row */}
                <div className='fv-row text-start mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Course Name</label>
                    <input
                        {...formik.getFieldProps('pfn_name')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_name && formik.errors.pfn_name,
                        })}
                        name='pfn_name'
                        autoComplete='off'
                    />
                    {formik.touched.pfn_name && formik.errors.pfn_name && (
                        <div className='fv-plugins-message-container'>
                            <span role='alert' className='text-danger'>
                                {formik.errors.pfn_name}
                            </span>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row text-start mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Session Host/Sponsor</label>
                    <input
                        {...formik.getFieldProps('pfn_sponsor')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_sponsor && formik.errors.pfn_sponsor,
                        })}
                        name='pfn_sponsor'
                        autoComplete='off'
                    />
                    {formik.touched.pfn_sponsor && formik.errors.pfn_sponsor && (
                        <div className='fv-plugins-message-container'>
                            <span role='alert' className='text-danger'>
                                {formik.errors.pfn_sponsor}
                            </span>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row text-start mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark'>Location</label>
                    <input
                        {...formik.getFieldProps('pfn_location')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_location && formik.errors.pfn_location,
                        })}
                        name='pfn_location'
                        autoComplete='off'
                    />
                    {formik.touched.pfn_location && formik.errors.pfn_location && (
                        <div className='fv-plugins-message-container'>
                            <span role='alert' className='text-danger'>
                                {formik.errors.pfn_location}
                            </span>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row text-start mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Speaker Name</label>
                    <input
                        {...formik.getFieldProps('pfn_speaker')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_speaker && formik.errors.pfn_speaker,
                        })}
                        name='pfn_speaker'
                        autoComplete='off'
                    />
                    {formik.touched.pfn_speaker && formik.errors.pfn_speaker && (
                        <div className='fv-plugins-message-container'>
                            <span role='alert' className='text-danger'>
                                {formik.errors.pfn_speaker}
                            </span>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row text-start mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6 required'>Start Date</label>

                    <input
                        type='date'
                        min='1900-01-01'
                        max='9999-12-31'
                        autoComplete='off'
                        {...formik.getFieldProps('pfn_startdate1')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_startdate1 && formik.errors.pfn_startdate1,
                        })}
                    />
                    {formik.touched.pfn_startdate1 && formik.errors.pfn_startdate1 && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                    {formik.errors.pfn_startdate1}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row text-start mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6 required'>End Date</label>

                    <input
                        type='date'
                        min='1900-01-01'
                        max='9999-12-31'
                        autoComplete='off'
                        {...formik.getFieldProps('pfn_enddate1')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_enddate1 && formik.errors.pfn_enddate1,
                        })}
                    />
                    {formik.touched.pfn_enddate1 && formik.errors.pfn_enddate1 && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert' className='text-danger'>
                                    {formik.errors.pfn_enddate1}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                {/* End Form Row */}
                {/* Start Form Row */}
                <div className='fv-row text-start mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Number of Credits</label>
                    <input
                        {...formik.getFieldProps('pfn_creditssubmitted')}
                        className={clsx('form-control form-control-lg form-control-solid', {
                            'is-invalid': formik.touched.pfn_creditssubmitted && formik.errors.pfn_creditssubmitted,
                        })}
                        type='number'
                        max='2000'
                        min='0'
                        name='pfn_creditssubmitted'
                        autoComplete='off'
                    />
                    {formik.touched.pfn_creditssubmitted && formik.errors.pfn_creditssubmitted && (
                        <div className='fv-plugins-message-container'>
                            <span role='alert' className='text-danger'>
                                {formik.errors.pfn_creditssubmitted}
                            </span>
                        </div>
                    )}
                </div>
                {/* End Form Row */}

                {/* Start Form Row */}
                <div className='fv-row text-start mb-5'>
                    <label className='form-label fs-6 fw-bolder text-dark required'>Uploaded Documents</label>
                    {course.uploadedDocs.map((doc, i) => (
                        <UploadedDocumentDelete course={course} doc={doc} key={i} />
                    ))}
                </div>
                {/* End Form Row */}

                {/* Start Form Row */}
                {formik.values.docs.map((doc) => {
                    return (
                        // Start Form Row
                        <div key={doc.id} className='fv-row text-start mb-5'>
                            <label className='form-label fs-6 fw-bolder text-dark'>
                                Document to Add ({doc.id + 1})
                            </label>
                            <input
                                onBlur={formik.getFieldProps(`docs[${doc.id}].documentbody`).onBlur}
                                onChange={(event) => {
                                    formik.setFieldValue(
                                        `docs[${doc.id}].documentbody`,
                                        event.currentTarget.files && event.currentTarget.files[0],
                                    );
                                }}
                                className={clsx('form-control form-control-lg form-control-solid', {
                                    'is-invalid':
                                        formik.touched.docs?.[doc.id]?.documentbody &&
                                        (formik.errors.docs?.[doc.id] as any)?.documentbody,
                                })}
                                name={`docs[${doc.id}].documentbody`}
                                type='file'
                            />
                            {formik.touched.docs?.[doc.id]?.documentbody &&
                                (formik.errors.docs?.[doc.id] as any)?.documentbody && (
                                    <div className='fv-plugins-message-container'>
                                        <span role='alert' className='text-danger'>
                                            {(formik.errors.docs?.[doc.id] as any)?.documentbody}
                                        </span>
                                    </div>
                                )}
                        </div>
                        // End Form Row
                    );
                })}
                <button
                    className='btn btn-primary btn-sm btn-icon me-2'
                    type='button'
                    onClick={() => {
                        formik.setFieldValue('docs', [
                            ...formik.values.docs,
                            { id: formik.values.docs.length, documentbody: '' },
                        ]);
                    }}
                >
                    <i className='bi bi-plus fs-1'></i>
                </button>
                {formik.values.docs.length > 1 && (
                    <button
                        className='btn btn-primary btn-sm btn-icon me-2'
                        type='button'
                        onClick={() => {
                            formik.setFieldValue('docs', formik.values.docs.splice(0, formik.values.docs.length - 1));
                        }}
                    >
                        <i className='bi bi-dash fs-1'></i>
                    </button>
                )}
                {/* End Form Row */}

                <div className='text-muted mb-5 mt-5'>
                    <b>Note:</b> If you attended a multi-day or multi-session conference, you only need to create a
                    single entry for the entire program. You will need to provide a list of the specific sessions you
                    attended along with your certificate of completion.
                </div>

                <div className='d-flex align-items-center mb-10'>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                    <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                </div>

                <div className='fv-row text-start'>
                    <div className='d-flex justify-content-end'>
                        <button type='button' className='btn btn-light me-3' data-bs-dismiss='modal'>
                            Cancel
                        </button>

                        <button type='submit' className='btn btn-primary' disabled={formik.isSubmitting || loading}>
                            {!loading && <span className='indicator-label'>Submit</span>}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </div>
                {errMsg && <div className='text-danger text-center mt-7'>{errMsg}</div>}
            </form>
        );
    };

    return (
        <>
            <button
                className='btn btn-primary btn-sm btn-icon me-2'
                data-bs-toggle='modal'
                data-bs-target={`#${modalName}`}
                onClick={() => {
                    formik.resetForm();
                    setErrMsg(null);
                }}
            >
                <i className='bi bi-pencil-square fs-2'></i>
            </button>

            {GenerateEditCourseModal()}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { EditCourseBtn };

/* -------------------------------------------------------------------------- */
