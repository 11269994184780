/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/pages/custom/facilities/facility-assessments/FacilityAssessments.tsx
 * @author Jesse Zonneveld
 * @description Facility assessments page
 */

/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PageTitle } from '../../../../../_metronic/layout/core';
import { AssessmentYear, FacilityAssessment } from '../../../../types';
import facilityAssessmentApi from '../../../../api/facilityAssessmentsApi';
import facilityApi from '../../../../api/facilityApi';
import { useAppSelector } from '../../../../redux/hooks';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const FacilityAssessmentsPage: FC = () => {
    const user = useAppSelector((state) => state.user);
    const [loadingAssessments, setLoadingAssessments] = useState(true);
    const [loadingCreateAssessment, setLoadingCreateAssessment] = useState(false);
    const [loadingAssessmentYear, setLoadingAssessmentYear] = useState(false);
    const [errMsgAssessments, setErrMsgAssessments] = useState<string | null>(null);
    const [errMsgAssessmentYear, setErrMsgAssessmentYear] = useState<string | null>(null);
    const [errMsgCreateAssessment, setErrMsgCreateAssessment] = useState<string | null>(null);
    const [assessments, setAssessments] = useState<FacilityAssessment[]>([]);
    const [currentAssessmentYear, setCurrentAssessmentYear] = useState<AssessmentYear | null>(null);
    const { facilityId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const getCurrentAssessmentYearAsync = async () => {
            try {
                const response = await facilityAssessmentApi.getCurrentAssessmentYear();

                if (response) {
                    if (!response.success) {
                        if (response.message) {
                            setErrMsgAssessmentYear(response.message);
                        } else {
                            setErrMsgAssessmentYear('An error occured, please try again later');
                        }
                    } else {
                        if (response.data) {
                            setCurrentAssessmentYear(response.data);
                        } else {
                            setErrMsgAssessmentYear('No assessment year found');
                        }
                    }
                } else {
                    setErrMsgAssessmentYear('An error has occurred, please try again later');
                }
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsgAssessmentYear(err.message);
                } else {
                    setErrMsgAssessmentYear('An error has occurred, please try again later');
                }
            }
            setLoadingAssessmentYear(false);
        };

        const getAssessmentsAsync = async () => {
            try {
                if (facilityId) {
                    const response1 = await facilityApi.getFacilityById(facilityId);

                    if (response1) {
                        if (!response1.success) {
                            if (response1.message) {
                                setErrMsgAssessments(response1.message);
                            } else {
                                setErrMsgAssessments('An error occured, please try again later');
                            }
                        } else {
                            if (response1.data) {
                                if (response1.data._pfn_contactid_value === user.id) {
                                    const response2 = await facilityAssessmentApi.getFacilityAssessmentsByFacilityId(
                                        facilityId,
                                    );

                                    if (response2) {
                                        if (!response2.success) {
                                            if (response2.message) {
                                                setErrMsgAssessments(response2.message);
                                            } else {
                                                setErrMsgAssessments('An error occured, please try again later');
                                            }
                                        } else {
                                            if (response2.data) {
                                                setAssessments(response2.data);
                                            }
                                        }
                                    } else {
                                        setErrMsgAssessments('An error has occurred, please try again later');
                                    }
                                } else {
                                    setErrMsgAssessments('You are not the designated Vet for this facility');
                                }
                            } else {
                                setErrMsgAssessments('No facility found');
                            }
                        }
                    } else {
                        setErrMsgAssessments('An error has occurred, please try again later');
                    }
                } else {
                    setErrMsgAssessments('An error has occurred, please try again later');
                }
            } catch (err) {
                if (err instanceof Error) {
                    setErrMsgAssessments(err.message);
                } else {
                    setErrMsgAssessments('An error has occurred, please try again later');
                }
            }
            setLoadingAssessments(false);
        };

        getAssessmentsAsync();
        getCurrentAssessmentYearAsync();

        return () => {
            setLoadingAssessments(false);
            setErrMsgAssessments(null);
            setAssessments([]);
            setLoadingAssessmentYear(false);
            setErrMsgAssessmentYear(null);
            setCurrentAssessmentYear(null);
        };
    }, [facilityId, user.id]);

    const createNewAssessment = async () => {
        try {
            if (facilityId) {
                setLoadingCreateAssessment(true);
                const response = await facilityAssessmentApi.getFacilityAssessmentsByFacilityId(facilityId);

                if (response) {
                    if (!response.success) {
                        if (response.message) {
                            setErrMsgCreateAssessment(response.message);
                        } else {
                            setErrMsgCreateAssessment('An error occured, please try again later');
                        }
                    } else {
                        if (currentAssessmentYear && currentAssessmentYear.pfn_assessmentyearid) {
                            if (response.data) {
                                const response2 = await facilityAssessmentApi.createAssessment(
                                    facilityId,
                                    currentAssessmentYear.pfn_assessmentyearid,
                                );

                                if (!response2.success) {
                                    if (response2.message) {
                                        setErrMsgCreateAssessment(response2.message);
                                    } else {
                                        setErrMsgCreateAssessment('An error occured, please try again later');
                                    }
                                } else {
                                    if (response2.data) {
                                        navigate(
                                            `/facilities/assessment/${facilityId}/${response2.data.pfn_facilityselfassessmentid}`,
                                        );
                                    }
                                }
                            } else {
                                const response2 = await facilityAssessmentApi.createAssessment(
                                    facilityId,
                                    currentAssessmentYear.pfn_assessmentyearid,
                                );

                                if (!response2.success) {
                                    if (response2.message) {
                                        setErrMsgCreateAssessment(response2.message);
                                    } else {
                                        setErrMsgCreateAssessment('An error occured, please try again later');
                                    }
                                } else {
                                    if (response2.data) {
                                        navigate(
                                            `/facilities/assessment/${facilityId}/${response2.data.pfn_facilityselfassessmentid}`,
                                        );
                                    }
                                }
                            }
                        } else {
                            setErrMsgCreateAssessment('Could not find current assessment year');
                        }
                    }
                } else {
                    setErrMsgCreateAssessment('An error has occurred, please try again later');
                }
            } else {
                setErrMsgCreateAssessment('An error has occurred, no facility id found');
            }
            setLoadingCreateAssessment(false);
        } catch (err) {
            if (err instanceof Error) {
                setErrMsgCreateAssessment(err.message);
            } else {
                setErrMsgCreateAssessment('An error has occurred, please try again later');
            }
            setLoadingCreateAssessment(false);
        }
    };

    const checkIfNeedToStartNewAssessment = () => {
        if (!assessments || assessments.length === 0) {
            return true;
        }

        if (assessments[0]._pfn_assessmentyear_value !== currentAssessmentYear?.pfn_assessmentyearid) {
            return true;
        } else {
            return false;
        }
    };

    const generateGeneralInfo = () => {
        return (
            <div className='card card-xl mb-5 mb-xl-8'>
                <div className='card-header border-0'>
                    <h3 className='card-title fw-bolder text-dark'>General Information</h3>
                </div>

                <div className='card-body pt-0'>
                    <ul>
                        <li className='mb-5'>
                            The Designated Registrant is responsible for submitting the Annual Declaration form for the
                            previous year by January 31st of the following year. The accompanying Self-Assessment Form 1
                            that assesses the previous year must also be completed and kept in the facility to be
                            available upon request. In the event of a Facility Inspection, the Declaration Form and
                            Self-Assessment Form must be submitted to the CVBC office for review by the inspector. In
                            the case of a new facility or addition of new scopes of practice, please fill out the forms
                            as they relate to the anticipated services.
                        </li>
                    </ul>
                </div>
            </div>
        );
    };

    const GenerateMyAssessments = () => {
        return (
            <div className='card card-xl mb-5 mb-xl-8'>
                <div className='card-header border-0'>
                    <h3 className='card-title fw-bolder text-dark'>My Annual Declarations</h3>
                </div>

                <div className='card-body pt-0'>
                    {assessments.length > 0 ? (
                        <>
                            <div className='table-container'>
                                <Table className='assessments-table' striped bordered>
                                    <thead>
                                        <tr>
                                            <th className='date fw-bolder ps-3'>Assessment Year</th>
                                            <th className='date fw-bolder'>Started Date</th>
                                            <th className='date fw-bolder'>Required Date</th>
                                            <th className='fw-bolder'>Completed</th>
                                            <th className='fw-bolder'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {assessments.map((assessment, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td className='date ps-3'>
                                                        {assessment._pfn_assessmentyear_value_Formatted}
                                                    </td>
                                                    <td className='date'>{assessment.pfn_startdate}</td>
                                                    <td className='date'>
                                                        {assessment.pfn_AssessmentYear?.pfn_expirydate?.split('T')[0]}
                                                    </td>
                                                    <td className='completed'>
                                                        {assessment.pfn_formcompleted ? 'Yes' : ' No'}
                                                    </td>
                                                    <td className='action'>
                                                        {assessment.pfn_AssessmentYear?.pfn_availableto &&
                                                        new Date() <
                                                            new Date(assessment.pfn_AssessmentYear?.pfn_availableto) &&
                                                        assessment.pfn_formcompleted === false ? (
                                                            <Link
                                                                to={`/facilities/assessment/${facilityId}/${assessment.pfn_facilityselfassessmentid}`}
                                                            >
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-sm btn-primary my-1'
                                                                >
                                                                    Continue
                                                                </button>
                                                            </Link>
                                                        ) : (
                                                            <div>---</div>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                            {checkIfNeedToStartNewAssessment() && (
                                <div className='mt-10'>
                                    <button
                                        type='button'
                                        className='btn btn-primary my-1'
                                        onClick={createNewAssessment}
                                        disabled={loadingCreateAssessment}
                                    >
                                        {!loadingCreateAssessment && (
                                            <span className='indicator-label'>
                                                Start {currentAssessmentYear?.pfn_name} Annual Declaration
                                            </span>
                                        )}
                                        {loadingCreateAssessment && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                    {errMsgCreateAssessment && <div>{errMsgCreateAssessment}</div>}
                                </div>
                            )}
                        </>
                    ) : (
                        <div>
                            <div>
                                You have not yet started an Annual Declaration. Please press the button below to begin
                                the process. You can save and resume the Annual Declaration here at any point if you do
                                not finish the entire Annual Declaration.
                            </div>
                            <div className='mt-10'>
                                <button
                                    type='button'
                                    className='btn btn-primary my-1'
                                    onClick={createNewAssessment}
                                    disabled={loadingCreateAssessment}
                                >
                                    {!loadingCreateAssessment && (
                                        <span className='indicator-label'>
                                            Start {currentAssessmentYear?.pfn_name} Annual Declaration
                                        </span>
                                    )}
                                    {loadingCreateAssessment && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                                {errMsgCreateAssessment && <div>{errMsgCreateAssessment}</div>}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            {generateGeneralInfo()}
            {loadingAssessments || loadingAssessmentYear ? (
                <p>Retreiviing Annual Declarations, please wait...</p>
            ) : errMsgAssessments ? (
                <p>{errMsgAssessments}</p>
            ) : errMsgAssessmentYear ? (
                <p>{errMsgAssessmentYear}</p>
            ) : (
                <>{GenerateMyAssessments()}</>
            )}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- WRAPPER -------------------------------- */

const FacilityAssessmentsWrapper: FC = () => {
    const { facilityName } = useParams();
    const title = `${facilityName}: Declarations`;

    return (
        <>
            <PageTitle breadcrumbs={[]}>{title}</PageTitle>
            <FacilityAssessmentsPage />
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { FacilityAssessmentsWrapper };

/* -------------------------------------------------------------------------- */
