/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file index/tsx
 * @author Jesse Zonneveld
 * @description Index
 */

/* --------------------------------- IMPORTS -------------------------------- */

import ReactDOM from 'react-dom';
import { Chart, registerables } from 'chart.js';

// Apps
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n';
import './_metronic/assets/sass/style.scss';
import './_metronic/assets/sass/style.react.scss';
import '../src/_metronic/assets/sass/style.scss';
import { AppRoutes } from './app/routing/AppRoutes';
import { apiInterceptors } from './app/api/config';
import { persistor, store } from './app/redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

Chart.register(...registerables);

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <MetronicI18nProvider>
                <AppRoutes />
            </MetronicI18nProvider>
        </PersistGate>
    </Provider>,
    document.getElementById('root'),
);

/* ------------------------------- AXIOS SETUP ------------------------------ */

apiInterceptors(store);

/* -------------------------------------------------------------------------- */
