/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file metronic/partials/widgets/lists/ListWidgetCustom1.tsx
 * @author Jesse Zonneveld
 * @description Custom list widget
 */

/* --------------------------------- IMPORTS -------------------------------- */

import clsx from 'clsx';
import React from 'react';
import { useAppSelector } from '../../../../app/redux/hooks';
import { KTSVG } from '../../../helpers';
import { DropdownCustom3 } from '../../content/dropdown/DropdownCustom3';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

type Props = {
    className: string;
};

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const ListsWidgetCustom1: React.FC<Props> = ({ className }) => {
    const user = useAppSelector((state) => state.user);

    const expiryDateIsExpired = () => {
        if (user.statusHistory.expiryDate.value && user.statusHistory.expiryDate.converted !== '---') {
            return new Date(user.statusHistory.expiryDate.value) < new Date();
        } else {
            return false;
        }
    };

    return (
        <div className={`card mb-5 ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0'>
                <h3 className='card-title fw-bolder text-dark'>Account Overview</h3>
                <div className='card-toolbar'>
                    {/* begin::Menu */}
                    <button
                        type='button'
                        className='btn btn-sm btn-icon btn-color-primary btn-active-light'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                    >
                        <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                    </button>
                    <DropdownCustom3 />
                    {/* end::Menu */}
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body pt-0'>
                {/* begin::Item */}
                <div className='d-flex align-items-center bg-light rounded p-5 mb-5'>
                    {/* begin::Color Bar */}
                    <span className='bullet bullet-vertical h-40px bg-primary me-10'></span>

                    {/* end::Color Bar */}
                    {/* begin::Title */}
                    <div className='flex-grow-1 me-2'>
                        <div className='fw-bolder text-gray-800 fs-6'>Registrant Type</div>
                        <span className='text-muted fw-bold d-block'>
                            {user.statusHistory.type.formatted} - {user.statusHistory.status.formatted}
                        </span>
                    </div>
                    {/* end::Title */}
                    {/* begin::Lable */}
                    {/* <span className='fw-bolder text-warning py-1'>+28%</span> */}
                    {/* end::Lable */}
                </div>
                {/* end::Item */}
                {/* begin::Item */}
                <div className='d-flex align-items-center bg-light rounded p-5 mb-5'>
                    {/* begin::Color Bar */}
                    <span className='bullet bullet-vertical h-40px bg-primary me-10'></span>

                    {/* end::Color Bar */}
                    {/* begin::Title */}
                    <div className='flex-grow-1 me-2'>
                        <div className='fw-bolder text-gray-800 fs-6'>Registration Category</div>
                        <span className='text-muted fw-bold d-block'>
                            {user.statusHistory.category.formatted} - {user.statusHistory.subcategory.formatted}
                        </span>
                    </div>
                    {/* end::Title */}
                    {/* begin::Lable */}
                    {/* <span className='fw-bolder text-warning py-1'>+28%</span> */}
                    {/* end::Lable */}
                </div>
                {/* end::Item */}
                {/* begin::Item */}
                <div className='d-flex align-items-center bg-light rounded p-5 mb-5'>
                    {/* begin::Color Bar */}
                    <span className='bullet bullet-vertical h-40px bg-primary me-10'></span>

                    {/* end::Color Bar */}
                    {/* begin::Title */}
                    <div className='flex-grow-1 me-2'>
                        <div className='fw-bolder text-gray-800 fs-6'>Registration Number</div>
                        <span className='text-muted fw-bold d-block'>{user.registrationNumber || '---'}</span>
                    </div>
                    {/* end::Title */}
                    {/* begin::Lable */}
                    {/* <span className='fw-bolder text-warning py-1'>+28%</span> */}
                    {/* end::Lable */}
                </div>
                {/* end::Item */}
                {/* begin::Item */}
                <div
                    className={clsx('d-flex align-items-center bg-light-success rounded p-5 mb-5', {
                        'bg-light-warning': user.statusHistory.effectiveDate.converted === '---',
                    })}
                >
                    {/* begin::Color Bar */}
                    <span
                        className={clsx('bullet bullet-vertical h-40px bg-success me-10', {
                            'bg-warning': user.statusHistory.effectiveDate.converted === '---',
                        })}
                    ></span>

                    {/* end::Color Bar */}
                    {/* begin::Title */}
                    <div className='flex-grow-1 me-2'>
                        <div className='fw-bolder text-gray-800 fs-6'>Effective Date</div>
                        <span className='text-muted fw-bold d-block'>{user.statusHistory.effectiveDate.converted}</span>
                    </div>
                    {/* end::Title */}
                    {/* begin::Lable */}
                    {/* <span className='fw-bolder text-warning py-1'>+28%</span> */}
                    {/* end::Lable */}
                </div>
                {/* end::Item */}
                {/* begin::Item */}
                <div
                    className={clsx('d-flex align-items-center bg-light-success rounded p-5 mb-5', {
                        'bg-light-danger': expiryDateIsExpired(),
                        'bg-light-warning': user.statusHistory.expiryDate.converted === '---',
                    })}
                >
                    {/* begin::Color Bar */}
                    <span
                        className={clsx('bullet bullet-vertical h-40px bg-success me-10', {
                            'bg-danger': expiryDateIsExpired(),
                            'bg-warning': user.statusHistory.expiryDate.converted === '---',
                        })}
                    ></span>

                    {/* end::Color Bar */}
                    {/* begin::Title */}
                    <div className='flex-grow-1 me-2'>
                        <div className='fw-bolder text-gray-800 fs-6'>Expiry Date</div>
                        <span className='text-muted fw-bold d-block'>{user.statusHistory.expiryDate.converted}</span>
                    </div>
                    {/* end::Title */}
                    {/* begin::Lable */}
                    {/* <span className='fw-bolder text-warning py-1'>+28%</span> */}
                    {/* end::Lable */}
                </div>
                {/* end::Item */}
            </div>
            {/* end::Body */}
        </div>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { ListsWidgetCustom1 };

/* -------------------------------------------------------------------------- */
