/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { DropdownCustom1 } from '../../content/dropdown/DropdownCustom1';
import { KTSVG } from '../../../helpers';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../../app/redux/hooks';

type Props = {
    className: string;
    color: string;
};

const MixedWidgetCustom1: React.FC<Props> = ({ className, color }) => {
    const user = useAppSelector((state) => state.user);

    return (
        <div className={`card ${className}`}>
            {/* begin::Body */}
            <div className='card-body p-0'>
                {/* begin::Header */}
                <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-${color}`}>
                    {/* begin::Heading */}
                    <div className='d-flex flex-stack'>
                        <h3 className='m-0 text-white fw-bolder fs-3'>Continuing Education</h3>
                        <div className='ms-1'>
                            {/* begin::Menu */}
                            <button
                                type='button'
                                className={`btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color-${color} border-0 me-n3`}
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                                data-kt-menu-flip='top-end'
                            >
                                <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                            </button>
                            <DropdownCustom1 />
                            {/* end::Menu */}
                        </div>
                    </div>
                    {/* end::Heading */}
                    {/* begin::Balance */}
                    {user.transcripts && user.transcripts.length > 0 ? (
                        <div className='d-flex text-center flex-column text-white pt-8'>
                            <span className='fw-bold fs-7'>
                                Submitted Credits for Current Cycle ({user.transcripts[0].name})
                            </span>
                            <span className='fw-bolder fs-2x pt-1'>{user.transcripts[0].totalCredits.formatted}</span>
                        </div>
                    ) : (
                        <div className='d-flex text-center flex-column text-white pt-8'>
                            <span className='fw-bold fs-7'>Submitted Credits for Current Cycle</span>
                            <span className='fw-bolder fs-2x pt-1'>0.00</span>
                        </div>
                    )}
                    {/* end::Balance */}
                </div>
                {/* end::Header */}
                {/* begin::Items */}
                <div
                    className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-white'
                    style={{ marginTop: '-100px' }}
                >
                    {/* begin::Item */}
                    <Link to='/transcripts' className='d-flex align-items-center py-3 dashboard-link'>
                        {/* begin::Symbol */}
                        <div className='symbol symbol-45px w-40px me-5'>
                            <span className='symbol-label bg-lighten'>
                                <KTSVG
                                    path='/media/icons/duotune/general/gen005.svg'
                                    className='svg-icon-1 icon-colorize'
                                />
                            </span>
                        </div>
                        {/* end::Symbol */}
                        {/* begin::Description */}
                        <div className='d-flex align-items-center flex-wrap w-100'>
                            {/* begin::Title */}
                            <div className='mb-1 pe-3 flex-grow-1'>
                                <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>CE Transcripts</span>
                                <div className='text-gray-400 fw-bold fs-7'>Report CE, View & Print</div>
                            </div>
                            {/* end::Title */}
                            {/* begin::Label */}
                            <div className='d-flex align-items-center arrow'>
                                {/* <div className='fw-bolder fs-5 text-gray-800 pe-1'>$2,5b</div> */}
                                <KTSVG
                                    path={'/media/icons/duotune/arrows/arr080.svg'}
                                    className={'svg-icon-1 rotate-180 pe-2 icon-colorize'}
                                />
                            </div>
                            {/* end::Label */}
                        </div>
                        {/* end::Description */}
                    </Link>
                    {/* end::Item */}
                    {/* begin::Item */}
                    <a
                        href='https://classroom.cvbc.ca/'
                        target='_blank'
                        rel='noreferrer'
                        className='d-flex align-items-center py-3 dashboard-link'
                    >
                        {/* begin::Symbol */}
                        <div className='symbol symbol-45px w-40px me-5'>
                            <span className='symbol-label bg-lighten'>
                                <KTSVG
                                    path='/media/icons/duotune/general/gen017.svg'
                                    className='svg-icon-1 icon-colorize'
                                />
                            </span>
                        </div>
                        {/* end::Symbol */}
                        {/* begin::Description */}
                        <div className='d-flex align-items-center flex-wrap w-100'>
                            {/* begin::Title */}
                            <div className='mb-1 pe-3 flex-grow-1'>
                                <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>Learning Hub</span>
                                <div className='text-gray-400 fw-bold fs-7'>Online Classroom</div>
                            </div>
                            {/* end::Title */}
                            {/* begin::Label */}
                            <div className='d-flex align-items-center arrow'>
                                {/* <div className='fw-bolder fs-5 text-gray-800 pe-1'>$1,7b</div> */}
                                <KTSVG
                                    path={'/media/icons/duotune/arrows/arr080.svg'}
                                    className={'svg-icon-1 rotate-180 pe-2 icon-colorize'}
                                />
                            </div>
                            {/* end::Label */}
                        </div>
                        {/* end::Description */}
                    </a>
                    {/* end::Item */}

                    {/* begin::Item */}
                    <a
                        href='https://www.cvbc.ca/registration/continuing-education/#approved'
                        target='_blank'
                        rel='noreferrer'
                        className='d-flex align-items-center py-3 dashboard-link'
                    >
                        {/* begin::Symbol */}
                        <div className='symbol symbol-45px w-40px me-5'>
                            <span className='symbol-label bg-lighten'>
                                <KTSVG
                                    path='/media/icons/duotune/files/fil016.svg'
                                    className='svg-icon-1 icon-colorize'
                                />
                            </span>
                        </div>
                        {/* end::Symbol */}
                        {/* begin::Description */}
                        <div className='d-flex align-items-center flex-wrap w-100'>
                            {/* begin::Title */}
                            <div className='mb-1 pe-3 flex-grow-1'>
                                <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                                    CVBC-Approved CE
                                </span>
                                <div className='text-gray-400 fw-bold fs-7'>Courses & Sessions</div>
                            </div>
                            {/* end::Title */}
                            {/* begin::Label */}
                            <div className='d-flex align-items-center arrow'>
                                {/* <div className='fw-bolder fs-5 text-gray-800 pe-1'>$1,7b</div> */}
                                <KTSVG
                                    path={'/media/icons/duotune/arrows/arr080.svg'}
                                    className={'svg-icon-1 rotate-180 pe-2 icon-colorize'}
                                />
                            </div>
                            {/* end::Label */}
                        </div>
                        {/* end::Description */}
                    </a>
                    {/* end::Item */}
                    {/* begin::Item */}
                    <a
                        href='https://www.cvbc.ca/registration/continuing-education/'
                        target='_blank'
                        rel='noreferrer'
                        className='d-flex align-items-center py-3 dashboard-link'
                    >
                        {/* begin::Symbol */}
                        <div className='symbol symbol-45px w-40px me-5'>
                            <span className='symbol-label bg-lighten'>
                                <KTSVG
                                    path='/media/icons/duotune/general/gen046.svg'
                                    className='svg-icon-1 icon-colorize'
                                />
                            </span>
                        </div>
                        {/* end::Symbol */}
                        {/* begin::Description */}
                        <div className='d-flex align-items-center flex-wrap w-100'>
                            {/* begin::Title */}
                            <div className='mb-1 pe-3 flex-grow-1'>
                                <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>General Info</span>
                                <div className='text-gray-400 fw-bold fs-7'>About CE</div>
                            </div>
                            {/* end::Title */}
                            {/* begin::Label */}
                            <div className='d-flex align-items-center arrow'>
                                {/* <div className='fw-bolder fs-5 text-gray-800 pe-1'>$1,7b</div> */}
                                <KTSVG
                                    path={'/media/icons/duotune/arrows/arr080.svg'}
                                    className={'svg-icon-1 rotate-180 pe-2 icon-colorize'}
                                />
                            </div>
                            {/* end::Label */}
                        </div>
                        {/* end::Description */}
                    </a>
                    {/* end::Item */}

                    {/* begin::Item */}
                    {/* <Link to='/event-registration' className='d-flex align-items-center py-3 dashboard-link'>
                        <div className='symbol symbol-45px w-40px me-5'>
                            <span className='symbol-label bg-lighten'>
                                <KTSVG
                                    path='/media/icons/duotune/files/fil002.svg'
                                    className='svg-icon-1 icon-colorize'
                                />
                            </span>
                        </div>
                        <div className='d-flex align-items-center flex-wrap w-100'>
                            <div className='mb-1 pe-3 flex-grow-1'>
                                <span className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                                    Event Registration
                                </span>
                                <div className='text-gray-400 fw-bold fs-7'>Online & In-person</div>
                            </div>
                            <div className='d-flex align-items-center'>
                                <KTSVG
                                    path={'/media/icons/duotune/arrows/arr080.svg'}
                                    className={'svg-icon-1 rotate-180 pe-2 icon-colorize'}
                                />
                            </div>
                        </div>
                    </Link> */}
                    {/* end::Item */}
                </div>
                {/* end::Items */}
            </div>
            {/* end::Body */}
        </div>
    );
};

export { MixedWidgetCustom1 };
