/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/api/receiptApi.ts
 * @author Jesse Zonneveld
 * @description Receipt api
 */

/* --------------------------------- LINTING -------------------------------- */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

/* -------------------------------------------------------------------------- */

/* --------------------------------- IMPORTS -------------------------------- */

import { ApiResponse, Receipt } from '../types';
import { apiClient } from './config';

/* -------------------------------------------------------------------------- */

/* ----------------------------------- API ---------------------------------- */

const getAllReceipts = async (): Promise<ApiResponse<Receipt[]>> => {
    try {
        const { data } = await apiClient.get<ApiResponse<Receipt[]>>('/receipts/all');
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getFacilityReceipts = async (pfn_accountid: string): Promise<ApiResponse<Receipt[]>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<Receipt[]>>('/receipts/facility', { pfn_accountid });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getReceipt = async (pfn_paymentid: string): Promise<ApiResponse<Receipt>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<Receipt>>('/receipts/receipt', { pfn_paymentid });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getReceiptByInvoiceId = async (pfn_invoiceid: string): Promise<ApiResponse<Receipt>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<Receipt>>('/receipts/get-receipt-by-invoice', {
            pfn_invoiceid,
        });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

const getReceiptsByAppId = async (pfn_applicationid: string): Promise<ApiResponse<Receipt[]>> => {
    try {
        const { data } = await apiClient.post<ApiResponse<Receipt[]>>('/receipts/application', { pfn_applicationid });
        return data;
    } catch (err: any) {
        if (err.response?.data) {
            return err.response?.data;
        } else {
            return err;
        }
    }
};

/* -------------------------------------------------------------------------- */

/* ------------------------------- API OBJECT ------------------------------- */

const receiptApi = { getAllReceipts, getFacilityReceipts, getReceipt, getReceiptByInvoiceId, getReceiptsByAppId };

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export default receiptApi;

/* -------------------------------------------------------------------------- */
