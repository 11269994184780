/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/pages/custom/facilities/facility-assessments/FacilityAssessment.tsx
 * @author Jesse Zonneveld
 * @description Facility assessment page
 */

/* --------------------------------- IMPORTS -------------------------------- */

import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { PageTitle } from '../../../../../_metronic/layout/core';
import { AssessmentWizard } from './formController/AssessmentWizard';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPONENT ------------------------------- */

const FacilityAssessmentPage: FC = () => {
    const { pfn_facilityid, pfn_facilityassessmentid } = useParams();

    return (
        <div>
            {pfn_facilityassessmentid && pfn_facilityid ? (
                <AssessmentWizard initialStep={0} assessmentId={pfn_facilityassessmentid} facilityId={pfn_facilityid} />
            ) : (
                <div>An error occured, please try again later</div>
            )}
        </div>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- WRAPPER -------------------------------- */

const FacilityAssessmentWrapper: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Annual Declaration</PageTitle>
            <FacilityAssessmentPage />
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { FacilityAssessmentWrapper };

/* -------------------------------------------------------------------------- */
