/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/redux/slices/UserSlice.ts
 * @author Jesse Zonneveld
 * @description User slice
 */

/* --------------------------------- IMPORTS -------------------------------- */

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import authApi from '../../api/authApi';
import facilityApi from '../../api/facilityApi';
import transcriptApi from '../../api/transcriptApi';
import userApi from '../../api/userApi';
import { closeAllModals, convertIsoDateToString, convertPhoneNumberToHyphens } from '../../helpers';
import {
    AddCourseFormDetails,
    ApiResponse,
    Course,
    UpdateCourseFormConvertedDetails,
    UpdateEmailFormValues,
    UpdateFacilityFormDetails,
    UpdateAddressFormValues,
    UpdatePersonalInfoFormValues,
    UpdatePhoneFormValues,
    Facility,
    UploadDocFormConvertedDetails,
    UploadedDoc,
    User,
    Application,
} from '../../types';
import { RootState } from '../store';
import applicationApi from '../../api/applicationApi';

/* -------------------------------------------------------------------------- */

/* ---------------------------------- TYPES --------------------------------- */

export interface StatusHistoryState {
    effectiveDate: {
        value: string | null;
        formatted: string | undefined;
        converted: string | null;
    };
    expiryDate: {
        value: string | null;
        formatted: string | undefined;
        converted: string | null;
    };
    status: {
        value: string | null;
        formatted: string | undefined;
    };
    type: {
        value: string | null;
        formatted: string | undefined;
    };
    category: {
        value: string | null;
        formatted: string | undefined;
    };
    subcategory: {
        value: string | null;
        formatted: string | undefined;
    };
}

export interface FacilityState {
    id: string | null;
    name: string | null;
    designatedVetId: string | null;
    telephone: string | null;
    address: {
        line1: string | null;
        line2: string | null;
        city: string | null;
        postalCode: string | null;
    };
    permission: {
        value: number | null;
        formatted: string | undefined;
    };
    email: {
        main: string | null;
        additional: string | null;
    };
    fax: string | null;
    website: string | null;
}

export interface TranscriptState {
    id: string | null;
    totalCredits: {
        value: number | null;
        formatted: string | undefined;
    };
    creditsRequired: {
        value: number | null;
        formatted: string | undefined;
    };
    status: {
        value: number | null;
        formatted: string | undefined;
    };
    name: string | null;
    isAudited: boolean | null;
    auditReason: {
        value: number | null;
        formatted: string | undefined;
    };
    creditsAuditted: {
        value: number | null;
        formatted: string | undefined;
    };
    renewalYear: string | undefined;
    cycle: {
        start: {
            value: string | null;
            formatted: string | undefined;
        };
        end: {
            value: string | null;
            formatted: string | undefined;
        };
    };
    courses: CourseState[];
}

export interface CourseState {
    id: string | null;
    transcriptId: string | null;
    name: string | null;
    creditsSubmitted: {
        value: number | null;
        formatted: string | undefined;
    };
    creditsApproved: {
        value: number | null;
        formatted: string | undefined;
    };
    creditsAuditted: {
        value: number | null;
        formatted: string | undefined;
    };
    startDate: {
        value: string | null;
        formatted: string | undefined;
        converted: string | null;
    };
    endDate: {
        value: string | null;
        formatted: string | undefined;
        converted: string | null;
    };
    status: {
        value: number | null;
        formatted: string | undefined;
    };
    sponsor: string | null;
    speaker: string | null;
    location: string | null;
    uploadedDocs: UploadedDocState[];
}

export interface UploadedDocState {
    id: string | null;
    fileName: string | null;
}

export interface AddressState {
    line1: string | null;
    line2: string | null;
    city: string | null;
    province: string | null;
    country: string | null;
    postalCode: string | null;
}

export interface PhoneState {
    mobile: string | null;
    home: string | null;
    business: string | null;
}

export interface EmailState {
    main: string | null;
    personal: string | null;
    work: string | null;
}

export interface PersonalState {
    salutation: {
        value: number | null;
        formatted: string | undefined;
    };
    gender: {
        value: number | null;
        formatted: string | undefined;
    };
    preferredIdentifier: {
        value: number | null;
        formatted: string | undefined;
    };
    preferredName: string | null;
}

export interface UserState {
    id: string | null;
    registrationNumber: string | null;
    username: string | null;
    firstname: string | null;
    middlename: string | null;
    lastname: string | null;
    immigrationStatus: string | undefined;
    permission: {
        value: number | null;
        formatted: string | undefined;
    };
    personal: PersonalState;
    email: EmailState;
    phone: PhoneState;
    address: {
        mailing: string | undefined;
        home: AddressState;
        work: AddressState;
    };
    statusHistory: StatusHistoryState;
    facilities: {
        isLocum: boolean | null;
        primary: FacilityState;
        other: FacilityState[];
    };
    transcripts: TranscriptState[];
    renewalYear: string | undefined;
    anyActiveInvoices: boolean | undefined;
}

/* -------------------------------------------------------------------------- */

/* ---------------------------- HELPER FUNCTIONS ---------------------------- */

const compareName = (a: FacilityState, b: FacilityState) => {
    if (a.name && b.name && a.name < b.name) {
        return -1;
    }
    if (a.name && b.name && a.name > b.name) {
        return 1;
    }
    return 0;
};

/* -------------------------------------------------------------------------- */

/* ------------------------------ INITIAL STATE ----------------------------- */

const initialState: UserState = {
    id: '',
    registrationNumber: '',
    username: '',
    firstname: '',
    middlename: '',
    lastname: '',
    immigrationStatus: '',
    permission: {
        value: null,
        formatted: '',
    },
    personal: {
        salutation: {
            value: null,
            formatted: '',
        },
        gender: {
            value: null,
            formatted: '',
        },
        preferredIdentifier: {
            value: null,
            formatted: '',
        },
        preferredName: '',
    },
    email: {
        main: '',
        personal: '',
        work: '',
    },
    phone: {
        mobile: '',
        home: '',
        business: '',
    },
    address: {
        mailing: '',
        home: {
            line1: '',
            line2: '',
            city: '',
            province: '',
            country: '',
            postalCode: '',
        },
        work: {
            line1: '',
            line2: '',
            city: '',
            province: '',
            country: '',
            postalCode: '',
        },
    },
    statusHistory: {
        effectiveDate: {
            value: '',
            formatted: '',
            converted: '',
        },
        expiryDate: {
            value: '',
            formatted: '',
            converted: '',
        },
        status: {
            value: '',
            formatted: '',
        },
        type: {
            value: '',
            formatted: '',
        },
        category: {
            value: '',
            formatted: '',
        },
        subcategory: {
            value: '',
            formatted: '',
        },
    },
    facilities: {
        isLocum: null,
        primary: {
            id: '',
            name: '',
            designatedVetId: '',
            telephone: '',
            address: {
                line1: '',
                line2: '',
                city: '',
                postalCode: '',
            },
            permission: {
                value: null,
                formatted: '',
            },
            email: {
                main: '',
                additional: '',
            },
            fax: '',
            website: '',
        },
        other: [],
    },
    transcripts: [],
    renewalYear: '',
    anyActiveInvoices: undefined,
};

/* -------------------------------------------------------------------------- */

/* ------------------------------ ASYNC ACTIONS ----------------------------- */

const loginUser = createAsyncThunk(
    'user/loginUser',
    async ({ pfn_username, pfn_password }: { pfn_username: string; pfn_password: string }) => {
        const response = await authApi.loginUser(pfn_username, pfn_password);
        return response;
    },
);

const logoutUser = createAsyncThunk('user/logoutUser', async () => {
    const response = await authApi.logoutUser();
    return response;
});

const logoutUserAll = createAsyncThunk('user/logoutUserAll', async () => {
    const response = await authApi.logoutUserAll();
    return response;
});

const refreshAccessToken = createAsyncThunk('user/refreshAccessToken', async () => {
    const response = await authApi.refreshAccessToken();
    return response;
});

const updatePhone = createAsyncThunk('user/updatePhone', async (details: UpdatePhoneFormValues) => {
    const response = await userApi.updatePhone(details);
    return response;
});

const updateAddress = createAsyncThunk('user/updateAddress', async (details: UpdateAddressFormValues) => {
    const response = await userApi.updateAddress(details);
    return response;
});

const updatePersonalInfo = createAsyncThunk(
    'user/updatePersonalInfo',
    async (details: UpdatePersonalInfoFormValues) => {
        const response = await userApi.updatePersonalInfo(details);
        return response;
    },
);

const updateMailingAddress = createAsyncThunk('user/updateMailingAddress', async (details: string) => {
    const response = await userApi.updateMailingAddress(details);
    return response;
});

const updatePermission = createAsyncThunk('user/updatePermission', async (details: string) => {
    const response = await userApi.updatePermission(details);
    return response;
});

const changeEmail = createAsyncThunk('user/changeEmail', async (details: UpdateEmailFormValues) => {
    const response = await userApi.changeEmail(details);
    return response;
});

// Example of asyncThunk with getting state and types
// const changeMainEmail = createAsyncThunk<any, { mainEmail: 'work' | 'personal'; email: string }, { state: UserState }>(
//     'user/changeMainEmail',
//     async ({ mainEmail, email }, { getState }) => {
//         const state = getState();
//         const response = await userApi.changeMainEmail(mainEmail, email);
//         return response;
//     },
// );

const changeMainEmail = createAsyncThunk('user/changeMainEmail', async (mainEmail: 'work' | 'personal') => {
    const response = await userApi.changeMainEmail(mainEmail);
    return response;
});

const deleteEmail = createAsyncThunk('user/deleteEmail', async (emailType: 'work' | 'personal') => {
    const response = await userApi.deleteEmail(emailType);
    return response;
});

const addFacility = createAsyncThunk('user/addFacility', async (accountId: string) => {
    const response = await facilityApi.addFacility(accountId);
    return response;
});

const removeFacility = createAsyncThunk('user/removeFacility', async (accountId: string) => {
    const response = await facilityApi.removeFacility(accountId);
    return response;
});

const setPrimaryFacility = createAsyncThunk('user/setPrimaryFacility', async (accountId: string) => {
    const response = await userApi.updatePrimaryFacility(accountId);
    return response;
});

const updateLocum = createAsyncThunk('user/updateLocum', async (isLocum: boolean) => {
    const response = await userApi.updateLocum(isLocum);
    return response;
});

const updateFacilityDetails = createAsyncThunk(
    'user/updateFacilityDetails',
    async (details: UpdateFacilityFormDetails) => {
        const response = await facilityApi.updateFacilityDetails(details);
        return response;
    },
);

const addCourse = createAsyncThunk(
    'user/addCourse',
    async ({ pfn_cetranscriptid, details }: { pfn_cetranscriptid: string; details: AddCourseFormDetails }) => {
        const response = await transcriptApi.addCourse(pfn_cetranscriptid, details);
        return response;
    },
);

const editCourse = createAsyncThunk(
    'user/editCourse',
    async ({ courseId, details }: { courseId: string; details: UpdateCourseFormConvertedDetails }) => {
        const response = await transcriptApi.editCourse(courseId, details);
        return response;
    },
);

const deleteCourse = createAsyncThunk('user/deleteCourse', async (courseId: string) => {
    const response = await transcriptApi.deleteCourse(courseId);
    return response;
});

const uploadDocuments = createAsyncThunk('user/uploadDocuments', async (details: UploadDocFormConvertedDetails) => {
    const response = await transcriptApi.uploadDocuments(details);
    return response;
});

const deleteDocument = createAsyncThunk(
    'user/deleteDocument',
    async ({ pfn_cecourseid, annotationid }: { pfn_cecourseid: string; annotationid: string }) => {
        const response = await transcriptApi.deleteDocument(pfn_cecourseid, annotationid);
        return response;
    },
);

const createRenewalApp = createAsyncThunk(
    'user/createRenewalApp',
    async ({ pfn_categoryid, pfn_subcategoryid }: { pfn_categoryid: string; pfn_subcategoryid: string }) => {
        const response = await applicationApi.createNewRenewalApp({
            pfn_categoryid,
            pfn_subcategoryid,
        });
        return response;
    },
);

const refreshUser = createAsyncThunk('user/refreshUser', async () => {
    const response = await userApi.getUserInfo();
    return response;
});

/* -------------------------------------------------------------------------- */

/* ---------------------------------- SLICE --------------------------------- */

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUsername: (state, action: PayloadAction<string>) => {
            state.username = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loginUser.fulfilled, (state, action: PayloadAction<ApiResponse<User>>) => {
            if (action.payload.success && action.payload.data) {
                state.id = action.payload.data.contactid;
                state.registrationNumber = action.payload.data.pfn_registrationnumber
                    ? action.payload.data.pfn_registrationnumber
                    : '---';
                state.username = action.payload.data.pfn_username;
                state.firstname = action.payload.data.firstname;
                state.middlename = action.payload.data.middlename;
                state.lastname = action.payload.data.lastname;
                state.immigrationStatus = action.payload.data.pfn_immigrationstatus_Formatted;
                state.permission = {
                    formatted: action.payload.data.pfn_onlinepublication_Formatted,
                    value: action.payload.data.pfn_onlinepublication,
                };
                state.personal = {
                    salutation: {
                        value: action.payload.data.pfn_salutation,
                        formatted: action.payload.data.pfn_salutation_Formatted,
                    },
                    gender: {
                        value: action.payload.data.gendercode,
                        formatted: action.payload.data.gendercode_Formatted,
                    },
                    preferredIdentifier: {
                        value: action.payload.data.pfn_preferredidentifier,
                        formatted: action.payload.data.pfn_preferredidentifier_Formatted,
                    },
                    preferredName: action.payload.data.nickname,
                };
                state.email = {
                    main: action.payload.data.emailaddress1,
                    personal: action.payload.data.emailaddress2,
                    work: action.payload.data.emailaddress3,
                };
                state.phone = {
                    mobile: convertPhoneNumberToHyphens(action.payload.data.mobilephone),
                    home: convertPhoneNumberToHyphens(action.payload.data.telephone2),
                    business: convertPhoneNumberToHyphens(action.payload.data.telephone1),
                };
                state.address = {
                    mailing: action.payload.data.pfn_mailingaddress_Formatted,
                    home: {
                        line1: action.payload.data.address2_line1,
                        line2: action.payload.data.address2_line2,
                        city: action.payload.data.address2_city,
                        province: action.payload.data.address2_stateorprovince,
                        country: action.payload.data.address2_country,
                        postalCode: action.payload.data.address2_postalcode,
                    },
                    work: {
                        line1: action.payload.data.address3_line1,
                        line2: action.payload.data.address3_line2,
                        city: action.payload.data.address3_city,
                        province: action.payload.data.address3_stateorprovince,
                        country: action.payload.data.address3_country,
                        postalCode: action.payload.data.address3_postalcode,
                    },
                };
                state.renewalYear = action.payload.data.renewalYear;
                state.anyActiveInvoices = action.payload.data.anyActiveInvoices;

                if (action.payload.data.pfn_statushistoryid) {
                    state.statusHistory = {
                        effectiveDate: {
                            value: action.payload.data.pfn_statushistoryid.pfn_effectivedate,
                            formatted: '',
                            converted:
                                action.payload.data.pfn_statushistoryid._pfn_statusid_value_Formatted === 'Active' &&
                                action.payload.data.pfn_statushistoryid._pfn_subcategoryid_value_Formatted !==
                                    'Applicant'
                                    ? convertIsoDateToString(action.payload.data.pfn_statushistoryid.pfn_effectivedate)
                                    : '---',
                        },
                        expiryDate: {
                            value: action.payload.data.pfn_statushistoryid.pfn_expirydate,
                            formatted: '',
                            converted:
                                action.payload.data.pfn_statushistoryid._pfn_statusid_value_Formatted === 'Active' &&
                                action.payload.data.pfn_statushistoryid._pfn_subcategoryid_value_Formatted !==
                                    'Applicant'
                                    ? convertIsoDateToString(action.payload.data.pfn_statushistoryid.pfn_expirydate)
                                    : '---',
                        },
                        status: {
                            value: action.payload.data.pfn_statushistoryid._pfn_statusid_value,
                            formatted: action.payload.data.pfn_statushistoryid._pfn_statusid_value_Formatted,
                        },
                        type: {
                            value: action.payload.data.pfn_statushistoryid._pfn_typeid_value,
                            formatted: action.payload.data.pfn_statushistoryid._pfn_typeid_value_Formatted,
                        },
                        category: {
                            value: action.payload.data.pfn_statushistoryid._pfn_categoryid_value,
                            formatted: action.payload.data.pfn_statushistoryid._pfn_categoryid_value_Formatted,
                        },
                        subcategory: {
                            value: action.payload.data.pfn_statushistoryid._pfn_subcategoryid_value,
                            formatted:
                                action.payload.data.pfn_statushistoryid._pfn_statusid_value_Formatted === 'Active'
                                    ? action.payload.data.pfn_statushistoryid._pfn_subcategoryid_value_Formatted
                                    : 'Inactive Member',
                        },
                    };
                }

                if (action.payload.data.pfn_account_contact) {
                    action.payload.data.pfn_account_contact.forEach((facility) => {
                        if (facility.accountid === action.payload.data?._pfn_accountid_value) {
                            state.facilities.primary = {
                                id: facility.accountid,
                                name: facility.name,
                                designatedVetId: facility._pfn_contactid_value,
                                telephone: convertPhoneNumberToHyphens(facility.telephone1),
                                address: {
                                    line1: facility.address2_line1,
                                    line2: facility.address2_line2,
                                    city: facility.address2_city,
                                    postalCode: facility.address2_postalcode,
                                },
                                permission: {
                                    value: facility.pfn_onlinepublication,
                                    formatted: facility.pfn_onlinepublication_Formatted,
                                },
                                email: {
                                    main: facility.emailaddress1,
                                    additional: facility.emailaddress2,
                                },
                                fax: convertPhoneNumberToHyphens(facility.fax),
                                website: facility.websiteurl,
                            };
                        } else {
                            state.facilities.other.push({
                                id: facility.accountid,
                                name: facility.name,
                                designatedVetId: facility._pfn_contactid_value,
                                telephone: convertPhoneNumberToHyphens(facility.telephone1),
                                address: {
                                    line1: facility.address2_line1,
                                    line2: facility.address2_line2,
                                    city: facility.address2_city,
                                    postalCode: facility.address2_postalcode,
                                },
                                permission: {
                                    value: facility.pfn_onlinepublication,
                                    formatted: facility.pfn_onlinepublication_Formatted,
                                },
                                email: {
                                    main: facility.emailaddress1,
                                    additional: facility.emailaddress2,
                                },
                                fax: convertPhoneNumberToHyphens(facility.fax),
                                website: facility.websiteurl,
                            });
                        }
                    });
                }

                if (action.payload.data.transcripts) {
                    action.payload.data.transcripts.forEach((transcript) => {
                        const transcriptToAdd: TranscriptState = {
                            id: transcript.pfn_cetranscriptid,
                            totalCredits: {
                                value: transcript.pfn_totalcredits,
                                formatted: transcript.pfn_totalcredits_Formatted || '0.00',
                            },
                            creditsRequired: {
                                value: transcript.pfn_creditsrequired,
                                formatted: transcript.pfn_creditsrequired_Formatted || '0.00',
                            },
                            status: {
                                value: transcript.statuscode,
                                formatted: transcript.statuscode_Formatted,
                            },
                            name: transcript.pfn_name,
                            isAudited: transcript.pfn_audited,
                            auditReason: {
                                value: transcript.pfn_auditreason,
                                formatted: transcript.pfn_auditreason_Formatted || 'NA',
                            },
                            creditsAuditted: {
                                value: transcript.pfn_totalauditverifiedcredits,
                                formatted: transcript.pfn_totalauditverifiedcredits_Formatted || '0.00',
                            },
                            renewalYear: transcript._pfn_renewalyearid_value_Formatted,
                            cycle: {
                                start: {
                                    value: transcript.pfn_startdate,
                                    formatted: transcript.pfn_startdate_Formatted,
                                },
                                end: {
                                    value: transcript.pfn_enddate,
                                    formatted: transcript.pfn_enddate_Formatted,
                                },
                            },
                            courses: [],
                        };

                        if (transcript.courses) {
                            const courses: CourseState[] = [];

                            transcript.courses.forEach((course) => {
                                const courseToAdd: CourseState = {
                                    id: course.pfn_cecourseid,
                                    transcriptId: course._pfn_cetranscriptid_value,
                                    name: course.pfn_name,
                                    creditsSubmitted: {
                                        value: course.pfn_creditssubmitted,
                                        formatted: course.pfn_creditssubmitted_Formatted,
                                    },
                                    creditsApproved: {
                                        value: course.pfn_creditsapproved,
                                        formatted: course.pfn_creditsapproved_Formatted
                                            ? course.pfn_creditsapproved_Formatted
                                            : '---',
                                    },
                                    creditsAuditted: {
                                        value: course.pfn_auditverifiedcredits,
                                        formatted: course.pfn_auditverifiedcredits_Formatted
                                            ? course.pfn_auditverifiedcredits_Formatted
                                            : '---',
                                    },
                                    startDate: {
                                        value: course.pfn_startdate1,
                                        formatted: course.pfn_startdate1_Formatted,
                                        converted: convertIsoDateToString(course.pfn_startdate1),
                                    },
                                    endDate: {
                                        value: course.pfn_enddate1,
                                        formatted: course.pfn_enddate1_Formatted,
                                        converted: convertIsoDateToString(course.pfn_enddate1),
                                    },
                                    status: {
                                        value: course.statuscode,
                                        formatted: course.statuscode_Formatted,
                                    },
                                    sponsor: course.pfn_sponsor,
                                    speaker: course.pfn_speaker,
                                    location: course.pfn_location,
                                    uploadedDocs: [],
                                };

                                if (course.uploadedDocs) {
                                    const uploadedDocs: UploadedDocState[] = [];

                                    course.uploadedDocs.forEach((doc) => {
                                        const uploadedDocToAdd: UploadedDocState = {
                                            id: doc.annotationid,
                                            fileName: doc.filename,
                                        };

                                        uploadedDocs.push(uploadedDocToAdd);
                                    });

                                    courseToAdd.uploadedDocs = uploadedDocs;
                                }

                                courses.push(courseToAdd);
                            });

                            transcriptToAdd.courses = courses;
                        }

                        state.transcripts.push(transcriptToAdd);
                    });
                }
            }
        });
        builder.addCase(refreshAccessToken.fulfilled, (state, action: PayloadAction<ApiResponse<User>>) => {
            if (action.payload.success && action.payload.data) {
                state.id = action.payload.data.contactid;
                state.registrationNumber = action.payload.data.pfn_registrationnumber
                    ? action.payload.data.pfn_registrationnumber
                    : '---';
                state.username = action.payload.data.pfn_username;
                state.firstname = action.payload.data.firstname;
                state.middlename = action.payload.data.middlename;
                state.lastname = action.payload.data.lastname;
                state.immigrationStatus = action.payload.data.pfn_immigrationstatus_Formatted;
                state.permission = {
                    formatted: action.payload.data.pfn_onlinepublication_Formatted,
                    value: action.payload.data.pfn_onlinepublication,
                };
                state.personal = {
                    salutation: {
                        value: action.payload.data.pfn_salutation,
                        formatted: action.payload.data.pfn_salutation_Formatted,
                    },
                    gender: {
                        value: action.payload.data.gendercode,
                        formatted: action.payload.data.gendercode_Formatted,
                    },
                    preferredIdentifier: {
                        value: action.payload.data.pfn_preferredidentifier,
                        formatted: action.payload.data.pfn_preferredidentifier_Formatted,
                    },
                    preferredName: action.payload.data.nickname,
                };
                state.email = {
                    main: action.payload.data.emailaddress1,
                    personal: action.payload.data.emailaddress2,
                    work: action.payload.data.emailaddress3,
                };
                state.phone = {
                    mobile: convertPhoneNumberToHyphens(action.payload.data.mobilephone),
                    home: convertPhoneNumberToHyphens(action.payload.data.telephone2),
                    business: convertPhoneNumberToHyphens(action.payload.data.telephone1),
                };
                state.address = {
                    mailing: action.payload.data.pfn_mailingaddress_Formatted,
                    home: {
                        line1: action.payload.data.address2_line1,
                        line2: action.payload.data.address2_line2,
                        city: action.payload.data.address2_city,
                        province: action.payload.data.address2_stateorprovince,
                        country: action.payload.data.address2_country,
                        postalCode: action.payload.data.address2_postalcode,
                    },
                    work: {
                        line1: action.payload.data.address3_line1,
                        line2: action.payload.data.address3_line2,
                        city: action.payload.data.address3_city,
                        province: action.payload.data.address3_stateorprovince,
                        country: action.payload.data.address3_country,
                        postalCode: action.payload.data.address3_postalcode,
                    },
                };
                state.renewalYear = action.payload.data.renewalYear;
                state.anyActiveInvoices = action.payload.data.anyActiveInvoices;

                if (action.payload.data.pfn_statushistoryid) {
                    state.statusHistory = {
                        effectiveDate: {
                            value: action.payload.data.pfn_statushistoryid.pfn_effectivedate,
                            formatted: '',
                            converted:
                                action.payload.data.pfn_statushistoryid._pfn_statusid_value_Formatted === 'Active' &&
                                action.payload.data.pfn_statushistoryid._pfn_subcategoryid_value_Formatted !==
                                    'Applicant'
                                    ? convertIsoDateToString(action.payload.data.pfn_statushistoryid.pfn_effectivedate)
                                    : '---',
                        },
                        expiryDate: {
                            value: action.payload.data.pfn_statushistoryid.pfn_expirydate,
                            formatted: '',
                            converted:
                                action.payload.data.pfn_statushistoryid._pfn_statusid_value_Formatted === 'Active' &&
                                action.payload.data.pfn_statushistoryid._pfn_subcategoryid_value_Formatted !==
                                    'Applicant'
                                    ? convertIsoDateToString(action.payload.data.pfn_statushistoryid.pfn_expirydate)
                                    : '---',
                        },
                        status: {
                            value: action.payload.data.pfn_statushistoryid._pfn_statusid_value,
                            formatted: action.payload.data.pfn_statushistoryid._pfn_statusid_value_Formatted,
                        },
                        type: {
                            value: action.payload.data.pfn_statushistoryid._pfn_typeid_value,
                            formatted: action.payload.data.pfn_statushistoryid._pfn_typeid_value_Formatted,
                        },
                        category: {
                            value: action.payload.data.pfn_statushistoryid._pfn_categoryid_value,
                            formatted: action.payload.data.pfn_statushistoryid._pfn_categoryid_value_Formatted,
                        },
                        subcategory: {
                            value: action.payload.data.pfn_statushistoryid._pfn_subcategoryid_value,
                            formatted:
                                action.payload.data.pfn_statushistoryid._pfn_statusid_value_Formatted === 'Active'
                                    ? action.payload.data.pfn_statushistoryid._pfn_subcategoryid_value_Formatted
                                    : 'Inactive Member',
                        },
                    };
                }

                if (action.payload.data.pfn_account_contact) {
                    action.payload.data.pfn_account_contact.forEach((facility) => {
                        if (facility.accountid === action.payload.data?._pfn_accountid_value) {
                            state.facilities.primary = {
                                id: facility.accountid,
                                name: facility.name,
                                designatedVetId: facility._pfn_contactid_value,
                                telephone: convertPhoneNumberToHyphens(facility.telephone1),
                                address: {
                                    line1: facility.address2_line1,
                                    line2: facility.address2_line2,
                                    city: facility.address2_city,
                                    postalCode: facility.address2_postalcode,
                                },
                                permission: {
                                    value: facility.pfn_onlinepublication,
                                    formatted: facility.pfn_onlinepublication_Formatted,
                                },
                                email: {
                                    main: facility.emailaddress1,
                                    additional: facility.emailaddress2,
                                },
                                fax: convertPhoneNumberToHyphens(facility.fax),
                                website: facility.websiteurl,
                            };
                        } else {
                            state.facilities.other.push({
                                id: facility.accountid,
                                name: facility.name,
                                designatedVetId: facility._pfn_contactid_value,
                                telephone: convertPhoneNumberToHyphens(facility.telephone1),
                                address: {
                                    line1: facility.address2_line1,
                                    line2: facility.address2_line2,
                                    city: facility.address2_city,
                                    postalCode: facility.address2_postalcode,
                                },
                                permission: {
                                    value: facility.pfn_onlinepublication,
                                    formatted: facility.pfn_onlinepublication_Formatted,
                                },
                                email: {
                                    main: facility.emailaddress1,
                                    additional: facility.emailaddress2,
                                },
                                fax: convertPhoneNumberToHyphens(facility.fax),
                                website: facility.websiteurl,
                            });
                        }
                    });
                }

                if (action.payload.data.transcripts) {
                    action.payload.data.transcripts.forEach((transcript) => {
                        const transcriptToAdd: TranscriptState = {
                            id: transcript.pfn_cetranscriptid,
                            totalCredits: {
                                value: transcript.pfn_totalcredits,
                                formatted: transcript.pfn_totalcredits_Formatted || '0.00',
                            },
                            creditsRequired: {
                                value: transcript.pfn_creditsrequired,
                                formatted: transcript.pfn_creditsrequired_Formatted || '0.00',
                            },
                            status: {
                                value: transcript.statuscode,
                                formatted: transcript.statuscode_Formatted,
                            },
                            name: transcript.pfn_name,
                            isAudited: transcript.pfn_audited,
                            auditReason: {
                                value: transcript.pfn_auditreason,
                                formatted: transcript.pfn_auditreason_Formatted || 'NA',
                            },
                            creditsAuditted: {
                                value: transcript.pfn_totalauditverifiedcredits,
                                formatted: transcript.pfn_totalauditverifiedcredits_Formatted || '0.00',
                            },
                            renewalYear: transcript._pfn_renewalyearid_value_Formatted,
                            cycle: {
                                start: {
                                    value: transcript.pfn_startdate,
                                    formatted: transcript.pfn_startdate_Formatted,
                                },
                                end: {
                                    value: transcript.pfn_enddate,
                                    formatted: transcript.pfn_enddate_Formatted,
                                },
                            },
                            courses: [],
                        };

                        if (transcript.courses) {
                            const courses: CourseState[] = [];

                            transcript.courses.forEach((course) => {
                                const courseToAdd: CourseState = {
                                    id: course.pfn_cecourseid,
                                    transcriptId: course._pfn_cetranscriptid_value,
                                    name: course.pfn_name,
                                    creditsSubmitted: {
                                        value: course.pfn_creditssubmitted,
                                        formatted: course.pfn_creditssubmitted_Formatted,
                                    },
                                    creditsApproved: {
                                        value: course.pfn_creditsapproved,
                                        formatted: course.pfn_creditsapproved_Formatted
                                            ? course.pfn_creditsapproved_Formatted
                                            : '---',
                                    },
                                    creditsAuditted: {
                                        value: course.pfn_auditverifiedcredits,
                                        formatted: course.pfn_auditverifiedcredits_Formatted
                                            ? course.pfn_auditverifiedcredits_Formatted
                                            : '---',
                                    },
                                    startDate: {
                                        value: course.pfn_startdate1,
                                        formatted: course.pfn_startdate1_Formatted,
                                        converted: convertIsoDateToString(course.pfn_startdate1),
                                    },
                                    endDate: {
                                        value: course.pfn_enddate1,
                                        formatted: course.pfn_enddate1_Formatted,
                                        converted: convertIsoDateToString(course.pfn_enddate1),
                                    },
                                    status: {
                                        value: course.statuscode,
                                        formatted: course.statuscode_Formatted,
                                    },
                                    sponsor: course.pfn_sponsor,
                                    speaker: course.pfn_speaker,
                                    location: course.pfn_location,
                                    uploadedDocs: [],
                                };

                                if (course.uploadedDocs) {
                                    const uploadedDocs: UploadedDocState[] = [];

                                    course.uploadedDocs.forEach((doc) => {
                                        const uploadedDocToAdd: UploadedDocState = {
                                            id: doc.annotationid,
                                            fileName: doc.filename,
                                        };

                                        uploadedDocs.push(uploadedDocToAdd);
                                    });

                                    courseToAdd.uploadedDocs = uploadedDocs;
                                }

                                courses.push(courseToAdd);
                            });

                            transcriptToAdd.courses = courses;
                        }

                        state.transcripts.push(transcriptToAdd);
                    });
                }
            }
        });
        builder.addCase(logoutUser.pending, () => {
            return initialState;
        });
        builder.addCase(logoutUserAll.pending, () => {
            return initialState;
        });
        builder.addCase(updatePhone.fulfilled, (state, action: PayloadAction<ApiResponse<Partial<User>>>) => {
            if (action.payload.success && action.payload.data) {
                state.phone = {
                    mobile: convertPhoneNumberToHyphens(action.payload.data.mobilephone),
                    home: convertPhoneNumberToHyphens(action.payload.data.telephone2),
                    business: convertPhoneNumberToHyphens(action.payload.data.telephone1),
                };
            }
        });
        builder.addCase(updateAddress.fulfilled, (state, action: PayloadAction<ApiResponse<Partial<User>>>) => {
            if (action.payload.success && action.payload.data) {
                state.address.home = {
                    line1: action.payload.data.address2_line1 || null,
                    line2: action.payload.data.address2_line2 || null,
                    city: action.payload.data.address2_city || null,
                    province: action.payload.data.address2_stateorprovince || null,
                    country: action.payload.data.address2_country || null,
                    postalCode: action.payload.data.address2_postalcode || null,
                };
            }
        });
        builder.addCase(updateMailingAddress.fulfilled, (state, action: PayloadAction<ApiResponse<Partial<User>>>) => {
            if (action.payload.success && action.payload.data) {
                state.address.mailing = action.payload.data.pfn_mailingaddress_Formatted;
            }
        });
        builder.addCase(updatePersonalInfo.fulfilled, (state, action: PayloadAction<ApiResponse<Partial<User>>>) => {
            if (action.payload.success && action.payload.data) {
                state.personal = {
                    salutation: {
                        value: action.payload.data.pfn_salutation || null,
                        formatted: action.payload.data.pfn_salutation_Formatted,
                    },
                    gender: {
                        value: action.payload.data.gendercode || null,
                        formatted: action.payload.data.gendercode_Formatted,
                    },
                    preferredIdentifier: {
                        value: action.payload.data.pfn_preferredidentifier || null,
                        formatted: action.payload.data.pfn_preferredidentifier_Formatted,
                    },
                    preferredName: action.payload.data.nickname || null,
                };
            }
        });
        builder.addCase(updatePermission.fulfilled, (state, action: PayloadAction<ApiResponse<Partial<User>>>) => {
            if (action.payload.success && action.payload.data) {
                state.permission = {
                    formatted: action.payload.data.pfn_onlinepublication_Formatted,
                    value: action.payload.data.pfn_onlinepublication || null,
                };
            }
        });
        builder.addCase(changeEmail.fulfilled, (state, action: PayloadAction<ApiResponse<Partial<User>>>) => {
            if (action.payload.success && action.payload.data) {
                state.email = {
                    main: action.payload.data.emailaddress1 || null,
                    personal: action.payload.data.emailaddress2 || null,
                    work: action.payload.data.emailaddress3 || null,
                };
            }
        });
        builder.addCase(changeMainEmail.fulfilled, (state, action: PayloadAction<ApiResponse<Partial<User>>>) => {
            if (action.payload.success && action.payload.data) {
                state.username = action.payload.data.pfn_username || null;
                state.email = {
                    main: action.payload.data.emailaddress1 || null,
                    personal: action.payload.data.emailaddress2 || null,
                    work: action.payload.data.emailaddress3 || null,
                };
            }
        });
        builder.addCase(deleteEmail.fulfilled, (state, action: PayloadAction<ApiResponse<Partial<User>>>) => {
            if (action.payload.success && action.payload.data) {
                state.email = {
                    main: action.payload.data.emailaddress1 || null,
                    personal: action.payload.data.emailaddress2 || null,
                    work: action.payload.data.emailaddress3 || null,
                };
            }
        });
        builder.addCase(addFacility.fulfilled, (state, action: PayloadAction<ApiResponse<Facility>>) => {
            if (action.payload.success && action.payload.data) {
                state.facilities.other.push({
                    id: action.payload.data.accountid,
                    name: action.payload.data.name,
                    designatedVetId: action.payload.data._pfn_contactid_value,
                    telephone: convertPhoneNumberToHyphens(action.payload.data.telephone1),
                    address: {
                        line1: action.payload.data.address2_line1,
                        line2: action.payload.data.address2_line2,
                        city: action.payload.data.address2_city,
                        postalCode: action.payload.data.address2_postalcode,
                    },
                    permission: {
                        value: action.payload.data.pfn_onlinepublication,
                        formatted: action.payload.data.pfn_onlinepublication_Formatted,
                    },
                    email: {
                        main: action.payload.data.emailaddress1,
                        additional: action.payload.data.emailaddress2,
                    },
                    fax: convertPhoneNumberToHyphens(action.payload.data.fax),
                    website: action.payload.data.websiteurl,
                });

                state.facilities.other.sort(compareName);
            }
        });
        builder.addCase(removeFacility.fulfilled, (state, action: PayloadAction<ApiResponse<string>>) => {
            if (action.payload.success) {
                closeAllModals();

                if (state.facilities.primary.id === action.payload.data) {
                    state.facilities.primary = {
                        id: '',
                        name: '',
                        designatedVetId: '',
                        telephone: '',
                        address: {
                            line1: '',
                            line2: '',
                            city: '',
                            postalCode: '',
                        },
                        permission: {
                            value: null,
                            formatted: '',
                        },
                        email: {
                            main: '',
                            additional: '',
                        },
                        fax: '',
                        website: '',
                    };

                    state.address.mailing = 'Home';
                } else {
                    for (let i = 0; i < state.facilities.other.length; i++) {
                        const facility = state.facilities.other[i];

                        if (facility.id === action.payload.data) {
                            state.facilities.other.splice(i, 1);

                            // end for loop
                            i = state.facilities.other.length + 1;
                        }
                    }
                }
            }
        });
        builder.addCase(setPrimaryFacility.fulfilled, (state, action: PayloadAction<ApiResponse<string>>) => {
            if (action.payload.success) {
                if (state.facilities.primary.name) {
                    state.facilities.other.push(state.facilities.primary);
                }

                for (let i = 0; i < state.facilities.other.length; i++) {
                    const facility = state.facilities.other[i];

                    if (facility.id === action.payload.data) {
                        state.facilities.primary = facility;
                        state.phone.business = facility.telephone;
                        state.facilities.other.splice(i, 1);

                        // end for loop
                        i = state.facilities.other.length + 1;
                    }
                }

                state.facilities.other.sort(compareName);
            }
        });
        builder.addCase(updateFacilityDetails.fulfilled, (state, action: PayloadAction<ApiResponse<Facility>>) => {
            if (action.payload.success && action.payload.data) {
                if (state.facilities.primary.id === action.payload.data.accountid) {
                    state.facilities.primary = {
                        id: action.payload.data.accountid,
                        name: action.payload.data.name,
                        designatedVetId: action.payload.data._pfn_contactid_value,
                        telephone: convertPhoneNumberToHyphens(action.payload.data.telephone1),
                        address: {
                            line1: action.payload.data.address2_line1,
                            line2: action.payload.data.address2_line2,
                            city: action.payload.data.address2_city,
                            postalCode: action.payload.data.address2_postalcode,
                        },
                        permission: {
                            value: action.payload.data.pfn_onlinepublication,
                            formatted: action.payload.data.pfn_onlinepublication_Formatted,
                        },
                        email: {
                            main: action.payload.data.emailaddress1,
                            additional: action.payload.data.emailaddress2,
                        },
                        fax: convertPhoneNumberToHyphens(action.payload.data.fax),
                        website: action.payload.data.websiteurl,
                    };
                } else {
                    for (let i = 0; i < state.facilities.other.length; i++) {
                        const facility = state.facilities.other[i];

                        if (facility.id === action.payload.data.accountid) {
                            state.facilities.other[i] = {
                                id: action.payload.data.accountid,
                                name: action.payload.data.name,
                                designatedVetId: action.payload.data._pfn_contactid_value,
                                telephone: convertPhoneNumberToHyphens(action.payload.data.telephone1),
                                address: {
                                    line1: action.payload.data.address2_line1,
                                    line2: action.payload.data.address2_line2,
                                    city: action.payload.data.address2_city,
                                    postalCode: action.payload.data.address2_postalcode,
                                },
                                permission: {
                                    value: action.payload.data.pfn_onlinepublication,
                                    formatted: action.payload.data.pfn_onlinepublication_Formatted,
                                },
                                email: {
                                    main: action.payload.data.emailaddress1,
                                    additional: action.payload.data.emailaddress2,
                                },
                                fax: convertPhoneNumberToHyphens(action.payload.data.fax),
                                website: action.payload.data.websiteurl,
                            };

                            // end for loop
                            i = state.facilities.other.length + 1;
                        }
                    }
                }
            }
        });
        builder.addCase(addCourse.fulfilled, (state, action: PayloadAction<ApiResponse<Course>>) => {
            if (action.payload.success && action.payload.data) {
                let transcriptIndex = -1;

                state.transcripts.some((transcript, i) => {
                    if (transcript.id === action.payload.data?._pfn_cetranscriptid_value) {
                        transcriptIndex = i;

                        return true;
                    } else {
                        return false;
                    }
                });

                if (transcriptIndex !== -1) {
                    state.transcripts[transcriptIndex].courses.push({
                        id: action.payload.data.pfn_cecourseid,
                        transcriptId: action.payload.data._pfn_cetranscriptid_value,
                        name: action.payload.data.pfn_name,
                        creditsSubmitted: {
                            value: action.payload.data.pfn_creditssubmitted,
                            formatted: action.payload.data.pfn_creditssubmitted_Formatted,
                        },
                        creditsApproved: {
                            value: action.payload.data.pfn_creditsapproved,
                            formatted: action.payload.data.pfn_creditsapproved_Formatted
                                ? action.payload.data.pfn_creditsapproved_Formatted
                                : '---',
                        },
                        creditsAuditted: {
                            value: action.payload.data.pfn_auditverifiedcredits,
                            formatted: action.payload.data.pfn_auditverifiedcredits_Formatted
                                ? action.payload.data.pfn_auditverifiedcredits_Formatted
                                : '---',
                        },
                        startDate: {
                            value: action.payload.data.pfn_startdate1,
                            formatted: action.payload.data.pfn_startdate1_Formatted,
                            converted: convertIsoDateToString(action.payload.data.pfn_startdate1),
                        },
                        endDate: {
                            value: action.payload.data.pfn_enddate1,
                            formatted: action.payload.data.pfn_enddate1_Formatted,
                            converted: convertIsoDateToString(action.payload.data.pfn_enddate1),
                        },
                        status: {
                            value: action.payload.data.statuscode,
                            formatted: action.payload.data.statuscode_Formatted,
                        },
                        sponsor: action.payload.data.pfn_sponsor,
                        speaker: action.payload.data.pfn_speaker,
                        location: action.payload.data.pfn_location,
                        uploadedDocs: [],
                    });

                    if (
                        action.payload.data.pfn_creditssubmitted &&
                        state.transcripts[transcriptIndex].totalCredits.value
                    ) {
                        if (
                            state.transcripts[transcriptIndex].totalCredits.value &&
                            action.payload.data.pfn_creditssubmitted
                        ) {
                            state.transcripts[transcriptIndex].totalCredits.value =
                                Math.round(
                                    ((state.transcripts[transcriptIndex].totalCredits.value as number) +
                                        (action.payload.data.pfn_creditssubmitted as number)) *
                                        100,
                                ) / 100;

                            state.transcripts[transcriptIndex].totalCredits.formatted =
                                state.transcripts[transcriptIndex].totalCredits.value?.toFixed(2);
                        }
                    }
                }
            }
        });
        builder.addCase(editCourse.fulfilled, (state, action: PayloadAction<ApiResponse<Course>>) => {
            if (action.payload.success && action.payload.data) {
                let transcriptIndex = -1;
                let courseIndex = -1;

                state.transcripts.some((transcript, i) => {
                    if (transcript.id === action.payload.data?._pfn_cetranscriptid_value) {
                        transcriptIndex = i;
                        return true;
                    } else {
                        return false;
                    }
                });

                if (transcriptIndex !== -1) {
                    state.transcripts[transcriptIndex].courses.some((course, i) => {
                        if (course.id === action.payload.data?.pfn_cecourseid) {
                            courseIndex = i;
                            return true;
                        } else {
                            return false;
                        }
                    });
                }

                if (courseIndex !== -1) {
                    if (
                        action.payload.data.pfn_creditssubmitted &&
                        state.transcripts[transcriptIndex].totalCredits.value &&
                        state.transcripts[transcriptIndex].courses[courseIndex].creditsSubmitted.value
                    ) {
                        const creditsDiff =
                            action.payload.data.pfn_creditssubmitted -
                            (state.transcripts[transcriptIndex].courses[courseIndex].creditsSubmitted.value as number);

                        state.transcripts[transcriptIndex].totalCredits.value =
                            Math.round(
                                ((state.transcripts[transcriptIndex].totalCredits.value as number) + creditsDiff) * 100,
                            ) / 100;

                        state.transcripts[transcriptIndex].totalCredits.formatted =
                            state.transcripts[transcriptIndex].totalCredits.value?.toFixed(2);
                    }

                    let newUploadedDocs: UploadedDocState[] =
                        state.transcripts[transcriptIndex].courses[courseIndex].uploadedDocs;

                    if (action.payload.data.uploadedDocs) {
                        action.payload.data.uploadedDocs.forEach((doc) => {
                            const uploadedDocToAdd: UploadedDocState = {
                                id: doc.annotationid,
                                fileName: doc.filename,
                            };

                            newUploadedDocs.push(uploadedDocToAdd);
                        });
                    }

                    state.transcripts[transcriptIndex].courses[courseIndex] = {
                        id: action.payload.data.pfn_cecourseid,
                        transcriptId: action.payload.data._pfn_cetranscriptid_value,
                        name: action.payload.data.pfn_name,
                        creditsSubmitted: {
                            value: action.payload.data.pfn_creditssubmitted,
                            formatted: action.payload.data.pfn_creditssubmitted_Formatted,
                        },
                        creditsApproved: {
                            value: null,
                            formatted: '---',
                        },
                        creditsAuditted: {
                            value: null,
                            formatted: '---',
                        },
                        startDate: {
                            value: action.payload.data.pfn_startdate1,
                            formatted: action.payload.data.pfn_startdate1_Formatted,
                            converted: convertIsoDateToString(action.payload.data.pfn_startdate1),
                        },
                        endDate: {
                            value: action.payload.data.pfn_enddate1,
                            formatted: action.payload.data.pfn_enddate1_Formatted,
                            converted: convertIsoDateToString(action.payload.data.pfn_enddate1),
                        },
                        status: {
                            value: null,
                            formatted: 'Active',
                        },
                        sponsor: action.payload.data.pfn_sponsor,
                        speaker: action.payload.data.pfn_speaker,
                        location: action.payload.data.pfn_location,
                        // uploadedDocs: state.transcripts[transcriptIndex].courses[courseIndex].uploadedDocs,
                        uploadedDocs: newUploadedDocs,
                    };
                }
            }
        });
        builder.addCase(deleteCourse.fulfilled, (state, action: PayloadAction<ApiResponse<Course>>) => {
            if (action.payload.success && action.payload.data) {
                closeAllModals();

                let transcriptIndex = -1;
                let courseIndex = -1;

                state.transcripts.some((transcript, i) => {
                    if (transcript.id === action.payload.data?._pfn_cetranscriptid_value) {
                        transcriptIndex = i;
                        return true;
                    } else {
                        return false;
                    }
                });

                if (transcriptIndex !== -1) {
                    state.transcripts[transcriptIndex].courses.some((course, i) => {
                        if (course.id === action.payload.data?.pfn_cecourseid) {
                            courseIndex = i;
                            return true;
                        } else {
                            return false;
                        }
                    });
                }

                if (courseIndex !== -1) {
                    state.transcripts[transcriptIndex].courses.splice(courseIndex, 1);
                }

                if (
                    action.payload.data?.pfn_creditssubmitted &&
                    state.transcripts[transcriptIndex].totalCredits.value
                ) {
                    state.transcripts[transcriptIndex].totalCredits.value =
                        Math.round(
                            ((state.transcripts[transcriptIndex].totalCredits.value as number) -
                                action.payload.data?.pfn_creditssubmitted) *
                                100,
                        ) / 100;

                    state.transcripts[transcriptIndex].totalCredits.formatted =
                        state.transcripts[transcriptIndex].totalCredits.value?.toFixed(2);
                }
            }
        });
        builder.addCase(uploadDocuments.fulfilled, (state, action: PayloadAction<ApiResponse<UploadedDoc[]>>) => {
            if (action.payload.success && action.payload.data && action.payload.data.length > 0) {
                closeAllModals();

                let transcriptIndex = -1;
                let courseIndex = -1;

                state.transcripts.some((transcript, i) => {
                    if (
                        transcript.courses?.some((course, k) => {
                            if (course.id === action.payload.data?.[0]._objectid_value) {
                                courseIndex = k;
                                return true;
                            } else {
                                return false;
                            }
                        })
                    ) {
                        transcriptIndex = i;
                        return true;
                    } else {
                        return false;
                    }
                });

                if (transcriptIndex !== -1 && courseIndex !== -1) {
                    action.payload.data.forEach((doc) => {
                        state.transcripts[transcriptIndex].courses[courseIndex].uploadedDocs.push({
                            id: doc.annotationid,
                            fileName: doc.filename,
                        });
                    });
                }
            }
        });
        builder.addCase(
            deleteDocument.fulfilled,
            (state, action: PayloadAction<ApiResponse<{ pfn_cecourseid: string; annotationid: string }>>) => {
                if (action.payload.success && action.payload.data) {
                    let transcriptIndex = -1;
                    let courseIndex = -1;

                    state.transcripts.some((transcript, i) => {
                        if (
                            transcript.courses?.some((course, k) => {
                                if (course.id === action.payload.data?.pfn_cecourseid) {
                                    courseIndex = k;
                                    return true;
                                } else {
                                    return false;
                                }
                            })
                        ) {
                            transcriptIndex = i;
                            return true;
                        } else {
                            return false;
                        }
                    });

                    if (transcriptIndex !== -1 && courseIndex !== -1) {
                        var index = state.transcripts[transcriptIndex].courses[courseIndex].uploadedDocs.findIndex(
                            (d) => d.id === action.payload.data?.annotationid,
                        );
                        if (index !== -1) {
                            state.transcripts[transcriptIndex].courses[courseIndex].uploadedDocs.splice(index, 1);
                        }
                    }
                }
            },
        );
        builder.addCase(updateLocum.fulfilled, (state, action: PayloadAction<ApiResponse<Partial<User>>>) => {
            if (action.payload.success && action.payload.data) {
                state.facilities.isLocum = action.payload.data.pfn_locum || null;
            }
        });
        builder.addCase(
            createRenewalApp.fulfilled,
            (state, action: PayloadAction<ApiResponse<Application | undefined>>) => {
                if (action.payload.success && action.payload.data) {
                    // state.renewalYear = undefined;
                }
            },
        );
        builder.addCase(refreshUser.fulfilled, (state, action: PayloadAction<ApiResponse<User>>) => {
            if (action.payload.success && action.payload.data) {
                state.id = action.payload.data.contactid;
                state.registrationNumber = action.payload.data.pfn_registrationnumber
                    ? action.payload.data.pfn_registrationnumber
                    : '---';
                state.username = action.payload.data.pfn_username;
                state.firstname = action.payload.data.firstname;
                state.middlename = action.payload.data.middlename;
                state.lastname = action.payload.data.lastname;
                state.immigrationStatus = action.payload.data.pfn_immigrationstatus_Formatted;
                state.permission = {
                    formatted: action.payload.data.pfn_onlinepublication_Formatted,
                    value: action.payload.data.pfn_onlinepublication,
                };
                state.personal = {
                    salutation: {
                        value: action.payload.data.pfn_salutation,
                        formatted: action.payload.data.pfn_salutation_Formatted,
                    },
                    gender: {
                        value: action.payload.data.gendercode,
                        formatted: action.payload.data.gendercode_Formatted,
                    },
                    preferredIdentifier: {
                        value: action.payload.data.pfn_preferredidentifier,
                        formatted: action.payload.data.pfn_preferredidentifier_Formatted,
                    },
                    preferredName: action.payload.data.nickname,
                };
                state.email = {
                    main: action.payload.data.emailaddress1,
                    personal: action.payload.data.emailaddress2,
                    work: action.payload.data.emailaddress3,
                };
                state.phone = {
                    mobile: convertPhoneNumberToHyphens(action.payload.data.mobilephone),
                    home: convertPhoneNumberToHyphens(action.payload.data.telephone2),
                    business: convertPhoneNumberToHyphens(action.payload.data.telephone1),
                };
                state.address = {
                    mailing: action.payload.data.pfn_mailingaddress_Formatted,
                    home: {
                        line1: action.payload.data.address2_line1,
                        line2: action.payload.data.address2_line2,
                        city: action.payload.data.address2_city,
                        province: action.payload.data.address2_stateorprovince,
                        country: action.payload.data.address2_country,
                        postalCode: action.payload.data.address2_postalcode,
                    },
                    work: {
                        line1: action.payload.data.address3_line1,
                        line2: action.payload.data.address3_line2,
                        city: action.payload.data.address3_city,
                        province: action.payload.data.address3_stateorprovince,
                        country: action.payload.data.address3_country,
                        postalCode: action.payload.data.address3_postalcode,
                    },
                };
                state.renewalYear = action.payload.data.renewalYear;
                state.anyActiveInvoices = action.payload.data.anyActiveInvoices;

                if (action.payload.data.pfn_statushistoryid) {
                    state.statusHistory = {
                        effectiveDate: {
                            value: action.payload.data.pfn_statushistoryid.pfn_effectivedate,
                            formatted: '',
                            converted:
                                action.payload.data.pfn_statushistoryid._pfn_statusid_value_Formatted === 'Active' &&
                                action.payload.data.pfn_statushistoryid._pfn_subcategoryid_value_Formatted !==
                                    'Applicant'
                                    ? convertIsoDateToString(action.payload.data.pfn_statushistoryid.pfn_effectivedate)
                                    : '---',
                        },
                        expiryDate: {
                            value: action.payload.data.pfn_statushistoryid.pfn_expirydate,
                            formatted: '',
                            converted:
                                action.payload.data.pfn_statushistoryid._pfn_statusid_value_Formatted === 'Active' &&
                                action.payload.data.pfn_statushistoryid._pfn_subcategoryid_value_Formatted !==
                                    'Applicant'
                                    ? convertIsoDateToString(action.payload.data.pfn_statushistoryid.pfn_expirydate)
                                    : '---',
                        },
                        status: {
                            value: action.payload.data.pfn_statushistoryid._pfn_statusid_value,
                            formatted: action.payload.data.pfn_statushistoryid._pfn_statusid_value_Formatted,
                        },
                        type: {
                            value: action.payload.data.pfn_statushistoryid._pfn_typeid_value,
                            formatted: action.payload.data.pfn_statushistoryid._pfn_typeid_value_Formatted,
                        },
                        category: {
                            value: action.payload.data.pfn_statushistoryid._pfn_categoryid_value,
                            formatted: action.payload.data.pfn_statushistoryid._pfn_categoryid_value_Formatted,
                        },
                        subcategory: {
                            value: action.payload.data.pfn_statushistoryid._pfn_subcategoryid_value,
                            formatted: action.payload.data.pfn_statushistoryid._pfn_subcategoryid_value_Formatted,
                        },
                    };
                } else {
                    state.statusHistory = {
                        effectiveDate: {
                            value: '',
                            formatted: '',
                            converted: '',
                        },
                        expiryDate: {
                            value: '',
                            formatted: '',
                            converted: '',
                        },
                        status: {
                            value: '',
                            formatted: '',
                        },
                        type: {
                            value: '',
                            formatted: '',
                        },
                        category: {
                            value: '',
                            formatted: '',
                        },
                        subcategory: {
                            value: '',
                            formatted: '',
                        },
                    };
                }

                state.facilities = {
                    isLocum: null,
                    primary: {
                        id: '',
                        name: '',
                        designatedVetId: '',
                        telephone: '',
                        address: {
                            line1: '',
                            line2: '',
                            city: '',
                            postalCode: '',
                        },
                        permission: {
                            value: null,
                            formatted: '',
                        },
                        email: {
                            main: '',
                            additional: '',
                        },
                        fax: '',
                        website: '',
                    },
                    other: [],
                };

                state.transcripts = [];

                if (action.payload.data.pfn_account_contact) {
                    action.payload.data.pfn_account_contact.forEach((facility) => {
                        if (facility.accountid === action.payload.data?._pfn_accountid_value) {
                            state.facilities.primary = {
                                id: facility.accountid,
                                name: facility.name,
                                designatedVetId: facility._pfn_contactid_value,
                                telephone: convertPhoneNumberToHyphens(facility.telephone1),
                                address: {
                                    line1: facility.address2_line1,
                                    line2: facility.address2_line2,
                                    city: facility.address2_city,
                                    postalCode: facility.address2_postalcode,
                                },
                                permission: {
                                    value: facility.pfn_onlinepublication,
                                    formatted: facility.pfn_onlinepublication_Formatted,
                                },
                                email: {
                                    main: facility.emailaddress1,
                                    additional: facility.emailaddress2,
                                },
                                fax: convertPhoneNumberToHyphens(facility.fax),
                                website: facility.websiteurl,
                            };
                        } else {
                            state.facilities.other.push({
                                id: facility.accountid,
                                name: facility.name,
                                designatedVetId: facility._pfn_contactid_value,
                                telephone: convertPhoneNumberToHyphens(facility.telephone1),
                                address: {
                                    line1: facility.address2_line1,
                                    line2: facility.address2_line2,
                                    city: facility.address2_city,
                                    postalCode: facility.address2_postalcode,
                                },
                                permission: {
                                    value: facility.pfn_onlinepublication,
                                    formatted: facility.pfn_onlinepublication_Formatted,
                                },
                                email: {
                                    main: facility.emailaddress1,
                                    additional: facility.emailaddress2,
                                },
                                fax: convertPhoneNumberToHyphens(facility.fax),
                                website: facility.websiteurl,
                            });
                        }
                    });
                }

                if (action.payload.data.transcripts) {
                    action.payload.data.transcripts.forEach((transcript) => {
                        const transcriptToAdd: TranscriptState = {
                            id: transcript.pfn_cetranscriptid,
                            totalCredits: {
                                value: transcript.pfn_totalcredits,
                                formatted: transcript.pfn_totalcredits_Formatted || '0.00',
                            },
                            creditsRequired: {
                                value: transcript.pfn_creditsrequired,
                                formatted: transcript.pfn_creditsrequired_Formatted || '0.00',
                            },
                            status: {
                                value: transcript.statuscode,
                                formatted: transcript.statuscode_Formatted,
                            },
                            name: transcript.pfn_name,
                            isAudited: transcript.pfn_audited,
                            auditReason: {
                                value: transcript.pfn_auditreason,
                                formatted: transcript.pfn_auditreason_Formatted || 'NA',
                            },
                            creditsAuditted: {
                                value: transcript.pfn_totalauditverifiedcredits,
                                formatted: transcript.pfn_totalauditverifiedcredits_Formatted || '0.00',
                            },
                            renewalYear: transcript._pfn_renewalyearid_value_Formatted,
                            cycle: {
                                start: {
                                    value: transcript.pfn_startdate,
                                    formatted: transcript.pfn_startdate_Formatted,
                                },
                                end: {
                                    value: transcript.pfn_enddate,
                                    formatted: transcript.pfn_enddate_Formatted,
                                },
                            },
                            courses: [],
                        };

                        if (transcript.courses) {
                            const courses: CourseState[] = [];

                            transcript.courses.forEach((course) => {
                                const courseToAdd: CourseState = {
                                    id: course.pfn_cecourseid,
                                    transcriptId: course._pfn_cetranscriptid_value,
                                    name: course.pfn_name,
                                    creditsSubmitted: {
                                        value: course.pfn_creditssubmitted,
                                        formatted: course.pfn_creditssubmitted_Formatted,
                                    },
                                    creditsApproved: {
                                        value: course.pfn_creditsapproved,
                                        formatted: course.pfn_creditsapproved_Formatted
                                            ? course.pfn_creditsapproved_Formatted
                                            : '---',
                                    },
                                    creditsAuditted: {
                                        value: course.pfn_auditverifiedcredits,
                                        formatted: course.pfn_auditverifiedcredits_Formatted
                                            ? course.pfn_auditverifiedcredits_Formatted
                                            : '---',
                                    },
                                    startDate: {
                                        value: course.pfn_startdate1,
                                        formatted: course.pfn_startdate1_Formatted,
                                        converted: convertIsoDateToString(course.pfn_startdate1),
                                    },
                                    endDate: {
                                        value: course.pfn_enddate1,
                                        formatted: course.pfn_enddate1_Formatted,
                                        converted: convertIsoDateToString(course.pfn_enddate1),
                                    },
                                    status: {
                                        value: course.statuscode,
                                        formatted: course.statuscode_Formatted,
                                    },
                                    sponsor: course.pfn_sponsor,
                                    speaker: course.pfn_speaker,
                                    location: course.pfn_location,
                                    uploadedDocs: [],
                                };

                                if (course.uploadedDocs) {
                                    const uploadedDocs: UploadedDocState[] = [];

                                    course.uploadedDocs.forEach((doc) => {
                                        const uploadedDocToAdd: UploadedDocState = {
                                            id: doc.annotationid,
                                            fileName: doc.filename,
                                        };

                                        uploadedDocs.push(uploadedDocToAdd);
                                    });

                                    courseToAdd.uploadedDocs = uploadedDocs;
                                }

                                courses.push(courseToAdd);
                            });

                            transcriptToAdd.courses = courses;
                        }

                        state.transcripts.push(transcriptToAdd);
                    });
                }
            }
        });
    },
});

const { setUsername } = userSlice.actions;

const selectUser = (state: RootState) => state.user;

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export {
    userSlice,
    loginUser,
    refreshAccessToken,
    logoutUser,
    logoutUserAll,
    updatePhone,
    updateAddress,
    updateMailingAddress,
    updatePersonalInfo,
    updatePermission,
    changeEmail,
    changeMainEmail,
    deleteEmail,
    addFacility,
    removeFacility,
    setPrimaryFacility,
    updateLocum,
    updateFacilityDetails,
    addCourse,
    editCourse,
    deleteCourse,
    uploadDocuments,
    deleteDocument,
    createRenewalApp,
    refreshUser,
    setUsername,
    selectUser,
};

/* -------------------------------------------------------------------------- */
