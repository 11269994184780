/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { KTSVG } from '../../../helpers';
import { DropdownCustom5 } from '../../content/dropdown/DropdownCustom5';
import { useAppSelector } from '../../../../app/redux/hooks';
import { TranscriptState } from '../../../../app/redux/slices/UserSlice';

type Props = {
    className: string;
};

const ListsWidgetCustom2: React.FC<Props> = ({ className }) => {
    const user = useAppSelector((state) => state.user);
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

    // const getCurrentCECycle = () => {
    //     let mostRecentTranscript: TranscriptState | null = null;
    //     for (let i = 0; i < user.transcripts.length; i++) {
    //         if (!mostRecentTranscript) {
    //             mostRecentTranscript = user.transcripts[i];
    //         } else if (
    //             user.transcripts[i].renewalYear &&
    //             mostRecentTranscript.renewalYear &&
    //             (user.transcripts[i].renewalYear as string) > mostRecentTranscript.renewalYear
    //         ) {
    //             mostRecentTranscript = user.transcripts[i];
    //         }
    //     }

    //     if (!mostRecentTranscript) {
    //         return null;
    //     }

    //     const startdate = new Date(mostRecentTranscript.cycle.start.value as string);
    //     const enddate = new Date(mostRecentTranscript.cycle.end.value as string);

    //     if (!startdate || !enddate) {
    //         return 'N/A';
    //     }

    //     return `${monthNames[startdate.getMonth()]} ${startdate.getDate()}, ${startdate.getFullYear()} - ${
    //         monthNames[enddate.getMonth()]
    //     } ${enddate.getDate()}, ${enddate.getFullYear()}`;
    // };

    return (
        <div className='card card-xl-stretch mb-5 mb-xl-8'>
            {/* begin::Header */}
            <div className='card-header border-0'>
                <h3 className='card-title fw-bolder text-dark'>
                    Current CE Cycle: <span className='fw-normal ps-2'>{process.env.REACT_APP_CE_CYCLE || 'N/A'}</span>
                </h3>
                <div className='card-toolbar'>
                    {/* begin::Menu */}
                    <button
                        type='button'
                        className='btn btn-sm btn-icon btn-color-primary btn-active-light'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                    >
                        <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                    </button>
                    <DropdownCustom5 />
                    {/* end::Menu */}
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body pt-0'>
                <div className='note-card d-flex align-items-center bg-light rounded p-5 mb-3'>
                    <div className='note-card-inner flex-grow-1 me-2'>
                        <div className='text-gray-800'>
                            All registrants are required to complete 30 CE hours every 2-year cycle, and to report their
                            annual CE hours during registration renewal. The current 2-year CE cycle ends on{' '}
                            {process.env.REACT_APP_CE_EXPIRY}. CE hours can be reported in the CE Transcripts section,
                            by clicking on the most recent transcript and selecting “Add Course”. If you have any
                            problems or questions, please contact us at 604-929-7090 or{' '}
                            <a className='text-decoration-underline fw-bold' href='mailto:reception@cvbc.ca'>
                                reception@cvbc.ca
                            </a>
                            .
                        </div>
                    </div>
                </div>
            </div>
            {/* end::Body */}
        </div>
    );
};

export { ListsWidgetCustom2 };
