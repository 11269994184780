/**
 * This source code is the confidential, proprietary information of
 * PilotFish, you may not disclose such information,
 * and may only use it in accordance with the terms of the license
 * agreement you entered into with PilotFish.
 *
 * PilotFish
 * All Rights Reserved.
 *
 * @file app/pages/certificate-licensure/CertificateLicensure.tsx
 * @author Jesse Zonneveld
 * @description Certificate licensure page
 */

/* --------------------------------- IMPORTS -------------------------------- */

import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { PageTitle } from '../../../../_metronic/layout/core';
import limitationApi from '../../../api/limitationApi';
import { useAppSelector } from '../../../redux/hooks';
import { Limitation } from '../../../types';

/* -------------------------------------------------------------------------- */

/* -------------------------------- COMPENENT ------------------------------- */

const CertificateLicensurePage: FC = () => {
    const user = useAppSelector((state) => state.user);
    const [limitaions, setLimitations] = useState<Limitation[]>();
    const [certificateReady, setCertificateReady] = useState<boolean>(false);

    useEffect(() => {
        const getLimitationsAsync = async () => {
            const { data } = await limitationApi.getLimitations();
            setLimitations(data);
            setCertificateReady(true);
        };

        getLimitationsAsync();

        return () => {
            setLimitations([]);
            setCertificateReady(false);
        };
    }, []);

    const isLimitationExpired = (isoDate: string | null) => {
        if (isoDate) {
            return new Date(isoDate) < new Date();
        } else {
            return false;
        }
    };

    // const limitaionsTest: Limitation[] = [
    //     {
    //         pfn_name: 'Controlled Substances',
    //         pfn_expirydate: '',
    //         pfn_limitationid: '',
    //         pfn_notes:
    //             '(1) Must work under indirect supervision of a CVBC-approved registrant; (2) limited to working no more than 40 hours per week and will not enter into solo practice nor perform rotating shift work;; (3) not eligible to assume role of Designated Registrant of a practice facility; (4) must not order, handle, dispense or process the waste/breakage of opioids, sedatives or any other drugs with potential for abuse.',
    //     },
    //     {
    //         pfn_name: 'Use or prescribe therapeutic substances',
    //         pfn_expirydate: '2020-08-31T07:00:00.000Z',
    //         pfn_limitationid: '',
    //         pfn_notes:
    //             'May not use, prescribe, recommend, or administer any drug or therapeutic substance that has not been approved for distribution in Canada by Health Canada (such as a drug (DIN), pesticide (PC No), or natural Health product (NHP)).',
    //     },
    //     {
    //         pfn_name: 'Controlled Substances',
    //         pfn_expirydate: '2025-08-31T07:00:00.000Z',
    //         pfn_limitationid: '',
    //         pfn_notes:
    //             '(1) Limited to practice 40 hours a week; (2) Storage of opiates, opioids, benzodiazepines, phenobarbital, and all other controlled substances in a locked safe without access with the exception of tramadol. The clinics tramadol supply that is to be used for dispensing to patients may be stored outside the safe; and (3) restricted for administering, disposing of, ordering, managing, or prescribing opiates, opioids, benzodiazepines, phenobarbital, and all other controlled substances with the exception that Phenobarbital may be administer on condition that a technical or associate draw up the medication and dispose of it after registrant administers it. Tramadol may be prescribed and dispensed from the clinic supply.',
    //     },
    //     {
    //         pfn_name: 'Controlled Substances',
    //         pfn_expirydate: '2025-08-31T07:00:00.000Z',
    //         pfn_limitationid: '',
    //         pfn_notes: '',
    //     },
    // ];

    return (
        <>
            {user.statusHistory.subcategory.formatted === 'Applicant' ? (
                <div>You are still an applicant, so you do not have a certificate of licensure yet.</div>
            ) : user.statusHistory.status.formatted !== 'Active' ? (
                <div>This section is restricted to active members only</div>
            ) : certificateReady ? (
                <>
                    <div className='d-flex justify-content-center'>
                        <div className='certificate-container mb-10 overflow-auto'>
                            <div
                                className='certificate-license border border-primary d-flex flex-column justify-content-center align-items-center py-12 px-15 mx-10'
                                style={{
                                    backgroundImage: `url(${toAbsoluteUrl('/media/logos/cvbc_logo_black.jpeg')})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: '45%',
                                    backgroundPosition: 'center',
                                    backgroundColor: 'rgba(355, 355, 355, 0.95)',
                                    backgroundBlendMode: 'lighten',
                                }}
                            >
                                <h1 className='text-center'>College of Veterinarians of British Columbia</h1>
                                <h4 className='text-primary border-bottom border-primary mb-10'>
                                    ANNUAL CERTIFICATE OF LICENSURE
                                </h4>
                                <div className='text-center mb-10'>
                                    The person whose name appears below is a duly qualified veterinarian registered by
                                    the College of Veterinarians of British Columbia pursuant to the Veterinarians Act
                                    in the {user.statusHistory.category.formatted}.
                                </div>
                                <div className='details d-flex justify-content-between mb-10'>
                                    <div className='pe-15'>
                                        <div>
                                            <span className='fw-bolder'>Name: </span>
                                            <span>
                                                {user.firstname} {user.lastname}
                                            </span>
                                        </div>
                                        <div>
                                            <span className='fw-bolder'>Registration Category: </span>
                                            <span>
                                                {user.statusHistory.category.formatted},{' '}
                                                {user.statusHistory.subcategory.formatted}
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <span className='fw-bolder'>Valid Until: </span>
                                            <span>{user.statusHistory.expiryDate.converted}</span>
                                        </div>

                                        <div>
                                            <span className='fw-bolder'>Registration Number: </span>
                                            <span>{user.registrationNumber}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='details'>
                                    <div className='pb-2'>
                                        <span className='fw-bolder'>
                                            Licensure restrictions, conditions or limitations:{' '}
                                        </span>
                                        {!limitaions || (limitaions && limitaions.length === 0) ? 'None' : ''}
                                    </div>
                                    <div className='mb-5'>
                                        <ul>
                                            {limitaions &&
                                                limitaions.length > 0 &&
                                                limitaions.map((limitation, i) => {
                                                    if (
                                                        !isLimitationExpired(limitation.pfn_expirydate) &&
                                                        limitation.pfn_notes
                                                    ) {
                                                        return (
                                                            <li key={i} className='pb-2'>
                                                                <b>{limitation.pfn_name}: </b> {limitation.pfn_notes}
                                                            </li>
                                                        );
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                        </ul>
                                    </div>
                                </div>

                                <div className='d-flex align-items-center ps-5'>
                                    <span className='fw-bolder'>Registrar: </span>
                                    {/* <img
                                        alt='Megan Signature'
                                        className='h-40px'
                                        src={toAbsoluteUrl('/media/signatures/megan-signature.jpeg')}
                                    /> */}
                                    <img
                                        alt='Stacey Signature'
                                        className='h-30px ps-2 pb-1'
                                        src={toAbsoluteUrl('/media/signatures/christine_arnold_signature.png')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center no-print'>
                        <button className='btn btn-primary me-5' onClick={() => window.print()}>
                            Print
                        </button>
                        <Link to='/dashboard'>
                            <button className='btn btn-primary'>Back</button>
                        </Link>
                    </div>
                </>
            ) : (
                <div>Setting up certificate, please wait...</div>
            )}
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- WRAPPER -------------------------------- */

const CertificateLicensureWrapper: FC = () => {
    const intl = useIntl();
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.CERTIFICATELICENSURE' })}</PageTitle>
            <CertificateLicensurePage />
        </>
    );
};

/* -------------------------------------------------------------------------- */

/* --------------------------------- EXPORTS -------------------------------- */

export { CertificateLicensureWrapper };

/* -------------------------------------------------------------------------- */
